import {
  Button,
  Card,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CreateNewAssociation = () => {
  const [BrandData, setBrandData] = useState([]);
  const [CategoryData, setCategoryData] = useState([]);
  const [categories, setSelectedCheckbox] = useState([]);
  const [brand, setBrand] = useState();
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const handleGetAllBrands = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllBrandAttributes"
      );
      if (res) {
        // console.log(res);
        setBrandData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle All Root Categories

  const handleGetAllCategories = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllSubCategoriesForRoot"
      );
      if (res) {
        // console.log("res", res.data[0]);
        setCategoryData(res.data[0].subcategories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle create caegory association

  const createCategoryAssociation = async () => {
    try {
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/createCategoryAssociation",
        {
          brand,
          categories,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Category Association Created SuccessFully",
          showConfirmButton: true,
          timer: 1500,
        });
        navigate("/category-association");
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
        timer: 1500,
      });
    }
  };

  const handleGetAllCategoryAssociations = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllCategoryAssociations"
      );
      if (res) {
        // console.log(res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllBrands();
    handleGetAllCategories();
    handleGetAllCategoryAssociations();
  }, []);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const categoryName = e.target.value;
    setSelectedCheckbox((prevState) => {
      if (isChecked) {
        return [...prevState, categoryName];
      } else {
        return prevState.filter((category) => category !== categoryName);
      }
    });
  };

  const availableBrands = BrandData.filter((brand) => {
    return !data.find((item) => item.brand === brand.name);
  });

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", ml: 3, mr: 3 }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Category Association
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/category-association")}
        >
          Back
        </Button>
      </Box>
      <Card
        elevation={0}
        style={{ border: "1px solid #dddddd" }}
        sx={{ m: 3, p: 2 }}
      >
        <Box sx={{ mt: 2 }}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "100px",
              marginBottom: "30px",
            }}
          >
            <Typography>Brand : </Typography>
            <Select
              displayEmpty
              sx={{ width: "400px" }}
              size="small"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
            >
              <InputLabel sx={{ ml: 2 }}>Select</InputLabel>
              {availableBrands.map((brand) => (
                <MenuItem value={brand.name}>{brand.name}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", gap: "50px" }}>
            <Typography>Main Category : </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                ml: -3,
              }}
            >
              {CategoryData.map((category) => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    value={category.name}
                    onChange={handleCheckboxChange}
                    // checked={selectedCheckbox.includes(category.name)}
                  />
                  <Typography>{category.name}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Button
          variant="contained"
          onClick={createCategoryAssociation}
          sx={{ mt: 2 }}
        >
          SUBMIT
        </Button>
      </Card>
    </div>
  );
};

export default CreateNewAssociation;
