import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import { useNavigate } from "react-router-dom";

const HomePageTable = ({ newdata }) => {
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  // console.log(modules);

  const handleGetAllHomepageModules = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/handleGetAllHomepageModules"
      );
      if (res.data) {
        console.log(res.data);
        setModules(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllHomepageModules();
  }, []);

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const updatedModules = Array.from(modules);
    const [movedModule] = updatedModules.splice(result.source.index, 1);
    updatedModules.splice(result.destination.index, 0, movedModule);

    setModules(updatedModules);

    try {
      // Make an API request to update the module order in the database
      await axios.put(
        "https://rccore-backend.cstechns.com/api/admin/updateHomepageModuleOrder",
        {
          moduleOrder: updatedModules.map((module) => module._id),
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (moduleId) => {
    try {
      const res = await axios.delete(
        `https://rccore-backend.cstechns.com/api/admin/handleDeleteHomepageModule/${moduleId}`
      );
      if (res) {
        const filteredModules = modules.filter(
          (module) => module._id !== moduleId
        );
        setModules(filteredModules);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {!loading ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <TableContainer
            component={Paper}
            sx={{
              mt: 3,
              width: "96.5%",
              ml: 3,
              mb: 3,
              border: "1px solid #dddddd",
            }}
          >
            <Table>
              <TableHead style={{ background: "#dddddd" }}>
                <TableRow>
                  <TableCell>Module Type</TableCell>
                  <TableCell>Module Name</TableCell>
                  {/* <TableCell>Module Image</TableCell> */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <Droppable droppableId="moduleList">
                {(provided) => (
                  <TableBody
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {modules.map((module, index) => (
                      <Draggable
                        key={module._id}
                        draggableId={module._id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              opacity: snapshot.isDragging ? 0.5 : 1,
                            }}
                          >
                            <TableCell>{module.moduleType}</TableCell>

                            <TableCell>{module.moduleName}</TableCell>
                            {/* <TableCell>
                              <img src={module.image} alt="" width="120px" />
                            </TableCell> */}

                            <TableCell>
                              {/* <Tooltip title="Edit">
                                <IconButton>
                                  <FiEdit size={20} />
                                </IconButton>
                              </Tooltip> */}
                              <Tooltip title="Manage">
                                <IconButton
                                  onClick={() => {
                                    // console.log(module.moduleName);
                                    if (
                                      module.moduleName ===
                                      modules[index].moduleName
                                    ) {
                                      navigate(
                                        `/theme-customization/manage-${module.moduleName}`
                                      );
                                    }
                                  }}
                                >
                                  <WysiwygIcon />
                                </IconButton>
                              </Tooltip>

                              {/* <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => handleDelete(module._id)}
                                >
                                  <MdDelete
                                    size={22}
                                    style={{ color: "red" }}
                                  />
                                </IconButton>
                              </Tooltip> */}
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </TableContainer>
        </DragDropContext>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default HomePageTable;
