import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const EditProductTypeValues = () => {
  const [productTypeData, setProductTypeData] = useState([]);
  const [MainAttributesData, setMainAttributesData] = useState([]);
  const [CustomAttributesData, setCustomAttributesData] = useState([]);
  const [ProductTypeValuesData, setProductTypeValuesData] = useState([]);
  const [data, setData] = useState({});
  const [newData, setNewData] = useState({});

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  //   console.log("selectedCheckbox", SelectedCheckboxForCustom);
  const handleGetAllProductTypes = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllProductTypesAttributes"
      );
      if (res) {
        setProductTypeData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // get all Main Attributes

  const getAllMainAttributes = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllMainProductAttributes"
      );
      if (res) {
        // console.log(res);
        setMainAttributesData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // get All custom Attributes
  const getAllCustomAttributes = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllCustomAttributes"
      );
      if (res) {
        // console.log(res);
        setCustomAttributesData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // get ProductTypeValues by id

  const handleGetProductTypeValuesById = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getProductTypeValuesById/${id}`
      );
      if (res) {
        // console.log(res.data.ProductType);
        setNewData(res.data.ProductType);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // handle Create Product Type Values

  const handleUpdateProductTypeValues = async () => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/UpdateProductTypeVaues/${id}`,
        {
          ProductType: data.ProductType,
          MainAttributes: data.MainAttributes,
          CustomAttributes: data.CustomAttributes,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Product Type Values Updated successfully",
          showConfirmButton: true,
          timer: 1500,
        });
        navigate("/product-type-values");
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
        timer: 1500,
      });
    }
  };

  const handleAllGetProductTypeValues = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllProductTypesValues"
      );
      if (res) {
        setProductTypeValuesData(res.data);
        // console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllProductTypes();
    getAllMainAttributes();
    getAllCustomAttributes();
    handleGetProductTypeValuesById();
    handleAllGetProductTypeValues();
  }, []);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const categoryName = e.target.value;
    setData((prevState) => {
      const updatedCategories = isChecked
        ? [...prevState.MainAttributes, categoryName]
        : prevState.MainAttributes.filter(
            (category) => category !== categoryName
          );
      return {
        ...prevState,
        MainAttributes: updatedCategories,
      };
    });
  };
  const handleCheckboxChangeforCustom = (e) => {
    const isChecked = e.target.checked;
    const categoryName = e.target.value;
    setData((prevState) => {
      const updatedCategories = isChecked
        ? [...prevState.CustomAttributes, categoryName]
        : prevState.CustomAttributes.filter(
            (category) => category !== categoryName
          );
      return {
        ...prevState,
        CustomAttributes: updatedCategories,
      };
    });
  };

  const availableProductTypes = productTypeData.filter((brand) => {
    return (
      brand.name === newData ||
      !ProductTypeValuesData.find((item) => item.ProductType === brand.name)
    );
  });

  return (
    <div style={{ marginTop: "150px" }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              ml: 3,
              mr: 3,
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Product Type Values
            </Typography>
            <Button variant="contained" onClick={() => navigate(-1)}>
              Back
            </Button>
          </Box>

          <Card elevation={0} sx={{ border: "1px solid #dddddd", m: 3, p: 2 }}>
            <Box sx={{ mt: 3 }}>
              <Box sx={{ display: "flex", gap: "70px", alignItems: "center" }}>
                <Typography>Product Type : </Typography>
                <Select
                  sx={{ width: "400px" }}
                  size="small"
                  value={data.ProductType || ""}
                  onChange={(e) =>
                    setData({ ...data, ProductType: e.target.value })
                  }
                >
                  {availableProductTypes.map((product) => (
                    <MenuItem value={product.name}>{product.name}</MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "50px",
                  marginTop: "40px",
                }}
              >
                <Typography>Main Attributes : </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "10px",
                    ml: 0.5,
                  }}
                >
                  {MainAttributesData.map((mainAttribute) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {mainAttribute.hide === false ? (
                        <>
                          <Checkbox
                            value={mainAttribute.AttributeName}
                            onChange={handleCheckboxChange}
                            checked={
                              data.MainAttributes?.includes(
                                mainAttribute.AttributeName
                              ) || ""
                            }
                          />
                          <Typography>{mainAttribute.AttributeName}</Typography>
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "65px",
                  marginTop: "40px",
                }}
              >
                <Typography>Custom Attributes : </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "10px",
                    ml: -5,
                  }}
                >
                  {CustomAttributesData.map((CustomAttribute) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        value={CustomAttribute.name}
                        onChange={handleCheckboxChangeforCustom}
                        checked={
                          data.CustomAttributes?.includes(
                            CustomAttribute.name
                          ) || ""
                        }
                      />
                      <Typography>{CustomAttribute.name}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "170px", mt: 2 }}>
                <Typography></Typography>
                <Button
                  variant="contained"
                  onClick={handleUpdateProductTypeValues}
                >
                  SUBMIT
                </Button>
              </Box>
            </Box>
          </Card>
        </>
      )}
    </div>
  );
};

export default EditProductTypeValues;
