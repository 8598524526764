import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const UpdateCategoryAssociation = () => {
  const [BrandData, setBrandData] = useState([]);
  const [CategoryData, setCategoryData] = useState([]);
  const [CategoryAssociationData, setCategoryAssociationData] = useState([]);

  const [data, setData] = useState({});
  const [newData, setNewData] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const handleGetAllBrands = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllBrandAttributes"
      );
      if (res) {
        // console.log(res);
        setBrandData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // handle All Root Categories

  const handleGetAllCategories = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllSubCategoriesForRoot"
      );
      if (res) {
        // console.log("res", res.data[0]);
        setCategoryData(res.data[0].subcategories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get Categorory Association by id
  const getCategororyAssociationById = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getCategoryAssociationById/${id}`
      );
      if (res) {
        setNewData(res.data.brand);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllCategoryAssociations = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllCategoryAssociations"
      );
      if (res) {
        // console.log(res);
        setCategoryAssociationData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handle Update Data

  const handleUpdateCategoryAssociation = async () => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/UpdateCategoryAssociation/${id}`,
        {
          brand: data.brand,
          categories: data.categories,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res.data.message,
          showConfirmButton: true,
          timer: 1500,
        });
        navigate("/category-association");
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
        timer: 1500,
      });
    }
  };
  useEffect(() => {
    handleGetAllBrands();
    handleGetAllCategories();
    getCategororyAssociationById();
    handleGetAllCategoryAssociations();
  }, []);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const categoryName = e.target.value;
    setData((prevState) => {
      const updatedCategories = isChecked
        ? [...prevState.categories, categoryName]
        : prevState.categories.filter((category) => category !== categoryName);
      return {
        ...prevState,
        categories: updatedCategories,
      };
    });
  };

  const availableProductTypes = BrandData.filter((brand) => {
    return (
      brand.name === newData ||
      !CategoryAssociationData.find((item) => item.brand === brand.name)
    );
  });

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", ml: 3, mr: 3 }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Category Association
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/category-association")}
        >
          Back
        </Button>
      </Box>
      {!loading ? (
        <Card
          elevation={0}
          style={{ border: "1px solid #dddddd" }}
          sx={{ m: 3, p: 2 }}
        >
          <Box sx={{ mt: 2 }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "100px",
                marginBottom: "30px",
              }}
            >
              <Typography>Brand : </Typography>
              <TextField
                value={data?.brand ?? ""}
                sx={{ width: "400px" }}
                size="small"
              />
            </Box>
            <Box style={{ display: "flex", alignItems: "center", gap: "50px" }}>
              <Typography>Main Category : </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  ml: -3,
                }}
              >
                {CategoryData.map((category) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      value={category.name}
                      onChange={handleCheckboxChange}
                      checked={data.categories?.includes(category.name) || ""}
                    />
                    <Typography>{category.name}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Button
            variant="contained"
            onClick={handleUpdateCategoryAssociation}
            sx={{ mt: 2 }}
          >
            UPDATE
          </Button>
        </Card>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default UpdateCategoryAssociation;
