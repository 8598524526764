import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  handleGetAllBuyerStaticPages,
  handleGetAllSellerStaticPages,
} from "../../../axios";
import BuyerStaticTable from "./SellerStaticTable";
import SellerStaticTable from "./SellerStaticTable";

const SellerStatic = () => {
  const [selected, setSelected] = useState("By Heading");
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleGetAllStaticPages = async () => {
    setLoading(true);
    try {
      const res = await handleGetAllSellerStaticPages();
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  };

  const handleSearch = () => {
    let filteredData = data;
    if (selected === "By Heading") {
      filteredData = filteredData.filter((row) =>
        row.Heading.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (selected === "By Link Name") {
      filteredData = filteredData.filter((row) =>
        row.Link.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (selected === "By PageLink") {
      filteredData = filteredData.filter((row) =>
        row.Page.includes(searchQuery)
      );
    }

    return filteredData;
  };

  useEffect(() => {
    handleSearch();
  }, [searchQuery, data, selected]);

  useEffect(() => {
    handleGetAllStaticPages();
  }, []);

  const handleCreatePage = () => {
    navigate("/create-Seller-static-pages");
  };

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Seller Static Pages
        </Typography>
        <Button variant="contained" onClick={handleCreatePage}>
          Create
        </Button>
      </Box>
      <Box sx={{ mt: 3, ml: 3, mr: 3 }}>
        <Typography>Search By</Typography>
      </Box>
      <Box
        sx={{
          ml: 3,
          mr: 3,
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Box>
          <Select
            size="small"
            sx={{ width: "300px" }}
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
          >
            <MenuItem value="By Heading">By Heading</MenuItem>
            <MenuItem value="By Link Name">By Link Name</MenuItem>
            <MenuItem value="By PageLink">By PageLink</MenuItem>
          </Select>
        </Box>
        <Box>
          <TextField
            sx={{ width: "300px" }}
            size="small"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <SellerStaticTable data={handleSearch()} setData={setData} Loading={Loading}/>
      </Box>
    </div>
  );
};

export default SellerStatic;
