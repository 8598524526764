import { Box, Button, Card, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const Themes = () => {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "150px" }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ml: 3,
            mr: 3,
          }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
            Theme Customizations
          </Typography>
          <Button variant="contained">BAck</Button>
        </Box>
        <Box>
          <Card
            elevation={0}
            sx={{
              border: "1px solid #dddddd",
              width: "94%",
              ml: 3,
              mt: 3,
              p: 2,
              background: "#333333",
            }}
          >
            <Typography
              sx={{ fontSize: "15px", fontWeight: "bold", color: "#fff" }}
            >
              Buyer Theme Customization
            </Typography>
          </Card>
          <Box
            sx={{
              ml: 3,
              mt: 1,
              mr: 3,
              display: "flex",
              gap: "30px",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                width: "307px",
                border: "1px solid #dddddd",
                color: "#000",
              }}
              onClick={() => navigate("/theme-customization/Home-page")}
            >
              HomePage
            </Button>
            <Button
              variant="outlined"
              sx={{
                width: "307px",
                border: "1px solid #dddddd",
                color: "#000",
              }}
              onClick={() => navigate("/theme-customization/PDP-customization")}
            >
              Product Details Page
            </Button>
            <Button
              variant="outlined"
              sx={{
                width: "307px",
                border: "1px solid #dddddd",
                color: "#000",
              }}
              onClick={() =>
                navigate("/theme-customization/category-listing-customization")
              }
            >
              Category Listing
            </Button>
            <Button
              variant="outlined"
              sx={{
                width: "307px",
                border: "1px solid #dddddd",
                color: "#000",
              }}
              onClick={() =>
                navigate("/theme-customization/Search-result-customization")
              }
            >
              Search Listing
            </Button>
            <Button
              variant="outlined"
              sx={{
                width: "307px",
                border: "1px solid #dddddd",
                color: "#000",
              }}
              onClick={() =>
                navigate("/theme-customization/seller-Profile-customization")
              }
            >
              Seller Profile
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Themes;
