import { Card, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import SelectedProducts from "./SelectedProducts";
import UnselectedProducts from "./UnselectedProducts";

const ManageBestSellingProducts = () => {
  return (
    <div>
      <Box>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold", ml: 4, mt: 4 }}>
          Manage Product Combo
        </Typography>
        <Card
          elevation={0}
          sx={{
            border: "1px solid #dddddd",
            p: 3,
            m: 4,
            display: "flex",
            gap: "20px",
          }}
        >
          <Box sx={{ width: "49%" }}>
            <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
              Un Selected Products
            </Typography>
            <Divider />
            <Box sx={{ mt: 2 }}>
              <UnselectedProducts />
            </Box>
          </Box>
          <Box sx={{ width: "49%" }}>
            <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
              Selected Products
            </Typography>
            <Divider />
            <Box sx={{ mt: 2 }}>
              <SelectedProducts />
            </Box>
          </Box>
        </Card>
      </Box>
    </div>
  );
};

export default ManageBestSellingProducts;
