import { Button, Card, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const CreateNewColorAttribute = () => {
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [sortingId, setSortingId] = useState();

  const navigate = useNavigate();
  const { id } = useParams();

  const handleCreateNewColorAttributes = async () => {
    try {
      const formData = new FormData();
      formData.append("file", image);
      formData.append("upload_preset", "insta-clone");
      formData.append("cloud_name", "bunny1705");
      formData.append("folder", "Color_images");

      const response = await fetch(
        "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      console.log(data);
      const logoUrl = data.secure_url;
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/createNewColorAttributes",
        {
          name,
          image: logoUrl,
          sortingId,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Color Attribute Created Successully",
          showConfirmButton: true,
          timer: 1500,
        });
        navigate(`/manage-Color-attributes/${id}`);
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
        timer: 1500,
      });
    }
  };
  return (
    <div style={{ marginTop: "150px" }}>
      <Card
        sx={{ maxWidth: "450px", p: 2 }}
        style={{ margin: "auto", marginTop: "30px" }}
      >
        <Typography
          sx={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}
        >
          Create New Color Attribute
        </Typography>
        <Box>
          <TextField
            label="Name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          />
          <Box>
            <Typography sx={{ fontSize: "17px" }}>Image</Typography>
            <TextField
              type="file"
              inputProps={{ accept: ".jpeg,.png,.jpg,.gif" }}
              onChange={(e) => setImage(e.target.files[0])}
              fullWidth
              sx={{ mb: 2, mt: 0.5 }}
            />
            {image && (
              <img
                src={URL.createObjectURL(image)}
                alt={image.name}
                width="120px"
                style={{ margin: "10px" }}
              />
            )}
          </Box>
          <TextField
            label="Sorting Id"
            placeholder="Sorting Id"
            value={sortingId}
            onChange={(e) => setSortingId(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Box>
        <Button
          variant="contained"
          fullWidth
          onClick={handleCreateNewColorAttributes}
        >
          SUBMIT
        </Button>
      </Card>
    </div>
  );
};

export default CreateNewColorAttribute;
