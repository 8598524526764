import { Box, Button, InputLabel, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllSellers } from "../../../axios";
import Swal from "sweetalert2";

const AddSingleListing = () => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const navigate = useNavigate();

  const handleGetAllSellers = async () => {
    try {
      const res = await getAllSellers();
      if (res.status === 200) {
        setData(res.data);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllSellers();
  }, []);

  const handleSelectChange = (event, value) => {
    const selectedSeller = data.find((item) => item.fullName === value);
    setSelected(selectedSeller?._id ?? null);
    setSelectedName(selectedSeller?.fullName || null);
  };

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
          height: "100%",
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Add Single Listing
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate(`/AddSingleListingForSeller/${selected}`)}
        >
          Next
        </Button>
      </Box>
      <Box sx={{ ml: 3, mt: 3 }}>
        <InputLabel>Select Seller Display Name</InputLabel>
        <Autocomplete
          sx={{ width: "450px", mt: 0.5 }}
          size="small"
          value={selectedName || ""}
          onChange={(event, value) => handleSelectChange(event, value)}
          options={data
            .filter((item) => item.approved === true)
            .map((item) => `${item.fullName}`)}
          renderInput={(params) => <TextField {...params} label="Select" />}
        />
      </Box>
    </div>
  );
};

export default AddSingleListing;
