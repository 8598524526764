import React, { useState } from "react";
import { Box, Button, CircularProgress, IconButton } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FileUpload } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

function UploadMidBannerImages() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setSelectedFiles(acceptedFiles);
    },
  });

  const uploadImageToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "insta-clone");
    formData.append("cloud_name", "bunny1705");

    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const uploadedImage = response.data.secure_url;
      return uploadedImage;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const uploadedImages = [];

      for (const file of selectedFiles) {
        const uploadedImage = await uploadImageToCloudinary(file);
        uploadedImages.push(uploadedImage);
      }

      await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/handleCreateMidBannerImages",
        {
          images: uploadedImages,
        }
      );

      navigate("/theme-customization/manage-Mid Banner");
      setSelectedFiles([]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "150px",
          flexDirection: "column",
          ml: 3,
          mr: 3,
          border: "2px dashed #ccc",
          borderRadius: "4px",
          padding: "20px",
          cursor: "pointer",
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p>Drag and drop Mid Banner Images here or click</p>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        {selectedFiles.map((file, index) => (
          <Box
            key={index}
            sx={{
              width: "16.6667%",
              margin: "10px",
              position: "relative",
              paddingBottom: "1.6667%",
            }}
          >
            <div style={{ position: "relative", display: "inline-block" }}>
              <img
                src={URL.createObjectURL(file)}
                alt=""
                style={{
                  width: "140px",
                  marginLeft: "10px",
                  objectFit: "cover",
                }}
              />
              <IconButton
                style={{
                  position: "absolute",
                  top: "-20px",
                  right: "-15px",
                  background: "none",
                  border: "none",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={() => handleDelete(index)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <Button
          variant={selectedFiles.length > 0 ? "contained" : "outlined"}
          color="primary"
          disabled={selectedFiles.length === 0 || loading}
          onClick={handleSubmit}
          sx={{ marginTop: "40px" }}
        >
          {loading ? <CircularProgress size={24} /> : "Upload Images"}
        </Button>
      </Box>
    </>
  );
}

export default UploadMidBannerImages;
