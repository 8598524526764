import React from "react";
import styled from "@emotion/styled";
import { Card } from "@mui/material";
import { Box } from "@mui/system";
import Chart from "react-apexcharts";

const FlexBetween = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CardHeader = styled(FlexBetween)`
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 12px;
`;

const InventoryLineChart1 = ({ data }) => {
  const getCurrentMonthDays = () => {
    const today = new Date();
    const currentDay = today.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const categories = [];
    for (let i = 13; i >= 0; i--) {
      const date = new Date(
        today.getFullYear(),
        today.getMonth(),
        currentDay - i
      );
      const formattedDate = `${monthNames[date.getMonth()]}-${date.getDate()}`;
      categories.push(formattedDate);
    }
    return categories;
  };

  const getUsersCountByDay = () => {
    const today = new Date();
    const startDate = new Date();
    startDate.setDate(today.getDate() - 13); // Subtract 13 days to get the starting date

    const counts = [];
    for (let i = 0; i < 14; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);
      const formattedDate = currentDate.toISOString().split("T")[0];
      const userCount = data?.filter(
        (user) => user.createdAt.split("T")[0] === formattedDate
      ).length;
      counts.push(userCount);
    }
    return counts;
  };

  const options = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#1976d2", "#fbaf0f"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    title: {
      text: "Orders",
      align: "left",
    },
    grid: {
      // borderColor: "#fff",
      // row: {
      //   colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      //   opacity: 0.5,
      // },
    },
    markers: {
      size: 4,
      hover: {
        size: 6,
      },
    },
    xaxis: {
      categories: getCurrentMonthDays(),
      title: {
        text: "Orders in the Last 14 Days",
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: "",
      },
      // min: 5,
      // max: 40,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  const series = [
    {
      name: "Orders",
      data: getUsersCountByDay(),
    },
  ];

  return (
    <Card sx={{ boxShadow: 2, borderRadius: "20px" }}>
      <Box sx={{ m: 5 }}>
        <Chart options={options} series={series} type="line" height={400} />
      </Box>
    </Card>
  );
};

export default InventoryLineChart1;
