import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import FooterNavigationTable from "./FooterNavigationTable";

const FooterNavigation = () => {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Footer Navigation
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/create-footer-navigation")}
        >
          Create
        </Button>
      </Box>
      <Box>
        <FooterNavigationTable />
      </Box>
    </div>
  );
};

export default FooterNavigation;
