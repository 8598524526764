import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const ManageMoreLikeThis = ({ toast }) => {
  const [id, setId] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const handleGetAllMoreLikeThis = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/handleGetAllMoreLikeThis`
      );
      if (res) {
        // console.log(res);
        setId(res.data[0]._id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetMoreLikeThisById = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/handleGetMoreLikeThisById/${id}`
      );
      if (res) {
        // console.log(res.data);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateMoreLikeThis = async () => {
    setLoading1(true);
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/handleUpdateMoreLikeThis/${id}`,
        {
          Button1Text: data.Button1Text,
          Button1Url: data.Button1Url,
          Button1Color: data.Button1Color,
          Button2Text: data.Button2Text,
          Button2Url: data.Button2Url,
          Button2Color: data.Button2Color,
        }
      );
      if (res) {
        // console.log(res);
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };
  useEffect(() => {
    handleGetAllMoreLikeThis();
  }, []);

  useEffect(() => {
    handleGetMoreLikeThisById();
  }, [id]);
  return (
    <div style={{ marginTop: "150px" }}>
      {!loading ? (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ml: 3,
              mr: 3,
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Manage More Like This
            </Typography>
            <Button variant="contained">Publish</Button>
          </Box>
          <Box sx={{ mt: 2, ml: 3 }}>
            <Box>
              <Typography>Button1 Text</Typography>
              <TextField
                type="text"
                size="small"
                placeholder="Button1 Text"
                value={data.Button1Text}
                onChange={(e) =>
                  setData({ ...data, Button1Text: e.target.value })
                }
                sx={{ width: "450px", mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <Typography>Button1 Url</Typography>
              <TextField
                type="text"
                size="small"
                placeholder="Button1 Url"
                value={data.Button1Url}
                onChange={(e) =>
                  setData({ ...data, Button1Url: e.target.value })
                }
                sx={{ width: "450px", mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <Typography>Button1 Color</Typography>
              <TextField
                type="text"
                size="small"
                placeholder="Button1 Color"
                value={data.Button1Color}
                onChange={(e) =>
                  setData({ ...data, Button1Color: e.target.value })
                }
                sx={{ width: "450px", mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <Typography>Button2 Text</Typography>
              <TextField
                type="text"
                size="small"
                placeholder="Button2 Text"
                value={data.Button2Text}
                onChange={(e) =>
                  setData({ ...data, Button2Text: e.target.value })
                }
                sx={{ width: "450px", mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <Typography>Button2 Url</Typography>
              <TextField
                type="text"
                size="small"
                placeholder="Button2 Url"
                value={data.Button2Url}
                onChange={(e) =>
                  setData({ ...data, Button2Url: e.target.value })
                }
                sx={{ width: "450px", mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <Typography>Button2 Color</Typography>
              <TextField
                type="text"
                size="small"
                placeholder="Button2 Color"
                value={data.Button2Color}
                onChange={(e) =>
                  setData({ ...data, Button2Color: e.target.value })
                }
                sx={{ width: "450px", mb: 2, mt: 0.5 }}
              />
            </Box>
            <Button
              variant="contained"
              sx={{ width: "450px", mb: 3 }}
              onClick={handleUpdateMoreLikeThis}
              disabled={loading1}
            >
              {loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default ManageMoreLikeThis;
