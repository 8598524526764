import {
  Button,
  Card,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";

const Attribute = ({
  selectedId,
  handleChange,
  checked,
  setChecked,
  Color = "Colors",
  Size = "Sizes",
  Fit,
  singleProductData,
  setSingleProductData,
}) => {
  const [data, setData] = useState({});
  const [CustomAttributedata, setCustomAttributeData] = useState([]);
  const [MainAttributedata, setMainAttributeData] = useState([]);
  const [customAttributes, setCustomAttributes] = useState([]);
  const [MainAttributes, setMainAttributes] = useState([]);
  const [colorAttributes, setColorAttributes] = useState([]);
  const [SizeAttributes, setSizeAttributes] = useState([]);
  const [SizeCharts, setSizeCharts] = useState([]);
  const [BrandAttributes, setBrandAttributes] = useState([]);

  //   console.log(BrandAttributes);

  const handleGetAllProductTypesCategory = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getProductTypeValuesById/${selectedId}`
      );

      if (res) {
        setData(res.data);
        setCustomAttributeData(res.data.CustomAttributes);
        setMainAttributeData(res.data.MainAttributes);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetAllCustomAttribues = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getAllCustomAttributes`
      );

      if (res) {
        setCustomAttributes(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get handle All Main Attributes

  const handleGetAllMainAttribues = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getAllMainProductAttributes`
      );

      if (res) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        setMainAttributes(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetAllBrandAttribues = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getAllBrandAttributes`
      );

      if (res) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        // setMainAttributes(res.data);
        // console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetAllColorAttribues = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getAllColorAttributes`
      );

      if (res) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        // setMainAttributes(res.data);
        // console.log(res.data);
        setColorAttributes(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetAllSizeAttribues = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getAllSizeAttributes`
      );

      if (res) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        // setMainAttributes(res.data);
        // console.log(res.data);
        setSizeAttributes(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetAllSizeCharts = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getAllSizeCharts`
      );

      if (res) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        // setMainAttributes(res.data);
        // console.log(res.data);
        setSizeCharts(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetAllBrandAttributes = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getAllBrandAttributes`
      );

      if (res) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        // setMainAttributes(res.data);
        // console.log(res.data);
        setBrandAttributes(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckedChange = (e) => {
    const isChecked = e.target.checked;
    const categoryName = e.target.name;
    setSingleProductData((prevState) => {
      const newData = { ...prevState.data };
      if (isChecked) {
        newData[Color] = [...prevState.data[Color], categoryName];
      } else {
        newData[Color] = prevState.data[Color].filter(
          (size) => size !== categoryName
        );
      }

      return {
        ...prevState,
        data: newData,
      };
    });
  };
  const handleCheckedSizeChange = (e) => {
    const isChecked = e.target.checked;
    const categoryName = e.target.name;

    setSingleProductData((prevState) => {
      const newData = { ...prevState.data };

      if (isChecked) {
        newData[Size] = [...prevState.data[Size], categoryName];
      } else {
        newData[Size] = prevState.data[Size].filter(
          (size) => size !== categoryName
        );
      }

      return {
        ...prevState,
        data: newData,
      };
    });
  };

  //   console.log(newData);

  const productData = [];
  customAttributes.forEach((item, i) => {
    const dataItem = CustomAttributedata.find((data, i) => data === item.name);
    if (dataItem) {
      const res = customAttributes.find((item) => item.name === dataItem);
      productData.push(res);
    }
  });
  const productMainData = [];
  MainAttributes.forEach((item, i) => {
    const dataItem = MainAttributedata.find(
      (data, i) => data === item.AttributeName
    );
    if (dataItem) {
      const res = MainAttributes.find(
        (item) => item.AttributeName === dataItem
      );
      productMainData.push(res);
    }
  });

  //   console.log(productMainData);

  useEffect(() => {
    handleGetAllProductTypesCategory();
    handleGetAllCustomAttribues();
    handleGetAllMainAttribues();
    handleGetAllBrandAttribues();
    handleGetAllColorAttribues();
    handleGetAllSizeAttribues();
    handleGetAllSizeCharts();
    handleGetAllBrandAttributes();
  }, []);
  return (
    <div>
      <Box>
        <Card
          sx={{ maxWidth: "790px", border: "1px solid #dddddd", p: 2 }}
          elevation={0}
        >
          <Box sx={{ width: "600px" }}>
            {productData.map((item) => {
              // console.log("item", item);

              // const name = item.name;

              if (item.AttributType == "DropdownList") {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography>{item.name} : </Typography>
                    <Select
                      displayEmpty
                      sx={{ width: "400px" }}
                      size="small"
                      value={singleProductData.data[item.name] || ""}
                      name={item.name}
                      onChange={(e) =>
                        setSingleProductData({
                          ...singleProductData,
                          [item.name]: e.target.value,
                        })
                      }
                    >
                      <InputLabel sx={{ ml: 2 }}>Select</InputLabel>
                      {item.initialValue.map((data) => (
                        <MenuItem value={data}>{data}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                );
              }
              if (item.AttributType == "TextArea") {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography>{item.name} : </Typography>
                    <TextField
                      multiline
                      sx={{ width: "400px" }}
                      value={singleProductData[item.name]}
                      size="small"
                      onChange={(e) =>
                        setSingleProductData((prevState) => ({
                          ...prevState,
                          [item.name]: e.target.value,
                        }))
                      }
                      placeholder={item.name}
                    />
                  </Box>
                );
              }
              if (item.AttributType == "TextBox") {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",

                      mb: 2,
                    }}
                  >
                    <Typography>{item.name} : </Typography>
                    <TextField
                      multiline
                      sx={{ width: "400px" }}
                      value={singleProductData.data[item.name]}
                      size="small"
                      onChange={(e) =>
                        setSingleProductData((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState.data,
                            [item.name]: e.target.value,
                          },
                        }))
                      }
                      placeholder={item.name}
                    />
                  </Box>
                );
              }
            })}
          </Box>
          <Box sx={{ width: "600px", mt: 2 }}>
            {MainAttributedata.map((item, i) => {
              if (item === "Brand") {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>{item} : </Typography>
                    <Select
                      displayEmpty
                      sx={{ width: "400px" }}
                      size="small"
                      value={singleProductData.BrandName || ""}
                      onChange={(e) =>
                        setSingleProductData({
                          ...singleProductData,
                          BrandName: e.target.value,
                        })
                      }
                    >
                      <InputLabel sx={{ ml: 2 }}>Select </InputLabel>
                      {BrandAttributes.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                );
              }
              if (item === "Color") {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      //   justifyContent: "space-between",
                      alignItems: "center",
                      gap: "115px",
                      width: "790px",
                    }}
                  >
                    <Typography>{item} </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 2,
                        flexWrap: "wrap",
                        // flexBasis: "50%",
                        // justifyContent: "space-between",
                      }}
                    >
                      {colorAttributes.map((item) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            ml: 4,
                          }}
                        >
                          <Checkbox
                            name={item.name}
                            checked={singleProductData.data[Color]?.includes(
                              item.name
                            )}
                            onChange={handleCheckedChange}
                          />

                          <Typography>{item.name}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                );
              }
              if (item === "Size") {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      //   justifyContent: "space-between",
                      alignItems: "center",
                      gap: "123px",
                      width: "790px",
                    }}
                  >
                    <Typography>{item} </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 2,
                        flexWrap: "wrap",
                        // flexBasis: "50%",
                        // justifyContent: "space-between",
                      }}
                    >
                      {SizeAttributes.map((item) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            ml: 4,
                          }}
                        >
                          <Checkbox
                            name={item.name}
                            checked={singleProductData.data[Size]?.includes(
                              item.name
                            )}
                            onChange={handleCheckedSizeChange}
                          />
                          <Typography>{item.name}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                );
              }
              if (item === "Size Chart") {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <Typography>{item} : </Typography>
                    <Select
                      displayEmpty
                      sx={{ width: "400px" }}
                      size="small"
                      value={singleProductData.data[item] || ""}
                      name={item.name}
                      onChange={(e) =>
                        setSingleProductData((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState.data,
                            [item]: e.target.value,
                          },
                        }))
                      }
                    >
                      <InputLabel sx={{ ml: 2 }}>Select </InputLabel>
                      {SizeCharts.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                );
              }
            })}
            <Typography sx={{ textAlign: "end" }}>
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={() => handleChange(0, 4)}
              >
                NEXT
              </Button>
            </Typography>
          </Box>
        </Card>
      </Box>
    </div>
  );
};

export default Attribute;
