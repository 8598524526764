import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import RatingsAndReviewsTable from "./RatingsAndReviewsTable";
// import ReturnPolicyTable from "./ReturnPolicyTable";

const RatingsAndReviews = () => {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Ratings And Reviews
        </Typography>
      </Box>
      <RatingsAndReviewsTable />
    </div>
  );
};

export default RatingsAndReviews;
