import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import CouponCodeTable from "./CouponCodeTable";

const CoupounCodes = () => {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Coupon Codes
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/create-coupoun-code")}
        >
          Create
        </Button>
      </Box>
      <Box sx={{ mt: 5 }}>
        <CouponCodeTable />
      </Box>
    </div>
  );
};

export default CoupounCodes;
