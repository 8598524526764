import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { CreateNewReturnPolicy1, CreateNewReturnResons } from "../../../axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const CreateNewReturnReasons = () => {
  const [Name, setName] = useState("");
  const [ReasonFor, setResonFor] = useState();
  const [Loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleCreateNewReturnReason = async () => {
    setLoading(true);
    try {
      const res = await CreateNewReturnResons(Name, ReasonFor);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Created Successfully",
          showConfirmButton: true,
        });
        navigate("/Return-reasons");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Return Reasons
        </Typography>
        <Button
          variant="contained"
          onClick={handleCreateNewReturnReason}
          disabled={Loading}
        >
          {Loading ? <CircularProgress size={24} /> : "SUBMIT"}
        </Button>
      </Box>
      <Box sx={{ mt: 3, ml: 3, mr: 3 }}>
        <Box sx={{ border: "1px solid #ddd" }}>
          <Box sx={{ p: 2, background: "#000" }}>
            <Typography sx={{ fontWeight: "bold", color: "#fff" }}>
              Create Return Reason
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Name:
              </Typography>
              <TextField
                size="small"
                placeholder="Name"
                sx={{ width: "400px" }}
                value={Name}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Reason For:
              </Typography>
              <Select
                size="small"
                sx={{ width: "400px" }}
                displayEmpty
                value={ReasonFor}
                onChange={(e) => setResonFor(e.target.value)}
              >
                <InputLabel sx={{ ml: 2 }}>---Select---</InputLabel>
                <MenuItem value="Buyer">Buyer</MenuItem>
                <MenuItem value="Seller">Seller</MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CreateNewReturnReasons;
