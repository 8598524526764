import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const Permissions = () => {
  const location = useLocation();
  const navigations = location.state?.navigations;
  const [selectedItems, setSelectedItems] = useState([]);
  const [data, setData] = useState({});
  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  //   console.log(selectedItems);
  const handleUpdatePermessions = async () => {
    setLoading(true);
    try {
      const oldItems = data?.permessions || [];
      const updatedPermessions = [...oldItems, ...selectedItems];

      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/updateStaffLogin/${id}`,
        {
          permessions: updatedPermessions,
        }
      );

      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated Successfully",
          showConfirmButton: true,
        });
        navigate("/staff-login", { state: { navigations: navigations } });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetStaffLoginById = async () => {
    setLoading1(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getStaffById/${id}`
      );
      if (res) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  const handleCheckboxChange = (itemName) => {
    setData((prevData) => {
      const updatedData = { ...prevData };
      const { permessions } = updatedData;

      if (permessions) {
        const updatedPermessions = prevData?.permessions.includes(itemName)
          ? permessions.filter((item) => item !== itemName)
          : [...permessions, itemName];

        updatedData.permessions = updatedPermessions.length
          ? updatedPermessions
          : null;
      } else {
        updatedData.permessions = prevData?.permessions
          ? [...permessions, itemName]
          : [itemName];
      }

      setSelectedItems(updatedData.permessions || []);

      return updatedData;
    });
  };

  useEffect(() => {
    handleGetStaffLoginById();
  }, []);

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Manage Permissions
        </Typography>
        <Button
          variant="contained"
          onClick={handleUpdatePermessions}
          disabled={Loading}
        >
          {Loading ? <CircularProgress size={24} /> : "SUBMIT"}
        </Button>
      </Box>
      {!Loading1 ? (
        <Box
          sx={{
            mt: 3,
            ml: 3,
            display: "flex",
            mb: 3,
            flexDirection: "column",
          }}
        >
          <Box>
            {navigations?.map((item) => (
              <Box key={item.name}>
                <Typography sx={{ fontWeight: "bold", mt: 2 }}>
                  {item.name}
                </Typography>

                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {item.children.map((childItem) => (
                    <Box
                      key={childItem.name}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Checkbox
                        checked={
                          data?.permessions?.includes(childItem.name) || ""
                        }
                        onChange={() => handleCheckboxChange(childItem.name)}
                      />
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          minWidth: "200px",
                        }}
                      >
                        {childItem.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default Permissions;
