import {
  Box,
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const MetaInformation = () => {
  const [singleProductData, setSingleProductData] = useState({});
  const [loading, setLoading] = useState(false);

  const [metaKeyWords, setMetaKeywords] = useState([]);
  const [metaDescription, setMetaDescription] = useState("");
  const [popularClicks, setPopularClicks] = useState(0);
  const [TrendingStyles, setTrendingStyles] = useState(false);
  const [latest, setLatest] = useState(false);
  const [Stock, setStock] = useState(false);
  const [sellOnline, setSellOnline] = useState(true);

  console.log(metaKeyWords);

  const { id } = useParams();
  const navigate = useNavigate();

  const handleSingleProductlistingById = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/get-single-product/${id}`
      );
      if (res) {
        console.log(res);
        setSingleProductData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSingleListing = async () => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/update-single-product/${id}`,
        {
          Title: singleProductData.Title,
          metaKeyWords: singleProductData.metaKeyWords,
          metaDescription: singleProductData.metaDescription,
          popularClicks: singleProductData.popularClicks,
          TrendingStyles: singleProductData.TrendingStyles,
          latest: singleProductData.latest,
          Stock: singleProductData.Stock,
          sellOnline: singleProductData.sellOnline,
        }
      );
      if (res) {
        console.log(res);
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated Meta Information SuccessFully",
          showConfirmButton: true,
        });
        navigate("/products-list");
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "error Occured",
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    handleSingleProductlistingById();
  }, []);
  return (
    <div style={{ marginTop: "150px", marginBottom: "30px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 4,
          mr: 4,
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Meta Information
        </Typography>
        <Button
          variant="contained"
          disableElevation
          onClick={handleSingleListing}
        >
          SUBMIT
        </Button>
      </Box>
      {!loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              width: "450px",
              border: "1px solid #dddddd",
              ml: 4,
              p: 2,
              mt: 3,
            }}
            elevation={0}
          >
            <Box sx={{ mt: 3 }}>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>Meta Title : </Typography>
                <TextField
                  placeholder="Meta Title"
                  size="small"
                  value={singleProductData.Title}
                  onChange={(e) =>
                    setSingleProductData({
                      ...singleProductData,
                      Title: e.target.value,
                    })
                  }
                  sx={{ width: "100%", mb: 2, mt: 0.5 }}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  Meta Keywords :{" "}
                </Typography>
                <TextField
                  size="small"
                  value={singleProductData?.metaKeyWords || ""}
                  onChange={(e) => {
                    const data = e.target.value.split(",");
                    setSingleProductData({
                      ...singleProductData,
                      metaKeyWords: data,
                    });
                  }}
                  sx={{ width: "100%", mb: 2, mt: 0.5 }}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  Meta Description :{" "}
                </Typography>
                <TextField
                  placeholder="Meta Description"
                  multiline
                  value={singleProductData?.metaDescription}
                  onChange={(e) =>
                    setSingleProductData({
                      ...singleProductData,
                      metaDescription: e.target.value,
                    })
                  }
                  size="small"
                  sx={{ width: "100%", mb: 2, mt: 0.5 }}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  {" "}
                  Most Popular Click :{" "}
                </Typography>
                <TextField
                  size="small"
                  value={singleProductData.popularClicks}
                  onChange={(e) =>
                    setSingleProductData({
                      ...singleProductData,
                      popularClicks: e.target.value,
                    })
                  }
                  sx={{ width: "100%", mb: 2, mt: 0.5 }}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  {" "}
                  Trending Styles :{" "}
                </Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={singleProductData.TrendingStyles}
                      checked={singleProductData.TrendingStyles === true}
                      onChange={() =>
                        setSingleProductData({
                          ...singleProductData,
                          TrendingStyles: true,
                        })
                      }
                      control={<Radio />}
                      label="yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value={singleProductData.TrendingStyles}
                      checked={singleProductData.TrendingStyles === false}
                      onChange={() =>
                        setSingleProductData({
                          ...singleProductData,
                          TrendingStyles: false,
                        })
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  {" "}
                  What's Latest :{" "}
                </Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={singleProductData.latest}
                      checked={singleProductData.latest === true}
                      onChange={() =>
                        setSingleProductData({
                          ...singleProductData,
                          latest: true,
                        })
                      }
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value={singleProductData.latest}
                      checked={singleProductData.latest === false}
                      onChange={() =>
                        setSingleProductData({
                          ...singleProductData,
                          latest: false,
                        })
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  {" "}
                  Out Of Stock :{" "}
                </Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={singleProductData.Stock}
                      checked={singleProductData.Stock === true}
                      onChange={() =>
                        setSingleProductData({
                          ...singleProductData,
                          Stock: true,
                        })
                      }
                      control={<Radio />}
                      label="yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value={singleProductData.Stock}
                      checked={singleProductData.Stock === false}
                      onChange={() =>
                        setSingleProductData({
                          ...singleProductData,
                          Stock: false,
                        })
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  {" "}
                  Do You Want To Sell Your Product Online :{" "}
                </Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={singleProductData.sellOnline}
                      checked={singleProductData.sellOnline === true}
                      onChange={() =>
                        setSingleProductData({
                          ...singleProductData,
                          sellOnline: true,
                        })
                      }
                      control={<Radio />}
                      label="yes"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="No"
                      value={singleProductData.sellOnline}
                      checked={singleProductData.sellOnline === false}
                      onChange={() =>
                        setSingleProductData({
                          ...singleProductData,
                          sellOnline: false,
                        })
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Card>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default MetaInformation;
