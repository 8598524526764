import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { useEffect } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StoreCalculationManageModal({
  data,
  setData,
  handleUpdateStoreCalculationData,
}) {
  const [open, setOpen] = React.useState(false);

  const [StoreData, setStoreData] = React.useState([]);
  const handleStoreProfileData = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllProfilers"
      );

      if (res) {
        // console.log(res);
        setStoreData(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleStoreProfileData();
  }, []);

  return (
    <div>
      <Button
        variant="contained"
        sx={{ background: "#1ab394" }}
        onClick={handleClickOpen}
      >
        Manage
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              Closing Fee Differential
            </Typography>
            <IconButton onClick={handleClose}>
              <ClearIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value={data.closingFeeTypeDifferentialType}
                  checked={data.closingFeeTypeDifferentialType == true}
                  onChange={() =>
                    setData({ ...data, closingFeeTypeDifferentialType: true })
                  }
                  control={<Radio />}
                  label="Flat"
                />
                <FormControlLabel
                  value={data.closingFeeTypeDifferentialType}
                  checked={data.closingFeeTypeDifferentialType == false}
                  onChange={() =>
                    setData({ ...data, closingFeeTypeDifferentialType: false })
                  }
                  control={<Radio />}
                  label="Percentage"
                />
              </RadioGroup>
            </FormControl>
            <Box sx={{ display: "flex", gap: "150px" }}>
              <Box>
                <Typography>From</Typography>
              </Box>
              <Box>
                <Typography>To</Typography>
              </Box>
              <Box>
                <Typography sx={{ ml: 1 }}>Value</Typography>
              </Box>
            </Box>
            {data.closingFeeTypeDifferentialFieldValues.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  sx={{ width: "170px", mt: 2 }}
                  size="small"
                  value={item.from}
                  onChange={(e) => {
                    setData((prevData) => ({
                      ...prevData,
                      closingFeeTypeDifferentialFieldValues: [
                        ...prevData.closingFeeTypeDifferentialFieldValues.slice(
                          0,
                          index
                        ),
                        {
                          ...prevData.closingFeeTypeDifferentialFieldValues[
                            index
                          ],
                          from: e.target.value,
                        },
                        ...prevData.closingFeeTypeDifferentialFieldValues.slice(
                          index + 1
                        ),
                      ],
                    }));
                  }}
                />
                <TextField
                  sx={{ width: "170px", mt: 2 }}
                  size="small"
                  value={item.to}
                  onChange={(e) => {
                    setData((prevData) => ({
                      ...prevData,
                      closingFeeTypeDifferentialFieldValues: [
                        ...prevData.closingFeeTypeDifferentialFieldValues.slice(
                          0,
                          index
                        ),
                        {
                          ...prevData.closingFeeTypeDifferentialFieldValues[
                            index
                          ],
                          to: e.target.value,
                        },
                        ...prevData.closingFeeTypeDifferentialFieldValues.slice(
                          index + 1
                        ),
                      ],
                    }));
                  }}
                />
                <TextField
                  sx={{ width: "170px", mt: 2 }}
                  size="small"
                  value={item.value}
                  onChange={(e) => {
                    setData((prevData) => ({
                      ...prevData,
                      closingFeeTypeDifferentialFieldValues: [
                        ...prevData.closingFeeTypeDifferentialFieldValues.slice(
                          0,
                          index
                        ),
                        {
                          ...prevData.closingFeeTypeDifferentialFieldValues[
                            index
                          ],
                          value: e.target.value,
                        },
                        ...prevData.closingFeeTypeDifferentialFieldValues.slice(
                          index + 1
                        ),
                      ],
                    }));
                  }}
                />
                <Typography sx={{ mt: 2 }}>
                  {data.closingFeeTypeDifferentialType == true
                    ? StoreData.DefaultCurrency &&
                      StoreData.DefaultCurrency.split("-")[1]
                    : "%"}
                </Typography>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <Typography sx={{ textAlign: "center", mb: 2 }}>
          <Button
            sx={{ background: "#ee6810", color: "#fff" }}
            variant="contained"
            onClick={() => {
              handleClose();
              handleUpdateStoreCalculationData();
            }}
          >
            SUBMIT
          </Button>
        </Typography>
      </Dialog>
    </div>
  );
}
