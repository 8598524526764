import axios from "axios";
import React, { useEffect, useState } from "react";
import ProviderListTable from "./ProviderListTable";

const LogisticProvidersList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getLogosticProvidersList = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllLogisticProviders",
        config
      );
      if (res) {
        console.log(res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLogosticProvidersList();
  }, []);
  return (
    <div>
      <ProviderListTable data={data} loading={loading} />
    </div>
  );
};

export default LogisticProvidersList;
