import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ClearIcon from "@mui/icons-material/Clear";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StoreCalculationManageModal2({
  data,
  setData,
  handleUpdateStoreCalculationData,
  DefaultCurrency,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{ background: "#1ab394" }}
        onClick={handleClickOpen}
      >
        Manage
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              PPC ONLINE Fee Differential
            </Typography>
            <IconButton onClick={handleClose}>
              <ClearIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value={data.PaymentChargesForOnlineTypeDifferentialType}
                  checked={
                    data.PaymentChargesForOnlineTypeDifferentialType == true
                  }
                  onChange={() =>
                    setData({
                      ...data,
                      PaymentChargesForOnlineTypeDifferentialType: true,
                    })
                  }
                  control={<Radio />}
                  label="Flat"
                />
                <FormControlLabel
                  value={data.PaymentChargesForOnlineTypeDifferentialType}
                  checked={
                    data.PaymentChargesForOnlineTypeDifferentialType == false
                  }
                  onChange={() =>
                    setData({
                      ...data,
                      PaymentChargesForOnlineTypeDifferentialType: false,
                    })
                  }
                  control={<Radio />}
                  label="Percentage"
                />
              </RadioGroup>
            </FormControl>
            <Box sx={{ display: "flex", gap: "150px" }}>
              <Box>
                <Typography>From</Typography>
              </Box>
              <Box>
                <Typography>To</Typography>
              </Box>
              <Box>
                <Typography sx={{ ml: 1 }}>Value</Typography>
              </Box>
            </Box>
            {data.PaymentChargesForOnlineTypeDifferentialFieldValues.map(
              (item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    sx={{ width: "170px", mt: 2 }}
                    size="small"
                    value={item.from}
                    onChange={(e) => {
                      const updatedValues = [
                        ...data.PaymentChargesForOnlineTypeDifferentialFieldValues,
                      ];
                      updatedValues[index].from = e.target.value;
                      setData({
                        ...data,
                        PaymentChargesForOnlineTypeDifferentialFieldValues:
                          updatedValues,
                      });
                    }}
                  />
                  <TextField
                    sx={{ width: "170px", mt: 2 }}
                    size="small"
                    value={item.to}
                    onChange={(e) => {
                      const updatedValues = [
                        ...data.PaymentChargesForOnlineTypeDifferentialFieldValues,
                      ];
                      updatedValues[index].to = e.target.value;
                      setData({
                        ...data,
                        PaymentChargesForOnlineTypeDifferentialFieldValues:
                          updatedValues,
                      });
                    }}
                  />
                  <TextField
                    sx={{ width: "170px", mt: 2 }}
                    size="small"
                    value={item.value}
                    onChange={(e) => {
                      const updatedValues = [
                        ...data.PaymentChargesForOnlineTypeDifferentialFieldValues,
                      ];
                      updatedValues[index].value = e.target.value;
                      setData({
                        ...data,
                        PaymentChargesForOnlineTypeDifferentialFieldValues:
                          updatedValues,
                      });
                    }}
                  />
                  <Typography sx={{ mt: 2 }}>
                    {data.PaymentChargesForOnlineTypeDifferentialType == true
                      ? DefaultCurrency && DefaultCurrency.split("-")[1]
                      : "%"}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </DialogContent>
        <Typography sx={{ textAlign: "center", mb: 2 }}>
          <Button
            sx={{ background: "#ee6810", color: "#fff" }}
            variant="contained"
            onClick={() => {
              handleClose();
              handleUpdateStoreCalculationData();
            }}
          >
            SUBMIT
          </Button>
        </Typography>
      </Dialog>
    </div>
  );
}
