import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApprovePriceTable from "./ApprovePriceTable";

const ManagePriceAndStock = () => {
  const [mrp, setMrp] = React.useState(0);
  const [OfferPrice, setOfferPrice] = React.useState(0);
  const [priceData, setPriceData] = React.useState({});
  const [IgstValue, setIgStValue] = React.useState(10);
  const [CgstValue, setCgStValue] = React.useState(5);
  const [SgstValue, setSgStValue] = React.useState(5);
  const [loading, setLoading] = React.useState(false);
  const { id } = useParams();
  const [data, setData] = useState({});

  const IGST = Math.floor(
    (data.offerPrice * (100 / 110) +
      (data.offerPrice * (100 / 110) * 10) / 100) /
      IgstValue
  );

  const CGST = IGST / 2;
  const SGST = IGST / 2;

  const handleSetPrice = () => {
    setPriceData({ mrp: data.mrp, offerPrice: data.offerPrice });
  };

  const getSingleListingData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/get-single-product/${id}`
      );
      if (res) {
        // console.log(res.data);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSingleListingData();
  }, []);
  return (
    <div style={{ marginTop: "150px" }}>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: "bold",
          textAlign: "center",
          mt: 3,
        }}
      >
        Product Price
      </Typography>
      <Typography
        sx={{ border: "2px solid #3482BC", width: "60px" }}
        style={{ margin: "auto" }}
      ></Typography>
      <Box>
        {!loading ? (
          <>
            <Box sx={{ width: "750px", m: 5 }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "257px",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography>Name : </Typography>
                <Typography>{data.Title}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "225px",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography>SKU Code : </Typography>
                <Typography>{data.SKUCode}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "166px",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography>Set/Reset All Price : </Typography>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <TextField
                    placeholder="Enter MRP"
                    size="small"
                    type="number"
                    value={data.mrp}
                    onChange={(e) => setData({ ...data, mrp: e.target.value })}
                    sx={{ width: "140px" }}
                  />
                  <TextField
                    placeholder="Offer Price"
                    size="small"
                    type="number"
                    value={data.offerPrice}
                    onChange={(e) =>
                      setData({ ...data, offerPrice: e.target.value })
                    }
                    sx={{ width: "140px" }}
                  />
                  <Button
                    sx={{ background: "#3482BC" }}
                    variant="contained"
                    onClick={handleSetPrice}
                  >
                    Confirm
                  </Button>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "266px",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography>IGST : </Typography>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <TextField
                    size="small"
                    value={IgstValue}
                    onChange={(e) => setIgStValue(e.target.value)}
                    sx={{ width: "100px" }}
                  />
                  <Typography> % - </Typography>
                  <TextField
                    size="small"
                    sx={{ width: "100px" }}
                    value={IGST ? IGST : data.IGST}
                  />
                  <Typography>Amount</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "260px",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography>CGST : </Typography>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <TextField
                    size="small"
                    value={CgstValue}
                    onChange={(e) => setCgStValue(e.target.value)}
                    sx={{ width: "100px" }}
                  />
                  <Typography> % - </Typography>
                  <TextField
                    size="small"
                    value={CGST ? CGST : data.CGST || "0"}
                    sx={{ width: "100px" }}
                  />
                  <Typography>Amount</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "260px",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography>SGST : </Typography>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <TextField
                    size="small"
                    value={SgstValue}
                    onChange={(e) => setSgStValue(e.target.value)}
                    sx={{ width: "100px" }}
                  />
                  <Typography> % - </Typography>
                  <TextField
                    size="small"
                    value={SGST ? SGST : data.SGST || "0"}
                    sx={{ width: "100px" }}
                  />
                  <Typography>Amount</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ m: 3, background: "#dddddd", p: 1.5, mb: 2 }}>
              <Typography sx={{ fontSize: "17px", fontWeight: "bold", ml: 1 }}>
                Approve Price
              </Typography>
            </Box>
            <ApprovePriceTable
              priceData={priceData}
              IGST={IGST}
              CGST={CGST}
              SGST={SGST}
              IgstValue={IgstValue}
            />
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default ManagePriceAndStock;
