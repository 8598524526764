import {
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const UpdateBrandAttributes = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const handleGetUpdateBrandById = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getProductTypeById/${id}`
      );
      if (res) {
        // console.log(res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetUpdateBrandById();
  }, []);

  const handleUpdateBrandData = async () => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/UpdateProductTypeAttributes/${id}`,
        {
          name: data.name,
          sortId: data.sortId,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated SuccessFully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };
  return (
    <div style={{ marginTop: "150px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mr: 3 }}>
        <Typography></Typography>
        <Button
          variant="contained"
          onClick={() => navigate(-1)}
          disableElevation
        >
          Back
        </Button>
      </Box>
      {!loading ? (
        <>
          <Card
            sx={{ maxWidth: "450px", p: 2 }}
            style={{
              margin: "auto",
              marginTop: "30px",
              border: "1px solid #dddddd",
            }}
          >
            <Typography
              sx={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}
            >
              Update Product Type Attributes
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Box>
                <Typography sx={{ fotSize: "17px" }}>Enter Name</Typography>
                <TextField
                  fullWidth
                  sx={{ mb: 2, mt: 0.5 }}
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </Box>

              <Box>
                <Typography sx={{ fotSize: "17px" }}>Sort Id</Typography>
                <TextField
                  fullWidth
                  sx={{ mb: 2, mt: 0.5 }}
                  value={data.sortId}
                  onChange={(e) => setData({ ...data, sortId: e.target.value })}
                />
              </Box>
            </Box>
            <Button
              variant="contained"
              sx={{ mb: 2 }}
              fullWidth
              onClick={handleUpdateBrandData}
            >
              SUBMIT
            </Button>
          </Card>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default UpdateBrandAttributes;
