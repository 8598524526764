import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { motion } from "framer-motion";

const HomepageCategories = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [bannerData, setBannerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [visibility, setVisibility] = React.useState({});
  const [punChlineData, setPunChlineData] = useState([]);
  const [image1, setImage] = useState(null);

  const cloudName = "bunny1705";
  const apiKey = "686471132156718";
  const apiSecret = "PF0u158oD2YzCfWEou1-ijeVOmQ";

  const handleEditBanner = (banner) => {
    // console.log(banner);
    if (data) {
      setSelectedBanner(banner);
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      setSelectedBanner(data[0]);
    }
  }, [data]);

  // handle Get punchline Data

  const handleGetPunchline = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/handleGetFeaturedCategoriesData"
      );
      if (res) {
        setPunChlineData(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle Update Punchline Data

  const handleUpdatePunchlineData = async () => {
    setLoading2(true);
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/UpdateFeaturedCategoriesData/${punChlineData._id}`,
        {
          Heading: punChlineData.Heading,
          ButtonText: punChlineData.ButtonText,
          linktoButton: punChlineData.linktoButton,
        }
      );
      if (res) {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading2(false);
    }
  };

  const generateSignature = (publicId) => {
    const timestamp = Date.now();
    const signature = CryptoJS.SHA1(
      `public_id=${publicId}&timestamp=${timestamp}${apiSecret}`
    ).toString();
    return { timestamp, signature };
  };

  const handleGetAllFeaturedCategoriesImages = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/handleGetAllImagesforFeaturedCategories"
      );
      if (res) {
        const visibilityObj = {};
        res.data.forEach((row) => {
          visibilityObj[row._id] = !row.visible;
        });
        setData(res.data);
        setVisibility(visibilityObj);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // handle visibility

  const handleVisibility = async (id, value) => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/handleUpdateFeaturedCategoriesImages/${id}`,
        {
          visible: value,
        }
      );
      if (res) {
        setVisibility((prevState) => ({
          ...prevState,
          [id]: !value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle Delete Banner Image

  const handleDeleteTopbarImage = async (id) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (shouldDelete) {
      try {
        const banner = data.find((item) => item._id === id);

        if (!banner) {
          console.log("Banner not found");
          return;
        }

        const publicId = banner.image.split("/").slice(-1)[0].split(".")[0];
        const { signature, timestamp } = generateSignature(publicId);

        const formData = new FormData();
        formData.append("public_id", publicId);
        formData.append("timestamp", timestamp);
        formData.append("api_key", apiKey);
        formData.append("signature", signature);

        const deleteImageResponse = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/destroy`,
          formData
        );

        if (deleteImageResponse.status === 200) {
          const deleteBannerResponse = await axios.delete(
            `https://rccore-backend.cstechns.com/api/admin/handleDeleteTopbarImages/${id}`
          );

          if (deleteBannerResponse.status === 200) {
            console.log("Image and data deleted successfully");
            // Update the state or perform any additional actions if needed
            const updatedData = data.filter((item) => item._id !== id);
            setData(updatedData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // handle get Banner Images

  const handleGetFeaturedCategoriesImageById = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/handleGetFeaturedCategoryImageById/${selectedBanner._id}`
      );
      if (res) {
        console.log(res);
        setBannerData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle update Banner data

  const handleUpdateFeaturedCategories = async () => {
    setLoading1(true);
    try {
      const oldPublicId = bannerData.image
        .split("/")
        .slice(-1)[0]
        .split(".")[0];

      const { signature, timestamp } = generateSignature(oldPublicId);

      const formData = new FormData();
      formData.append("file", image1);
      formData.append("upload_preset", "insta-clone");
      formData.append("cloud_name", "bunny1705");

      let newPublicId = oldPublicId;
      let imageUrl = bannerData.image;
      if (image1) {
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
          formData
        );

        newPublicId = response.data.public_id;
        imageUrl = response.data.secure_url;

        if (oldPublicId) {
          const DestoryData = new FormData();
          DestoryData.append("public_id", oldPublicId);
          DestoryData.append("timestamp", timestamp);
          DestoryData.append("api_key", apiKey);
          DestoryData.append("signature", signature);
          await axios.post(
            `https://api.cloudinary.com/v1_1/${cloudName}/image/destroy`,
            DestoryData
          );
        }
      }

      // Update the banner data in your backend
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/handleUpdateFeaturedCategoriesImages/${selectedBanner._id}`,
        {
          image: imageUrl,
          url: bannerData.url,
          altText: bannerData.altText,
          sortingOrder: bannerData.sortingOrder,
          title: bannerData.title,
          subtitle: bannerData.subtitle,
        }
      );

      if (res) {
        console.log("Image and data updated successfully");

        // Fetch the updated data
        const updatedData = await axios.get(
          "https://rccore-backend.cstechns.com/api/admin/handleGetAllImagesforFeaturedCategories"
        );

        // Update the data state with the fetched data
        setData(updatedData.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    handleGetAllFeaturedCategoriesImages();
    handleGetPunchline();
  }, []);

  useEffect(() => {
    handleGetFeaturedCategoriesImageById();
  }, [selectedBanner]);
  return (
    <div style={{ marginTop: "150px" }}>
      {!loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ml: 3,
              mr: 3,
              mb: 2,
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              {punChlineData.Heading}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", gap: "50px", ml: 3 }}
          >
            <Box>
              <Typography>
                This section allows you to manage Home Page Banner
              </Typography>{" "}
              <Typography>
                {" "}
                To upload new Home Page Banner images please use the upload
                button on the right,
                <br /> you can modify or delete already existing Home Page
                Banner images from the list below.
              </Typography>{" "}
              <Typography sx={{ color: "red" }}>
                Make sure you have published the Home Page Banner images once
                you are finished editing and image
                <br /> dimension should be 73px X 25px
              </Typography>
            </Box>
            {/* <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                onClick={() =>
                  navigate("/theme-customization/upload-featuredCategories")
                }
              >
                Upload Images
              </Button>
            </Box> */}
          </Box>
          <Box>
            <Box sx={{ ml: 3, mt: 2, mb: 2 }}>
              <Typography sx={{ fontSize: "17px" }}>Heading</Typography>
              <TextField
                placeholder="Heading"
                multiline
                value={punChlineData.Heading}
                onChange={(e) =>
                  setPunChlineData({
                    ...punChlineData,
                    Heading: e.target.value,
                  })
                }
                sx={{ width: "450px" }}
                size="small"
              />
            </Box>
            <Box sx={{ ml: 3, mt: 2, mb: 2 }}>
              <Typography sx={{ fontSize: "17px" }}>Button Text</Typography>
              <TextField
                placeholder="Button Text"
                value={punChlineData.ButtonText}
                onChange={(e) =>
                  setPunChlineData({
                    ...punChlineData,
                    ButtonText: e.target.value,
                  })
                }
                sx={{ width: "450px" }}
                size="small"
              />
            </Box>
            <Box sx={{ ml: 3, mt: 2, mb: 2 }}>
              <Typography sx={{ fontSize: "17px" }}>Link To Button</Typography>
              <TextField
                placeholder="Link To Button"
                value={punChlineData.linktoButton}
                onChange={(e) =>
                  setPunChlineData({
                    ...punChlineData,
                    linktoButton: e.target.value,
                  })
                }
                sx={{ width: "450px" }}
                size="small"
              />
            </Box>
            <Button
              variant="contained"
              sx={{ ml: 3, width: "450px" }}
              onClick={handleUpdatePunchlineData}
              disabled={loading2}
            >
              {loading2 ? <CircularProgress size={24} /> : "SUBMIT"}
            </Button>
          </Box>
          <Box sx={{ m: 3, display: "flex", justifyContent: "space-between" }}>
            <Card elevation={0} sx={{ width: "100%" }}>
              <Box>
                <Card elevation={0} sx={{ border: "2px solid #dddddd" }}>
                  <Grid container spacing={2}>
                    {data.map((item, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={6}
                        lg={3}
                        xl={3}
                        key={index}
                      >
                        <Box
                          sx={{
                            p: 2,
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              height: "150px",
                              width: "150px",
                              border: "2px solid #dddddd",

                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img src={item.image} alt="" width="120px" />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "5px",
                              mt: 0.5,
                            }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                width: "162px",
                                height: "30px",
                              }}
                              onClick={() =>
                                handleVisibility(item._id, visibility[item._id])
                              }
                            >
                              {visibility[item._id] ? "In-visible" : "visible"}
                            </Button>
                            <Button
                              variant="contained"
                              sx={{ width: "162px", height: "30px" }}
                              onClick={() => handleEditBanner(item)}
                            >
                              Edit
                            </Button>
                            {/* <Button
                              variant="contained"
                              sx={{
                                width: "162px",
                                height: "30px",
                                background: "red",
                              }}
                              onClick={() => handleDeleteTopbarImage(item._id)}
                            >
                              Delete
                            </Button> */}
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Box>
            </Card>

            {selectedBanner && (
              <motion.div
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ duration: 0.3 }}
                style={{ marginLeft: "20px" }}
              >
                <Card
                  elevation={0}
                  sx={{ border: "2px solid #dddddd", width: "450px", p: 2 }}
                >
                  <Box>
                    <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      Edit Info
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {image1 ? (
                      <img
                        src={URL.createObjectURL(image1)}
                        alt=""
                        width="120px"
                      />
                    ) : (
                      <img src={bannerData.image} alt="" width="120px" />
                    )}
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>Update Image </Typography>
                    <TextField
                      type="file"
                      variant="outlined"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                      fullWidth
                      size="small"
                      sx={{ mt: 0.5 }}
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>Url </Typography>
                    <TextField
                      variant="outlined"
                      value={bannerData.url}
                      onChange={(e) =>
                        setBannerData({ ...bannerData, url: e.target.value })
                      }
                      fullWidth
                      size="small"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>Alt Text </Typography>
                    <TextField
                      variant="outlined"
                      value={bannerData.altText}
                      onChange={(e) =>
                        setBannerData({
                          ...bannerData,
                          altText: e.target.value,
                        })
                      }
                      fullWidth
                      size="small"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>Sorting Order</Typography>
                    <TextField
                      variant="outlined"
                      value={bannerData.sortingOrder}
                      onChange={(e) =>
                        setBannerData({
                          ...bannerData,
                          sortingOrder: e.target.value,
                        })
                      }
                      fullWidth
                      size="small"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>Title</Typography>
                    <TextField
                      variant="outlined"
                      value={bannerData.title}
                      onChange={(e) =>
                        setBannerData({
                          ...bannerData,
                          title: e.target.value,
                        })
                      }
                      fullWidth
                      size="small"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>Sub Title</Typography>
                    <TextField
                      variant="outlined"
                      value={bannerData.subtitle}
                      onChange={(e) =>
                        setBannerData({
                          ...bannerData,
                          subtitle: e.target.value,
                        })
                      }
                      fullWidth
                      size="small"
                    />
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleUpdateFeaturedCategories}
                    sx={{ mt: 2 }}
                    disabled={loading1}
                  >
                    {loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
                  </Button>
                </Card>
              </motion.div>
            )}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default HomepageCategories;
