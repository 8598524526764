import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";

import React from "react";
import {
  createNewStaticPage,
  getBuyerStaticPageById,
  getSellerStaticPageById,
  handleUpdateBuyerStaticPage,
  handleUpdateSellerStaticPage,
} from "../../../axios";
import { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { CKEditor } from "ckeditor4-react";

const EditSellerStaticPage = () => {
  const [data, setdata] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);
  const [parsedData, setParsedData] = useState("");

  const { id } = useParams();

  // console.log(data.Description);

  const navigate = useNavigate();

  const handleEditorChange = (event) => {
    const parseddata = event.editor.getData();

    setdata({ ...data, Description: parseddata });
    // setParsedData(parseddata);

    // console.log("Table:", formattedHtml);
  };

  const handleGetBuyerStaticPages = async () => {
    setLoading(true);
    try {
      const res = await getSellerStaticPageById(id);
      if (res) {
        setdata(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    setLoading1(true);
    try {
      const res = await handleUpdateSellerStaticPage(
        id,
        data.Heading,
        data.Link,
        data.Page,
        data.SortingId,
        data.Description
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated successfully",
          showConfirmButton: true,
        });
        navigate("/seller-static-pages");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    handleGetBuyerStaticPages();
  }, [id]);

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Manage Seller Static Page
        </Typography>
        <Button variant="contained" onClick={handleEdit} disabled={Loading1}>
          {Loading1 ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </Box>
      {!Loading ? (
        <Box sx={{ m: 3 }}>
          <Box sx={{ border: "1px solid #ddd", borderRadius: "5px" }}>
            <Box sx={{ background: "#000", p: 1.5 }}>
              <Typography sx={{ color: "white", fontWeight: "bold" }}>
                Static Pages
              </Typography>
            </Box>

            <Box sx={{ p: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Heading:
                </Typography>
                <TextField
                  size="small"
                  sx={{ width: "400px", mt: 0.5, mb: 2 }}
                  placeholder="Heading"
                  value={data?.Heading}
                  onChange={(e) =>
                    setdata({ ...data, Heading: e.target.value })
                  }
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Link Name:
                </Typography>
                <TextField
                  size="small"
                  sx={{ width: "400px", mt: 0.5, mb: 2 }}
                  placeholder="Link Name"
                  value={data?.Link}
                  onChange={(e) => setdata({ ...data, Link: e.target.value })}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Page URL:
                </Typography>
                <TextField
                  size="small"
                  sx={{ width: "400px", mt: 0.5, mb: 2 }}
                  placeholder="Page URL"
                  value={data?.Page}
                  onChange={(e) => setdata({ ...data, Page: e.target.value })}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Sorting ID:
                </Typography>
                <TextField
                  size="small"
                  sx={{ width: "400px", mt: 0.5, mb: 2 }}
                  placeholder="Sorting ID"
                  value={data?.SortingId}
                  onChange={(e) =>
                    setdata({ ...data, SortingId: e.target.value })
                  }
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Upload Banner Image:
                </Typography>
                <Box>
                  <TextField
                    type="file"
                    size="small"
                    sx={{ width: "400px", mt: 0.5, mb: 2 }}
                  />
                  <Typography sx={{ fontSize: "15px" }}>
                    Upload image file types of png,jpeg,jpg and size of 1370*400
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    minWidth: "300px",
                    mt: 2,
                  }}
                >
                  Description:
                </Typography>
                <Box sx={{ mt: 2, mb: 2 }}>
                  {/* <textarea
                    style={{
                      width: "600px",
                      height: "400px",
                      resize: "vertical",
                      border: "1px solid #ddd",
                      borderRadius: "5px",
                      padding: "20px",
                    }}
                    value={data?.Description}
                    onChange={(e) =>
                      setdata({ ...data, Description: e.target.value })
                    }
                  ></textarea> */}
                  <CKEditor
                    initData={data?.Description || ""}
                    onChange={handleEditorChange}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default EditSellerStaticPage;
