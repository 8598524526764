import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  UpdateCouponCodeSplash,
  UpdateUserWise,
  createNewStoreWise,
  getAllCouponCOdes,
  getAllCouponCodeSplash,
  getAllUserWise,
  getCouponSplashById,
  getUserWiseById,
} from "../../../axios";
import Swal from "sweetalert2";
import { format } from "date-fns";
import StoreWiseTable from "./StoreWiseTable";

const Promotions = () => {
  const [userWiseOpen, setUserWiseOpen] = useState(false);
  const [storeWiseOpen, setStoreWiseOpen] = useState(false);
  const [PromotionWiseOpen, setPromotionWiseOpen] = useState(false);
  const [discountIn, setDiscountIn] = useState("Percentage");

  const [userWiseData, setUserWiseData] = useState([]);
  const [userWiseOrderDataById, setUserWiseOrderDataById] = useState({});
  const [userWiseActive, setUserWiseActive] = useState();

  // storeWise
  const [active, setActive] = useState(true);
  const [selectedFromDate, setSelectedFromDate] = React.useState(null);
  const [selectedToDate, setSelectedToDate] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [error1, setError1] = React.useState(false);
  const [orderAmount, setOrderAmount] = useState(null);
  const [AmountCapping, setAmountCapping] = useState(null);
  const [Discount, setDiscount] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [couponCOdeSplash, setCouponCodeSplash] = useState([]);
  const [couponsplashData, setCouponSplashData] = useState({});
  const [couponcodesplashactive, setCodeSplashActive] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading3, setLoading3] = useState(false);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    return formattedDate;
  }

  const handleDateChange = (date) => {
    if (date && date <= selectedFromDate) {
      setError(true);
    } else {
      setError(false);
      setSelectedFromDate(date);
    }
  };
  const handleDateChange1 = (date) => {
    if (date && date < new Date()) {
      setError1(true);
    } else {
      setError1(false);
      setSelectedToDate(date);
    }
  };

  const handleGetAllUserWise = async () => {
    try {
      const res = await getAllUserWise();
      if (res) {
        setUserWiseData(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetUserWiseById = async () => {
    setLoading(true);
    try {
      const res = await getUserWiseById(userWiseData?.[0]?._id);
      if (res) {
        setUserWiseOrderDataById(res);
        setUserWiseActive(res.active);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUserWise = async () => {
    try {
      const res = await UpdateUserWise(
        userWiseData?.[0]?._id,
        userWiseOrderDataById?.active,
        userWiseOrderDataById?.DisCount,
        userWiseOrderDataById?.OrderAmount
      );
      if (res) {
        setUserWiseActive(userWiseOrderDataById?.active);
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated Successfully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // coupon code splash

  const handleGetAllCouponCOdeSPlash = async () => {
    try {
      const res = await getAllCouponCodeSplash();
      if (res) {
        setCouponCodeSplash(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCouponCOdeSplashById = async () => {
    setLoading1(true);
    try {
      const res = await getCouponSplashById(couponCOdeSplash?.[0]?._id);
      if (res) {
        setCouponSplashData(res);
        setCodeSplashActive(res.active);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  const handleUpdateCouponCOdeSplash = async () => {
    try {
      const res = await UpdateCouponCodeSplash(
        couponCOdeSplash?.[0]?._id,
        couponsplashData?.active,
        couponsplashData?.MainTitle,
        couponsplashData?.subTitle,
        couponsplashData?.CouponCode,
        couponsplashData?.Time
      );
      if (res) {
        setCodeSplashActive(couponsplashData?.active);
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated Successfully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const currentDate = format(new Date(), "yyyy-MM-dd");

  // store Wise

  const handleCreateNewStoreWise = async () => {
    try {
      const res = await createNewStoreWise(
        active,
        formatDate(selectedFromDate),
        formatDate(selectedToDate),
        orderAmount,
        discountIn,
        AmountCapping,
        Discount
      );
      if (res) {
        setRefresh((refresh) => !refresh);
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Created Store Wise Successfully",
          showConfirmButton: true,
        });
        setSelectedFromDate("");
        setSelectedToDate("");
        setOrderAmount("");
        setAmountCapping("");
        setDiscount("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllCouponCodes = async () => {
    try {
      const res = await getAllCouponCOdes();
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllUserWise();
    handleGetAllCouponCOdeSPlash();
    handleGetAllCouponCodes();
  }, []);

  useEffect(() => {
    handleGetUserWiseById();
  }, [userWiseData]);

  useEffect(() => {
    handleGetCouponCOdeSplashById();
  }, [couponCOdeSplash]);

  const handleUserWiseOpen = () => {
    setUserWiseOpen((open) => !open);
  };

  const handleStoreWiseOpen = () => {
    setStoreWiseOpen((open) => !open);
  };
  const handlePromotionWiseOpen = () => {
    setPromotionWiseOpen((open) => !open);
  };

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Promotions
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            handleUpdateUserWise();
            handleUpdateCouponCOdeSplash();
          }}
        >
          Submit
        </Button>
      </Box>
      <Box sx={{ ml: 3, mr: 3, mt: 5 }}>
        <Box sx={{ border: "1px solid #ddd" }}>
          <Box>
            <Typography sx={{ fontWeight: "bold", p: 2 }}>User Wise</Typography>
            <Box>
              <Card
                elevation={0}
                sx={{
                  border: "1px solid #ddd",
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    background: "#000",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  onClick={handleUserWiseOpen}
                >
                  <Typography
                    sx={{ p: 1.5, color: "white", fontWeight: "bold" }}
                  >
                    On First Order Get Discount
                  </Typography>
                  {userWiseActive ? (
                    <Typography
                      sx={{ fontSize: "16px", color: "#65a13d", p: 1.5 }}
                    >
                      Active
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: "16px", color: "red", p: 1.5 }}>
                      IN-Active
                    </Typography>
                  )}
                </Box>
                {!loading ? (
                  <>
                    {userWiseOpen && (
                      <Box sx={{ p: 2 }}>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          >
                            Active:
                          </Typography>
                          <Checkbox
                            value={userWiseOrderDataById?.active}
                            checked={userWiseOrderDataById?.active === true}
                            onChange={() =>
                              setUserWiseOrderDataById({
                                ...userWiseOrderDataById,
                                active: !userWiseOrderDataById?.active,
                              })
                            }
                          />
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          >
                            Discount Amount:
                          </Typography>
                          <TextField
                            size="small"
                            placeholder="Discount Amount"
                            type="number"
                            sx={{ width: "400px" }}
                            value={userWiseOrderDataById?.DisCount}
                            onChange={(e) =>
                              setUserWiseOrderDataById({
                                ...userWiseOrderDataById,
                                DisCount: e.target.value,
                              })
                            }
                          />
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          >
                            Min. Order Amount:
                          </Typography>
                          <TextField
                            size="small"
                            placeholder=" Min. Order Amount"
                            type="number"
                            sx={{ width: "400px" }}
                            value={userWiseOrderDataById?.OrderAmount}
                            onChange={(e) =>
                              setUserWiseOrderDataById({
                                ...userWiseOrderDataById,
                                OrderAmount: e.target.value,
                              })
                            }
                          />
                        </Box>
                      </Box>
                    )}
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50vh",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Card>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "bold", p: 2 }}>
              Store Wise
            </Typography>
            <Box>
              <Card
                elevation={0}
                sx={{
                  border: "1px solid #ddd",
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    background: "#000",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  onClick={handleStoreWiseOpen}
                >
                  <Typography
                    sx={{ p: 1.5, color: "white", fontWeight: "bold" }}
                  >
                    Discount according to slab and period
                  </Typography>
                  {active ? (
                    <Typography
                      sx={{ fontSize: "16px", color: "#65a13d", p: 1.5 }}
                    >
                      Active
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: "16px", color: "red", p: 1.5 }}>
                      IN-Active
                    </Typography>
                  )}
                </Box>
                {!loading3 ? (
                  <>
                    {storeWiseOpen && (
                      <Box sx={{ p: 2 }}>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          >
                            Active:
                          </Typography>
                          <Checkbox
                            value={active}
                            checked={active === true}
                            onChange={() => setActive((active) => !active)}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          >
                            Date From(MM/DD/YYYY):
                          </Typography>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                size="small"
                                sx={{ width: "400px" }}
                                disablePast
                                value={selectedFromDate && selectedFromDate.$d}
                                onChange={(date) => handleDateChange(date)}
                                inputProps={{
                                  min: currentDate,
                                }}
                              />
                            </LocalizationProvider>

                            {error && (
                              <Typography sx={{ color: "red" }}>
                                From Date should be greater than or equal to
                                current date
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          >
                            Date To(MM/DD/YYYY):
                          </Typography>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                size="small"
                                sx={{ width: "400px" }}
                                disablePast
                                value={selectedToDate && selectedToDate.$d}
                                onChange={(date) => handleDateChange1(date)}
                                inputProps={{
                                  min: currentDate,
                                }}
                              />
                            </LocalizationProvider>

                            {error1 && (
                              <Typography sx={{ color: "red" }}>
                                To Date should be greater than From Date
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          >
                            Min. Order Amount:
                          </Typography>
                          <TextField
                            size="small"
                            placeholder=" Min. Order Amount"
                            type="number"
                            sx={{ width: "400px" }}
                            value={orderAmount}
                            onChange={(e) => setOrderAmount(e.target.value)}
                          />
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          >
                            Discount In:
                          </Typography>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={discountIn}
                              onChange={(e) => setDiscountIn(e.target.value)}
                            >
                              <FormControlLabel
                                value="Percentage"
                                control={<Radio />}
                                label="Percentage"
                              />
                              <FormControlLabel
                                value="Flat"
                                control={<Radio />}
                                label="Flat"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        {discountIn === "Percentage" && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                minWidth: "300px",
                              }}
                            >
                              Maximum Amount Capping:
                            </Typography>
                            <TextField
                              size="small"
                              placeholder=" Maximum Amount Capping"
                              type="number"
                              sx={{ width: "400px" }}
                              value={AmountCapping}
                              onChange={(e) => setAmountCapping(e.target.value)}
                            />
                          </Box>
                        )}
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          >
                            Discount:
                          </Typography>
                          <TextField
                            size="small"
                            placeholder="Discount"
                            type="number"
                            sx={{ width: "400px" }}
                            value={Discount}
                            onChange={(e) => setDiscount(e.target.value)}
                          />
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          ></Typography>
                          <Button
                            variant="contained"
                            onClick={handleCreateNewStoreWise}
                          >
                            SUBMIT
                          </Button>
                        </Box>
                        <StoreWiseTable
                          refresh={refresh}
                          setLoading3={setLoading3}
                        />
                      </Box>
                    )}
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50vh",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Card>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "bold", p: 2 }}>Promotion</Typography>
            <Box>
              <Card
                elevation={0}
                sx={{
                  border: "1px solid #ddd",
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    background: "#000",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  onClick={handlePromotionWiseOpen}
                >
                  <Typography
                    sx={{ p: 1.5, color: "white", fontWeight: "bold" }}
                  >
                    Coupon Code Splash
                  </Typography>
                  {couponcodesplashactive ? (
                    <Typography
                      sx={{ fontSize: "16px", color: "#65a13d", p: 1.5 }}
                    >
                      Active
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: "16px", color: "red", p: 1.5 }}>
                      IN-Active
                    </Typography>
                  )}
                </Box>
                {!loading1 ? (
                  <>
                    {PromotionWiseOpen && (
                      <Box sx={{ p: 2 }}>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          >
                            Active:
                          </Typography>
                          <Checkbox
                            value={couponsplashData?.active}
                            checked={couponsplashData?.active === true}
                            onChange={(e) =>
                              setCouponSplashData({
                                ...couponsplashData,
                                active: !couponsplashData?.active,
                              })
                            }
                          />
                        </Box>

                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          >
                            Main Title:
                          </Typography>
                          <TextField
                            size="small"
                            placeholder="Main Title"
                            sx={{ width: "400px" }}
                            value={couponsplashData?.MainTitle}
                            onChange={(e) =>
                              setCouponSplashData({
                                ...couponsplashData,
                                MainTitle: e.target.value,
                              })
                            }
                          />
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          >
                            Sub Title:
                          </Typography>
                          <TextField
                            size="small"
                            placeholder="Sub Title"
                            sx={{ width: "400px" }}
                            value={couponsplashData?.subTitle}
                            onChange={(e) =>
                              setCouponSplashData({
                                ...couponsplashData,
                                subTitle: e.target.value,
                              })
                            }
                          />
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          >
                            Upload Image :<br />
                            (Image Should be 900*630 pixels)
                          </Typography>
                          <TextField
                            size="small"
                            type="file"
                            placeholder="Sub Title"
                            sx={{ width: "400px" }}
                          />
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          >
                            Coupon Code :
                          </Typography>
                          <Select
                            size="small"
                            sx={{ width: "400px" }}
                            value={couponsplashData?.CouponCode}
                            onChange={(e) =>
                              setCouponSplashData({
                                ...couponsplashData,
                                CouponCode: e.target.value,
                              })
                            }
                          >
                            {data.map((item) => {
                              if (item.active === true) {
                                return (
                                  <MenuItem value={item.couponCode}>
                                    {item.couponCode}
                                  </MenuItem>
                                );
                              }
                            })}
                          </Select>
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "300px",
                            }}
                          >
                            Time(in seconds):
                          </Typography>
                          <TextField
                            size="small"
                            placeholder="Time(in seconds)"
                            type="number"
                            sx={{ width: "400px" }}
                            value={couponsplashData?.Time}
                            onChange={(e) =>
                              setCouponSplashData({
                                ...couponsplashData,
                                Time: e.target.value,
                              })
                            }
                          />
                        </Box>
                      </Box>
                    )}
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      aignItems: "center",
                      height: "50vh",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Card>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Promotions;
