import { Button, Card, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const CreateNewBrand = () => {
  const [name, setName] = useState();
  const [image, setImage] = useState(null);
  const [sortId, setsortId] = useState();

  const { id } = useParams();

  const navigate = useNavigate();

  const handleNewAddBrand = async () => {
    try {
      // Upload the logo image to Cloudinary
      const formData = new FormData();
      formData.append("file", image);
      formData.append("upload_preset", "insta-clone");
      formData.append("cloud_name", "bunny1705");
      formData.append("folder", "Brand_images");

      const response = await fetch(
        "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      console.log(data);
      const logoUrl = data.secure_url;

      // Make the request to the API to create a new brand attribute
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/AddNewBrandAttribute",
        {
          name,
          image: logoUrl,
          sortId,
        }
      );

      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Brand Created SuccessFully",
          showConfirmButton: true,
          timer: 1500,
        });
        navigate(`/manage-brand/${id}`);
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
        timer: 1500,
      });
    }
  };

  return (
    <div style={{ marginTop: "150px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mr: 3 }}>
        <Typography></Typography>
        <Button variant="contained" onClick={() => navigate(-1)}>
          BACK
        </Button>
      </Box>
      <Card
        sx={{ maxWidth: "450px", p: 2 }}
        style={{ margin: "auto", marginTop: "30px" }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "bold",
            textAlign: "center",
            mb: 2,
          }}
        >
          Create New Brand
        </Typography>
        <Box>
          <Box>
            <Typography sx={{ fontSize: "17px" }}>Attribute Name</Typography>
            <TextField
              placeholder="Attribute Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              sx={{ mb: 2, mt: 0.5 }}
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "17px" }}>Image</Typography>
            <TextField
              type="file"
              inputProps={{ accept: ".jpeg,.png,.jpg,.gif" }}
              onChange={(e) => setImage(e.target.files[0])}
              fullWidth
              sx={{ mb: 2, mt: 0.5 }}
            />
            {image && (
              <img
                src={URL.createObjectURL(image)}
                alt={image.name}
                width="120px"
                style={{ margin: "10px" }}
              />
            )}
          </Box>
          <Box>
            <Typography sx={{ fontSize: "17px" }}>Sort Id</Typography>
            <TextField
              placeholder="Sort Id"
              variant="outlined"
              value={sortId}
              onChange={(e) => setsortId(e.target.value)}
              fullWidth
              sx={{ mb: 2, mt: 0.5 }}
            />
          </Box>
        </Box>
        <Button variant="contained" fullWidth onClick={handleNewAddBrand}>
          SUBMIT
        </Button>
      </Card>
    </div>
  );
};

export default CreateNewBrand;
