import {
  FormControl,
  Card,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import countries from "../Masters/CountryMaster.json";
import { postcodeValidator } from "postcode-validator";

import CurrencyMaster from "./LogisticProviders/CurrencyMaster.json";

const StoreProfile = () => {
  const [storeProfile, setStoreProfile] = useState({});
  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);
  const [pincode, setPincode] = useState();
  const [address, setAddress] = useState(null);
  const [error, setError] = useState("");
  const [ComapnyNameError, setCompanyNameError] = useState("");
  const [ComapnyAddressError, setCompanyAddressError] = useState("");
  const [TitleError, setTitleError] = useState("");
  const [validationError, setValidationError] = useState("");
  const [CopyrightError, setCopyrightError] = useState("");
  const [OrderSequenceNumberError, setOrderSequenceNumberError] = useState("");
  const [SequenceNumberError, setSequenceNumberError] = useState("");
  const [shippingChargesError, setshippingChargesError] = useState("");
  const [CreditNoteError, setCreditNoteError] = useState("");
  const [code, setcode] = useState("");
  const [currency, setCurrency] = useState("");

  const [emailError, setEmailError] = useState("");
  const [newselectedCountry, setNewSelectedCountry] = useState("");

  // console.log(CurrencyMaster);

  const [handleGetAdmin, setHandleGetAdmin] = useState([]);

  const handleGetAdminDetails = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getAdmin`
      );
      if (res) {
        setHandleGetAdmin(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleCompanyName = (e) => {
    if (e.target.value.length === 0) {
      setCompanyNameError("Company Name is Required");
    } else if (e.target.value.length < 3) {
      setCompanyNameError("Company Name Should be greater than 3 charecters");
    } else {
      setCompanyNameError("");
    }
    setStoreProfile({
      ...storeProfile,
      CompanyName: e.target.value,
    });
  };

  const handleValidatePhoneNumber = (e) => {
    if (e.target.value.length === 0) {
      setValidationError("Contact number is required");
    } else {
      setValidationError(null);
    }
    setStoreProfile({
      ...storeProfile,
      ContactNumber: e.target.value,
    });
  };
  const handleCompanyAddressChange = (e) => {
    if (e.target.value.length === 0) {
      setCompanyAddressError("Company Address is Required");
    } else {
      setCompanyAddressError("");
    }
    setStoreProfile({
      ...storeProfile,
      ComapnayAddress: e.target.value,
    });
  };

  const handleTitleChange = (e) => {
    if (e.target.value.length === 0) {
      setTitleError("Title is required");
    } else if (e.target.value.length < 3) {
      setTitleError("Title Should be greather than 3 charecters");
    } else {
      setTitleError("");
    }
    setStoreProfile({
      ...storeProfile,
      MarketPlaceTitle: e.target.value,
    });
  };
  const handleFooterChange = (e) => {
    if (e.target.value.length === 0) {
      setCopyrightError("Footer is required");
    } else if (e.target.value.length < 3) {
      setCopyrightError("Footer Should be greather than 3 charecters");
    } else {
      setCopyrightError("");
    }
    setStoreProfile({
      ...storeProfile,
      copyRightMessage: e.target.value,
    });
  };
  const handleSequenceNumberChange = (e) => {
    if (e.target.value.length === 0) {
      setOrderSequenceNumberError("Seller Invoice Sequence Number is required");
    } else {
      setOrderSequenceNumberError("");
    }
    setStoreProfile({
      ...storeProfile,
      SellerInvoiceSequenceNumber: e.target.value,
    });
  };

  const handleOrderSequenceNumberChange = (e) => {
    if (e.target.value.length === 0) {
      setSequenceNumberError("Order Sequence Number is required");
    } else {
      setSequenceNumberError("");
    }
    setStoreProfile({
      ...storeProfile,
      OrderSequenceNumber: e.target.value,
    });
  };
  const handleShippingChargesChange = (e) => {
    if (e.target.value.length === 0) {
      setshippingChargesError("Shipping Charges is required");
    } else {
      setshippingChargesError("");
    }
    setStoreProfile({
      ...storeProfile,
      shippingChargesByMarketPlace: e.target.value,
    });
  };
  const handleCreditNoteChange = (e) => {
    if (e.target.value.length === 0) {
      setCreditNoteError("Credit Note Number is required");
    } else {
      setCreditNoteError("");
    }
    setStoreProfile({
      ...storeProfile,
      CreditNoteNumber: e.target.value,
    });
  };

  // State variables

  // Handle email change
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setStoreProfile({
      ...storeProfile,
      Email: email,
    });

    // Validate email
    if (email === "") {
      setEmailError("Email is required");
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  // console.log("store", storeProfile);

  const { id } = useParams();

  const handleStoreProfile = async () => {
    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const result = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getStoreProfile/${id}`,
        config
      );
      if (result) {
        // console.log("single store profile", result.data);
        setStoreProfile(result.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // handleStore Edit

  const updatedStoreProfile = {
    ...storeProfile,
    Country:
      address && address.Country ? address.Country : storeProfile.Country,
    City: address && address.city ? address.city : storeProfile.City,
    State: address && address.state ? address.state : storeProfile.State,
    DefaultCurrency:
      currency && currency ? currency : storeProfile.DefaultCurrency,
  };

  const handleStoreEdit = async () => {
    setLoading1(true);
    try {
      if (
        ComapnyNameError ||
        ComapnyAddressError ||
        TitleError ||
        validationError ||
        CopyrightError ||
        OrderSequenceNumberError ||
        SequenceNumberError ||
        shippingChargesError ||
        CreditNoteError ||
        error
      ) {
        return;
      }
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/EditStoreProfile/${id}`,
        updatedStoreProfile,
        config
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Data Updated Successfully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        position: "center",
        icon: "error",
        text: "error",
        showConfirmButton: true,
      });
    } finally {
      setLoading1(false);
      // loading
    }
  };

  // get country,state,city from pincode

  // console.log(address);

  const handleInputChange = (event) => {
    const postalCode = event.target.value;
    setStoreProfile({ ...storeProfile, PinCode: postalCode });

    // If the country is India, validate PIN code
    if (storeProfile.Country === "India") {
      const isValidPincode = /^[1-9][0-9]{5}$/.test(postalCode);
      if (isValidPincode) {
        axios
          .get(`https://api.postalpincode.in/pincode/${postalCode}`)
          .then((response) => {
            const postOffice = response.data[0].PostOffice[0];
            const city = postOffice.Block;
            const state = postOffice.State;
            setAddress({ city, state });
            setError(null);
          })
          .catch((error) => {
            console.error(error);
            setAddress(null);
          });
        setPincode(postalCode);
      } else {
        setAddress(null);
        setPincode(postalCode);
        setError("Invalid PIN code");
      }
    } else {
      // For non-India countries, validate postal code using postcode-validator library
      const isValidPostalCode = postcodeValidator(postalCode, code);
      if (isValidPostalCode) {
        axios
          .get(
            `https://secure.geonames.org/postalCodeSearchJSON?postalcode=${postalCode}&country=${code}&maxRows=1&username=venkat_1705`
          )
          .then((response) => {
            const city = response.data.postalCodes[0].placeName;
            const state = response.data.postalCodes[0].adminName1;
            setAddress({ city, state });
            setError(null);
          })
          .catch((error) => {
            console.error(error);
            setAddress(null);
          });
        setPincode(postalCode);
      } else {
        setAddress(null);
        setPincode(postalCode);
        setError("Invalid postal/Zipcode code");
      }
    }
  };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setStoreProfile((prevStoreProfile) => ({
      ...prevStoreProfile,
      Country: selectedCountry,
    }));
    setNewSelectedCountry(selectedCountry);

    // Determine the country code for the selected country
    const selectedCode = countries.find(
      (c) => c.name === selectedCountry
    )?.code;
    setcode(selectedCode);

    // Determine whether the current pin/zip code is valid for the selected country
    let isValid = false;
    if (selectedCountry === "India") {
      isValid = /^[1-9][0-9]{5}$/.test(storeProfile.PinCode);
    } else {
      isValid = postcodeValidator(storeProfile.PinCode, selectedCode);
    }

    // Update the error state based on whether the pin/zip code is valid
    if (isValid) {
      setError(null);
    } else {
      setError(
        `Invalid postal/zipcode code for ${selectedCountry}. Please enter a valid code.`
      );
    }
  };

  useEffect(() => {
    countries.map((item) => {
      if (item.name == storeProfile.Country && storeProfile.Country) {
        console.log(item.code);
        setcode(item.code);
      }
    });
  }, [storeProfile.Country]);

  useEffect(() => {
    handleStoreProfile();
    handleGetAdminDetails();
  }, []);

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 4,
          mr: 4,
          mb: 2,
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          MarketPlace Profile
        </Typography>
        <Button
          variant="contained"
          onClick={handleStoreEdit}
          disableElevation
          disabled={Loading1}
        >
          {Loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
        </Button>
      </Box>
      {!Loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              width: "43%",
              p: 2,
              border: "1px solid #dddddd",
              mb: 2,
            }}
            elevation={0}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
                mb: 2,
              }}
            >
              Manage Store Profile
            </Typography>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>Company Name : </Typography>
              <TextField
                value={storeProfile.CompanyName}
                type="text"
                size="small"
                onChange={handleCompanyName}
                error={ComapnyNameError}
                helperText={ComapnyNameError}
                sx={{ mb: 2, width: "100%" }}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>
                Company Address :{" "}
              </Typography>
              <TextField
                value={storeProfile.ComapnayAddress}
                multiline
                type="text"
                size="small"
                onChange={handleCompanyAddressChange}
                error={ComapnyAddressError}
                helperText={ComapnyAddressError}
                sx={{ mb: 2, width: "100%" }}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>
                Default Currency :{" "}
              </Typography>
              <Select
                id="currency-select"
                sx={{ width: "100%", mb: 2 }}
                size="small"
                value={
                  currency && currency ? currency : storeProfile.DefaultCurrency
                }
                onChange={(e) => {
                  setCurrency(e.target.value);
                  setStoreProfile({
                    ...storeProfile,
                    DefaultCurrency: currency,
                  });
                }}
              >
                {Object.keys(CurrencyMaster).map((currencyCode) => (
                  <MenuItem
                    key={currencyCode}
                    value={`${CurrencyMaster[currencyCode].name}-${CurrencyMaster[currencyCode].symbol}`}
                  >
                    {CurrencyMaster[currencyCode].name} (
                    {CurrencyMaster[currencyCode].symbol})
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>Country : </Typography>
              <Select
                sx={{ mb: 2, width: "100%" }}
                size="small"
                value={storeProfile.Country}
                onChange={handleCountryChange}
                error={error}
                helperText={error}
              >
                {countries.map((item) => (
                  <MenuItem value={item.name}>{item.name}</MenuItem>
                ))}
              </Select>
            </Box>
            {storeProfile.Country == "India" && (
              <Box>
                <Box>
                  <Typography sx={{ fontSize: "17px" }}>PinCode : </Typography>
                  <TextField
                    size="small"
                    value={pincode && pincode ? pincode : storeProfile.PinCode}
                    onChange={handleInputChange}
                    maxLength="6"
                    error={error}
                    helperText={error}
                    sx={{ mb: 2, width: "100%" }}
                  />
                </Box>
                <Box>
                  {storeProfile && (
                    <Box>
                      <Box>
                        <Typography sx={{ fontSize: "17px" }}>
                          City :
                        </Typography>
                        <TextField
                          size="small"
                          value={
                            address && address.city
                              ? address.city
                              : storeProfile.City
                          }
                          sx={{ mb: 2, mt: 0.5, width: "100%" }}
                        />
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "17px" }}>
                          State :
                        </Typography>
                        <TextField
                          size="small"
                          value={
                            address && address.state
                              ? address.state
                              : storeProfile.State
                          }
                          sx={{ mb: 2, mt: 0.5, width: "100%" }}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "17px" }}>GSTIN No : </Typography>
                  <TextField
                    value={storeProfile.GSTINNo}
                    multiline
                    type="number"
                    size="small"
                    onChange={(e) =>
                      setStoreProfile({
                        ...storeProfile,
                        GSTINNo: e.target.value,
                      })
                    }
                    sx={{ mb: 2, width: "100%" }}
                  />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "17px" }}>PAN No : </Typography>
                  <TextField
                    value={storeProfile.PanNo}
                    multiline
                    type="number"
                    size="small"
                    onChange={(e) =>
                      setStoreProfile({
                        ...storeProfile,
                        PanNo: e.target.value,
                      })
                    }
                    sx={{ mb: 2, width: "100%" }}
                  />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "17px" }}>CIN No : </Typography>
                  <TextField
                    value={storeProfile.CINNo}
                    type="number"
                    size="small"
                    onChange={(e) =>
                      setStoreProfile({
                        ...storeProfile,
                        CINNo: e.target.value,
                      })
                    }
                    sx={{ mb: 2, width: "100%" }}
                  />
                </Box>
              </Box>
            )}

            {storeProfile.Country != "India" && (
              <>
                <Box>
                  <Box>
                    <Typography sx={{ fontSize: "17px" }}>
                      PostCode/ZipCode :{" "}
                    </Typography>
                    <TextField
                      size="small"
                      value={
                        pincode && pincode ? pincode : storeProfile.PinCode
                      }
                      onChange={handleInputChange}
                      maxLength="6"
                      error={error ? true : false}
                      helperText={error}
                      sx={{ mb: 2, width: "100%" }}
                    />
                  </Box>
                  {storeProfile && (
                    <>
                      <Box>
                        <Typography sx={{ fontSize: "17px" }}>City</Typography>
                        <TextField
                          size="small"
                          value={
                            address && address.city
                              ? address.city
                              : storeProfile.City
                          }
                          sx={{ mb: 2, mt: 0.5, width: "100%" }}
                        />
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "17px" }}>
                          State/County
                        </Typography>
                        <TextField
                          size="small"
                          value={
                            address && address.state
                              ? address.state
                              : storeProfile.State
                          }
                          sx={{ mb: 2, mt: 0.5, width: "100%" }}
                        />
                      </Box>
                    </>
                  )}
                </Box>
                <Box>
                  <Box>
                    <Typography sx={{ fontSize: "17px" }}>VAT No : </Typography>
                    <TextField
                      value={storeProfile.VATNo}
                      multiline
                      type="number"
                      size="small"
                      onChange={(e) =>
                        setStoreProfile({
                          ...storeProfile,
                          VATNo: e.target.value,
                        })
                      }
                      sx={{ mb: 2, width: "100%" }}
                    />
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "17px" }}>
                      Social Security Number :{" "}
                    </Typography>
                    <TextField
                      value={storeProfile.SocialSecurity}
                      multiline
                      type="number"
                      size="small"
                      onChange={(e) =>
                        setStoreProfile({
                          ...storeProfile,
                          SocialSecurity: e.target.value,
                        })
                      }
                      sx={{ mb: 2, width: "100%" }}
                    />
                  </Box>
                </Box>
              </>
            )}

            <Box>
              <Typography sx={{ fontSize: "17px" }}>
                Contact Number :{" "}
              </Typography>
              <TextField
                fullWidth
                type="number"
                size="small"
                variant="outlined"
                value={storeProfile.ContactNumber}
                onChange={handleValidatePhoneNumber}
                error={Boolean(validationError)}
                helperText={validationError}
                sx={{ mb: 2, width: "100%" }}
              />
            </Box>

            <Box>
              <Typography sx={{ fontSize: "17px" }}> Email : </Typography>
              <TextField
                value={storeProfile.Email}
                multiline
                type="email"
                size="small"
                onChange={handleEmailChange}
                error={emailError !== ""}
                helperText={emailError}
                InputProps={{
                  style: { color: emailError !== "" ? "red" : "inherit" },
                }}
                sx={{ mb: 2, width: "100%" }}
              />
            </Box>

            <Box>
              <Typography sx={{ fontSize: "17px" }}>Store URL : </Typography>
              <Typography sx={{ fontSize: "16px", mb: 2 }}>
                {storeProfile.StoreURL}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>
                Super Admin Email :{" "}
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "bold", cursor: "pointer" }}
                // onClick={()=>}
              >
                {handleGetAdmin.email}
              </Typography>
              <Typography sx={{ color: "red", mt: 1 }}>
                *Note : Click on Email to change Super Admin Email and Password
              </Typography>
            </Box>
          </Card>
          <Card
            sx={{
              width: "43%",
              p: 2,
              border: "1px solid #dddddd",
              mb: 2,
            }}
            elevation={0}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
                mb: 2,
              }}
            >
              SEO Settings
            </Typography>
            <Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>Title : </Typography>
                <TextField
                  value={storeProfile?.MarketPlaceTitle}
                  type="text"
                  size="small"
                  onChange={handleTitleChange}
                  error={TitleError}
                  helperText={TitleError}
                  sx={{ mb: 2, width: "100%" }}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>Keywords : </Typography>
                <TextField
                  value={storeProfile?.MetaKeywords}
                  multiline
                  type="text"
                  size="small"
                  onChange={(e) =>
                    setStoreProfile({
                      ...storeProfile,
                      MetaKeywords: e.target.value,
                    })
                  }
                  sx={{ mb: 2, width: "100%" }}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  Description :{" "}
                </Typography>
                <TextField
                  value={storeProfile.MetaDescription}
                  multiline
                  type="text"
                  size="small"
                  onChange={(e) =>
                    setStoreProfile({
                      ...storeProfile,
                      MetaDescription: e.target.value,
                    })
                  }
                  sx={{ mb: 2, width: "100%" }}
                />
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              width: "43%",
              p: 2,
              border: "1px solid #dddddd",
              mb: 2,
            }}
            elevation={0}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
                mb: 2,
              }}
            >
              Miscellaneous
            </Typography>
            <Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  Maximum length of Product Title in listing :{" "}
                </Typography>
                <TextField
                  value={storeProfile.MaxLengthofProductTitle}
                  multiline
                  type="number"
                  size="small"
                  onChange={(e) =>
                    setStoreProfile({
                      ...storeProfile,
                      MaxLengthofProductTitle: e.target.value,
                    })
                  }
                  sx={{ mb: 2, width: "100%" }}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  Copyright message in footer of store :{" "}
                </Typography>
                <TextField
                  value={storeProfile.copyRightMessage}
                  multiline
                  type="text"
                  size="small"
                  onChange={handleFooterChange}
                  error={CopyrightError}
                  helperText={CopyrightError}
                  sx={{ mb: 2, width: "100%" }}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  Order Sequence Number :{" "}
                </Typography>
                <TextField
                  value={storeProfile.OrderSequenceNumber}
                  multiline
                  type="number"
                  size="small"
                  onChange={handleOrderSequenceNumberChange}
                  error={SequenceNumberError}
                  helperText={SequenceNumberError}
                  sx={{ mb: 2, width: "100%" }}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  Seller Invoice Sequence Number :{" "}
                </Typography>
                <TextField
                  value={storeProfile.SellerInvoiceSequenceNumber}
                  multiline
                  type="number"
                  size="small"
                  onChange={handleSequenceNumberChange}
                  error={OrderSequenceNumberError}
                  helperText={OrderSequenceNumberError}
                  sx={{ mb: 2, width: "100%" }}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  credit note number :{" "}
                </Typography>
                <TextField
                  type="number"
                  value={storeProfile.CreditNoteNumber}
                  size="small"
                  onChange={handleCreditNoteChange}
                  error={CreditNoteError}
                  helperText={CreditNoteError}
                  sx={{ mb: 2, width: "100%" }}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  Shipping Charges Charged By MarketPlace :{" "}
                </Typography>
                <TextField
                  value={storeProfile.shippingChargesByMarketPlace}
                  multiline
                  type="number"
                  size="small"
                  onChange={handleShippingChargesChange}
                  error={shippingChargesError}
                  helperText={shippingChargesError}
                  sx={{ mb: 2, width: "100%" }}
                />
              </Box>
              <Box>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Manage Inventory
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      checked={storeProfile.manageInventory === true}
                      onChange={(e) =>
                        setStoreProfile({
                          ...storeProfile,
                          manageInventory: true,
                        })
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      checked={storeProfile.manageInventory === false}
                      onChange={(e) =>
                        setStoreProfile({
                          ...storeProfile,
                          manageInventory: false,
                        })
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {storeProfile.manageInventory == true && (
                <>
                  <Box sx={{ mt: 2 }}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Do you want to enable low stock notification
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          checked={storeProfile.lowStockNotification === true}
                          onChange={(e) =>
                            setStoreProfile({
                              ...storeProfile,
                              lowStockNotification:
                                storeProfile.manageInventory === true && true,
                            })
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          checked={storeProfile.lowStockNotification === false}
                          onChange={(e) =>
                            setStoreProfile({
                              ...storeProfile,
                              lowStockNotification:
                                storeProfile.manageInventory === false && false,
                            })
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  {storeProfile.lowStockNotification && (
                    <Box sx={{ mt: 2 }}>
                      <Typography sx={{ fontSize: "17px" }}>
                        LowStock Items Threshold:{" "}
                      </Typography>
                      <TextField
                        value={storeProfile.ItemsThreshold}
                        multiline
                        type="number"
                        size="small"
                        onChange={(e) =>
                          setStoreProfile({
                            ...storeProfile,
                            ItemsThreshold: e.target.value,
                          })
                        }
                        sx={{ mb: 2, width: "100%" }}
                      />
                    </Box>
                  )}
                </>
              )}
              <Box sx={{ mt: 2 }}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Do you want to put your store offline for Under Construction
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      checked={storeProfile.StoreUnderConstruction === true}
                      onChange={(e) =>
                        setStoreProfile({
                          ...storeProfile,
                          StoreUnderConstruction: true,
                        })
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      checked={storeProfile.StoreUnderConstruction === false}
                      onChange={(e) =>
                        setStoreProfile({
                          ...storeProfile,
                          StoreUnderConstruction: false,
                        })
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "17px" }}>
                  The period of Abandon Cart(Hrs) :{" "}
                </Typography>
                <TextField
                  value={storeProfile.periodOfAbandonCart}
                  multiline
                  type="number"
                  size="small"
                  onChange={(e) =>
                    setStoreProfile({
                      ...storeProfile,
                      periodOfAbandonCart: e.target.value,
                    })
                  }
                  sx={{ mb: 2, width: "100%" }}
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "17px" }}>
                  Exchange and Return Text (pre-filled) :{" "}
                </Typography>
                <TextField
                  value={storeProfile.exchangeAndReturns}
                  multiline
                  type="number"
                  size="small"
                  onChange={(e) =>
                    setStoreProfile({
                      ...storeProfile,
                      exchangeAndReturns: e.target.value,
                    })
                  }
                  sx={{ mb: 2, width: "100%" }}
                />
              </Box>
            </Box>
          </Card>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "70vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default StoreProfile;
