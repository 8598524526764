import React from "react";
import SellerTable from "./SellerTabe";

const Sellers = () => {
  return (
    <div>
      <SellerTable />
    </div>
  );
};

export default Sellers;
