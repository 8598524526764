import {
  Button,
  Card,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

const CreateNew = () => {
  const [data, setData] = useState([]);
  const [selectRootCategory, setRootCategory] = useState();
  const [SelectedCategory, setSelectedCategory] = React.useState([]);
  const [SelectedCategoryId, setSelectedCategoryId] = React.useState();
  const [MainCategory, setMainCategory] = React.useState();
  const [MainCategoryId, setMainCategoryId] = React.useState();
  const [SelectedSubCategory, setSelectedSubCategory] = React.useState([]);
  const [SelectedSubCategoryData, setSelectedSubCategoryData] =
    React.useState();
  const [SelectedSubCategoryDataId, setSelectedSubCategoryDataId] =
    React.useState();

  const [categoryName, setCategoryName] = React.useState();
  const [uniqueUrl, setuniqueUrl] = React.useState();
  const [CategoryBannerUrl, setCategoryBannerUrl] = React.useState();
  const [TodaysDeal, setTodaysDeal] = React.useState(false);
  const [HomePageCarouseal, setHomePageCarouseal] = React.useState(false);
  const [FeatureBrand, setFeatureBrand] = React.useState(false);
  const [Commission, setCommission] = React.useState();
  const [ImageAltText, setImageAltText] = React.useState();
  const [Description, setDescription] = React.useState();

  const handleChange = (event) => {
    const newValue = event.target.value.replace(/\s+/g, "-");
    setCategoryName(event.target.value);
    setuniqueUrl(newValue);
  };

  const { id } = useParams();
  const handleRootCategory = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllSubCategoriesForRoot"
      );

      if (res) {
        setData(res.data[0].subcategories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handle create for Main root category

  const handleMainRootCategory = async () => {
    if (selectRootCategory && !MainCategory) {
      try {
        const res = await axios.post(
          `https://rccore-backend.cstechns.com/api/admin/createNewCategory/${SelectedCategoryId}`,
          {
            categoryName,
            uniqueUrl,
            CategoryBannerUrl,
            TodaysDeal,
            HomePageCarouseal,
            FeatureBrand,
            Commission,
            ImageAltText,
            Description,
          }
        );
        if (res) {
          Swal.fire({
            position: "center",
            icon: "success",
            text: "Created SuccessFully",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        Swal.fire({
          position: "center",
          icon: "error",
          text: error.response.data.error,
          showConfirmButton: true,
        });
      }
    } else if (!selectRootCategory && !MainCategory) {
      try {
        const res = await axios.post(
          `https://rccore-backend.cstechns.com/api/admin/createNewCategory/${id}`,
          {
            categoryName,
            uniqueUrl,
            CategoryBannerUrl,
            TodaysDeal,
            HomePageCarouseal,
            FeatureBrand,
            Commission,
            ImageAltText,
            Description,
          }
        );
        if (res) {
          Swal.fire({
            position: "center",
            icon: "success",
            text: "Created SuccessFully",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        Swal.fire({
          position: "center",
          icon: "error",
          text: error.response.data.error,
          showConfirmButton: true,
        });
      }
    } else if (selectRootCategory && !SelectedSubCategoryData) {
      try {
        const res = await axios.post(
          `https://rccore-backend.cstechns.com/api/admin/createNewCategory/${MainCategoryId}`,
          {
            categoryName,
            uniqueUrl,
            CategoryBannerUrl,
            TodaysDeal,
            HomePageCarouseal,
            FeatureBrand,
            Commission,
            ImageAltText,
            Description,
          }
        );
        if (res) {
          Swal.fire({
            position: "center",
            icon: "success",
            text: "Created SuccessFully",
            showConfirmButton: true,
            timer: 1500,
          });
        }
      } catch (error) {
        Swal.fire({
          position: "center",
          icon: "error",
          text: error.response.data.error,
          showConfirmButton: true,
          timer: 1500,
        });
      }
    } else {
      try {
        const res = await axios.post(
          `https://rccore-backend.cstechns.com/api/admin/createNewCategory/${SelectedSubCategoryDataId}`,
          {
            categoryName,
            uniqueUrl,
            CategoryBannerUrl,
            TodaysDeal,
            HomePageCarouseal,
            FeatureBrand,
            Commission,
            ImageAltText,
            Description,
          }
        );
        if (res) {
          Swal.fire({
            position: "center",
            icon: "success",
            text: "Created SuccessFully",
            showConfirmButton: true,
            timer: 1500,
          });
        }
      } catch (error) {
        Swal.fire({
          position: "center",
          icon: "error",
          text: error.response.data.error,
          showConfirmButton: true,
          timer: 1500,
        });
      }
    }
  };

  React.useEffect(() => {
    handleRootCategory();
  }, []);
  return (
    <div style={{ marginTop: "150px" }}>
      <Card
        sx={{ maxWidth: "700px", p: 2 }}
        style={{
          margin: "auto",
          marginTop: "30px",
          border: "1px solid #dddddd",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Create New Category
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography>Root Category : </Typography>
            <Select
              displayEmpty
              sx={{ width: "400px" }}
              size="small"
              value={selectRootCategory}
              onChange={(e) => {
                const selectedCategory = data.find(
                  (item) => item.name === e.target.value
                );
                setRootCategory(e.target.value);
                setSelectedCategoryId(selectedCategory._id);
                setSelectedCategory(selectedCategory.subcategories);
              }}
            >
              <InputLabel sx={{ ml: 2 }}>Select Category</InputLabel>
              {data.map((item) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography>Main Category : </Typography>
            <Select
              displayEmpty
              sx={{ width: "400px" }}
              size="small"
              value={MainCategory}
              onChange={(e) => {
                const selectedCategory = SelectedCategory.find(
                  (item) => item.name === e.target.value
                );
                setMainCategory(e.target.value);
                setMainCategoryId(selectedCategory._id);
                setSelectedSubCategory(selectedCategory.subcategories);
              }}
            >
              <InputLabel sx={{ ml: 2 }}>Select Category</InputLabel>
              {SelectedCategory.map((item) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography>Sub Category : </Typography>
            <Select
              displayEmpty
              sx={{ width: "400px" }}
              size="small"
              value={SelectedSubCategoryData}
              onChange={(e) => {
                const selectedCategory = SelectedSubCategory.find(
                  (item) => item.name === e.target.value
                );
                setSelectedSubCategoryData(e.target.value);
                setSelectedSubCategoryDataId(selectedCategory._id);
              }}
            >
              <InputLabel sx={{ ml: 2 }}>Select Category</InputLabel>

              {SelectedSubCategory.map((item) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography>Category Name : </Typography>
            <TextField
              placeholder="Category Name"
              sx={{ width: "400px" }}
              value={categoryName}
              onChange={handleChange}
              size="small"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography>Unique URL : </Typography>
            <TextField
              placeholder="Unique URL"
              value={uniqueUrl}
              sx={{ width: "400px" }}
              size="small"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography> Image : </Typography>
            <TextField type="file" sx={{ width: "400px" }} size="small" />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography> Category Banner Image : </Typography>
            <TextField type="file" sx={{ width: "400px" }} size="small" />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box>
              <Typography> Category Home Image : </Typography>
              <Typography>(Image Should be 300*391 pixels) : </Typography>
            </Box>
            <TextField type="file" sx={{ width: "400px" }} size="small" />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography> Category Banner Url : </Typography>
            <TextField
              placeholder="Category Banner Url"
              value={CategoryBannerUrl}
              onChange={(e) => setCategoryBannerUrl(e.target.value)}
              sx={{ width: "400px" }}
              size="small"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "200px",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography> Today's Deal : </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value={TodaysDeal}
                  control={<Radio />}
                  checked={TodaysDeal === true}
                  onChange={(e) => setTodaysDeal(true)}
                  label="Yes"
                />
                <FormControlLabel
                  value={TodaysDeal}
                  control={<Radio />}
                  checked={TodaysDeal === false}
                  onChange={(e) => setTodaysDeal(false)}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "154px",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography> Homepage Crousal : </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value={HomePageCarouseal}
                  control={<Radio />}
                  checked={HomePageCarouseal === true}
                  onChange={(e) => setHomePageCarouseal(true)}
                  label="Yes"
                />
                <FormControlLabel
                  value={HomePageCarouseal}
                  control={<Radio />}
                  checked={HomePageCarouseal === false}
                  onChange={(e) => setHomePageCarouseal(false)}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "190px",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography> Feature Brand : </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value={FeatureBrand}
                  control={<Radio />}
                  checked={FeatureBrand === true}
                  onChange={(e) => setFeatureBrand(true)}
                  label="Yes"
                />
                <FormControlLabel
                  value={FeatureBrand}
                  control={<Radio />}
                  checked={FeatureBrand === false}
                  onChange={(e) => setFeatureBrand(false)}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography>Commission (%): </Typography>
            <TextField
              placeholder="Commission"
              value={Commission}
              onChange={(e) => setCommission(e.target.value)}
              sx={{ width: "400px" }}
              size="small"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography> Image Alt Text : </Typography>
            <TextField
              placeholder=" Image Alt Text"
              value={ImageAltText}
              onChange={(e) => setImageAltText(e.target.value)}
              sx={{ width: "400px" }}
              size="small"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography> Description : </Typography>
            <TextField
              placeholder="Description "
              value={Description}
              onChange={(e) => setDescription(e.target.value)}
              sx={{ width: "400px" }}
              size="small"
            />
          </Box>
        </Box>
        <Typography sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            fullWidth
            onClick={handleMainRootCategory}
          >
            Submit
          </Button>
        </Typography>
      </Card>
    </div>
  );
};

export default CreateNew;
