import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import CkEditor from "./CKEditorComponent";
import Example from "./CKEditorComponent";
// import { CKEditor } from "ckeditor4-react";
import CKEditorComponent from "./CKEditorComponent";
import { TemplatesData } from "./TemplateData";
import { CKEditor } from "ckeditor4-react";

const EmailTemplateEdit = () => {
  const [data, setData] = useState({});
  const [mailProfiles, setMailProfiles] = useState([]);
  const { id } = useParams();

  const [subject, setSubject] = useState(data.Subject);
  const [fromEmail, setFromEmail] = useState();
  const [emailError, setEmailError] = useState(false);
  const [subjectError, setSubJectError] = useState();
  const [htmlContent, setHtmContent] = useState();
  const [parsedData, setParsedData] = useState("");

  const [loading, setLoading] = useState(false);

  const handlegetMailTemplate = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getMailTemplateByid/${id}`
      );
      if (res) {
        // console.log(res);
        setData(res.data);

        TemplatesData.find((item) => {
          if (item.id == res.data.newid) {
            setHtmContent(item.htmlContent);
          }
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllMailProfiles = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllEmailSettings"
      );
      if (res) {
        // console.log(res);
        setMailProfiles(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const selectedProfile = mailProfiles.find(
      (item) => item.profileName === data.profileName
    );
    if (selectedProfile) {
      setFromEmail(selectedProfile.email);
    }
  }, [data.profileName, mailProfiles]);

  const handleProfileNameChange = (event) => {
    setData({ ...data, profileName: event.target.value });
  };

  const handleUpdateMailTemplate = async () => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/handleUpdateMailTemplate/${id}`,
        {
          Subject: data.Subject,
          profileName: data.profileName,
          content: data.content,
          bcc: data.bcc,
          mailName: data.mailName,
          mailType: data.mailType,
          replyTo: data.replyTo,
        }
      );
      if (res) {
        alert("Updated");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handlegetMailTemplate();
    getAllMailProfiles();
  }, []);

  const handleEmailChange = (e) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setData({ ...data, replyTo: e.target.value });
    setEmailError(!emailPattern.test(e.target.value));
  };

  console.log(data);

  const handleEditorChange = (event) => {
    const parseddata = event.editor.getData();
    // setParsedData(data);

    setData({ ...data, content: parseddata });

    // Create a new table element to mirror the appearance of the table
    const tableElement = document.createElement("table");
    tableElement.innerHTML = data;

    // Get the formatted HTML data from the table element's outerHTML property
    const formattedHtml = tableElement.outerHTML;

    return formattedHtml;

    // console.log("Table:", formattedHtml);
  };

  return (
    <div style={{ marginTop: "150px", marginBottom: "50px" }}>
      {!loading ? (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              m: 3,
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Email Template Edit
            </Typography>
            <Button variant="contained" onClick={handleUpdateMailTemplate}>
              SUBMIT
            </Button>
          </Box>
          <Card
            elevation={0}
            sx={{ border: "1px solid #dddddd", p: 2, ml: 3, mr: 3 }}
          >
            <Box
              sx={{
                display: "flex",
                width: "600px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>Subject : </Typography>
              <TextField
                size="small"
                sx={{ width: "300px" }}
                multiline
                value={data?.Subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "600px",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography>Profile Name : </Typography>
              <Select
                sx={{ width: "300px" }}
                size="small"
                value={data?.profileName || ""}
                onChange={handleProfileNameChange}
              >
                {mailProfiles.map((item) => (
                  <MenuItem key={item.profileName} value={item.profileName}>
                    {item.profileName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "600px",
                gap: "198px",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography>From Email id : </Typography>
              <Typography>{fromEmail}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "600px",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography>Bcc : </Typography>
              <TextField
                size="small"
                sx={{ width: "300px", fontSize: "14px" }}
                multiline
                value={data?.bcc}
                onChange={(e) => {
                  const newData = e.target.value.split(",");
                  setData({ ...data, bcc: newData });
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "600px",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography>Mail Name : </Typography>
              <TextField
                size="small"
                sx={{ width: "300px" }}
                multiline
                value={data.mailName}
                onChange={(e) => setData({ ...data, mailName: e.target.value })}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "600px",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography>Type : </Typography>
              <TextField
                size="small"
                sx={{ width: "300px" }}
                multiline
                value={data.mailType}
                onChange={(e) => setData({ ...data, mailType: e.target.value })}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "600px",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography>Reply To : </Typography>
              <TextField
                variant="outlined"
                size="small"
                type="email"
                placeholder="Email"
                value={data.replyTo}
                onChange={handleEmailChange}
                sx={{ mt: 1.5, mb: 1.5, width: "300px" }}
                error={emailError}
                helperText={emailError && "Invalid email address"}
              />
            </Box>

            <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <Box sx={{ mb: 5 }}>
                <Typography>Email Message * : </Typography>
                <Box
                  sx={{
                    height: "350px",
                    width: "700px",
                    mt: 2,
                  }}
                >
                  {/* <MYMailEditor htmlContent={htmlContent} /> */}
                  <CKEditor
                    initData={htmlContent}
                    onChange={handleEditorChange}
                  />
                </Box>
              </Box>
              <Box sx={{ mt: -4 }}>
                <Typography
                  sx={{ border: "1px solid red", background: "#fcbfbf", p: 1 }}
                >
                  Copy and paste below variable in text box
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    mt: 1.5,
                  }}
                >
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{user-name}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{user-emailaddress}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{mpname}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{email-logo}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{socialmedia}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{shopurl}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{vendorurl}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{sellerurl}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{sellerdisplayname}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{sellerentityname}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{password}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{orderno}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{orderdate}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{orderstatus}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{shippingmode}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{awbnumber}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{shippingdate}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{address}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{paystatus}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{paymode}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{orderdetails}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{admin-contactemail}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{mobile}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{Query}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{clickhere}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{TrackingURL}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{MyAccount}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{name}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{PostCode}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{billingname}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{billingaddress}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{billingpin}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{billingmobile}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{Amount}`}</Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >{`{Trans No.}`}</Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {/* <CKEditorComponent /> */}
    </div>
  );
};

export default EmailTemplateEdit;
