import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Grid,
  Card,
  IconButton,
  Icon,
  Typography,
  useTheme,
} from "@mui/material";
import { RiLoginCircleFill } from "react-icons/ri";
import { GiConfirmed } from "react-icons/gi";
import { MdPendingActions } from "react-icons/md";

import HandshakeIcon from "@mui/icons-material/Handshake";
import axios from "axios";

const StatCard3 = ({ data, loginToday }) => {
  console.log(data);
  const ConfirmedOrders = data?.filter(
    (item) => item?.orderStatus === "accepted"
  );

  const PendingOrders = data?.filter((item) => item?.orderStatus === "pending");
  const statList = [
    {
      icon: <RiLoginCircleFill />,
      amount: loginToday?.loggedInTodayCount || 0,
      title: "Login Today",
    },
    {
      icon: <GiConfirmed />,
      amount: ConfirmedOrders?.length || 0,
      title: "Confirm Orders",
    },
    {
      icon: <MdPendingActions />,
      amount: PendingOrders?.length || 0,
      title: "Pending Orders",
    },
    {
      icon: <HandshakeIcon />,
      amount: 0,
      title: "SLA Breached",
    },
  ];
  const { palette } = useTheme();
  const textMuted = palette.text.secondary;

  return (
    <Grid container spacing={3}>
      {statList.map((item, ind) => (
        <Grid key={item.title} item xs={12} sm={6} xl={6} lg={6}>
          <Card
            elevation={2}
            sx={{ p: "20px", display: "flex", borderRadius: "20px" }}
          >
            <div>
              <IconButton
                size="small"
                sx={{
                  padding: "8px",
                  background: "rgba(0, 0, 0, 0.01)",
                }}
              >
                <Icon sx={{ color: textMuted }}>{item.icon}</Icon>
              </IconButton>
            </div>
            <Box ml={2}>
              <Typography sx={{ mt: "-4px", fontSize: "32px" }}>
                {item.amount}
              </Typography>
              <Typography sx={{ color: textMuted }}>{item.title}</Typography>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default StatCard3;
