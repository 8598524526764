import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import { useSelector } from "react-redux";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { GiAlliedStar } from "react-icons/gi";
import { BiEdit, BiShow, BiDollarCircle } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { AiFillDislike } from "react-icons/ai";
import { BsFillImageFill } from "react-icons/bs";
import { GoCreditCard } from "react-icons/go";
import Swal from "sweetalert2";
import { useState } from "react";
import {
  DeleteSingleProduct,
  RejectSingleProduct,
  getAllProducts,
  getSingleProductById,
  updateStatusForSingleProduct,
  visiblityForSingleProduct,
} from "../../../axios";

const columns = [
  { id: "sno", label: "Check", minWidth: 40 },
  { id: "sno", label: "S NO", minWidth: 40 },
  { id: "displayName", label: "Display Name", minWidth: 100, align: "center" },
  {
    id: "createdAt",
    label: "Date of Upload (MM/DD/YYYY)",
    minWidth: 60,
    align: "center",
  },
  {
    id: "SkuCode",
    label: "Product SKU Code.",
    minWidth: 80,
    align: "center",
  },
  {
    id: "BrandName",
    label: "Brand Name.",
    minWidth: 60,
    align: "center",
  },
  {
    id: "Title",
    label: "Product Name",
    minWidth: 120,
    align: "center",
  },

  {
    id: "UniqueUrl",
    label: "Unique Url",
    minWidth: 120,
    align: "center",
  },
  {
    id: "PreBook",
    label: "Prebook",
    minWidth: 40,
    align: "center",
  },
  {
    id: "Status",
    label: "Status",
    minWidth: 40,
    align: "center",
  },
  {
    id: "image",
    label: "Image",
    minWidth: 80,
    align: "center",
  },

  {
    id: "manageProduct",
    label: "Manage Product",
    minWidth: 100,
    align: "center",
  },
];

export default function MyProductListTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [data, setData] = React.useState([]);
  const [visibility, setVisibility] = React.useState({});
  const [rejected, setRejected] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [Loading1, setLoading1] = useState(false);
  const [Loading2, setLoading2] = useState(false);
  const [sortBy, setSortBy] = useState("All");
  const [searchOptions, setSearchOptions] = useState("Product Name");
  const [searchQuery, setSearchQuery] = useState("");

  const options = ["All", "Pending", "Approved", "Rejected"];

  const SearchOptions = ["Product Name", "Brand Name", "User Display Name"];

  // i want to search with Search Options and Sort by options

  const filteredData = () => {
    if (sortBy === "All") {
      if (searchOptions === "Product Name") {
        return data.filter((row) =>
          row.Title.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      if (searchOptions === "Brand Name") {
        return data.filter((row) =>
          row.BrandName.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      if (searchOptions === "User Display Name") {
        return data.filter((row) =>
          row.createdBy.fullName
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      }
    } else if (sortBy === "Pending") {
      if (searchOptions === "Product Name") {
        return data.filter(
          (row) =>
            row.Title.toLowerCase().includes(searchQuery.toLowerCase()) &&
            row.active === false &&
            row.rejected === false
        );
      }

      if (searchOptions === "Brand Name") {
        return data.filter(
          (row) =>
            row.BrandName.toLowerCase().includes(searchQuery.toLowerCase()) &&
            row.active === false &&
            row.rejected === false
        );
      }
      if (searchOptions === "User Display Name") {
        return data.filter(
          (row) =>
            row.createdBy.fullName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) &&
            row.active === false &&
            row.rejected === false
        );
      }
    } else if (sortBy === "Approved") {
      if (searchOptions === "Product Name") {
        return data.filter(
          (row) =>
            row.Title.toLowerCase().includes(searchQuery.toLowerCase()) &&
            row.active === true &&
            row.rejected === false
        );
      }
      if (searchOptions === "Brand Name") {
        return data.filter(
          (row) =>
            row.BrandName.toLowerCase().includes(searchQuery.toLowerCase()) &&
            row.active === true &&
            row.rejected === false
        );
      }
      if (searchOptions === "User Display Name") {
        return data.filter(
          (row) =>
            row.createdBy.fullName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) &&
            row.active === true &&
            row.rejected === false
        );
      }
    } else if (sortBy === "Rejected") {
      if (searchOptions === "Product Name") {
        return data.filter(
          (row) =>
            row.Title.toLowerCase().includes(searchQuery.toLowerCase()) &&
            row.rejected === true
        );
      }
      if (searchOptions === "Brand Name") {
        return data.filter(
          (row) =>
            row.BrandName.toLowerCase().includes(searchQuery.toLowerCase()) &&
            row.rejected === true
        );
      }
      if (searchOptions === "User Display Name") {
        return data.filter(
          (row) =>
            row.createdBy.fullName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) && row.rejected === true
        );
      }
    } else {
      return data;
    }
  };

  // console.log(selected);

  const handleClick = (event, _id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (_id) => selected.indexOf(_id) !== -1;

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleGetProductsByMe = async () => {
    setLoading(true);
    try {
      const res = await getAllProducts();
      if (res.status === 200) {
        const visibilityObj = {};
        res.data.forEach((row) => {
          visibilityObj[row._id] = !row.hide;
        });

        setData(res.data);
        setVisibility(visibilityObj);
        const rejectedObj = {};
        res.data.forEach((row) => {
          rejectedObj[row._id] = !row.rejected;
        });
        setRejected(rejectedObj);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateVisbilityinSingleProduct = async (id, value) => {
    try {
      const res = await visiblityForSingleProduct(id, value);
      if (res.status === 200) {
        setVisibility((prevState) => ({
          ...prevState,
          [id]: !value,
        }));
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRejectSingleProduct = async (id, value) => {
    try {
      const res = await RejectSingleProduct(id, value);
      if (res.status === 200) {
        setRejected((prevState) => ({
          ...prevState,
          [id]: !value,
        }));
        setData((prevData) =>
          prevData.map((item) =>
            item._id === id ? { ...item, rejected: value } : item
          )
        );
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBulkActive = async () => {
    setLoading1(true);
    try {
      if (selected.length === 0) {
        Swal.fire({
          position: "center",
          icon: "error",
          text: `Please select Atleast One Product to Activate`,
          showConfirmButton: true,
        });
        return;
      }
      let hasError = false; // Flag to check if any products have already been approved
      for (let i = 0; i < selected.length; i++) {
        const productId = selected[i];
        const { data: product } = await getSingleProductById(productId);
        if (product && product.active) {
          // Check if the product is already approved
          Swal.fire({
            position: "center",
            icon: "error",
            text: `Product is already approved`,
            showConfirmButton: true,
          });
          hasError = true;
          break; // Break out of the loop if a product is already active
        } else {
          const res = await updateStatusForSingleProduct(productId, true);
          if (res.status === 200) {
            if (!hasError && selected.length > 0) {
              // Show success message if all products were updated
              setData((prevData) =>
                prevData.map((item) =>
                  selected.includes(item._id) ? { ...item, active: true } : item
                )
              );
              setSelected([]);
              Swal.fire({
                position: "center",
                icon: "success",
                text: "All selected products have been activated successfully",
                showConfirmButton: true,
              });
            }
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              text: res.data.error,
              showConfirmButton: true,
            });
          }
        }
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "Error Occured",
        showConfirmButton: true,
      });
    } finally {
      setLoading1(false);
    }
  };

  const handleBulkDeActive = async () => {
    setLoading2(true);
    try {
      if (selected.length === 0) {
        Swal.fire({
          position: "center",
          icon: "error",
          text: `Please select Atleast One Product to DeActivate`,
          showConfirmButton: true,
        });
        return;
      }
      let hasError = false; // Flag to check if any products have already been approved
      for (let i = 0; i < selected.length; i++) {
        const productId = selected[i];
        const { data: product } = await getSingleProductById(productId);
        if (!product.active) {
          // Check if the product is already approved
          Swal.fire({
            position: "center",
            icon: "error",
            text: `Product is already Deactivated`,
            showConfirmButton: true,
          });
          hasError = true;
          break; // Break out of the loop if a product is already active
        } else {
          const res = await updateStatusForSingleProduct(productId, false);
          if (res.status === 200) {
            if (!hasError && selected.length > 0) {
              // Show success message if all products were updated
              setData((prevData) =>
                prevData.map((item) =>
                  selected.includes(item._id)
                    ? { ...item, active: false }
                    : item
                )
              );
              setSelected([]);
              Swal.fire({
                position: "center",
                icon: "success",
                text: "All selected products have been deactivated successfully",
                showConfirmButton: true,
              });
            }
          }
        }
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "Error Occured",
        showConfirmButton: true,
      });
    } finally {
      setLoading2(false);
    }
  };

  React.useEffect(() => {
    Promise.all([handleGetProductsByMe()]);
  }, []);

  const handleDeleteSingleProduct = async (id) => {
    try {
      const res = await DeleteSingleProduct(id);

      if (res.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res.data.message,
          showConfirmButton: true,
        });
        setData(data.filter((item) => item._id !== id));
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   const handleSearch = (e) => {
  //     setSearchQuery(e.target.value);
  //   };
  //   const handleSearchforName = (e) => {
  //     setSearchQueryforName(e.target.value);
  //   };

  //   const filterData = () => {
  //     const newData = data.filter((row) =>
  //       row.SKUCode.toLowerCase().includes(searchQuery.toLowerCase())
  //     );
  //     setData(newData);
  //   };
  //   const filterDataforName = () => {
  //     const newData = data.filter((row) =>
  //       row.Title.toLowerCase().includes(searchQueryforName.toLowerCase())
  //     );
  //     setData(newData);
  //   };

  const convertUTCtoIST = (utcDate) => {
    const date = new Date(utcDate);
    // const istOffset = 330; // IST offset in minutes
    // const istTime = new Date(date.getTime() + istOffset * 60 * 1000);
    return date.toLocaleDateString();
  };

  //   const filterDataByStatus = (data, status) => {
  //     return data.filter((row) => row.Status === status);
  //   };

  //   const convertToCSV = (data) => {
  //     const rows = [];
  //     const headers = Object.keys(data[0]);

  //     rows.push(headers.join(","));

  //     data.forEach((row) => {
  //       const values = headers.map((header) => row[header]);
  //       rows.push(values.join(","));
  //     });

  //     return rows.join("\n");
  //   };

  //   const handleExport = () => {
  //     const filteredData = filterDataByStatus(data, false);
  //     console.log(filteredData);
  //     const csv = convertToCSV(filteredData);
  //     const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //     const link = document.createElement("a");
  //     const url = URL.createObjectURL(blob);
  //     link.setAttribute("href", url);
  //     link.setAttribute("download", `${status}.csv`);
  //     link.style.visibility = "hidden";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   };

  return (
    <>
      {/* <Box>
        <Typography
          sx={{
            fontSize: "23px",
            fontWeight: "bold",
            textAlign: "center",
            mt: 5,
          }}
        >
          Product List
        </Typography>
        <Typography
          sx={{
            border: "2px solid #3482bc",
            width: "60px",
          }}
          style={{ margin: "auto" }}
        ></Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "17px", fontWeight: "bold", m: 4 }}>
            Total Items : {data.length}
          </Typography>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Select
              displayEmpty
              size="small"
              sx={{ width: "200px", height: "40px" }}
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <InputLabel sx={{ ml: 2 }}>Select</InputLabel>
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
              <MenuItem value="Low Inventory">Low Inventory</MenuItem>
            </Select>
            <Button
              variant="contained"
              sx={{ width: "200px", height: "40px", mr: 4 }}
              disableElevation
              onClick={handleExport}
            >
              Export To Excel
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px", ml: 4 }}>
            <TextField
              placeholder="Product SKU Code"
              size="small"
              value={searchQuery}
              onChange={handleSearch}
            />
            <TextField
              placeholder="Product Name"
              size="small"
              value={searchQueryforName}
              onChange={handleSearchforName}
            />
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                if (searchQuery !== "") {
                  filterData();
                } else if (searchQueryforName !== "") {
                  filterDataforName();
                }
              }}
            >
              SUBMIT
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                setSearchQuery("");
                setSearchQueryforName("");
                filterData("");
                filterDataforName("");
                handleGetProductsByMe();
              }}
            >
              CLEAR
            </Button>
          </Box>
          <Box
            sx={{ display: "flex", gap: "10px", alignItems: "center", mr: 4 }}
          >
            <Typography>Product Sorting : </Typography>
            <Select displayEmpty size="small" sx={{ width: "200px" }}>
              <InputLabel sx={{ ml: 2 }}>Select</InputLabel>
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
          </Box>
        </Box>
      </Box> */}
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mr: 3,
        }}
        style={{ marginTop: "150px" }}
      >
        <Typography sx={{ fontSize: "20px", ml: 4, fontWeight: "bold" }}>
          Product's List
        </Typography>

        <Box sx={{ display: "flex", gap: "20px" }}>
          <Button
            variant="contained"
            onClick={handleBulkActive}
            disabled={!selected.length > 0 || Loading1}
            sx={{ width: "170px" }}
          >
            {Loading1 ? <CircularProgress size={24} /> : "Bulk Active"}
          </Button>
          <Button
            variant="contained"
            onClick={handleBulkDeActive}
            disabled={!selected.length > 0 || Loading2}
            sx={{ width: "170px", mr: 2 }}
          >
            {Loading2 ? <CircularProgress size={24} /> : "Bulk DeActive"}
          </Button>
        </Box>
      </Box>

      <Box sx={{ ml: 4, mt: 3 }}>
        <Box>
          <Typography sx={{ fontSize: "17px" }}>Search By</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ mt: 2, display: "flex", gap: "10px" }}>
              <Autocomplete
                size="small"
                sx={{ width: "200px" }}
                options={SearchOptions}
                onChange={(e, value) => setSearchOptions(value)}
                renderInput={(params) => (
                  <TextField {...params} label="--Select--" />
                )}
              />
              <TextField
                sx={{ width: "200px" }}
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                size="small"
              />
            </Box>

            <Box
              sx={{
                mt: 2,
                display: "flex",
                gap: "10px",
                alignItems: "center",
                mr: 4,
              }}
            >
              <Typography sx={{ fontSize: "17px" }}>Sort By</Typography>
              <Autocomplete
                size="small"
                sx={{ width: "200px" }}
                value={sortBy}
                onChange={(e, value) => setSortBy(value)}
                options={options}
                renderInput={(params) => (
                  <TextField {...params} label="--Select--" />
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {!loading ? (
        <Paper
          sx={{
            width: "95.5%",
            overflow: "hidden",
            ml: 4,
            mt: 3,
            border: "1px solid #ddd",
          }}
          elevation={0}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        background: "#eee",
                        fontWeight: "bold",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {filteredData()?.length > 0 ? (
                <TableBody>
                  {filteredData()
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row._id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                          selected={isItemSelected}
                        >
                          <TableCell
                            padding="checkbox"
                            sx={{ border: "1px solid #ddd" }}
                          >
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                              onClick={(event) => handleClick(event, row._id)}
                            />
                          </TableCell>
                          <TableCell sx={{ border: "1px solid #ddd" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell sx={{ border: "1px solid #ddd" }}>
                            {row?.createdBy?.fullName}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ border: "1px solid #ddd" }}
                          >
                            {convertUTCtoIST(row?.createdAt)}
                          </TableCell>{" "}
                          <TableCell
                            align="center"
                            sx={{ border: "1px solid #ddd" }}
                          >
                            {row?.SkuCode}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ border: "1px solid #ddd" }}
                          >
                            {row?.BrandName}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ border: "1px solid #ddd" }}
                          >
                            {row?.Title}
                          </TableCell>
                          {/* modified to display IST time */}
                          <TableCell
                            align="center"
                            sx={{ border: "1px solid #ddd" }}
                          >
                            {row?.UniqueUrl}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ border: "1px solid #ddd" }}
                          >
                            {row?.PreBook == "Yes" ? "Yes" : "No"}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ border: "1px solid #ddd" }}
                          >
                            {row?.rejected
                              ? "Rejected"
                              : row.active
                              ? "Approved"
                              : "Pending"}
                          </TableCell>
                          <TableCell sx={{ border: "1px solid #ddd" }}>
                            <Box sx={{ width: "60px" }}>
                              <img
                                src={row?.MainImage}
                                alt=""
                                width="100%"
                                height="100%"
                              />
                            </Box>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ border: "1px solid #ddd" }}
                          >
                            <Tooltip title="Most Popular">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-meta-information/${row._id}`
                                  )
                                }
                              >
                                <GiAlliedStar />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Manage Price & stock">
                              <IconButton
                                onClick={() =>
                                  navigate(`/manage-price-stocking/${row._id}`)
                                }
                              >
                                <BiDollarCircle />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() =>
                                  navigate(`/manage-single-listing/${row._id}`)
                                }
                              >
                                <BiEdit />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Frequently bought together">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/frequently-bought-together/${row._id}`
                                  )
                                }
                              >
                                <GoCreditCard />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="hide">
                              <IconButton
                                onClick={() =>
                                  handleUpdateVisbilityinSingleProduct(
                                    row._id,
                                    visibility[row._id]
                                  )
                                }
                              >
                                {visibility[row._id] ? (
                                  <VisibilityIcon style={{ color: "green" }} />
                                ) : (
                                  <VisibilityOffIcon style={{ color: "red" }} />
                                )}
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="delete">
                              <IconButton>
                                <MdDelete
                                  onClick={() =>
                                    handleDeleteSingleProduct(row._id)
                                  }
                                  style={{ color: "red" }}
                                />
                              </IconButton>
                            </Tooltip>
                            {rejected[row._id] ? (
                              <>
                                <Tooltip title="Reject">
                                  <IconButton
                                    onClick={() =>
                                      handleRejectSingleProduct(
                                        row._id,
                                        rejected[row._id]
                                      )
                                    }
                                  >
                                    <ThumbUpIcon style={{ color: "green" }} />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Tooltip title="Un-Reject">
                                  <IconButton
                                    onClick={() =>
                                      handleRejectSingleProduct(
                                        row._id,
                                        rejected[row._id]
                                      )
                                    }
                                  >
                                    <AiFillDislike style={{ color: "red" }} />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                            <Tooltip title="manage image">
                              <IconButton>
                                <BsFillImageFill />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      align="center"
                      sx={{ border: "1px solid #ddd" }}
                    >
                      No Data Found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
