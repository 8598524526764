import { Button, Card, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

const ChangePassword = () => {
  const [Oldpassword, setOldPassword] = useState();
  const [password, setPassword] = useState();
  const [ReEnterpassword, setReEnterPassword] = useState();

  const { id } = useParams();
  const handleUpdateAdminPassword = async () => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/ChangeAdminPassword/${id}`,
        {
          Oldpassword,
          password,
          ReEnterpassword,
        }
      );
      if (res.status == 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Password Changed SuccessFully",
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };
  return (
    <div style={{ marginTop: "150px" }}>
      <Card
        sx={{ width: "450px", p: 2 }}
        style={{
          margin: "auto",
          marginTop: "30px",
          border: "1px solid #dddddd",
        }}
      >
        <Typography
          sx={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}
        >
          Change Password
        </Typography>
        <Box>
          <TextField
            label="Enter Old Password"
            placeholder="Enter Old Password"
            type="password"
            value={Oldpassword}
            onChange={(e) => setOldPassword(e.target.value)}
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          />
          <TextField
            label="Enter New Password"
            placeholder="Enter New Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Re-Enter New Password"
            placeholder="Re-Enter New Password"
            type="password"
            value={ReEnterpassword}
            onChange={(e) => setReEnterPassword(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Box>
        <Button
          variant="contained"
          fullWidth
          onClick={handleUpdateAdminPassword}
        >
          SUBMIT
        </Button>
      </Card>
    </div>
  );
};

export default ChangePassword;
