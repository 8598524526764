import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const BuyerEdit = () => {
  const [loading1, setLoading1] = React.useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [error, setError] = useState("");
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const { id } = useParams();
  const navigate = useNavigate();

  const handleGetUserById = async () => {
    setLoading1(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/buyer/fetchUserById/${id}`
      );
      if (res) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  const handlePincodeChange = async (event) => {
    const postalCode = event.target.value;

    try {
      const isValidPincode = /^[1-9][0-9]{5}$/.test(postalCode);
      if (isValidPincode) {
        axios
          .get(`https://api.postalpincode.in/pincode/${postalCode}`)
          .then((response) => {
            const postOffice = response.data[0].PostOffice[0];
            const city = postOffice.Block;
            const state = postOffice.State;
            const country = postOffice.Country;
            setCity(city);
            setState(state);
            setCountry(country);
            setError(null);
          })
          .catch((error) => {
            console.error(error);
            setCity("");
            setState("");
            setCountry("");
          });
        setData({ ...data, pincode: postalCode });
      } else {
        setCity("");
        setState("");
        setCountry("");
        setData({ ...data, pincode: postalCode });
        setError("Invalid PIN code");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserInformation = async () => {
    setLoading(true);
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/buyer/handleUpdateUser/${id}`,
        {
          name: data.name,
          userEmail: data.userEmail,
          contactNumber: data.contactNumber,
          city: city ? city : data.city,
          state: state ? state : data.state,
          country: country ? country : data.country,
          pincode: data.pincode,
        }
      );
      if (res) {
        // console.log(res);
        toast.success("Information Updated SuccessFully", {
          position: "top-right",
        });
        setData({
          ...data,
          city: city ? city : data.city,
          state: state ? state : data.state,
          country: country ? country : data.country,
        });
        navigate("/BuyerList");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetUserById();
  }, []);
  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Buyers Edit
        </Typography>
        <Button
          variant="contained"
          onClick={handleUserInformation}
          disabled={Loading}
        >
          {Loading ? <CircularProgress size={24} /> : "SUBMIT"}
        </Button>
      </Box>
      {!loading1 ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 5,
                }}
              >
                <Card
                  sx={{ width: "450px", p: 2, border: "1px solid #ddd" }}
                  elevation={0}
                >
                  <Box sx={{ mt: 2 }}>
                    <Typography>FullName</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter Full Name"
                      size="small"
                      value={data.name}
                      onChange={(e) =>
                        setData({ ...data, name: e.target.value })
                      }
                      sx={{ mt: 0.5, mb: 2 }}
                    />
                  </Box>
                  <Box>
                    <Typography>Email Id</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter Email Id"
                      size="small"
                      value={data?.userEmail}
                      sx={{ mt: 0.5, mb: 2 }}
                      onChange={(e) =>
                        setData({ ...data, userEmail: e.target.value })
                      }
                    />
                  </Box>
                  <Box>
                    <Typography>contact Number</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter contact Number"
                      size="small"
                      value={data.contactNumber}
                      sx={{ mt: 0.5, mb: 2 }}
                      onChange={(e) =>
                        setData({ ...data, contactNumber: e.target.value })
                      }
                    />
                  </Box>
                  <Box>
                    <Typography>PinCode</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter Pincode"
                      size="small"
                      sx={{ mt: 0.5, mb: 2 }}
                      error={error}
                      helperText={error}
                      value={data.pincode}
                      onChange={handlePincodeChange} // Call handlePincodeChange on change
                    />
                  </Box>
                  <Box>
                    <Typography>City</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter City"
                      size="small"
                      value={city ? city : data.city}
                      sx={{ mt: 0.5, mb: 2 }}
                    />
                  </Box>
                  <Box>
                    <Typography>State</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter State"
                      size="small"
                      value={state ? state : data.state}
                      sx={{ mt: 0.5, mb: 2 }}
                    />
                  </Box>
                  <Box>
                    <Typography>Country</Typography>
                    <TextField
                      fullWidth
                      placeholder="Enter Country"
                      size="small"
                      value={country ? country : data.country}
                      sx={{ mt: 0.5, mb: 2 }}
                    />
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default BuyerEdit;
