import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ManageProductTypeTable from "../Attributes/ManageProductTypeTable";
import ManageProductTypeTableForProducts from "./ManageProductTypeTableForProducts";

const ManageProductsForProductType = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getProductTypeValuesById = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getProductTypeValuesById/${id}`
      );
      if (res) {
        // console.log(res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetAllProductsBySellers = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/get-all-Products"
      );
      if (res && data) {
        // console.log(res.data);
        const ProductType = data && data.ProductType;
        // console.log(ProductType);

        const AllProducts = res.data.filter(
          (product) => ProductType === product.ProductType
        );
        // console.log(AllProducts);
        setAllProducts(AllProducts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductTypeValuesById();
  }, []);

  useEffect(() => {
    handleGetAllProductsBySellers();
  }, [data]);
  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Manage Products for {data.ProductType}
        </Typography>
        <Button variant="contained" onClick={() => navigate(-1)}>
          BACK
        </Button>
      </Box>
      <ManageProductTypeTableForProducts
        allProducts={allProducts}
        loading={loading}
      />
    </div>
  );
};

export default ManageProductsForProductType;
