import { Button, Card, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { Category } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { CircularProgress } from "@mui/material";

const CodAndShipping = ({
  checked,
  setChecked,
  CodAvailable = "No",
  ShippingWeight,
  Fragile = "No",
}) => {
  const { token } = useSelector((data) => data.auth);
  const navigate = useNavigate();
  const { id } = useParams();
  const [Loading, setLoading] = useState(false);

  const generateRandomNumber = () => {
    const uuid = uuidv4(); // Generate a UUID
    const randomNumberString = uuid.split("-").join("").slice(0, 12); // Remove hyphens and take the first 12 characters
    const randomNumber = parseInt(randomNumberString, 16); // Convert the string to a decimal number
    return randomNumber;
  };

  const uploadImageToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "insta-clone");
    formData.append("cloud_name", "bunny1705");

    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const uploadedImage = response.data.secure_url;
      return uploadedImage;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const uploadImageToCloudinary1 = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "insta-clone");
    formData.append("cloud_name", "bunny1705");
    formData.append("pages", "all");

    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/bunny1705/upload",
        formData,
        {
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );

      const uploadedImage = response.data.secure_url;
      return uploadedImage;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const handleSingleListing = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/seller/Products/add-single-listingByAdmin",
        {
          data: checked,
          CodAvailable: checked.CodAvailable,
          Fragile: checked.Fragile,
          PreBook: checked.PreBook,
          replacement: checked.replacement,
          refund: checked.refund,
          Title: checked.Title,
          SKUCode: checked.SkuCode,
          BrandName: checked.Brand,
          UniqueUrl: checked.UniqueUrl,
          Category: checked.Category,
          mainCategory: checked.mainCategory,
          subCategory: checked.subCategory,
          ProductType: checked.ProductType,
          HSNCode: checked.HSNCode,
          Tax: checked.Tax,
          Description: checked.Description,
          SkuCode: checked.SkuCode,
          searchTitle: checked.searchTitle,
          searchKeywords: checked.searchKeywords,
          searchDescription: checked.searchDescription,
          shipment: checked.shipment,
          returnPolicy: checked.returnPolicy,
          returnPolicyDescription: checked.returnPolicyDescription,
          MemoryStorage: checked.MemoryStorage,
          ShippingWeight: checked.ShippingWeight,
          Colors: checked.Colors,
          Sizes: checked.Sizes,
          createdBy: id,
          MainImage: await uploadImageToCloudinary(checked.MainImage),
          PrdoductDetailsDoc: await uploadImageToCloudinary1(
            checked.PrdoductDetailsDoc
          ),
          uniqueId: generateRandomNumber(),
        },
        config
      );
      if (res) {
        console.log(res);
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Created SuccessFully",
          showConfirmButton: true,
        });
        navigate("/products-list");
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "error Occured",
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Card sx={{ p: 2, border: "1px solid #dddddd" }} elevation={0}>
        <Box>
          <Box
            sx={{
              display: "flex",
              //   justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              COD Available :{" "}
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                // defaultValue="No"
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio />}
                  checked={CodAvailable == "Yes"}
                  label="Yes"
                  onChange={(e) =>
                    setChecked({ ...checked, CodAvailable: "Yes" })
                  }
                />
                <FormControlLabel
                  value="No"
                  control={<Radio />}
                  checked={CodAvailable == "No"}
                  label="No"
                  onChange={(e) =>
                    setChecked({ ...checked, CodAvailable: "No" })
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",

              mt: 2,
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              Shipping Weight (gms) :{" "}
            </Typography>
            <TextField
              placeholder="Shipping Weight (gms)"
              sx={{ width: "350px" }}
              value={ShippingWeight}
              onChange={(e) =>
                setChecked({ ...checked, ShippingWeight: e.target.value })
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              //   justifyContent: "space-between",

              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
              mt: 2,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              Fragile :{" "}
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value={Fragile}
                  control={<Radio />}
                  checked={Fragile == "Yes"}
                  label="Yes"
                  onChange={(e) => setChecked({ ...checked, Fragile: "Yes" })}
                />
                <FormControlLabel
                  value={Fragile}
                  control={<Radio />}
                  checked={Fragile == "No"}
                  label="No"
                  onChange={(e) => setChecked({ ...checked, Fragile: "No" })}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
              mt: 3,
              mb: 5,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            ></Typography>
            <Button
              variant="contained"
              onClick={() => handleSingleListing()}
              sx={{ width: "200px" }}
              disabled={Loading}
            >
              {Loading ? <CircularProgress size={24} /> : "SUBMIT"}
            </Button>
          </Box>
        </Box>
      </Card>
    </div>
  );
};

export default CodAndShipping;
