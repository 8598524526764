import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useEffect } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const columns = [
  { id: "S.No", label: "S.No", minWidth: 170, align: "center" },
  { id: "code", label: "Attribute Name", minWidth: 100, align: "center" },
  {
    id: "population",
    label: "Sorting Id",
    minWidth: 170,
    align: "center",
  },
  {
    id: "size",
    label: "Action",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [SizeList, setSizeList] = React.useState([]);
  const [visibility, setVisibility] = React.useState({});

  // console.log(SizeList);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [data, setData] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const navigate = useNavigate();
  const handleBrandAttributes = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllSizeAttributes"
      );
      if (res) {
        // console.log(res.data);
        const visibilityObj = {};
        res.data.forEach((row) => {
          visibilityObj[row._id] = !row.hide;
        });
        setData(res.data);
        setVisibility(visibilityObj);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVisibility = async (id, value) => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/UpdateSizeInsideAttributes/${id}`,
        {
          hide: value,
        }
      );
      if (res) {
        setVisibility((prevState) => ({
          ...prevState,
          [id]: !value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllProductsList = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/get-all-Products"
      );
      if (res && data) {
        // console.log(res);
        const sizeAttributes = data.map((item) => item.name);

        // Create an empty array to store all sizes
        let allSizes = [];

        // Push all sizes to the allSizes array
        res.data.forEach((item, i) => {
          if (item.data.Sizes) {
            allSizes = allSizes.concat(item.data.Sizes);
          }
        });

        // Filter out the sizes that are present in sizeAttributes
        const productListSizes = allSizes.filter(
          (size) => !sizeAttributes.includes(size)
        );

        if (productListSizes && productListSizes.length > 0) {
          setSizeList(productListSizes);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteSizeAttributes = async (id) => {
    try {
      const res = await axios.delete(
        `https://rccore-backend.cstechns.com/api/admin/DeleteSizeAttributes/${id}`
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });
        setData(data.filter((item) => item._id !== id));
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };

  React.useEffect(() => {
    handleBrandAttributes();
  }, []);

  useEffect(() => {
    handleGetAllProductsList();
  }, [data]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data.filter((row) =>
    row.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const uniqueProductTypes = Array.from(new Set(SizeList.map((item) => item)));

  return (
    <>
      {!loading ? (
        <>
          <TextField
            label="Search"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearch}
            size="small"
            sx={{ ml: 3, width: "400px", mb: 2 }}
          />

          <Paper
            sx={{
              width: "96.5%",
              overflow: "hidden",
              ml: 3,
              mb: 3,
              border: "1px solid #dddddd",
            }}
          >
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          background: "#dddddd",
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row.sortingId}</TableCell>
                          <TableCell align="center">
                            {uniqueProductTypes.map((product) => {
                              if (product === row.name) {
                                return (
                                  <>
                                    {visibility[row._id] ? (
                                      <>
                                        <Tooltip title="De-Activate">
                                          <IconButton
                                            onClick={() =>
                                              handleVisibility(
                                                row._id,
                                                visibility[row._id]
                                              )
                                            }
                                          >
                                            <VisibilityIcon
                                              style={{ color: "green" }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    ) : (
                                      <>
                                        <Tooltip title="Activate">
                                          <IconButton
                                            onClick={() =>
                                              handleVisibility(
                                                row._id,
                                                visibility[row._id]
                                              )
                                            }
                                          >
                                            <VisibilityOffIcon
                                              style={{ color: "red" }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    )}
                                  </>
                                );
                              }
                            })}
                            {SizeList.some(
                              (product) => product === row.name
                            ) ? null : (
                              <>
                                <IconButton>
                                  <EditIcon
                                    onClick={() =>
                                      navigate(
                                        `/update-Size-inside-attributes/${row._id}`
                                      )
                                    }
                                  />
                                </IconButton>
                                <IconButton>
                                  <DeleteIcon
                                    onClick={() =>
                                      handleDeleteSizeAttributes(row._id)
                                    }
                                    style={{ color: "red" }}
                                  />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
    </>
  );
}
