import {
  Avatar,
  Box,
  Card,
  Icon,
  IconButton,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import React from "react";

const FlexBetween = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const CardHeader = styled(FlexBetween)(() => ({
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));

const ProductTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& small": {
    height: 15,
    width: 50,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" },
}));

const TopSellingTable = () => {
  const { palette } = useTheme();

  return (
    <Card elevation={2} sx={{ pt: "20px", borderRadius: "20px" }}>
      <CardHeader>
        <Title>
          <Typography sx={{ fontWeight: "bold" }}>
            Top Selling Products
          </Typography>
        </Title>
        <Select size="small" defaultValue="this_month">
          <MenuItem value="this_month">This Month</MenuItem>
          <MenuItem value="last_month">Last Month</MenuItem>
        </Select>
      </CardHeader>

      <Box overflow="auto">
        <ProductTable>
          <TableBody>
            {productList.map((product, index) => (
              <TableRow key={index} hover>
                <TableCell
                  colSpan={4}
                  align="left"
                  sx={{ px: 0, textTransform: "capitalize" }}
                >
                  <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                    <Avatar src={product.imgUrl} />
                    <Box>
                      <Typography
                        sx={{ m: 0, ml: 1 }}
                        style={{
                          fontWeight: "bold",
                          lineHeight: "24px",
                          letterSpacing: "2px",
                        }}
                      >
                        {product.name}
                      </Typography>
                      <Typography
                        sx={{ m: 0, ml: 1 }}
                        style={{ color: "rgba(82,82,108,0.75)" }}
                      >
                        10 minutes ago
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>

                <TableCell
                  align="left"
                  colSpan={2}
                  sx={{
                    px: 0,
                    textTransform: "capitalize",
                    color: "#81cc7a",
                    fontWeight: "bold",
                  }}
                >
                  $
                  {product.price > 999
                    ? (product.price / 1000).toFixed(1) + "k"
                    : product.price}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ProductTable>
      </Box>
    </Card>
  );
};

const productList = [
  {
    imgUrl:
      "https://admin.pixelstrap.com/cuba/assets/images/dashboard/user/1.jpg",
    name: "earphone",
    price: 100,
    available: 15,
  },
  {
    imgUrl:
      "https://admin.pixelstrap.com/cuba/assets/images/dashboard/user/1.jpg",
    name: "earphone",
    price: 200,
    available: 30,
  },
  {
    imgUrl:
      "https://admin.pixelstrap.com/cuba/assets/images/dashboard/user/1.jpg",
    name: "iPhone x",
    price: 300,
    available: 35,
  },
  {
    imgUrl:
      "https://admin.pixelstrap.com/cuba/assets/images/dashboard/user/1.jpg",
    name: "iPhone x",
    price: 100,
    available: 0,
  },
  {
    imgUrl:
      "https://admin.pixelstrap.com/cuba/assets/images/dashboard/user/1.jpg",
    name: "Head phone",
    price: 400,
    available: 5,
  },
];

export default TopSellingTable;
