import { Box } from "@mui/system";
import React from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomAttributesTable from "./CustomAttributesTable";
const CustomAttributes = () => {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "150px" }}>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", m: 3 }}>
          <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
            Product Attributes - Custom
          </Typography>
          <Box sx={{ display: "flex", gap: "30px" }}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => navigate("/create-attributes-custom")}
            >
              Create New Attribute
            </Button>
          </Box>
        </Box>
        <CustomAttributesTable />
      </Box>
    </div>
  );
};

export default CustomAttributes;
