import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { BiHide, BiShow } from "react-icons/bi";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
  DeleteSellerNavigationItem,
  handleGetAllSellerNavigations,
  handleUpdateActive,
} from "../../../axios";

export default function SellerNavigationTable() {
  const [data, setData] = useState([]);
  const [visibility, setVisibility] = React.useState({});
  const [Loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleGetAllSellerNavigations1 = async () => {
    setLoading(true);
    try {
      const res = await handleGetAllSellerNavigations();
      if (res) {
        const visibilityObj = {};
        res.forEach((row) => {
          visibilityObj[row._id] = !row.active;
        });
        setData(res);
        setVisibility(visibilityObj);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVisibility = async (id, value) => {
    try {
      const res = await handleUpdateActive(id, value);
      if (res) {
        setVisibility((prevState) => ({
          ...prevState,
          [id]: !value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteFooterItem = async (id) => {
    try {
      const res = await DeleteSellerNavigationItem(id);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });
        setData(data.filter((item) => item._id !== id));
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "Error While Deleting the Footer Navigation",
        showConfirmButton: true,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    handleGetAllSellerNavigations1();
  }, []);
  return (
    <>
      {!Loading ? (
        <TableContainer
          component={Paper}
          sx={{ mt: 3, width: "96.5%", ml: 3, border: "1px solid #ddd" }}
          elevation={0}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ background: "#eee" }}>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  ID
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Heading
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  MenuType
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Navigation For
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Link
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Sorting Order
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.Heading}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.MenuType}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.NavigationFor}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.Link || "---"}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.SortingOrder}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() =>
                          navigate(`/edit-seller-navigation/${row._id}`)
                        }
                      >
                        <FiEdit size={20} />
                      </IconButton>
                    </Tooltip>
                    {visibility[row._id] ? (
                      <Tooltip title="Activate">
                        <IconButton
                          onClick={() =>
                            handleVisibility(row._id, visibility[row._id])
                          }
                        >
                          <BiHide style={{ color: "red" }} size={22} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="DeActivate">
                        <IconButton
                          onClick={() =>
                            handleVisibility(row._id, visibility[row._id])
                          }
                        >
                          <BiShow style={{ color: "green" }} size={22} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleDeleteFooterItem(row._id)}
                      >
                        <MdDelete style={{ color: "red" }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
