import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { MdDelete } from "react-icons/md";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";

const columns = [
  { id: "sno", label: "S NO", minWidth: 100 },
  {
    id: "SkuCode",
    label: "Product Type.",
    minWidth: 170,
    align: "center",
  },

  {
    id: "SkuCode",
    label: "Product SKU Code.",
    minWidth: 170,
    align: "center",
  },
  {
    id: "BrandName",
    label: "Brand Name.",
    minWidth: 170,
    align: "center",
  },
  {
    id: "Title",
    label: "Product Name",
    minWidth: 170,
    align: "center",
  },

  {
    id: "UniqueUrl",
    label: "Unique Url",
    minWidth: 170,
    align: "center",
  },
  {
    id: "PreBook",
    label: "Prebook",
    minWidth: 170,
    align: "center",
  },
  {
    id: "Status",
    label: "Status",
    minWidth: 170,
    align: "center",
  },
  {
    id: "image",
    label: "Image",
    minWidth: 170,
    align: "center",
  },
];

export default function ManageProductTypeTableForProducts({
  allProducts,
  loading,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {!loading ? (
        <Paper sx={{ width: "96.5%", overflow: "hidden", ml: 3, mt: 2 }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        background: "#dddddd",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allProducts
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell align="center">{row.ProductType}</TableCell>
                        <TableCell align="center">{row.SkuCode}</TableCell>
                        <TableCell align="center">{row.BrandName}</TableCell>
                        <TableCell align="center">{row.Title}</TableCell>
                        {/* modified to display IST time */}
                        <TableCell align="center">{row.UniqueUrl}</TableCell>
                        <TableCell align="center">
                          {row.PreBook == "Yes" ? "Yes" : "No"}
                        </TableCell>
                        <TableCell align="center">
                          {row.Status === false ? "Pending" : "Approved"}
                        </TableCell>
                        <TableCell align="center">---</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={allProducts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
