import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductCategoryTable from "./ProductCategoryTable";
import ProductCreateTabPanel from "./ProductCreateTabPanel";

const ProductCategory = ({ Products }) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const handleRootCategory = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllSubCategoriesForRoot"
      );

      if (res) {
        // console.log(res.data);
        setData(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleRootCategory();
  }, []);
  return (
    <div style={{ marginTop: "150px" }}>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", m: 3 }}>
          <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
            Product Categories
          </Typography>
          <Box sx={{ display: "flex", gap: "40px" }}>
            {/* <Button
              variant="contained"
              disableElevation
              onClick={() => navigate(`/create-new/${data._id}`)}
            >
              Create New
            </Button> */}
            <Button
              variant="contained"
              onClick={() =>
                navigate(`/Create-new-product-Category/${data._id}`)
              }
              disableElevation
            >
              Add Bulk Category
            </Button>
          </Box>
        </Box>
      </Box>
      <ProductCategoryTable Products={Products} />
    </div>
  );
};

export default ProductCategory;
