import React from "react";
import OrderTable from "./OrderTable";

const NewOrders = ({ data }) => {
  return (
    <div style={{ marginTop: "150px" }}>
      <OrderTable data={data} />
    </div>
  );
};

export default NewOrders;
