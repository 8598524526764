import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AddItemsToSelected,
  RemoveItemsFromSelected,
  UpdateCoupon,
  createNewCouponCOdes,
  getCouponCodeById,
} from "../../../axios";
import SelectedItemsEdit from "./SelectedProductsEdit";
import UnselectedItemsEdit from "./UnselectedItemsEdit";
import Swal from "sweetalert2";

const SelectItemsForCouponCodeEdit = ({ Products }) => {
  const location = useLocation();

  const CouponData = location.state?.CouponData;
  // console.log(CouponData);

  const [selectedItems, setSelectedItems] = useState([]);
  const [NewSelectedItems, setNewSelectedItems] = useState([]);
  const [selectProductItems, setSelectProductItems] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [filteredUnselectedItems, setFilteredUnselectedItems] = useState([]);
  const [filteredSelectedItems, setFilteredSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    return formattedDate;
  }

  const handleGetCouponCodeById = async () => {
    try {
      const res = await getCouponCodeById(id);
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    handleGetCouponCodeById();
  }, [refresh]);

  const handleUpdateCouponCode = async () => {
    setLoading(true);
    try {
      const res = await UpdateCoupon(
        id,
        CouponData.data.couponCode,
        CouponData.data.couponCodeType,
        CouponData.data.AmountCapping,
        CouponData.data.CouponType,
        CouponData.Discount,
        CouponData.ValidityStart !== null
          ? formatDate(CouponData.ValidityStart.$d)
          : CouponData.data.validityStartDate,
        CouponData.ValidityEnd !== null
          ? formatDate(CouponData.ValidityEnd.$d)
          : CouponData.data.validityEndDate,
        CouponData.data.Description,
        CouponData.data.multiple,
        CouponData.data.TotalCouponUsageInTimes,
        CouponData.data.UserCouponUsageInTimes
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated Successfully",
          showConfirmButton: true,
        });
        navigate("/coupoun-codes");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const { id } = useParams();

  const handlePushSelectedItems = async () => {
    const res = await AddItemsToSelected(
      id,
      selectedItems.map((item) => {
        return item._id;
      })
    );
    if (res) {
      setRefresh((refresh) => !refresh);
    }
  };

  const handleRemoveSelectedItems = async () => {
    const res = await RemoveItemsFromSelected(
      id,
      selectProductItems.map((item) => {
        return item._id;
      })
    );
    if (res) {
      setRefresh((refresh) => !refresh);
    }
  };

  const filterProducts = (products, query) => {
    return products.filter((item) => {
      const matchBrand = item.BrandName.toLowerCase().includes(
        query.toLowerCase()
      );
      const matchSeller = item.createdBy.fullName
        .toLowerCase()
        .includes(query.toLowerCase());
      const matchSkuCode = item.SKUCode.toLowerCase().includes(
        query.toLowerCase()
      );
      // const matchCategories = item.categories.some((category) =>
      //   category.toLowerCase().includes(query.toLowerCase())
      // );

      return matchBrand || matchSeller || matchSkuCode;
    });
  };

  const filteredselectedItems = Products.filter((item) => {
    const hasMatchingId = data?.selectedProducts?.some(
      (Newitem) => item._id === Newitem
    );
    return !hasMatchingId;
  });

  const filteredProducts = Products.filter((item) => {
    const hasMatchingId = data?.selectedProducts?.some(
      (Newitem) => item._id === Newitem
    );
    return hasMatchingId;
  });

  useEffect(() => {
    const filteredUnselected = filterProducts(
      filteredselectedItems,
      searchQuery
    );
    const filteredSelected = filterProducts(filteredProducts, searchQuery1);

    setFilteredUnselectedItems(filteredUnselected);
    setFilteredSelectedItems(filteredSelected);
  }, [searchQuery, filteredselectedItems, filteredProducts, searchQuery1]);

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Coupon Code - {data?.couponCode}
        </Typography>
        <Button
          variant="contained"
          onClick={handleUpdateCouponCode}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ px: 3, mt: 3 }}>
        <Grid item xs={12} lg={6} xl={6}>
          <Box sx={{ border: "1px solid #ddd" }}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
                Unselected Products
              </Typography>
              <Button variant="contained" onClick={handlePushSelectedItems}>
                Add Multiple
              </Button>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography>
                Search By Brand, sellerName, SkuCode, categories
              </Typography>
              <TextField
                size="small"
                placeholder="Search By Brand, sellerName, SkuCode, categories"
                sx={{ width: "400px", mt: 0.5 }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Box>
            <UnselectedItemsEdit
              Products={filteredUnselectedItems}
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} xl={6}>
          <Box sx={{ border: "1px solid #ddd" }}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
                Selected Products
              </Typography>
              <Button variant="contained" onClick={handleRemoveSelectedItems}>
                Remove Multiple
              </Button>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography>Search By Brand, sellerName, SkuCode</Typography>
              <TextField
                size="small"
                placeholder="Search By Brand, sellerName, SkuCode"
                sx={{ width: "400px", mt: 0.5 }}
                value={searchQuery1}
                onChange={(e) => setSearchQuery1(e.target.value)}
              />
            </Box>
            <SelectedItemsEdit
              Products={filteredSelectedItems}
              setSelectProductItems={setSelectProductItems}
              selectProductItems={selectProductItems}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectItemsForCouponCodeEdit;
