import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";

export default function ManageOrder({ setData, newData }) {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location?.state?.orderData;

  const [paymentStatus, setPaymentStatus] = React.useState(
    data?.[0]?.orderPaymentStatus || "Unpaid"
  );
  const [OrderStatus, setOrderStatus] = React.useState(
    data?.[0]?.orderStatus || "pending"
  );

  const [orderConfirmDate, setorderConfirmDate] = useState();

  useEffect(() => {
    if (OrderStatus === "accepted") {
      const currentDate = new Date().toLocaleDateString();
      // console.log(currentDate);
      setorderConfirmDate(currentDate);
    }
  }, [OrderStatus]);

  // console.log(orderConfirmDate);

  const handleUpdateOrderStatus = async () => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/buyer/updateOrder/${data?.[0]?._id}`,
        {
          orderPaymentStatus: paymentStatus,
          orderStatus: OrderStatus,
          orderConfirmDate: orderConfirmDate,
          orderAdminCancelled: OrderStatus === "cancelled" ? true : false,
        }
      );
      if (res) {
        toast.success("Updated SuccessFully", {
          position: "top-right",
        });
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[0].orderPaymentStatus = paymentStatus;
          updatedData[0].orderStatus = OrderStatus;
          updatedData[0].orderConfirmDate = orderConfirmDate;
          return updatedData;
        });
        navigate("/all-orders");
      } else {
        toast.error("Error updating Data", {
          position: "top-right",
        });
      }
    } catch (error) {}
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
          mt: 2,
          marginTop: "150px",
          mb: 2,
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Manage Order
        </Typography>
        <Button variant="contained" onClick={handleUpdateOrderStatus}>
          SUBMIT
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ width: "96.5%", ml: 3 }}>
        <Table sx={{ border: "1px solid #ddd" }} aria-label="simple table">
          <TableHead sx={{ background: "#eee" }}>
            <TableRow>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
              >
                S.No
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
              >
                Image
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
              >
                Product Details
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
              >
                Price
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
              >
                Quantity
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
              >
                Total Price (INCL TAX)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.[0]?.orderItems.map((row, index) => (
              <TableRow key={row.name}>
                <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                  {index + 1}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ borderRight: "1px solid #ddd" }}
                >
                  <Box
                    sx={{
                      width: "140px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={row?.productId?.MainImage}
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ borderRight: "1px solid #ddd" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",

                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        minWidth: "150px",
                      }}
                    >
                      Name:
                    </Typography>
                    <Typography>
                      {row?.productId?.Title.slice(0, 20)}....
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",

                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        minWidth: "150px",
                      }}
                    >
                      SKU Code:
                    </Typography>
                    <Typography>{row?.productId?.SkuCode}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",

                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        minWidth: "150px",
                      }}
                    >
                      Brand:
                    </Typography>
                    <Typography>{row?.productId?.BrandName}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",

                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        minWidth: "150px",
                      }}
                    >
                      Color:
                    </Typography>
                    <Typography>{row?.selectedColor}</Typography>
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ borderRight: "1px solid #ddd" }}
                >
                  ₹ {row?.productId?.offerPrice}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ borderRight: "1px solid #ddd" }}
                >
                  {row?.quantity}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ borderRight: "1px solid #ddd" }}
                >
                  ₹ {row?.productId?.offerPrice + 79}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mt: 4 }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "24px", ml: 3 }}>
          Order Details
        </Typography>
        <Typography
          sx={{ border: "2px solid #0099CC", width: "60px", ml: 3 }}
        ></Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Box sx={{ mt: 3, ml: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Order Id:
                </Typography>
                <Typography>{data?.[0]?.orderId}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",

                  flexDirection: "row",
                  mt: 2, // Adjust margin-top to create space between lines
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Date:
                </Typography>
                <Typography>{data?.[0]?.orderDate}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",

                  flexDirection: "row",
                  mt: 2, // Adjust margin-top to create space between lines
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Order By :
                </Typography>
                <Typography>{data?.[0]?.orderCreatedBy?.name}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",

                  flexDirection: "row",
                  mt: 2, // Adjust margin-top to create space between lines
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Mode Of Payment :
                </Typography>
                <Typography>{data?.[0]?.orderPaymentMethod}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",

                  flexDirection: "row",
                  mt: 2, // Adjust margin-top to create space between lines
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Mobile :
                </Typography>
                <Typography>{data?.[0]?.orderCreatedBy?.mobile}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",

                  flexDirection: "row",
                  mt: 2, // Adjust margin-top to create space between lines
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Email Id :
                </Typography>
                <Typography>{data?.[0]?.orderCreatedBy?.userEmail}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "row",
                  mt: 2, // Adjust margin-top to create space between lines
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Shipping Address :
                </Typography>
                <Box>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {data?.[0]?.fullName}
                  </Typography>
                  <Typography>
                    {data?.[0]?.orderAddress},{data?.[0]?.orderState},
                    {data?.[0]?.orderCountry},{data?.[0]?.orderPincode}
                  </Typography>
                  <Box>
                    <Typography>Mobile:{data?.[0]?.orderPhone}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ mt: 3 }}>
          <Typography sx={{ fontSize: "24px", fontWeight: "bold", ml: 3 }}>
            MANAGE ORDER
          </Typography>
          <Typography
            sx={{ border: "2px solid #0099CC", width: "60px", ml: 3 }}
          ></Typography>
          <Box sx={{ mt: 3, mb: 3, ml: 3 }}>
            <Box>
              <Box>
                <Typography>Total Order Amount : </Typography>
                <TextField
                  sx={{ width: "450px", mb: 2 }}
                  size="small"
                  value={`₹ ${data?.[0]?.totalPrice}.00`}
                  disabled
                />
              </Box>
              {/* <Box>
                <Typography>Order Delivary Status : </Typography>
                <Select size="small" sx={{ width: "450px", mb: 2 }}>
                  <MenuItem value="Delivered">Delivered</MenuItem>
                  <MenuItem value="Cancel">Cancel</MenuItem>
                </Select>
              </Box> */}
              <Box>
                <Typography>Order Status : </Typography>
                <Select
                  size="small"
                  sx={{ width: "450px", mb: 2 }}
                  value={OrderStatus}
                  onChange={(e) => setOrderStatus(e.target.value)}
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="accepted">Accept</MenuItem>
                  <MenuItem value="rejected">Reject</MenuItem>
                  <MenuItem value="cancelled">Cancel</MenuItem>
                </Select>
              </Box>
              <Box>
                <Typography>Payment Status : </Typography>
                <Select
                  size="small"
                  sx={{ width: "450px", mb: 2 }}
                  value={paymentStatus}
                  onChange={(e) => setPaymentStatus(e.target.value)}
                >
                  <MenuItem value="UnPaid">UnPaid</MenuItem>
                  <MenuItem value="Paid">Paid</MenuItem>
                </Select>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
