import { Box, Button, Card, TextField, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();

  const [data, setData] = useState([]);

  const handleEmailChange = (e) => {
    if (email !== data[0].email) {
      setEmailError("Enter a valid Admin Email");
    }
    setEmail(e.target.value);
  };

  const handleGetAdmin = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAdmin"
      );
      if (res) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAdmin();
  }, [email]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box>
        <Card
          sx={{ width: "450px", border: "1px solid #dddddd", p: 2 }}
          elevation={0}
        >
          <Typography sx={{ fontSize: "22px", fontWeight: "bold", mb: 0.7 }}>
            Forgot your password?
          </Typography>
          <Typography sx={{ fontSize: "15px" }}>
            Please use your registration email address
          </Typography>
          <Box>
            <TextField
              label="Email"
              placeholder="Enter Email Address"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              error={emailError}
              helperText={emailError}
              sx={{ mt: 2, mb: 2 }}
            />
          </Box>
          <Button variant="contained" fullWidth sx={{ p: 1.5 }}>
            SUBMIT
          </Button>
          <Typography sx={{ fontSize: "14px", textAlign: "end", mt: 2 }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              Try to log in again.
            </Link>
          </Typography>
        </Card>
        <Typography
          sx={{
            fontSize: "14px",
            textAlign: "center",
            mt: 2,
          }}
        >
          Copyright © 2023, skillshop.in, All Rights Reserved
        </Typography>
      </Box>
    </div>
  );
};

export default ForgotPassword;
