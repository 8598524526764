import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { BiHide, BiShow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { getAllBuyers } from "../../../axios";
import Swal from "sweetalert2";

export default function BuyerTable() {
  const [data, setData] = useState([]);
  const [visibility, setVisibility] = useState({});
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("Name");
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const handleGetAllBuyers = async () => {
    setLoading(true);
    try {
      const res = await getAllBuyers();
      if (res.status === 200) {
        const visibilityObj = {};
        res.data.forEach((row) => {
          visibilityObj[row._id] = !row.active;
        });
        setData(res.data);
        setVisibility(visibilityObj);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVisibility = async (id, value) => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/buyer/handleUpdateUser/${id}`,
        {
          active: value,
        }
      );
      if (res) {
        setVisibility((prevState) => ({
          ...prevState,
          [id]: !value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = () => {
    let filteredData = data;
    if (selected === "Name") {
      filteredData = filteredData.filter((row) =>
        row?.name?.toLowerCase().includes(searchQuery?.toLowerCase())
      );
    } else if (selected === "Email") {
      filteredData = filteredData.filter((row) =>
        (row.userEmail || row.email)
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase())
      );
    } else if (selected === "Mobile") {
      filteredData = filteredData?.filter((row) =>
        (row.contactNumber || row.mobile)?.includes(searchQuery)
      );
    } else if (selected === "City") {
      filteredData = filteredData.filter((row) =>
        row?.city?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
    } else if (selected === "State") {
      filteredData = filteredData.filter((row) =>
        row?.state?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
    } else if (selected === "Country") {
      filteredData = filteredData.filter((row) =>
        row.country?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
    }

    return filteredData;
  };

  useEffect(() => {
    handleGetAllBuyers();
  }, []);

  return (
    <>
      <Box sx={{ ml: 3, mb: 2 }}>
        <Typography>Filter By</Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Box>
            <Select
              size="small"
              sx={{ width: "200px" }}
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
            >
              <MenuItem value="Name">Name</MenuItem>
              <MenuItem value="Email">Email</MenuItem>
              <MenuItem value="Mobile">Mobile</MenuItem>
              <MenuItem value="City">City</MenuItem>
              <MenuItem value="State">State</MenuItem>
              <MenuItem value="Country">Country</MenuItem>
            </Select>
          </Box>
          <Box>
            <TextField
              size="small"
              sx={{ width: "400px" }}
              placeholder="search "
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
        </Box>
      </Box>
      {!loading ? (
        <TableContainer component={Paper} sx={{ width: "96.5%", ml: 3 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ background: "#eee" }}>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  ID
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Name
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Email
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Phone
                </TableCell>

                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  City
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  State
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Country
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {handleSearch()?.length > 0 ? (
              <TableBody>
                {handleSearch().map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ borderRight: "1px solid #ddd" }}
                    >
                      <Box>
                        <Box sx={{ alignItems: "left" }}>
                          <Typography sx={{ fontSize: "14px" }}>
                            {row.name}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ borderRight: "1px solid #ddd" }}
                    >
                      {row.userEmail || row.email}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ borderRight: "1px solid #ddd" }}
                    >
                      {row.contactNumber || row.mobile}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ borderRight: "1px solid #ddd" }}
                    >
                      {row.city}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ borderRight: "1px solid #ddd" }}
                    >
                      {row.state}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ borderRight: "1px solid #ddd" }}
                    >
                      {row.country}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ borderRight: "1px solid #ddd" }}
                    >
                      <Tooltip title="edit">
                        <IconButton
                          onClick={() => navigate(`/BuyerEdit/${row._id}`)}
                        >
                          <FiEdit size={20} />
                        </IconButton>
                      </Tooltip>

                      {visibility[row._id] ? (
                        <Tooltip title="Activate">
                          <IconButton
                            onClick={() =>
                              handleVisibility(row._id, visibility[row._id])
                            }
                          >
                            <BiHide style={{ color: "red" }} size={22} />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="DeActivate">
                          <IconButton
                            onClick={() =>
                              handleVisibility(row._id, visibility[row._id])
                            }
                          >
                            <BiShow style={{ color: "green" }} size={22} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8} sx={{ textAlign: "center" }}>
                    No Data Found
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
