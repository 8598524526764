import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { useState } from "react";
import { useEffect } from "react";
import { getAllSellers } from "../../axios";
import Swal from "sweetalert2";

const columns = [
  { id: "name", label: "S.No", minWidth: 60 },
  { id: "code", label: "Seller Details", minWidth: 300, align: "left" },
  {
    id: "population",
    label: "Entity Detail",
    minWidth: 220,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Email",
    minWidth: 150,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "Creation Date(MM/DD/YYYY)",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "density",
    label: "Status",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "density1",
    label: "Actions",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

export default function SellerTabe() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(500);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [sortBy, setSortBy] = useState("All");
  const [SearchOptions, setSearchOptions] = useState("SellerId");
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const searchOptions = ["SellerId", "Entity Name", "Email", "Contact No"];
  const sortByOptions = ["All", "Active", "In-Active"];

  console.log(SearchOptions, searchQuery, sortBy);

  const handlegetAllSellers = async () => {
    setLoading(true);
    try {
      const res = await getAllSellers();
      if (res.status === 200) {
        setData(res.data);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handlegetAllSellers();
  }, []);

  const UTCtoIST = (timestamp) => {
    const date = new Date(timestamp);

    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };

    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredData = () => {
    if (sortBy === "All") {
      if (SearchOptions === "SellerId") {
        return data.filter((row) =>
          row?.sellerId?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      if (SearchOptions === "Entity Name") {
        return data.filter((row) =>
          row?.companyName?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      if (SearchOptions === "Email") {
        return data.filter((row) =>
          row?.email?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      if (SearchOptions === "Contact No") {
        return data.filter((row) =>
          row?.mobileNumber?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    } else if (sortBy === "Active") {
      if (SearchOptions === "SellerId") {
        return data.filter(
          (row) =>
            row?.sellerId?.toLowerCase().includes(searchQuery.toLowerCase()) &&
            row.approved === true
        );
      }
      if (SearchOptions === "Entity Name") {
        return data.filter(
          (row) =>
            row?.companyName
              ?.toLowerCase()
              .includes(searchQuery.toLowerCase()) && row.approved === true
        );
      }
      if (SearchOptions === "Email") {
        return data.filter(
          (row) =>
            row?.email?.toLowerCase().includes(searchQuery.toLowerCase()) &&
            row.approved === true
        );
      }
      if (SearchOptions === "Contact No") {
        return data.filter(
          (row) =>
            row?.mobileNumber
              ?.toLowerCase()
              .includes(searchQuery.toLowerCase()) && row.approved === true
        );
      }
    } else if (sortBy === "In-Active") {
      if (SearchOptions === "SellerId") {
        return data.filter(
          (row) =>
            row?.sellerId?.toLowerCase().includes(searchQuery.toLowerCase()) &&
            row.approved === false
        );
      }
      if (SearchOptions === "Entity Name") {
        return data.filter(
          (row) =>
            row?.companyName
              ?.toLowerCase()
              .includes(searchQuery.toLowerCase()) && row.approved === false
        );
      }
      if (SearchOptions === "Email") {
        return data.filter(
          (row) =>
            row?.email?.toLowerCase().includes(searchQuery.toLowerCase()) &&
            row.approved === false
        );
      }
      if (SearchOptions === "Contact No") {
        return data.filter(
          (row) =>
            row?.mobileNumber
              ?.toLowerCase()
              .includes(searchQuery.toLowerCase()) && row.approved === false
        );
      }
    } else {
      return data;
    }
  };

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Sellers
        </Typography>
        <Button variant="contained" onClick={() => navigate(-1)}>
          BACK
        </Button>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography sx={{ fontSize: "17px", ml: 3 }}>Search by</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              mt: 1,
            }}
          >
            <Autocomplete
              size="small"
              sx={{ width: "250px", ml: 3 }}
              value={SearchOptions}
              onChange={(e, value) => setSearchOptions(value)}
              options={searchOptions}
              renderInput={(params) => <TextField {...params} label="Select" />}
            />

            <TextField
              placeholder="Search"
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ width: "250px" }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",

              alignItems: "center",
              mt: 1,
              mr: 3,
            }}
          >
            <Typography sx={{ fontSize: "17px" }}>Sort by :</Typography>
            <Autocomplete
              size="small"
              sx={{ width: "250px", ml: 3 }}
              value={sortBy}
              onChange={(e, value) => setSortBy(value)}
              options={sortByOptions}
              renderInput={(params) => <TextField {...params} label="Select" />}
            />
          </Box>
        </Box>
      </Box>
      {!loading ? (
        <>
          <Paper
            sx={{
              width: "96.5%",
              overflow: "hidden",
              ml: 3,
              border: "1px solid #ddd",
            }}
            elevation={0}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          background: "#eee",
                          fontWeight: "bold",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {filteredData()?.length > 0 ? (
                  <TableBody>
                    {filteredData()?.map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            align="center"
                            style={{ borderRight: "1px solid #ddd" }}
                          >
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell style={{ borderRight: "1px solid #ddd" }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexWrap: "wrap",
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    SellerId:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {row.sellerId}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Contact Name:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {row.fullName}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Contact No:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {row.mobileNumber}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Beneficiary Name:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {row.sellerBankAccountName}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Account Number:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {row.sellerAccountNumber}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Bank Name:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {row.sellerBankName}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </TableCell>
                          {/*  */}
                          <TableCell style={{ borderRight: "1px solid #ddd" }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexWrap: "wrap",
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Display Name:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {row.companyName}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Entity Name:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {row.companyDisplayName}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    City:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {row.city}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    State:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {row.state}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Country:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {row.country}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Postcode:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {row.POBox}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="left"
                            verticalAlign="middle"
                            style={{ borderRight: "1px solid #ddd" }}
                          >
                            <Typography sx={{ fontSize: "14px" }}>
                              {row.email}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            verticalAlign="middle"
                            style={{ borderRight: "1px solid #ddd" }}
                          >
                            <Typography sx={{ fontSize: "14px" }}>
                              {UTCtoIST(row.createdAt)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            verticalAlign="middle"
                            style={{ borderRight: "1px solid #ddd" }}
                          >
                            <Typography sx={{ fontSize: "14px" }}>
                              {row.approved === false ? "In-Active" : "Active"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            verticalAlign="middle"
                            style={{ borderRight: "1px solid #ddd" }}
                          >
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() =>
                                  navigate(`/seller-edit/${row._id}`)
                                }
                              >
                                <FiEdit size={22} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No Data Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
