import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import axios from "axios";

export default function Navbar() {
  const [data, setData] = React.useState([]);
  const [LogoData, setLogoData] = React.useState([]);
  const [paymentData, setPaymentData] = React.useState([]);
  const [storeCalculationData, setStoreCalculationData] = React.useState([]);
  const { token, user } = useSelector((state) => state.auth);

  const [staff, setStaff] = React.useState({});

  // console.log(staff);

  const handleGetStaffById = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getStaffById/${user?._id}`
      );
      if (res) {
        setStaff(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    handleGetStaffById();
  }, [user]);

  const navigations = [
    {
      name: "Setup",
      children: [
        {
          name: "MarketPlace Profile",
          to: `/store-profile/${data._id}`,
        },
        {
          name: "Logo And Details",
          to: `/LogoDetails/${LogoData._id}`,
        },
        {
          name: "Payment Mode",
          to: `/paymentMethods/${paymentData._id}`,
        },
        // {
        //   name: "Logistic Providers",
        //   to: "/LogisticProviders",
        // },
        {
          name: "Store Calculation",
          to: `storeCalculation/${storeCalculationData._id}`,
        },
        {
          name: "Theme Customization",
          to: "/theme-customization/themes",
        },
        {
          name: "Import Mega Menu",
          to: "/MegaMenu",
        },
        {
          name: "Footer Navigation",
          to: "/footer-navigation",
        },
        {
          name: "Seller Navigation",
          to: "/seller-navigation",
        },
        {
          name: "Email Settings",
          to: "/EmailSettings",
        },
        {
          name: "Email Templates",
          to: "/email-templates",
        },
        {
          name: "Staff Login",
          to: "/staff-login",
        },
        {
          name: "Change Password",
          to: `/change-password/${user._id}`,
        },
      ],
    },
    {
      name: "MASTERS",
      children: [
        {
          name: "Product Attributes",
          to: "/product-attributes-main",
        },
        {
          name: "Product Type Values",
          to: "/product-type-values",
        },
        {
          name: "Product Categories",
          to: "/product-categories",
        },
        {
          name: "Category Association",
          to: "/category-association",
        },

        {
          name: "Add Single Product ",
          to: "/selectVendor",
        },
        {
          name: "Product's List",
          to: "/products-list",
        },
        {
          name: "Product Inventory",
          to: "/product-inventory",
        },

        {
          name: "Loyalty Points Master",
          to: "/loyaltyPoints",
        },
        {
          name: "Return Policy",
          to: "/Return-policy",
        },
        {
          name: "Return Reasons",
          to: "/Return-reasons",
        },
      ],
    },
    {
      name: "USERS",
      children: [
        {
          name: "Buyers",
          to: "/BuyerList",
        },
        {
          name: "Manage Sellers",
          to: "/sellers",
          // to: "/product-type-values",
        },
        {
          name: "Holiday's List",
          // to: "/product-categories",
        },
        {
          name: "Seller Queries",
          // to: "/category-association",
        },

        {
          name: "Brand Request ",
          // to: "/selectVendor",
        },
      ],
    },
    {
      name: "CONTENT",
      children: [
        {
          name: "Buyer Static Pages",
          to: "/buyer-static-pages",
        },
        {
          name: "Seller Static Pages",
          to: "/seller-static-pages",
        },
        {
          name: "Seller Ratings",
        },
        {
          name: "Product Reviews",
          to: "/Ratings-and-reviews",
        },
        {
          name: "Email Subscription",
        },
        {
          name: "Product Question By Customer",
          to: "/ProductQuestion",
        },
        {
          name: "Blogs",
        },
      ],
    },
    {
      name: "SEO",
      children: [
        {
          name: "Product's Meta Tag",
        },
        {
          name: "XML Sitemap",
        },
      ],
    },
    {
      name: "SALES",
      children: [
        {
          name: "New Orders",
          to: "/all-orders",
        },
        {
          name: "Return Request",
        },
        {
          name: "Pre Orders",
        },
        {
          name: "Invoice Raised By Sellers Against Orders",
        },
        {
          name: "Seller Invoices",
        },
        {
          name: "Invoice Raised By MarketPlace For Sellers",
        },
        {
          name: "Promotions",
          to: "/promotions",
        },
        {
          name: "Coupon Codes",
          to: "/coupoun-codes",
        },
      ],
    },
    {
      name: "ACCOUNTS & LEDGERS",
      children: [
        {
          name: "Summary Report - Seller",
        },
        {
          name: "Detailed Report - Seller",
        },
        {
          name: "OrderWise Report Seller's Ledger",
        },
        {
          name: "Buyers Ledger",
        },
        {
          name: "MarketPlace Raising Invoices For Sellers ",
        },
        {
          name: "Day Book",
        },
        {
          name: "Day Book Buyer",
        },
      ],
    },
    {
      name: "REPORTS",
      children: [
        {
          name: "Best Selling Items",
        },
        {
          name: "Best Category",
        },
        {
          name: "Sales Report",
        },
        {
          name: "Sales By Location",
        },
        {
          name: "Invoices Raised By Sellers Against Orders",
        },
        {
          name: "Return Refund Orders",
        },
        {
          name: "Buyers Purchase History",
        },

        {
          name: "Best Performing Seller",
        },
        {
          name: "Products In Cart",
        },
        {
          name: "Abandoned Cart for Buyer",
        },
        {
          name: "WishList Report",
        },
        {
          name: "SEO Report",
        },
        {
          name: "Product SEO Report",
        },
        {
          name: "Category SEO Report",
        },
        {
          name: "StaticPage SEO Report",
        },
        {
          name: "SkillShop Credit Report",
        },
        {
          name: "Buyer Registrations",
        },
        {
          name: "Most Viewed Product",
        },
        {
          name: "Most Searched Keyword",
        },
        {
          name: "Zero Result Keyword",
        },
      ],
    },
    {
      name: "EXCEPTION MANAGE",
      children: [
        {
          name: "Not Registered User Carts",
        },
        {
          name: "Add All Items To Cart",
        },
        {
          name: "Remove Items From Cart",
        },
        {
          name: "Products Missing Images",
        },
        {
          name: "Duplicate Titles",
        },
        {
          name: "Duplicate Description",
        },
      ],
    },
  ];
  const [anchorEls, setAnchorEls] = React.useState(
    new Array(navigations.length).fill(null)
  );

  const handleMenuOpen = (index, event) => {
    let newAnchorEls = [...anchorEls];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handleMenuClose = (item, index) => {
    let newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
    navigate(item.to, { state: { navigations: navigations } });
  };

  const isMenuOpen = (index) => {
    return Boolean(anchorEls[index]);
  };

  const navigate = useNavigate();

  const handleGetAllStoreProfile = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllProfilers"
      );
      if (res) {
        setData(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle LogoAndDetails

  const handleLogoAndDetails = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getLogoDetails"
      );
      if (res) {
        // console.log(res);
        setLogoData(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handlePayment Methods

  const handlePaymentMethods = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/paymentMethods"
      );
      if (res) {
        // console.log("payments", res.data);
        setPaymentData(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle Store Calculation

  const handleStoreCalculation = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/StoreCalculationData"
      );
      if (res) {
        setStoreCalculationData(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    handleGetAllStoreProfile();
    handleLogoAndDetails();
    handlePaymentMethods();
    handleStoreCalculation();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{
          background: "#fff",
          border: "1px solid #dddddd",
          marginTop: "64px",
        }}
        elevation={0}
      >
        <>
          <Toolbar>
            <Button
              sx={{ color: "black" }}
              onClick={() => navigate("/dashboard")}
              disableRipple
            >
              Dashboard
            </Button>
            {navigations.map((navigation, index) => {
              const allowedChildren = staff?.permessions
                ? navigation.children.filter((item) =>
                    staff?.permessions?.includes(item.name)
                  )
                : navigation.children;

              if (allowedChildren.length > 0) {
                return (
                  <>
                    <Button
                      sx={{ color: "black", ml: 1 }}
                      onClick={(event) => handleMenuOpen(index, event)}
                      endIcon={
                        isMenuOpen(index) ? (
                          <ExpandLessIcon sx={{ ml: -1 }} />
                        ) : (
                          <ExpandMoreIcon sx={{ ml: -1 }} />
                        )
                      }
                      disableRipple
                    >
                      {navigation.name}
                    </Button>
                    <Menu
                      anchorEl={anchorEls[index]}
                      open={isMenuOpen(index)}
                      onClose={() => handleMenuClose(navigation, index)}
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                      transformOrigin={{ vertical: "top", horizontal: "left" }}
                    >
                      {allowedChildren.map((item) => (
                        <MenuItem onClick={() => handleMenuClose(item, index)}>
                          <Link
                            to={item.to}
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            {item.name}
                          </Link>
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                );
              }
              return null; // Hide the parent button if children are empty
            })}
          </Toolbar>
        </>
      </AppBar>
    </Box>
  );
}
