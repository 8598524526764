import {
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const CreateEmailSettings = () => {
  const navigate = useNavigate();
  const [profileName, setprofileName] = useState();
  const [email, setemail] = useState();
  const [replyTo, setreplyTo] = useState();
  const [password, setpassword] = useState();
  const [userName, setuserName] = useState();
  const [SMTPHost, setSMTPHost] = useState();
  const [SMTPPort, setSMTPPort] = useState();
  const [EnableSSL, setEnableSSL] = useState(false);
  const [NetworkCredential, setNetworkCredential] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [ReplyToemailError, setReplyToEmailError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [PasswordError, setPasswordError] = useState("");
  const [hostError, setHostError] = useState("");
  const [portError, setportError] = useState("");
  const [ProfileNameError, setProfileNameError] = useState("");
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setemail(email);

    // Validate email
    if (email.length === 0) {
      setEmailError("Email is required");
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };
  const handleReplytoEmailChange = (e) => {
    const email = e.target.value;
    setreplyTo(email);

    // Validate email
    if (email.length === 0) {
      setReplyToEmailError("ReplyTo Email is required");
    } else if (!validateEmail(email)) {
      setReplyToEmailError("Please enter a valid email address");
    } else {
      setReplyToEmailError("");
    }
  };

  const handleSmtpHostChange = (e) => {
    if (e.target.value.length === 0) {
      setHostError("SMTP Host is required");
    } else {
      setHostError("");
    }
    setSMTPHost(e.target.value);
  };
  const handleSmtpPortChange = (e) => {
    if (e.target.value.length === 0) {
      setportError("SMTP Port is required");
    } else {
      setportError("");
    }
    setSMTPPort(e.target.value);
  };

  const handleProfileNameChange = (e) => {
    if (e.target.value.length === 0) {
      setProfileNameError("Profile Name is Required");
    } else if (e.target.value.length < 3) {
      setProfileNameError("Profile Name must be at least 3 characters");
    } else {
      setProfileNameError("");
    }
    setprofileName(e.target.value);
  };

  const handleUserNameChange = (e) => {
    if (e.target.value.length === 0) {
      setUserNameError("UserName is Required");
    } else {
      setUserNameError("");
    }
    setuserName(e.target.value);
  };

  const handlePasswordChange = (e) => {
    if (e.target.value.length === 0) {
      setPasswordError("Password is Required");
    } else {
      setPasswordError("");
    }
    setpassword(e.target.value);
  };

  const handleCreateEmailSettings = async () => {
    setLoading(true);
    try {
      if (
        ProfileNameError ||
        emailError ||
        ReplyToemailError ||
        userNameError ||
        PasswordError ||
        hostError ||
        portError
      ) {
        return;
      }
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/EmailSetting",
        {
          profileName,
          email,
          replyTo,
          password,
          userName,
          SMTPHost,
          SMTPPort,
          EnableSSL,
          NetworkCredential,
        },
        config
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "New Email Setting Created SuccessFully",
          showConfirmButton: true,
          timer: 1500,
        });
        navigate("/EmailSettings");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div style={{ marginTop: "150px" }}>
      <Card
        sx={{ maxWidth: "450px", p: 2 }}
        style={{ margin: "auto", marginTop: "30px" }}
      >
        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          Create New Email Settings
        </Typography>
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Profile Name"
            fullWidth
            sx={{ mb: 1.5 }}
            value={profileName}
            error={ProfileNameError}
            helperText={ProfileNameError}
            onChange={handleProfileNameChange}
          />
          <TextField
            label="Email "
            fullWidth
            sx={{ mb: 1.5 }}
            value={email}
            error={emailError}
            helperText={emailError}
            onChange={handleEmailChange}
          />
          <TextField
            label="ReplyTo"
            fullWidth
            sx={{ mb: 1.5 }}
            value={replyTo}
            error={ReplyToemailError}
            helperText={ReplyToemailError}
            onChange={handleReplytoEmailChange}
          />
          <TextField
            label="Password"
            fullWidth
            sx={{ mb: 1.5 }}
            value={password}
            error={PasswordError}
            helperText={PasswordError}
            onChange={handlePasswordChange}
          />
          <TextField
            label="UserName"
            fullWidth
            sx={{ mb: 1.5 }}
            value={userName}
            error={userNameError}
            helperText={userNameError}
            onChange={handleUserNameChange}
          />
          <TextField
            label="SMTP Host"
            fullWidth
            sx={{ mb: 1.5 }}
            value={SMTPHost}
            error={hostError}
            helperText={hostError}
            onChange={handleSmtpHostChange}
          />
          <TextField
            label="SMTP Port"
            fullWidth
            sx={{ mb: 1.5 }}
            value={SMTPPort}
            error={portError}
            helperText={portError}
            onChange={handleSmtpPortChange}
          />

          <Box>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Enable SSL
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  checked={EnableSSL === true}
                  onChange={() => setEnableSSL(true)}
                  label="Enable"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  checked={EnableSSL === false}
                  onChange={() => setEnableSSL(false)}
                  label="Disable"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Network Credential
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  checked={NetworkCredential === true}
                  onChange={() => setNetworkCredential(true)}
                  label="Enable"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  checked={NetworkCredential === false}
                  onChange={() => setNetworkCredential(false)}
                  label="Disable"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 1.5, mb: 1.5 }}
          onClick={handleCreateEmailSettings}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "SUBMIT"}
        </Button>
      </Card>
    </div>
  );
};

export default CreateEmailSettings;
