import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Box } from "@mui/system";

export default function CategoryAssociationTable() {
  const [data, setData] = React.useState([]);
  const [Loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const handleGetAllCategoryAssociations = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllCategoryAssociations"
      );
      if (res) {
        // console.log(res);
        setData(res.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //
  const handleDeleteCategoryAssociation = async (id) => {
    try {
      const res = await axios.delete(
        `https://rccore-backend.cstechns.com/api/admin/DeleteCategoryAssociation/${id}`
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });
        setData(data.filter((item) => item._id !== id));
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };

  React.useEffect(() => {
    handleGetAllCategoryAssociations();
  }, []);
  return (
    <>
      {Loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ width: "96.5%", ml: 3 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "#dddddd" }}>
                <TableRow>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    S.No
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Brand Name
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Main Categories
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">{row.brand}</TableCell>
                    <TableCell align="center">
                      {row.categories.join(", ")}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() =>
                          navigate(`/update-category-association/${row._id}`)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton>
                        <DeleteIcon
                          onClick={() =>
                            handleDeleteCategoryAssociation(row._id)
                          }
                          style={{ color: "red" }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
