import {
  Button,
  Card,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const CreateAttributeCustom = () => {
  const [name, setName] = useState();
  const [AttributType, setAttributType] = useState();
  const [initialValue, setInitialValue] = useState([]);
  const [sequenceId, setsequenceId] = useState();
  const [useInProductSearch, setuseInProductSearch] = useState(false);
  const [OrderDetails, setOrderDetails] = useState(false);

  const navigate = useNavigate();

  const handleCustomAttributeCreation = async () => {
    try {
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/createCustomAttributes",
        {
          name,
          AttributType,
          initialValue,
          sequenceId,
          useInProductSearch,
          OrderDetails,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "New Custom Attribute Created SuccessFully",
          showConfirmButton: true,
        });
        navigate("/product-attributes-custom");
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };
  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mr: 3,
        }}
      >
        <Typography></Typography>
        <Button variant="contained" onClick={() => navigate(-1)}>
          BACK
        </Button>
      </Box>
      <Card
        sx={{ maxWidth: "450px", p: 2 }}
        style={{
          margin: "auto",
          marginTop: "30px",
          border: "1px solid #dddddd",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            textAlign: "center",
            mb: 2,
          }}
        >
          Create Custom Attributes
        </Typography>
        <Box>
          <Box>
            <Typography sx={{ fontSize: "17px" }}>Enter Name</Typography>
            <TextField
              variant="outlined"
              placeholder="Enter Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              sx={{ mb: 2, mt: 0.5 }}
            />
          </Box>
          <Box>
            <Typography>Select Type</Typography>
            <Select
              fullWidth
              displayEmpty
              value={AttributType}
              onChange={(e) => setAttributType(e.target.value)}
              sx={{ mb: 2, mt: 0.5 }}
            >
              <InputLabel sx={{ ml: 2 }}>Select</InputLabel>

              <MenuItem value="RadioButton">RadioButton</MenuItem>
              <MenuItem value="TextBox">TextBox</MenuItem>
              <MenuItem value="CheckBox">CheckBox</MenuItem>
              <MenuItem value="TextArea">TextArea</MenuItem>
              <MenuItem value="DropdownList">DropdownList</MenuItem>
            </Select>
          </Box>
          {AttributType === "DropdownList" && (
            <TextField
              variant="outlined"
              label="Initial Values"
              placeholder="Initial Values"
              value={initialValue.join(",")}
              onChange={(e) => {
                const values = e.target.value.split(",");
                setInitialValue(values);
              }}
              fullWidth
              sx={{ mt: 2 }}
            />
          )}

          {AttributType == "RadioButton" && (
            <FormControl sx={{ mt: 2 }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Initial Value
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="true"
                  onChange={(e) => setInitialValue(true)}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  onChange={(e) => setInitialValue(false)}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          )}
          {AttributType == "CheckBox" && (
            <FormControl sx={{ mt: 2 }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Initial Value
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="true"
                  onChange={(e) => setInitialValue(true)}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  onChange={(e) => setInitialValue(false)}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          )}
          <Box>
            <Typography>Sequence Id</Typography>
            <TextField
              variant="outlined"
              placeholder="Sequence Id"
              value={sequenceId}
              onChange={(e) => setsequenceId(e.target.value)}
              fullWidth
              sx={{ mt: 0.5, mb: 2 }}
            />
          </Box>
          <Box style={{ display: "flex", gap: "60px" }}>
            <Box>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Use in Product Search
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={useInProductSearch}
                    checked={useInProductSearch == true}
                    onChange={(e) => setuseInProductSearch(true)}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={useInProductSearch}
                    checked={useInProductSearch == false}
                    onChange={(e) => setuseInProductSearch(false)}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Use in Order Details
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={OrderDetails}
                    checked={OrderDetails == true}
                    control={<Radio />}
                    onChange={(e) => setOrderDetails(true)}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={OrderDetails}
                    control={<Radio />}
                    checked={OrderDetails == false}
                    onChange={(e) => setOrderDetails(false)}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
          onClick={handleCustomAttributeCreation}
        >
          SUBMIT
        </Button>
      </Card>
    </div>
  );
};

export default CreateAttributeCustom;
