import {
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const UpdateSizeChartAttributes = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const handleGetUpdateBrandById = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getSizeChartById/${id}`
      );
      if (res) {
        // console.log(res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetUpdateBrandById();
  }, []);

  const handleUpdateBrandData = async () => {
    setLoading1(true);
    try {
      const formData = new FormData();
      formData.append("file", newImage);
      formData.append("upload_preset", "insta-clone");
      formData.append("cloud_name", "bunny1705");
      formData.append("folder", "Brand_images");

      const response = await fetch(
        "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const imageData = await response.json();
      const logoUrl = imageData.secure_url;
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/UpdateSizeChart/${id}`,
        {
          name: data.name,
          image: logoUrl,
          sortingId: data.sortingId,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated SuccessFully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    } finally {
      setLoading1(false);
    }
  };
  return (
    <div style={{ marginTop: "150px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mr: 3 }}>
        <Typography></Typography>
        <Button
          variant="contained"
          disableElevation
          onClick={() => navigate(-1)}
        >
          BACK
        </Button>
      </Box>
      {!loading ? (
        <>
          <Card
            sx={{ maxWidth: "450px", p: 2 }}
            style={{
              margin: "auto",
              marginTop: "30px",
              border: "1px solid #dddddd",
            }}
          >
            <Typography
              sx={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}
            >
              Update Size chart Attributes
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>Enter Name</Typography>
                <TextField
                  fullWidth
                  sx={{ mb: 2, mt: 0.5 }}
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </Box>
              <Box>
                <Typography>Image</Typography>
                <TextField
                  fullWidth
                  type="file"
                  sx={{ mb: 2, mt: 0.5 }}
                  inputProps={{ accept: ".jpeg,.png,.jpg,.gif" }}
                  onChange={(e) => {
                    setNewImage(e.target.files[0]);
                    setData({ ...data, image: newImage });
                    const reader = new FileReader();
                    reader.onload = () => {
                      if (reader.readyState === 2) {
                        setData({ ...data, imagePreview: reader.result });
                      }
                    };
                    reader.readAsDataURL(e.target.files[0]);
                  }}
                />
                <img
                  src={data.imagePreview ? data.imagePreview : data.image}
                  alt={data.name}
                  width="120px"
                />
              </Box>
              <Box>
                <Typography>Sort Id</Typography>
                <TextField
                  fullWidth
                  sx={{ mb: 2, mt: 0.5 }}
                  value={data.sortingId}
                  onChange={(e) =>
                    setData({ ...data, sortingId: e.target.value })
                  }
                />
              </Box>
            </Box>
            <Button
              variant="contained"
              sx={{ mb: 2 }}
              fullWidth
              onClick={handleUpdateBrandData}
              disabled={loading1}
            >
              {loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
            </Button>
          </Card>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
    </div>
  );
};

export default UpdateSizeChartAttributes;
