import { Box, Button, Card, TextField, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const NavigationAdd = () => {
  const [Heading, setHeading] = useState("");
  const [Link, setLink] = useState("");
  const [SortingOrder, setSortingOrder] = useState("");
  const navigate = useNavigate();

  const handleCreateNavbar = async () => {
    try {
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/handleCreateNAvbar",
        {
          Heading,
          Link,
          SortingOrder,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Created SuccessFully",
          showConfirmButton: true,
        });
        navigate("/theme-customization/manage-Navbar");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div style={{ marginTop: "150px" }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ml: 3,
            mr: 3,
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            Navigation Manage
          </Typography>
          <Button variant="contained" onClick={handleCreateNavbar}>
            Submit
          </Button>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
          <Card
            elevation={0}
            sx={{ border: "1px solid #ddd", width: "450px", p: 2 }}
          >
            <Box>
              <Box>
                <Typography>Heading</Typography>
                <TextField
                  size="small"
                  placeholder="Heading"
                  value={Heading}
                  onChange={(e) => setHeading(e.target.value)}
                  fullWidth
                  sx={{ mt: 0.5, mb: 2 }}
                />
              </Box>
              <Box>
                <Typography>Link</Typography>
                <TextField
                  size="small"
                  placeholder="Link"
                  value={Link}
                  onChange={(e) => setLink(e.target.value)}
                  fullWidth
                  sx={{ mt: 0.5, mb: 2 }}
                />
              </Box>
              <Box>
                <Typography>Sorting Order</Typography>
                <TextField
                  size="small"
                  placeholder="Sorting Order"
                  value={SortingOrder}
                  onChange={(e) => setSortingOrder(e.target.value)}
                  fullWidth
                  sx={{ mt: 0.5, mb: 2 }}
                />
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
    </div>
  );
};

export default NavigationAdd;
