import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { Link } from "react-router-dom";
import { Box, CircularProgress, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { useState } from "react";
const columns = [
  { id: "SNo", label: "S.No", align: "center" },
  { id: "name", label: "Name", align: "center", minWidth: 600 },
  {
    id: "AttributType",
    label: "Field Type",
    minWidth: 170,
    align: "center",
  },
  {
    id: "sequenceId",
    label: "Seq.Id",
    minWidth: 170,
    align: "center",
  },
  {
    id: "Action",
    label: "Action",
    minWidth: 170,
    align: "center",
  },
];

function createData(index, name, AttributType, sequenceId, _id) {
  return { index: index, name, AttributType, sequenceId, _id };
}

export default function CustomAttributesTable() {
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleGetAllCustomAttributes = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllCustomAttributes"
      );
      if (res) {
        const data = res.data.map((row, index) =>
          createData(index, row.name, row.AttributType, row.sequenceId, row._id)
        );
        setData(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCustomAttribute = async (id) => {
    try {
      const res = await axios.delete(
        `https://rccore-backend.cstechns.com/api/admin/DeleteCustomAttribute/${id}`
      );
      if (res.status == 200) {
        // console.log(res);
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });
        setData(
          data
            .filter((item) => item._id !== id)
            .map((row, index) =>
              createData(
                index,
                row.name,
                row.AttributType,
                row.sequenceId,
                row._id
              )
            )
        );
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };

  React.useEffect(() => {
    handleGetAllCustomAttributes();
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <Paper
            sx={{
              width: "97%",
              overflow: "hidden",
              ml: 2.5,
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                          backgroundColor: "#dddddd",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];

                            if (column.id === "Action") {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <IconButton
                                    component={Link}
                                    to={`/edit-attributes-custom/${row._id}`} // specify the route for the edit page
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton>
                                    <DeleteIcon
                                      onClick={() =>
                                        handleDeleteCustomAttribute(row._id)
                                      }
                                      style={{ color: "red" }}
                                    />
                                  </IconButton>
                                </TableCell>
                              );
                            }

                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "SNo" ? row.index + 1 : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
