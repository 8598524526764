import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const EditFooterNavigation = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);

  console.log(data);
  const navigate = useNavigate();

  const handleGetFooterDataById = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/handleGetFooterById/${id}`
      );
      if (res) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateFooter = async () => {
    setLoading1(true);
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/handleFooterUpdateById/${id}`,
        {
          FooterColumn: data.FooterColumn,
          MenuType: data.MenuType,
          Heading: data.Heading,
          Link: data.Link,
          SortingOrder: data.SortingOrder,
          ShowNew: data.ShowNew,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated Successfully",
          showConfirmButton: true,
        });
        navigate("/footer-navigation");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    handleGetFooterDataById();
  }, []);
  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Navigation Manage
        </Typography>
        <Button
          variant="contained"
          onClick={handleUpdateFooter}
          disabled={Loading1}
        >
          {Loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
        </Button>
      </Box>
      {!Loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          <Card
            sx={{ border: "1px solid #ddd", width: "450px", p: 2 }}
            elevation={0}
          >
            <Box>
              <Box>
                <Typography>Footer Column</Typography>
                <Select
                  fullWidth
                  size="small"
                  sx={{ mt: 0.5, mb: 2 }}
                  value={data?.FooterColumn ?? ""}
                  onChange={(e) =>
                    setData({ ...data, FooterColumn: e.target.value })
                  }
                >
                  <MenuItem value="Footer Column 1">Footer Column 1</MenuItem>
                  <MenuItem value="Footer Column 2">Footer Column 2</MenuItem>
                  <MenuItem value="Footer Column 3">Footer Column 3</MenuItem>
                  <MenuItem value="Footer Column 4">Footer Column 4</MenuItem>
                </Select>
              </Box>
              <Box>
                <Typography>Menu Type</Typography>
                <Select
                  fullWidth
                  size="small"
                  sx={{ mt: 0.5, mb: 2 }}
                  value={data?.MenuType ?? ""}
                  onChange={(e) =>
                    setData({ ...data, MenuType: e.target.value })
                  }
                >
                  <MenuItem value="Label">Label</MenuItem>
                  <MenuItem value="Static Page">Static Page</MenuItem>
                </Select>
              </Box>
              <Box>
                <Typography>Heading</Typography>
                <TextField
                  size="small"
                  placeholder="Heading"
                  fullWidth
                  value={data.Heading}
                  onChange={(e) =>
                    setData({ ...data, Heading: e.target.value })
                  }
                  sx={{ mt: 0.5, mb: 2 }}
                />
              </Box>
              <Box>
                <Typography>Link</Typography>
                <TextField
                  size="small"
                  placeholder="Link"
                  fullWidth
                  value={data.Link}
                  onChange={(e) => setData({ ...data, Link: e.target.value })}
                  sx={{ mt: 0.5, mb: 2 }}
                />
              </Box>
              <Box>
                <Typography>Sorting Order</Typography>
                <TextField
                  size="small"
                  placeholder="Sorting Order"
                  fullWidth
                  value={data.SortingOrder}
                  onChange={(e) =>
                    setData({ ...data, SortingOrder: e.target.value })
                  }
                  sx={{ mt: 0.5, mb: 2 }}
                />
              </Box>
              <Box>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Show New
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={data.ShowNew === true}
                      control={
                        <Radio
                          checked={data.ShowNew === true}
                          onChange={(e) => setData({ ...data, ShowNew: true })}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={data.ShowNew === false}
                      control={
                        <Radio
                          checked={data.ShowNew === false}
                          onChange={(e) => setData({ ...data, ShowNew: false })}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Card>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default EditFooterNavigation;
