import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Navbar from "./components/Navbar";
import Sellers from "./components/Sellers/Sellers";
import SellerEdit from "./components/Sellers/SellerEdit";
import StoreProfile from "./components/SetUp/StoreProfile";
import LogoAndDetails from "./components/SetUp/LogoAndDetails";
import PaymentMethods from "./components/SetUp/PaymentMethods";
import LogisticProvidersList from "./components/SetUp/LogisticProviders/LogisticProvidersList";
import CreateLogisticProvider from "./components/SetUp/LogisticProviders/CreateLogisticProvider";
import LogisticProviderEdit from "./components/SetUp/LogisticProviders/LogisticProviderEdit";
import StoreCalculation from "./components/SetUp/StoreCalculation";
import EmailSettings from "./components/SetUp/EmailSettings/EmailSettings";
import CreateEmailSettings from "./components/SetUp/EmailSettings/CreateEmailSettings";
import EmailSettingEdit from "./components/SetUp/EmailSettings/EmailSettingEdit";

import NavbarTop from "./components/NavbarTop";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import CustomAttributes from "./components/Masters/Attributes/CustomAttributes";
import ProductAttributes from "./components/Masters/Attributes/ProductAttributes";
import CreateAttributeCustom from "./components/Masters/Attributes/CreateAttributeCustom";
import EditCustomAttributes from "./components/Masters/Attributes/EditCustomAttributes";
import CreateProductAttributes from "./components/Masters/Attributes/CreateProductAttributes";
import CreateNewProductCategory from "./components/Masters/ProductCategories/CreateNewProductCategory";
import ProductCategory from "./components/Masters/ProductCategories/ProductCategory";
import CreateNew from "./components/Masters/ProductCategories/CreateNew";
import ManageBrand from "./components/Masters/Attributes/ManageBrand";
import CreateNewBrand from "./components/Masters/Attributes/CreateNewBrand";
import ManageProductType from "./components/Masters/Attributes/ManageProductType";
import CreateNewProductType from "./components/Masters/Attributes/CreateNewProductType";
import ManageSizeChart from "./components/Masters/Attributes/ManageSizeChart";
import CreateNewSizeChart from "./components/Masters/Attributes/createNewSizeChart";
import ManageColorAttributes from "./components/Masters/Attributes/ManageColorAttributes";
import CreateNewColorAttribute from "./components/Masters/Attributes/CreateNewColorAttribute";
import ManageSizeAttribute from "./components/Masters/Attributes/ManageSizeAttribute";
import CreateNewSizeAttribute from "./components/Masters/Attributes/CreateNewSizeAttribute";
import ManageVariantAttribute from "./components/Masters/Attributes/ManageVariantAttributes";
import CreateNewVariantAttribute from "./components/Masters/Attributes/CreateNewVariantAttribute";
import ManageModelAttribute from "./components/Masters/Attributes/ManageModelAttributes";
import CreateNewModelAttribute from "./components/Masters/Attributes/CreateNewModelAttribute";
import ManageAttributes1 from "./components/Masters/Attributes/ManageAttributes1";
import CreateNewAttributes1 from "./components/Masters/Attributes/CreateNewAttributes1";
import CreateNewAttributes2 from "./components/Masters/Attributes/CreateNewAttributes2";
import ManageAttributes2 from "./components/Masters/Attributes/ManageAttributes2";
import CreateNewAttributes3 from "./components/Masters/Attributes/CreateNewAttributes3";
import ManageAttributes3 from "./components/Masters/Attributes/ManageAttributes3";
import CreateNewAttributes4 from "./components/Masters/Attributes/CreateNewAttributes4";
import ManageAttributes4 from "./components/Masters/Attributes/ManageAttributes4";
import CategoryAssociation from "./components/Masters/categoryAssociation/CategoryAssociation";
import CreateNewAssociation from "./components/Masters/categoryAssociation/CreateNewAssociation";
import UpdateCategoryAssociation from "./components/Masters/categoryAssociation/updateCategoryAssociation";
import ProductType from "./components/Masters/ProductType/ProductType";
import CreateNewProductTypeValues from "./components/Masters/ProductType/CreateNewProductType";
import EditProductTypeVaues from "./components/Masters/ProductType/EditProductTypeVaues";
import UpdateColorAttributes from "./components/Masters/Attributes/UpdateColorAttributes";
import UpdateAttributes from "./components/Masters/Attributes/UpdateColorAttributes";
import UpdateSizeAttributes from "./components/Masters/Attributes/UpdateSizeAttributes";
import UpdateVariantsAttributes from "./components/Masters/Attributes/UpdateVariantsAttributes";
import UpdateModelsAttributes from "./components/Masters/Attributes/UpdateModelAttributes";
import UpdateAttributes1 from "./components/Masters/Attributes/UpdateAttributes1";
import UpdateAttributes2 from "./components/Masters/Attributes/UpdateAttributes2";
import UpdateAttributes3 from "./components/Masters/Attributes/UpdateAttributes3";
import UpdateAttributes4 from "./components/Masters/Attributes/UpdateAttributes4";
import UpdateBrandAttributes from "./components/Masters/Attributes/updateBrandAttribute";
import UpdateProductTypeAttributes from "./components/Masters/Attributes/UpdateProductTypeAttributes";
import UpdateSizeChartAttributes from "./components/Masters/Attributes/UpdateSizeChartAttributes";
import UpdateColorInsideAttributes from "./components/Masters/Attributes/UpdateColorInsideAttributes";
import UpdateSizeInsideAttributes from "./components/Masters/Attributes/UpdateSizeInsideAttributes";
import UpdateVarinatsInsideAttributes from "./components/Masters/Attributes/UpdateVariantsInsideAttributes";
import UpdateModelsInsideAttributes from "./components/Masters/Attributes/UpdateModelInsideAttributes";
import UpdateAttributes1InsideAttributes from "./components/Masters/Attributes/UpdateAttributes1InsideAttributes";
import UpdateAttributes2InsideAttributes from "./components/Masters/Attributes/UpdateAttributes2InsideAttributes";
import UpdateAttributes3InsideAttributes from "./components/Masters/Attributes/UpdateAttributes3InsideAttributes";
import UpdateAttributes4InsideAttributes from "./components/Masters/Attributes/UpdateAttributes4InsideAttributes";
import ChangePassword from "./components/SetUp/ChangePassword";
import ProductList from "./components/Masters/ProductList/ProductList";
import AddSingleProduct from "./components/Masters/ProductList/ManageSingleProduct";
import ManageSingleProduct from "./components/Masters/ProductList/ManageSingleProduct";
import MetaInformation from "./components/Masters/ProductList/MetaInformation";
import ManagePriceAndStock from "./components/Masters/PriceAndStocking/ManagePriceAndStock";
import FrequentlyBoughtTogether from "./components/Masters/frequentlyBought/FrequentlyBoughtTogether";
import UpdateProductCategory from "./components/Masters/ProductCategories/UpdateProductCategory";
import Analytics2 from "./dashboard/Analytics2";
import EmailTemplates from "./components/SetUp/EmailTemplates/EmailTemplates";
import EmailTemplateEdit from "./components/SetUp/EmailTemplates/EmailTemplateEdit";
import StaffLogin from "./components/SetUp/stafLogin/StaffLogin";
import CreateNewStaffLogin from "./components/SetUp/stafLogin/CreateNewStaffLogin";
import Footer from "./components/Footer";
import CreateNewStaffLoginEdit from "./components/SetUp/stafLogin/CreateNewStaffLoginEdit";
import ManageProductsForProductType from "./components/Masters/ProductType/ManageProductsForProductType";
import HomePage from "./components/ThemeCustomiaztion/HomePageCustomiaztion/HomePage";
import ManageHomePage from "./components/ThemeCustomiaztion/HomePageCustomiaztion/ManageHomePageMainBanners";
import ManageHomePageMainBanners from "./components/ThemeCustomiaztion/HomePageCustomiaztion/ManageHomePageMainBanners";
import UploadBannerImages from "./components/ThemeCustomiaztion/HomePageCustomiaztion/UploadBannerImages";
import Themes from "./components/ThemeCustomiaztion/Themes";
import ProductDetailsPage from "./components/ThemeCustomiaztion/ProductDetailCustomization/ProductDetailsPage";
import SellerProfile from "./components/ThemeCustomiaztion/SellerProfileCustomization/SellerProfilePage";
import CategoryListingPage from "./components/ThemeCustomiaztion/CategoryListingCustomization/CategoryListingPage";
import SearchResult from "./components/ThemeCustomiaztion/SearchResultCustomization/SearchResult";
import ManageTopbar from "./components/ThemeCustomiaztion/HomePageCustomiaztion/ManageTopbar";
import UploadTobarImages from "./components/ThemeCustomiaztion/HomePageCustomiaztion/UploadTopbarImages";
import ManageHeader from "./components/ThemeCustomiaztion/HomePageCustomiaztion/ManageHeader";
import UploadHeaderIcons from "./components/ThemeCustomiaztion/HomePageCustomiaztion/UploadHeaderIcons";
import Homepage16images from "./components/ThemeCustomiaztion/HomePageCustomiaztion/Homepage16images";
import HomePageFeaturedCategories from "./components/ThemeCustomiaztion/HomePageCustomiaztion/Homepagecategories";
import HomePageMidBanner from "./components/ThemeCustomiaztion/HomePageCustomiaztion/HomepageMidBanner";
import HomePageFeaturedBanners from "./components/ThemeCustomiaztion/HomePageCustomiaztion/HomepageFeturedBrands";
import HomepageEndBanner from "./components/ThemeCustomiaztion/HomePageCustomiaztion/HomepageEndBanner";
import HomepagePreFooter from "./components/ThemeCustomiaztion/HomePageCustomiaztion/HomepagePreFooter";
import HomepageFooterNav from "./components/ThemeCustomiaztion/HomePageCustomiaztion/HomePageFooterNav";
import HomePageSubFooter from "./components/ThemeCustomiaztion/HomePageCustomiaztion/HomepageSubFooter";
import HomepageCreditsFooter from "./components/ThemeCustomiaztion/HomePageCustomiaztion/HompageCreditsFooter";
import Upload16Images from "./components/ThemeCustomiaztion/HomePageCustomiaztion/Upload16Banner";
import HomepageCategories from "./components/ThemeCustomiaztion/HomePageCustomiaztion/Homepagecategories";
import UploadFeaturedCategories from "./components/ThemeCustomiaztion/HomePageCustomiaztion/UploadFeaturedCategories";
import UploadMidBannerImages from "./components/ThemeCustomiaztion/HomePageCustomiaztion/UploadMidBannerImages";
import FeaturedBanners from "./components/ThemeCustomiaztion/HomePageCustomiaztion/HomepageFeturedBrands";
import UploadFeaturedBanners from "./components/ThemeCustomiaztion/HomePageCustomiaztion/UploadFeaturedBanners";
import UploadEndBannerImages from "./components/ThemeCustomiaztion/HomePageCustomiaztion/UploadEndBannerImages";
import UploadPreFooter from "./components/ThemeCustomiaztion/HomePageCustomiaztion/UploadPreFooter";
import HomepageSubFooter from "./components/ThemeCustomiaztion/HomePageCustomiaztion/HomepageSubFooter";
import UploadSubFooter from "./components/ThemeCustomiaztion/HomePageCustomiaztion/UploadSubFooter";
import HomepageCategorySlider from "./components/ThemeCustomiaztion/HomePageCustomiaztion/HomepageCategorySlider";
import HomepageFeaturedBrands from "./components/ThemeCustomiaztion/HomePageCustomiaztion/HomepageFeturedBrands";
import UploadFeaturedBrands from "./components/ThemeCustomiaztion/HomePageCustomiaztion/UploadFeaturedBrands";
import ManageFooterBanners from "./components/ThemeCustomiaztion/HomePageCustomiaztion/ManageFooterBanners";
import HomepageFooterBanners from "./components/ThemeCustomiaztion/HomePageCustomiaztion/ManageFooterBanners";
import UploadFooterBannerImages from "./components/ThemeCustomiaztion/HomePageCustomiaztion/UploadFooterBannerImages";
import HomepageFeaturedBanners from "./components/ThemeCustomiaztion/HomePageCustomiaztion/HomepageFeaturedBanners";
import ManageCreditsBanner from "./components/ThemeCustomiaztion/ProductDetailCustomization/ManageCreditsBanner";
import UploadCreditFooterImages from "./components/ThemeCustomiaztion/ProductDetailCustomization/UploadCreditBannerImages";
import UploadCreditBannerImages from "./components/ThemeCustomiaztion/ProductDetailCustomization/UploadCreditBannerImages";
import ManageRecentlyViewed from "./components/ThemeCustomiaztion/ProductDetailCustomization/ManageRecentlyViewed";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ManageRatingsAndReviews from "./components/ThemeCustomiaztion/ProductDetailCustomization/ManageRatingsAndReviews";
import ManageQuestionsAboutProduct from "./components/ThemeCustomiaztion/ProductDetailCustomization/ManageQuestionsAboutProduct";
import ManageProductDescription from "./components/ThemeCustomiaztion/ProductDetailCustomization/ManageProductDescription";
import ManageMoreLikeThis from "./components/ThemeCustomiaztion/ProductDetailCustomization/ManageMoreLikeThis";
import ManageMostSimilar from "./components/ThemeCustomiaztion/ProductDetailCustomization/ManageMostSimilar";
import NewOrders from "./components/Sales/Orders/NewOrders";
import ManageOrder from "./components/Sales/Orders/ManageOrder";
import axios from "axios";
import AddSingleListing from "./components/Masters/AddSingleListing/AddSingleListing";
import AddSingleListingForSeller from "./components/Masters/AddSingleListing/AddSingleListingForSeller";
import ManageNavbar from "./components/ThemeCustomiaztion/HomePageCustomiaztion/ManageNavbar";
import NavigationAdd from "./components/ThemeCustomiaztion/HomePageCustomiaztion/NavigationAdd";
import MegaMenu from "./components/SetUp/MegaMenu";
import FooterNavigation from "./components/SetUp/FooterNavigation/FooterNavigation";
import CreateNewFooterNavigation from "./components/SetUp/FooterNavigation/CreateNewFooterNavigation";
import ManageBestSelling from "./components/Masters/ProductCategories/ManageBestSelling";
import ManageProducts from "./components/Masters/ProductCategories/ManageProducts";
import { Box } from "@mui/material";
import ForgotPassword from "./components/ForgotPassword";
import Permessions from "./components/SetUp/stafLogin/Permessions";
import BuyerList from "./components/Users/Buyers/BuyerList";
import BuyerEdit from "./components/Users/Buyers/BuyerEdit";
import EditFooterNavigation from "./components/SetUp/FooterNavigation/EditFooterNavigation";
import LoayltyPointsMaster from "./components/Masters/LoyaltyPointsMaster/LoayltyPointsMaster";
import BuyerStatic from "./components/Content/BuyerStatic/BuyerStatic";
import CreateNewPage from "./components/Content/BuyerStatic/CreateNewPage";
import EditBuyerStaticPage from "./components/Content/BuyerStatic/EditBuyerStaticPage";
import SellerStatic from "./components/Content/SellerStatic/sellerStatic";
import CreateNewSellerPage from "./components/Content/SellerStatic/CreateNewSellerPage";
import EditSellerStaticPage from "./components/Content/SellerStatic/EditSellerStaticPage";
import SellerPolicy from "./components/Masters/ReturnPolicy/ReturnPolicy";
import ReturnPolicy from "./components/Masters/ReturnPolicy/ReturnPolicy";
import CreateNewReturnPolicy from "./components/Masters/ReturnPolicy/CreateNewReturnPolicy";
import EditReturnPolicy from "./components/Masters/ReturnPolicy/EditReturnPolicy";
import CreateNewReturnReasons from "./components/Masters/ReturnReasons/CreateNewReturnReasons";
import ReturnReasons from "./components/Masters/ReturnReasons/ReturnReasons";
import EditReturnReasons from "./components/Masters/ReturnReasons/EditReturnReasons";
import RatingsAndReviews from "./components/Masters/RatingAndReviews/RatingAndReviews";
import Promotions from "./components/Sales/Promotions/Promotions";
import CoupounCodes from "./components/Sales/CouponCodes/CoupounCodes";
import CreateNewCouponCode from "./components/Sales/CouponCodes/CreateNewCouponCode";
import SelectItemsForCouponCode from "./components/Sales/CouponCodes/SelectItemsForCouponCode";
import EditCouponCode from "./components/Sales/CouponCodes/EditCouponCodes";
import SelectItemsForCouponCodeEdit from "./components/Sales/CouponCodes/SelectItemsForCouponCodeEdit";
import SellerFooterNavigation from "./components/SetUp/SellerFooterNavigation/SellerFooterNavigation";
import CreateNewSellerFooterNavigation from "./components/SetUp/SellerFooterNavigation/CreateNewSellerFooterNavigation";
import EditSellerNavigation from "./components/SetUp/SellerFooterNavigation/EditSellerNavigation";
import ProductInventory from "./components/ProductInverntory/ProductInventory";
import ProductQuestion from "./components/Content/productQuestions/ProductQuestion";

function App() {
  const { token, user } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [Products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [Loading1, setLoading1] = useState(false);
  const [Loading2, setLoading2] = useState(false);
  const [Loading3, setLoading3] = useState(false);
  const [Loading4, setLoading4] = useState(false);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  useEffect(() => {
    if (token && window.location.pathname === "/") {
      window.location.href = "/dashboard";
    }
  }, [window.location.pathname]);

  //

  return (
    <div className="App">
      <Wrapper>
        {token ? (
          <>
            <NavbarTop />
            <Navbar />
          </>
        ) : (
          <>
            <NavbarTop />
          </>
        )}
        <Routes>
          {token ? (
            <>
              <>
                <Route
                  exact
                  path="/dashboard"
                  element={
                    <Analytics2
                      data={data}
                      sellers={sellers}
                      Products={Products}
                      users={users}
                      Loading1={Loading1}
                      Loading2={Loading2}
                      Loading3={Loading3}
                      Loading4={Loading4}
                    />
                  }
                />
                <Route exact path="/sellers" element={<Sellers />} />

                <Route exact path="/seller-edit/:id" element={<SellerEdit />} />
                <Route
                  exact
                  path="/store-profile/:id"
                  element={<StoreProfile />}
                />
                <Route
                  exact
                  path="/LogoDetails/:id"
                  element={<LogoAndDetails />}
                />
                <Route
                  exact
                  path="/paymentMethods/:id"
                  element={<PaymentMethods />}
                />
                <Route
                  exact
                  path="/LogisticProviders"
                  element={<LogisticProvidersList />}
                />
                <Route
                  exact
                  path="/createLogisticProvider"
                  element={<CreateLogisticProvider />}
                />
                <Route
                  exact
                  path="/logisiticProviderEdit/:id"
                  element={<LogisticProviderEdit />}
                />
                <Route
                  exact
                  path="/storeCalculation/:id"
                  element={<StoreCalculation />}
                />
                <Route
                  exact
                  path="/EmailSettings"
                  element={<EmailSettings />}
                />
                <Route
                  exact
                  path="/CreateEmailSetting"
                  element={<CreateEmailSettings />}
                />
                <Route
                  exact
                  path="/EmailSettingsEdit/:id"
                  element={<EmailSettingEdit />}
                />
                <Route
                  exact
                  path="/product-attributes-main"
                  element={<ProductAttributes />}
                />
                <Route
                  exact
                  path="/product-attributes-custom"
                  element={<CustomAttributes />}
                />
                <Route
                  exact
                  path="/create-attributes-custom"
                  element={<CreateAttributeCustom />}
                />
                <Route
                  exact
                  path="/edit-attributes-custom/:id"
                  element={<EditCustomAttributes />}
                />
                <Route
                  exact
                  path="/Create-new-product-attribute"
                  element={<CreateProductAttributes />}
                />
                <Route
                  exact
                  path="/Create-new-product-Category/:id"
                  element={<CreateNewProductCategory />}
                />
                <Route
                  exact
                  path="/product-categories"
                  element={<ProductCategory Products={Products} />}
                />
                <Route exact path="/create-new/:id" element={<CreateNew />} />
                <Route
                  exact
                  path="/manage-brand/:id"
                  element={<ManageBrand />}
                />
                <Route
                  exact
                  path="/manage-product-type/:id"
                  element={<ManageProductType />}
                />
                <Route
                  exact
                  path="/manage-Size-chart/:id"
                  element={<ManageSizeChart />}
                />
                <Route
                  exact
                  path="/manage-Color-attributes/:id"
                  element={<ManageColorAttributes />}
                />
                <Route
                  exact
                  path="/manage-Size-attributes/:id"
                  element={<ManageSizeAttribute />}
                />
                <Route
                  exact
                  path="/manage-Variant-attributes/:id"
                  element={<ManageVariantAttribute />}
                />
                <Route
                  exact
                  path="/manage-Model-attributes/:id"
                  element={<ManageModelAttribute />}
                />
                <Route
                  exact
                  path="/manage-attributes1/:id"
                  element={<ManageAttributes1 />}
                />
                <Route
                  exact
                  path="/manage-attributes2/:id"
                  element={<ManageAttributes2 />}
                />
                <Route
                  exact
                  path="/manage-attributes3/:id"
                  element={<ManageAttributes3 />}
                />
                <Route
                  exact
                  path="/manage-attributes4/:id"
                  element={<ManageAttributes4 />}
                />
                <Route
                  exact
                  path="/create-new-product-type/:id"
                  element={<CreateNewProductType />}
                />
                <Route
                  exact
                  path="/create-new-Attributes1/:id"
                  element={<CreateNewAttributes1 />}
                />
                <Route
                  exact
                  path="/create-new-Attributes2/:id"
                  element={<CreateNewAttributes2 />}
                />
                <Route
                  exact
                  path="/create-new-Attributes3/:id"
                  element={<CreateNewAttributes3 />}
                />
                <Route
                  exact
                  path="/create-new-Attributes4/:id"
                  element={<CreateNewAttributes4 />}
                />
                <Route
                  exact
                  path="/create-new-Model-Attribute/:id"
                  element={<CreateNewModelAttribute />}
                />
                <Route
                  exact
                  path="/create-new-Variant-Attribute/:id"
                  element={<CreateNewVariantAttribute />}
                />
                <Route
                  exact
                  path="/create-new-brand/:id"
                  element={<CreateNewBrand />}
                />
                <Route
                  exact
                  path="/create-new-Size-Chart/:id"
                  element={<CreateNewSizeChart />}
                />
                <Route
                  exact
                  path="/create-new-Color-Attributes/:id"
                  element={<CreateNewColorAttribute />}
                />
                <Route
                  exact
                  path="/create-new-Size-Attributes/:id"
                  element={<CreateNewSizeAttribute />}
                />
                <Route
                  exact
                  path="/category-association"
                  element={<CategoryAssociation />}
                />
                <Route
                  exact
                  path="/new-category-association"
                  element={<CreateNewAssociation />}
                />
                <Route
                  exact
                  path="/update-category-association/:id"
                  element={<UpdateCategoryAssociation />}
                />
                <Route
                  exact
                  path="/product-type-values"
                  element={<ProductType />}
                />
                <Route
                  exact
                  path="/create-new-product-type"
                  element={<CreateNewProductTypeValues />}
                />
                <Route
                  exact
                  path="/edit-product-type-values/:id"
                  element={<EditProductTypeVaues />}
                />
                <Route
                  exact
                  path="/update-color-attributes/:id"
                  element={<UpdateColorAttributes />}
                />
                <Route
                  exact
                  path="/update-size-attributes/:id"
                  element={<UpdateSizeAttributes />}
                />
                <Route
                  exact
                  path="/update-variants-attributes/:id"
                  element={<UpdateVariantsAttributes />}
                />
                <Route
                  exact
                  path="/update-Model-attributes/:id"
                  element={<UpdateModelsAttributes />}
                />
                <Route
                  exact
                  path="/update-attributes1/:id"
                  element={<UpdateAttributes1 />}
                />
                <Route
                  exact
                  path="/update-attributes2/:id"
                  element={<UpdateAttributes2 />}
                />
                <Route
                  exact
                  path="/update-attributes3/:id"
                  element={<UpdateAttributes3 />}
                />
                <Route
                  exact
                  path="/update-attributes4/:id"
                  element={<UpdateAttributes4 />}
                />
                <Route
                  exact
                  path="/update-Model-attributes/:id"
                  element={<UpdateModelsAttributes />}
                />
                <Route
                  exact
                  path="/update-brand-attributes/:id"
                  element={<UpdateBrandAttributes />}
                />
                <Route
                  exact
                  path="/update-ProductType-attributes/:id"
                  element={<UpdateProductTypeAttributes />}
                />
                <Route
                  exact
                  path="/update-sizeChart-attributes/:id"
                  element={<UpdateSizeChartAttributes />}
                />
                <Route
                  exact
                  path="/update-color-inside-attributes/:id"
                  element={<UpdateColorInsideAttributes />}
                />
                <Route
                  exact
                  path="/update-Size-inside-attributes/:id"
                  element={<UpdateSizeInsideAttributes />}
                />
                <Route
                  exact
                  path="/update-variants-inside-attributes/:id"
                  element={<UpdateVarinatsInsideAttributes />}
                />
                <Route
                  exact
                  path="/update-Model-inside-attributes/:id"
                  element={<UpdateModelsInsideAttributes />}
                />
                <Route
                  exact
                  path="/update-Attributes1-inside-attributes/:id"
                  element={<UpdateAttributes1InsideAttributes />}
                />
                <Route
                  exact
                  path="/update-Attributes2-inside-attributes/:id"
                  element={<UpdateAttributes2InsideAttributes />}
                />
                <Route
                  exact
                  path="/update-Attributes3-inside-attributes/:id"
                  element={<UpdateAttributes3InsideAttributes />}
                />
                <Route
                  exact
                  path="/update-Attributes4-inside-attributes/:id"
                  element={<UpdateAttributes4InsideAttributes />}
                />
                <Route
                  exact
                  path="/change-password/:id"
                  element={<ChangePassword />}
                />
                <Route exact path="/products-list" element={<ProductList />} />
                <Route
                  exact
                  path="/manage-single-listing/:id"
                  element={<ManageSingleProduct />}
                />
                <Route
                  exact
                  path="/manage-meta-information/:id"
                  element={<MetaInformation />}
                />
                <Route
                  exact
                  path="/manage-price-stocking/:id"
                  element={<ManagePriceAndStock />}
                />
                <Route
                  exact
                  path="/frequently-bought-together/:id"
                  element={<FrequentlyBoughtTogether />}
                />
                <Route
                  exact
                  path="/update-product-categories/:id"
                  element={<UpdateProductCategory />}
                />
                <Route
                  exact
                  path="/email-templates"
                  element={<EmailTemplates />}
                />
                <Route
                  exact
                  path="/edit-email-template/:id"
                  element={<EmailTemplateEdit />}
                />
                {/* staff login */}
                <Route exact path="/staff-login" element={<StaffLogin />} />
                <Route
                  exact
                  path="/new-staff-login"
                  element={<CreateNewStaffLogin />}
                />
                <Route
                  exact
                  path="/manage-product-productType/:id"
                  element={<ManageProductsForProductType />}
                />
                <Route
                  exact
                  path="/staff-login-edit/:id"
                  element={<CreateNewStaffLoginEdit />}
                />

                {/* Theme Customiaztion */}

                <Route
                  exact
                  path="/theme-customization/Home-page"
                  element={<HomePage />}
                />
                <Route
                  exact
                  path="/theme-customization/themes"
                  element={<Themes />}
                />
                <Route
                  exact
                  path="/theme-customization/manage-FullBanners"
                  element={<ManageHomePageMainBanners />}
                />
                <Route
                  exact
                  path="/theme-customization/manage-Topbar"
                  element={<ManageTopbar />}
                />
                <Route
                  exact
                  path="/theme-customization/upload-banner-images"
                  element={<UploadBannerImages />}
                />
                <Route
                  exact
                  path="/theme-customization/upload-topbar-images"
                  element={<UploadTobarImages />}
                />
                <Route
                  exact
                  path="/theme-customization/manage-16-images"
                  element={<Homepage16images />}
                />
                <Route
                  exact
                  path="/theme-customization/manage-Featured Categories"
                  element={<HomepageCategories />}
                />
                <Route
                  exact
                  path="/theme-customization/manage-Mid Banner"
                  element={<HomePageMidBanner />}
                />
                <Route
                  exact
                  path="/theme-customization/manage-Featured Banners"
                  element={<HomepageFeaturedBanners />}
                />
                <Route
                  exact
                  path="/theme-customization/manage-End Banner"
                  element={<HomepageEndBanner />}
                />
                <Route
                  exact
                  path="/theme-customization/manage-Pre Footer"
                  element={<HomepagePreFooter />}
                />
                <Route
                  exact
                  path="/theme-customization/manage-sub Footer"
                  element={<HomepageSubFooter />}
                />
                <Route
                  exact
                  path="/theme-customization/manage-Category Slider"
                  element={<HomepageCategorySlider />}
                />
                <Route
                  exact
                  path="/theme-customization/manage-Featured Brands"
                  element={<HomepageFeaturedBrands />}
                />
                <Route
                  exact
                  path="/theme-customization/manage-Footer Banners"
                  element={<HomepageFooterBanners />}
                />

                <Route
                  exact
                  path="/theme-customization/upload-16Banner-images"
                  element={<Upload16Images />}
                />
                <Route
                  exact
                  path="/theme-customization/upload-featuredCategories"
                  element={<UploadFeaturedCategories />}
                />
                <Route
                  exact
                  path="/theme-customization/upload-Mid-banner"
                  element={<UploadMidBannerImages />}
                />
                <Route
                  exact
                  path="/theme-customization/upload-Featured-banners"
                  element={<UploadFeaturedBanners />}
                />
                <Route
                  exact
                  path="/theme-customization/upload-end-banners"
                  element={<UploadEndBannerImages />}
                />
                <Route
                  exact
                  path="/theme-customization/manage-Navbar"
                  element={<ManageNavbar />}
                />
                <Route
                  exact
                  path="/theme-customization/navigation-add"
                  element={<NavigationAdd />}
                />
                <Route exact path="/MegaMenu" element={<MegaMenu />} />
                <Route
                  exact
                  path="/theme-customization/upload-Pre-Footer"
                  element={<UploadPreFooter />}
                />
                <Route
                  exact
                  path="/footer-navigation"
                  element={<FooterNavigation />}
                />
                <Route
                  exact
                  path="/create-footer-navigation"
                  element={<CreateNewFooterNavigation />}
                />
                <Route
                  exact
                  path="/Edit-footer-navigation/:id"
                  element={<EditFooterNavigation />}
                />
                <Route
                  exact
                  path="/theme-customization/upload-sub-Footer"
                  element={<UploadSubFooter />}
                />
                <Route
                  exact
                  path="/theme-customization/upload-Featured-brands"
                  element={<UploadFeaturedBrands />}
                />
                <Route
                  exact
                  path="/theme-customization/upload-Footer-banners"
                  element={<UploadFooterBannerImages />}
                />

                {/* header Routes */}
                <Route
                  exact
                  path="/theme-customization/manage-header"
                  element={<ManageHeader />}
                />

                <Route
                  exact
                  path="/theme-customization/upload-header-icons"
                  element={<UploadHeaderIcons />}
                />

                {/* product Details Page Customization */}

                <Route
                  exact
                  path="/theme-customization/PDP-customization"
                  element={<ProductDetailsPage />}
                />
                <Route
                  exact
                  path="/theme-customization/PDP/manage-Credit Banner"
                  element={<ManageCreditsBanner />}
                />
                <Route
                  exact
                  path="/theme-customization/PDP/manage-Recently viewed"
                  element={<ManageRecentlyViewed toast={toast} />}
                />
                <Route
                  exact
                  path="/theme-customization/PDP/upload-credit-Footer"
                  element={<UploadCreditBannerImages />}
                />
                <Route
                  exact
                  path="/theme-customization/PDP/manage-Ratings and Reviews"
                  element={<ManageRatingsAndReviews toast={toast} />}
                />
                <Route
                  exact
                  path="/theme-customization/PDP/manage-Question About Product"
                  element={<ManageQuestionsAboutProduct toast={toast} />}
                />
                <Route
                  exact
                  path="/theme-customization/PDP/manage-Product Description"
                  element={<ManageProductDescription toast={toast} />}
                />
                <Route
                  exact
                  path="/theme-customization/PDP/manage-More like this"
                  element={<ManageMoreLikeThis toast={toast} />}
                />
                <Route
                  exact
                  path="/theme-customization/PDP/manage-Most similar"
                  element={<ManageMostSimilar toast={toast} />}
                />

                {/* seller Profile Customization */}

                <Route
                  exact
                  path="/theme-customization/seller-Profile-customization"
                  element={<SellerProfile />}
                />

                {/* Category Listing Customization */}

                <Route
                  exact
                  path="/theme-customization/category-listing-customization"
                  element={<CategoryListingPage />}
                />

                {/* Search Result */}

                <Route
                  exact
                  path="/theme-customization/Search-result-customization"
                  element={<SearchResult />}
                />

                {/* order Routes */}

                <Route exact path="/all-orders" element={<NewOrders />} />
                <Route
                  exact
                  path="/manage-order/:id"
                  element={<ManageOrder setData={setData} newData={data} />}
                />
                <Route
                  exact
                  path="/selectVendor"
                  element={<AddSingleListing />}
                />
                <Route
                  exact
                  path="/AddSingleListingForSeller/:id"
                  element={<AddSingleListingForSeller />}
                />
                <Route
                  exact
                  path="/ManageBestSelling/:name/:id"
                  element={<ManageBestSelling Products={Products} />}
                />
                <Route
                  exact
                  path="/ManageProducts/:name/:id"
                  element={<ManageProducts Products={Products} />}
                />
                <Route
                  exact
                  path="/ManagePermessions/:id"
                  element={<Permessions />}
                />
                <Route exact path="/BuyerList" element={<BuyerList />} />
                <Route exact path="/BuyerEdit/:id" element={<BuyerEdit />} />

                {/* Loyalty Points */}
                <Route
                  exact
                  path="/loyaltyPoints"
                  element={<LoayltyPointsMaster />}
                />

                {/* content */}
                <Route
                  exact
                  path="/buyer-static-pages"
                  element={<BuyerStatic />}
                />
                <Route
                  exact
                  path="/create-buyer-static-pages"
                  element={<CreateNewPage />}
                />
                <Route
                  exact
                  path="/edit-buyer-static-pages/:id"
                  element={<EditBuyerStaticPage />}
                />
                <Route
                  exact
                  path="/seller-static-pages"
                  element={<SellerStatic />}
                />
                <Route
                  exact
                  path="/create-Seller-static-pages"
                  element={<CreateNewSellerPage />}
                />
                <Route
                  exact
                  path="/edit-seller-static-pages/:id"
                  element={<EditSellerStaticPage />}
                />
                <Route exact path="/Return-policy" element={<ReturnPolicy />} />
                <Route
                  exact
                  path="/Return-reasons"
                  element={<ReturnReasons />}
                />
                <Route
                  exact
                  path="/create-Return-policy"
                  element={<CreateNewReturnPolicy />}
                />
                <Route
                  exact
                  path="/create-Return-Reason"
                  element={<CreateNewReturnReasons />}
                />
                <Route
                  exact
                  path="/edit-Return-policy/:id"
                  element={<EditReturnPolicy />}
                />
                <Route
                  exact
                  path="/edit-Return-Reasons/:id"
                  element={<EditReturnReasons />}
                />
                <Route
                  exact
                  path="/Ratings-and-reviews"
                  element={<RatingsAndReviews />}
                />
                <Route exact path="/promotions" element={<Promotions />} />
                <Route exact path="/coupoun-codes" element={<CoupounCodes />} />
                <Route
                  exact
                  path="/create-coupoun-code"
                  element={<CreateNewCouponCode />}
                />
                <Route
                  exact
                  path="/select-items-coupoun-code"
                  element={<SelectItemsForCouponCode Products={Products} />}
                />
                <Route
                  exact
                  path="/edit-selected-items-coupoun-code/:id"
                  element={
                    <SelectItemsForCouponCodeEdit
                      Products={Products}
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  }
                />
                <Route
                  exact
                  path="/edit-coupoun-code/:id"
                  element={
                    <EditCouponCode setRefresh={setRefresh} refresh={refresh} />
                  }
                />
                <Route
                  exact
                  path="/seller-navigation"
                  element={<SellerFooterNavigation />}
                />
                <Route
                  exact
                  path="/create-new-seller-navigation"
                  element={<CreateNewSellerFooterNavigation />}
                />
                <Route
                  exact
                  path="/edit-seller-navigation/:id"
                  element={<EditSellerNavigation />}
                />
                <Route
                  exact
                  path="/product-inventory"
                  element={<ProductInventory />}
                />
                <Route
                  exact
                  path="/ProductQuestion"
                  element={<ProductQuestion />}
                />
              </>
            </>
          ) : (
            <>
              <Route exact path="/" element={<Login />} />
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPassword />}
              />
            </>
          )}
        </Routes>

        <Footer />

        <ToastContainer />
      </Wrapper>
    </div>
  );
}

export default App;
