import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CKEditor } from "ckeditor4-react";
import React from "react";
import { createNewSellerStaticPage, createNewStaticPage } from "../../../axios";
import { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import htmlToReactParser from "html-react-parser";

const CreateNewSellerPage = () => {
  const [Heading, setHeading] = useState(null);
  const [Link, setLink] = useState(null);
  const [Page, setPage] = useState(null);
  const [SortingId, setSortingId] = useState(null);
  const [Description, setDescription] = useState("");
  const [parsedData, setParsedData] = useState("");
  const [PageFor, setPageFor] = useState("Header");

  const navigate = useNavigate();

  // console.log(parsedData);

  const handleEditorChange = (event) => {
    const data = event.editor.getData();

    setParsedData(data);

    // console.log("Table:", formattedHtml);
  };
  const handleCreateStaticPage = async () => {
    try {
      const res = await createNewSellerStaticPage(
        Heading,
        Link,
        Page,
        PageFor,
        SortingId,
        parsedData
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Created successfully",
          showConfirmButton: true,
        });
        navigate("/seller-static-pages");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Manage Seller Static Page
        </Typography>
        <Button
          variant="contained"
          onClick={handleCreateStaticPage}
          disabled={!Heading || !Page || !Link || !parsedData || !SortingId}
        >
          Submit
        </Button>
      </Box>
      <Box sx={{ m: 3 }}>
        <Box sx={{ border: "1px solid #ddd", borderRadius: "5px" }}>
          <Box sx={{ background: "#000", p: 1.5 }}>
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              Static Pages
            </Typography>
          </Box>

          <Box sx={{ p: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Heading:
              </Typography>
              <TextField
                size="small"
                sx={{ width: "400px", mt: 0.5, mb: 2 }}
                placeholder="Heading"
                value={Heading}
                onChange={(e) => setHeading(e.target.value)}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Navigation For{" "}
              </Typography>
              <Select
                fullWidth
                size="small"
                sx={{ mt: 0.5, mb: 2, width: "400px" }}
                value={PageFor}
                onChange={(e) => setPageFor(e.target.value)}
                displayEmpty
              >
                <InputLabel sx={{ ml: 2 }}></InputLabel>
                <MenuItem value="Header"> Header</MenuItem>
                <MenuItem value="Footer"> Footer</MenuItem>
              </Select>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Link Name:
              </Typography>
              <TextField
                size="small"
                sx={{ width: "400px", mt: 0.5, mb: 2 }}
                placeholder="Link Name"
                value={Link}
                onChange={(e) => setLink(e.target.value)}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Page URL:
              </Typography>
              <TextField
                size="small"
                sx={{ width: "400px", mt: 0.5, mb: 2 }}
                placeholder="Page URL"
                value={Page}
                onChange={(e) => setPage(e.target.value)}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Sorting ID:
              </Typography>
              <TextField
                size="small"
                sx={{ width: "400px", mt: 0.5, mb: 2 }}
                placeholder="Sorting ID"
                value={SortingId}
                onChange={(e) => setSortingId(e.target.value)}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Upload Banner Image:
              </Typography>
              <Box>
                <TextField
                  type="file"
                  size="small"
                  sx={{ width: "400px", mt: 0.5, mb: 2 }}
                />
                <Typography sx={{ fontSize: "15px" }}>
                  Upload image file types of png,jpeg,jpg and size of 1370*400
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  minWidth: "300px",
                  mt: 2,
                }}
              >
                Description:
              </Typography>
              <Box sx={{ mt: 2, mb: 2 }}>
                {/* <textarea
                  style={{
                    width: "600px",
                    height: "400px",
                    resize: "vertical",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                  }}
                  value={Description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea> */}

                <CKEditor onChange={handleEditorChange} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CreateNewSellerPage;
