import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import ProductQuestionsTable from "./ProductQuestionsTable";
import { handleGetAllProductQuestion } from "../../../axios";
import { useState } from "react";
import { useEffect } from "react";

const ProductQuestion = () => {
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [visibility, setVisibility] = React.useState({});
  const [visibility1, setVisibility1] = React.useState({});
  const [Loading, setLoading] = useState(false);

  console.log(visibility);
  const productQuestions = async () => {
    setLoading(true);
    try {
      const res = await handleGetAllProductQuestion();
      if (res) {
        const visibilityObj = {};
        const visibilityObj1 = {};
        res.forEach((row) => {
          visibilityObj[row._id] = row.QuestionDeactivated;
          visibilityObj1[row._id] = row.AnswerDeactivated;
        });
        // console.log(res);
        setData(res);
        setVisibility(visibilityObj);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    productQuestions();
  }, [refresh]);
  return (
    <div style={{ marginTop: "140px" }}>
      <Box sx={{ mt: 2 }}>
        <Typography sx={{ fontSize: "24px", fontWeight: "bold", ml: 3 }}>
          Product Question By Customer
        </Typography>
      </Box>
      {Loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item xs={12} xl={12}>
            <ProductQuestionsTable
              data={data}
              setRefresh={setRefresh}
              visibility={visibility}
              setVisibility={setVisibility}
              visibility1={visibility1}
              setVisibility1={setVisibility1}
              setData={setData}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ProductQuestion;
