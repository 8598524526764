import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const EmailSettingEdit = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [ProfileNameError, setProfileNameError] = useState("");
  const { id } = useParams();

  const [emailError, setEmailError] = useState("");
  const [ReplyToemailError, setReplyToEmailError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [PasswordError, setPasswordError] = useState("");
  const [hostError, setHostError] = useState("");
  const [portError, setportError] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setData({ ...data, email: email });

    // Validate email
    if (email.length === 0) {
      setEmailError("Email is required");
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };
  const handleReplytoEmailChange = (e) => {
    const email = e.target.value;
    setData({ ...data, replyTo: email });

    // Validate email
    if (email.length === 0) {
      setReplyToEmailError("ReplyTo Email is required");
    } else if (!validateEmail(email)) {
      setReplyToEmailError("Please enter a valid email address");
    } else {
      setReplyToEmailError("");
    }
  };

  const handleSmtpHostChange = (e) => {
    if (e.target.value.length === 0) {
      setHostError("SMTP Host is required");
    } else {
      setHostError("");
    }
    setData({ ...data, SMTPHost: e.target.value });
  };
  const handleSmtpPortChange = (e) => {
    if (e.target.value.length === 0) {
      setportError("SMTP Port is required");
    } else {
      setportError("");
    }
    setData({ ...data, SMTPPort: e.target.value });
  };

  const handleProfileNameChange = (e) => {
    if (e.target.value.length === 0) {
      setProfileNameError("Profile Name is Required");
    } else if (e.target.value.length < 3) {
      setProfileNameError("Profile Name must be at least 3 characters");
    } else {
      setProfileNameError("");
    }
    setData({ ...data, profileName: e.target.value });
  };
  const handleUserNameChange = (e) => {
    if (e.target.value.length === 0) {
      setUserNameError("UserName is Required");
    } else {
      setUserNameError("");
    }
    setData({ ...data, userName: e.target.value });
  };
  const handlePasswordChange = (e) => {
    if (e.target.value.length === 0) {
      setPasswordError("Password is Required");
    } else {
      setPasswordError("");
    }
    setData({ ...data, password: e.target.value });
  };

  const handleEmailSettingsById = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getEmailSettingsById/${id}`,
        config
      );
      if (res) {
        // console.log("email id", res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // handle Email Settings Update

  const handleEmailSettingsUpdate = async () => {
    setLoading1(true);
    try {
      if (
        ProfileNameError ||
        emailError ||
        ReplyToemailError ||
        userNameError ||
        PasswordError ||
        hostError ||
        portError
      ) {
        return;
      }
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/updateEmailSetting/${id}`,
        data,
        config
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Data Updated SuccessFully",
          showConfirmButton: true,
          timer: 1500,
        });
        navigate("/EmailSettings");
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    } finally {
      setLoading1(false);
    }
  };
  useEffect(() => {
    handleEmailSettingsById();
  }, []);
  return (
    <div style={{ marginTop: "150px" }}>
      {!loading ? (
        <Card
          sx={{ maxWidth: "450px", p: 2 }}
          style={{ margin: "auto", marginTop: "30px" }}
        >
          <Typography
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Edit Email Settings
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>Profile Name</Typography>
              <TextField
                fullWidth
                sx={{ mb: 1.5 }}
                value={data.profileName}
                error={ProfileNameError}
                helperText={ProfileNameError}
                onChange={handleProfileNameChange}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>Email</Typography>
              <TextField
                fullWidth
                sx={{ mb: 1.5 }}
                value={data.email}
                error={emailError}
                helperText={emailError}
                onChange={handleEmailChange}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>ReplyTo</Typography>
              <TextField
                fullWidth
                sx={{ mb: 1.5 }}
                value={data.replyTo}
                error={ReplyToemailError}
                helperText={ReplyToemailError}
                onChange={handleReplytoEmailChange}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>Password</Typography>
              <TextField
                fullWidth
                sx={{ mb: 1.5 }}
                value={data.password}
                error={PasswordError}
                helperText={PasswordError}
                onChange={handlePasswordChange}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>UserName</Typography>
              <TextField
                fullWidth
                sx={{ mb: 1.5 }}
                value={data.userName}
                error={userNameError}
                helperText={userNameError}
                onChange={handleUserNameChange}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>SMTP Host</Typography>
              <TextField
                fullWidth
                sx={{ mb: 1.5 }}
                value={data.SMTPHost}
                error={hostError}
                helperText={hostError}
                onChange={handleSmtpHostChange}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>SMTP Port</Typography>
              <TextField
                fullWidth
                sx={{ mb: 1.5 }}
                value={data.SMTPPort}
                error={portError}
                helperText={portError}
                onChange={handleSmtpPortChange}
              />
            </Box>
            <Box>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Enable SSL
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    checked={data.EnableSSL === true}
                    onChange={(e) => setData({ ...data, EnableSSL: true })}
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    checked={data.EnableSSL === false}
                    onChange={(e) => setData({ ...data, EnableSSL: false })}
                    label="Disable"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Network Credential
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    checked={data.NetworkCredential === true}
                    onChange={(e) =>
                      setData({ ...data, NetworkCredential: true })
                    }
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    checked={data.NetworkCredential === false}
                    onChange={(e) =>
                      setData({ ...data, NetworkCredential: false })
                    }
                    label="Disable"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 1.5, mb: 1.5 }}
            onClick={handleEmailSettingsUpdate}
            disabled={loading1}
          >
            {loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
          </Button>
        </Card>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default EmailSettingEdit;
