import axios from "axios";
import { BASE_URL, BASE_URL_Seller, BASE_URL_Buyer } from "./BaseUrl";
import { Password } from "@mui/icons-material";
axios.defaults.baseURL = BASE_URL;

// Buyer Static Pages
export const createNewStaticPage = async (
  Heading,
  Link,
  Page,
  SortingId,
  Description
) => {
  try {
    const res = await axios.post("/createNewPage", {
      Heading,
      Link,
      Page,
      SortingId,
      Description,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const handleGetAllBuyerStaticPages = async () => {
  try {
    const res = await axios.get("/handleGetAllBuyerStaticPages");
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const DeleteStaticPAges = async (id) => {
  try {
    const res = await axios.delete(`/DeleteBuyerStaticPages/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getBuyerStaticPageById = async (id) => {
  try {
    const res = await axios.get(`/getBuyerStaticPageById/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateBuyerStaticPage = async (
  id,
  Heading,
  Link,
  Page,
  SortingId,
  Description
) => {
  try {
    const res = await axios.put(`/handleUpdateBuyerStaticPage/${id}`, {
      Heading,
      Link,
      Page,
      SortingId,
      Description,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};
export const createNewSellerStaticPage = async (
  Heading,
  Link,
  Page,
  PageFor,
  SortingId,
  Description
) => {
  try {
    const res = await axios.post("/createNewSellerPage", {
      Heading,
      Link,
      Page,
      PageFor,
      SortingId,
      Description,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};

export const handleGetAllSellerStaticPages = async () => {
  try {
    const res = await axios.get("/handleGetAllSellerStaticPages");
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const DeleteSellerStaticPAges = async (id) => {
  try {
    const res = await axios.delete(`/DeleteSellerStaticPages/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSellerStaticPageById = async (id) => {
  try {
    const res = await axios.get(`/getSellerStaticPageById/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateSellerStaticPage = async (
  id,
  Heading,
  Link,
  Page,
  SortingId,
  Description
) => {
  try {
    const res = await axios.put(`/handleUpdateSellerStaticPage/${id}`, {
      Heading,
      Link,
      Page,
      SortingId,
      Description,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// Return Policy

export const CreateNewReturnPolicy1 = async (Heading, Description, days) => {
  try {
    const res = await axios.post("/createNewReturnPolicy", {
      Heading,
      Description,
      days,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllReturnPolicy = async () => {
  try {
    const res = await axios.get("/getAllPolicy");
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getPolicyById = async (id) => {
  try {
    const res = await axios.get(`/getReturnPolicyById/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const UpdateReturnPolicy = async (id, Heading, Description, days) => {
  try {
    const res = await axios.put(`/UpdateReturnPolicy/${id}`, {
      Heading,
      Description,
      days,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const DeleteReturnPolicy = async (id) => {
  try {
    const res = await axios.delete(`/DeleteReturnPolicy/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// Return Reasons
export const CreateNewReturnResons = async (Name, ReasonFor) => {
  try {
    const res = await axios.post("/createNewReturnReason", {
      Name,
      ReasonFor,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllReturnReason = async () => {
  try {
    const res = await axios.get("/getAllReturnReasons");
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getReasonById = async (id) => {
  try {
    const res = await axios.get(`/getReturnReasonById/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const UpdateReturnReason = async (id, Name, ReasonFor) => {
  try {
    const res = await axios.put(`/UpdateReturnReason/${id}`, {
      Name,
      ReasonFor,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const DeleteReturnReason = async (id) => {
  try {
    const res = await axios.delete(`/DeleteReturnReason/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

//  Rating And Revoews

export const getAllRatingsAndReviews = async () => {
  try {
    const res = await axios.get("/handleGetAllReviews");
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// Promotions

export const getAllUserWise = async () => {
  try {
    const res = await axios.get("/getAllUserWise");
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getUserWiseById = async (id) => {
  try {
    const res = await axios.get(`getUserWiseById/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const UpdateUserWise = async (id, active, DisCount, OrderAmount) => {
  try {
    const res = await axios.put(`/updateUserWise/${id}`, {
      active,
      DisCount,
      OrderAmount,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// promotions_storeWise

export const createNewStoreWise = async (
  active,
  DateFrom,
  DateTo,
  orderAmount,
  DiscountIn,
  MaximumAmountCapping,
  Discount
) => {
  try {
    const res = await axios.post("/createNewStoreWise", {
      active,
      DateFrom,
      DateTo,
      orderAmount,
      DiscountIn,
      MaximumAmountCapping,
      Discount,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllStoreWise = async () => {
  try {
    const res = await axios.get("/getAllStoreWise");
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteStoreWise = async (id) => {
  try {
    const res = await axios.delete(`/DeleteStoreWiseById/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// couponCOdes
export const createNewCouponCOdes = async (
  couponCode,
  couponCodeType,
  AmountCapping,
  CouponType,
  Discount,
  validityStartDate,
  validityEndDate,
  Description,
  multiple,
  TotalCouponUsageInTimes,
  UserCouponUsageInTimes,
  selectedProducts
) => {
  try {
    const res = await axios.post("/createCouponCodes", {
      couponCode,
      couponCodeType,
      AmountCapping,
      CouponType,
      Discount,
      validityStartDate,
      validityEndDate,
      Description,
      multiple,
      TotalCouponUsageInTimes,
      UserCouponUsageInTimes,

      selectedProducts,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllCouponCOdes = async () => {
  try {
    const res = await axios.get("/getAllCouponCOdes");
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteCouponCOdeByID = async (id) => {
  try {
    const res = await axios.delete(`/DeleteCouponCOdeById/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCouponCodeById = async (id) => {
  try {
    const res = await axios.get(`getCouponById/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const AddItemsToSelected = async (id, selectedItems) => {
  try {
    const res = await axios.put(`/AddItemsToSelected/${id}`, {
      selectedItems,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};
export const RemoveItemsFromSelected = async (id, selectedItems) => {
  try {
    const res = await axios.put(`/RemoveItemsFromSelected/${id}`, {
      selectedItems,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const UpdateCoupon = async (
  id,
  couponCode,
  couponCodeType,
  AmountCapping,
  CouponType,
  Discount,
  validityStartDate,
  validityEndDate,
  Description,
  multiple,
  TotalCouponUsageInTimes,
  UserCouponUsageInTimes
) => {
  try {
    const res = await axios.put(`updateCoupon/${id}`, {
      couponCode,
      couponCodeType,
      AmountCapping,
      CouponType,
      Discount,
      validityStartDate,
      validityEndDate,
      Description,
      multiple,
      TotalCouponUsageInTimes,
      UserCouponUsageInTimes,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const DeactiveCouponCOde = async (id, value) => {
  try {
    const res = await axios.put(`updateCoupon/${id}`, {
      active: value,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// coupon code splash

export const getAllCouponCodeSplash = async () => {
  try {
    const res = await axios.get("/getAllCouponCodeSplash");
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCouponSplashById = async (id) => {
  try {
    const res = await axios.get(`getCouponCOdespashById/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const UpdateCouponCodeSplash = async (
  id,
  active,
  MainTitle,
  subTitle,
  CouponCode,
  Time
) => {
  try {
    const res = await axios.put(`/updatePromotionCouponCOdeSplash/${id}`, {
      active,
      MainTitle,
      subTitle,
      CouponCode,
      Time,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// seller Navigation

export const CreateSellerNavigation = async (
  NavigationFor,
  FooterColumn,
  staticPage,

  MenuType,
  Heading,
  PageUrl,
  Link,
  SortingOrder,
  ShowNew
) => {
  try {
    const res = await axios.post("/CreateSellerNavigation", {
      NavigationFor,
      FooterColumn,
      staticPage,
      MenuType,
      Heading,
      PageUrl,
      Link,
      SortingOrder,
      ShowNew,
    });
    if (res) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    return error.response.data.error;
  }
};

export const handleGetAllSellerNavigations = async () => {
  try {
    const res = await axios.get("/handleGetAllSellerNavigations");
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleGetSellerNAvigationById = async (id) => {
  try {
    const res = await axios.get(`/handleGetSellerNAvigationById/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleSellerNavigationUpdateById = async (
  id,
  NavigationFor,
  FooterColumn,
  staticPage,
  MenuType,
  Heading,
  PageUrl,
  Link,
  SortingOrder,
  ShowNew
) => {
  try {
    const res = await axios.put(`/handleSellerNavigationUpdateById/${id}`, {
      NavigationFor,
      FooterColumn,
      staticPage,
      MenuType,
      Heading,
      PageUrl,
      Link,
      SortingOrder,
      ShowNew,
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateActive = async (id, active) => {
  try {
    const res = await axios.put(`/handleSellerNavigationUpdateById/${id}`, {
      active,
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const DeleteSellerNavigationItem = async (id) => {
  try {
    const res = await axios.delete(`/DeleteSellerNavigationItem/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

/// All Products

export const GetAllProducts = async () => {
  try {
    const res = await axios.get(`/get-all-Products`);
    if (res) {
      console.log(res);
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// productInvebtory Stock

export const updateStockOverView = async (data) => {
  try {
    const res = await axios.post(`${BASE_URL_Seller}/Products/stockOverview`, {
      data,
    });
    if (res) {
      return res.data.message;
    }
  } catch (error) {
    console.log(error);
  }
};

// Product Question By Seller

export const handleGetAllProductQuestion = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Buyer}/getAllQuestionsBySeller`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const SolutionForQuestionByAdmin = async (
  id,
  Answer,
  AnsweredBy,
  AnsweredByAdmin
) => {
  try {
    const res = await axios.put(
      `${BASE_URL_Buyer}/SolutionForQuestionByAdmin/${id}`,
      {
        Answer,
        AnsweredBy,
        AnsweredByAdmin,
      }
    );
    if (res) {
      return res.data.message;
    }
  } catch (error) {
    console.log(error);
  }
};

export const DeactivateProductQuestion = async (id, QuestionDeactivated) => {
  try {
    const res = await axios.put(
      `${BASE_URL_Buyer}/SolutionForQuestionByAdmin/${id}`,
      {
        QuestionDeactivated,
      }
    );
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};
export const DeactivateProductAnswer = async (id, AnswerDeactivated) => {
  try {
    const res = await axios.put(
      `${BASE_URL_Buyer}/SolutionForQuestionByAdmin/${id}`,
      {
        AnswerDeactivated,
      }
    );
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const DeleteQuestion = async (id) => {
  try {
    const res = await axios.delete(`${BASE_URL_Buyer}/DeleteQuestion/${id}`);
    if (res) {
      return res.data.message;
    }
  } catch (error) {
    console.log(error);
  }
};

export const DeleteAnswer = async (id, Answer) => {
  try {
    const res = await axios.put(`${BASE_URL_Buyer}/DeleteAnswer/${id}`, {
      Answer,
    });
    if (res) {
      return res.data.message;
    }
  } catch (error) {
    console.log(error);
  }
};

// --------------------------------------*********-------------------------------------------

// all orders
export const handleGetAllOrder = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Buyer}/getAllOrders`);
    if (res) {
      // console.log(res);
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// all sellers

export const getAllSellers = async () => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios.get(`${BASE_URL}/getAllSellers`, config);
    // console.log(res.data);
    return res;
  } catch (error) {
    return error;
  }
};

// all products

export const handleGetAllProducts = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/get-all-Products`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// all users
export const handleGetAllUsers = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Buyer}/HandleGetAllUsers`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// logged in toady

export const handleGetLoginToday = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Buyer}/GetLoggedInTodayCount`);

    if (res) {
      // console.log(res);
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// get All Categories from admin panel

export const getAllRootCategories = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/getAllSubCategoriesForRoot`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get all product Types from admin panel

export const getAllProductTypes = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/getAllProductTypesValues`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get all product Types by id from admin panel

export const getAllProductTypesById = async (id) => {
  try {
    const res = await axios.get(`${BASE_URL}/getProductTypeValuesById/${id}`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

//  get all custom attributes from admin panel

export const getAllCustomAttributes = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/getAllCustomAttributes`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get all Main attributes from admin panel

export const getAllMainAttributes = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/getAllMainProductAttributes`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get all Brand attributes from admin panel

export const getAllBrandAttributes = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/getAllBrandAttributes`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get all Color attributes from admin panel

export const getAllColorAttributes = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/getAllColorAttributes`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get all Size attributes from admin panel

export const getAllSizeAttributes = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/getAllSizeAttributes`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get all Size charts from admin panel

export const getAllSizeCharts = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/getAllSizeCharts`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get all Products

export const getAllProducts = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/get-all-Products`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// visiblity for single product by id from admin panel

export const visiblityForSingleProduct = async (id, value) => {
  try {
    const res = await axios.put(`${BASE_URL}/update-single-product/${id}`, {
      hide: value,
    });
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

//  Reject single Product By Admin

export const RejectSingleProduct = async (id, value) => {
  try {
    const res = await axios.put(`${BASE_URL}/update-single-product/${id}`, {
      rejected: value,
    });
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

//  get single product by id from admin panel

export const getSingleProductById = async (id) => {
  try {
    const res = await axios.get(`${BASE_URL}/get-single-product/${id}`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// update status for single product by id from admin panel

export const updateStatusForSingleProduct = async (id, value) => {
  try {
    const res = await axios.put(`${BASE_URL}/update-single-product/${id}`, {
      active: value,
    });
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// Delete single product by id from admin panel

export const DeleteSingleProduct = async (id) => {
  try {
    const res = await axios.delete(`${BASE_URL}/delete-single-product/${id}`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

//  get all buyers

export const getAllBuyers = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Buyer}/HandleGetAllUsers`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// Admin Login

export const AdminLogin = async (email, password) => {
  try {
    const res = await axios.post(`${BASE_URL}/login`, {
      email,
      password,
    });

    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};
