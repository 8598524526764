import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { CreateNewReturnPolicy1 } from "../../../axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const CreateNewReturnPolicy = () => {
  const [Heading, setHeading] = useState(null);
  const [Description, setDescription] = useState(null);
  const [days, setdays] = useState(null);
  const [Loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleCreateNewPolicy = async () => {
    setLoading(true);
    try {
      const res = await CreateNewReturnPolicy1(Heading, Description, days);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Created Successfully",
          showConfirmButton: true,
        });
        navigate("/Return-policy");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Return Policy
        </Typography>
        <Button
          variant="contained"
          onClick={handleCreateNewPolicy}
          disabled={Loading}
        >
          {Loading ? <CircularProgress size={24} /> : "SUBMIT"}
        </Button>
      </Box>
      <Box sx={{ mt: 3, ml: 3, mr: 3 }}>
        <Box sx={{ border: "1px solid #ddd" }}>
          <Box sx={{ p: 2, background: "#000" }}>
            <Typography sx={{ fontWeight: "bold", color: "#fff" }}>
              Return Policy
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Heading:
              </Typography>
              <TextField
                size="small"
                placeholder="Heading"
                sx={{ width: "400px" }}
                value={Heading}
                onChange={(e) => setHeading(e.target.value)}
              />
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Description:
              </Typography>
              <textarea
                size="small"
                placeholder="Description"
                style={{
                  width: "380px",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  padding: "10px",
                  height: "300px",
                  resize: "vertical",
                }}
                value={Description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                No.Of Days:
              </Typography>
              <TextField
                type="number"
                size="small"
                placeholder="No.of Days"
                sx={{ width: "400px" }}
                value={days}
                onChange={(e) => setdays(e.target.value)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CreateNewReturnPolicy;
