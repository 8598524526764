import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Swal from "sweetalert2";
import { useState } from "react";
import { useEffect } from "react";
import { Visibility } from "@mui/icons-material";

export default function ManageBrandTable() {
  const [data, setData] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [productsList, setProductsList] = React.useState([]);
  const [visibility, setVisibility] = React.useState({});

  // console.log(productsList);

  const navigate = useNavigate();
  const handleBrandAttributes = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllBrandAttributes"
      );
      if (res) {
        const visibilityObj = {};
        res.data.forEach((row) => {
          visibilityObj[row._id] = !row.hide;
        });
        setData(res.data);
        setVisibility(visibilityObj);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVisibility = async (id, value) => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/updateBrandAttributes/${id}`,
        {
          hide: value,
        }
      );
      if (res) {
        setVisibility((prevState) => ({
          ...prevState,
          [id]: !value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllProductsList = async () => {
    try {
      const brandAttributes = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllBrandAttributes"
      );
      const products = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/get-all-Products"
      );
      if (brandAttributes && products) {
        const brandNames = brandAttributes.data.map((brand) => brand.name);
        const productsWithBrand = products.data.filter((product) =>
          brandNames.includes(product.BrandName)
        );
        // productsWithBrand.forEach((product) => console.log(product.BrandName));
        setProductsList(productsWithBrand);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteBrandAttributes = async (id) => {
    try {
      const res = await axios.delete(
        `https://rccore-backend.cstechns.com/api/admin/DeleteBrandAttribute/${id}`
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });
        setData(data.filter((item) => item._id !== id));
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };

  React.useEffect(() => {
    handleBrandAttributes();
  }, []);

  useEffect(() => {
    handleGetAllProductsList();
  }, [data]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data.filter((row) =>
    row.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const uniqueProductTypes = Array.from(
    new Set(productsList.map((item) => item.BrandName))
  );
  return (
    <div>
      {!loading ? (
        <>
          <TextField
            label="Search"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearch}
            size="small"
            sx={{ ml: 3, width: "400px", mb: 2 }}
          />
          <TableContainer component={Paper} sx={{ width: "96.5%", ml: 3 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "#dddddd" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>S.No</TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Attribute Name
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Image
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Sort Id
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">
                      <img src={row.image} alt={row.name} width="120px" />
                    </TableCell>
                    <TableCell align="center">{row.sortId}</TableCell>
                    <TableCell align="center">
                      {uniqueProductTypes.map((product) => {
                        if (product === row.name) {
                          return (
                            <>
                              {visibility[row._id] ? (
                                <>
                                  <Tooltip title="De-Activate">
                                    <IconButton
                                      onClick={() =>
                                        handleVisibility(
                                          row._id,
                                          visibility[row._id]
                                        )
                                      }
                                    >
                                      <VisibilityIcon
                                        style={{ color: "green" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              ) : (
                                <>
                                  <Tooltip title="Activate">
                                    <IconButton
                                      onClick={() =>
                                        handleVisibility(
                                          row._id,
                                          visibility[row._id]
                                        )
                                      }
                                    >
                                      <VisibilityOffIcon
                                        style={{ color: "red" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </>
                          );
                        }
                      })}
                      {productsList.some(
                        (product) => product.BrandName === row.name
                      ) ? null : (
                        <>
                          <IconButton>
                            <EditIcon
                              onClick={() =>
                                navigate(`/update-brand-attributes/${row._id}`)
                              }
                            />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon
                              onClick={() =>
                                handleDeleteBrandAttributes(row._id)
                              }
                              style={{ color: "red" }}
                            />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
