export const TemplatesData = [
  {
    id: 1,
    htmlContent: `<table style="border: 1px solid #CCCCCC" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:1px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p><strong>Dear {user-name},</strong></p>
            <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif;">Hurray! We are happy to let you know that your order you placed on {shopname} has been shipped.<o:p></o:p></span></p>
            <p>&nbsp;</p>
            <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif;">Your order is on the way. You can track your order with the link provided below.</span></p>
            <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;</p>
            <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif;">Your order details are as follows.<o:p></o:p></span></p>
            <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif;"><o:p></o:p></span></p>
            <p><strong>Order Number : </strong>{orderno}<br />
            <strong>Order Date :</strong> {orderdate} <br />
            <strong>Current Status : </strong>{orderstatus} <br />
            <strong>Logistic name : </strong>{shippingmode} <br />
            <strong>Tracking URL : </strong>{TrackingURL} <br />
            <strong>Shipping Date : </strong>{shippingdate} <br />
            <strong>AWB Number/ Tracking No. :</strong> {awbnumber}</p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none"><span style="font-family:Arial, Helvetica, sans-serif; font-size:12px; color:#000000;">{orderdetails}</span></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p class="MsoNormal"><span style="font-size: 9pt; line-height: 115%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">*Note that it can take up to 12 to 24 hours for the Tracking Number to reflect on the courier site.&nbsp;<o:p></o:p></span></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
  </table>
  <p>&nbsp;</p>`,
  },
  {
    id: 2,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:1px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p><strong>Dear {user-name},</strong></p>
            <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif;">We are happy to inform you that your Order No. {orderno} has been&nbsp;Delivered.<o:p></o:p></span></p>
            <p>&nbsp;</p>
            <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif;">Your order details are given below.<o:p></o:p></span></p>
            <p><strong>Order Number : </strong>{orderno}<br />
            <strong>Order Date : </strong>{orderdate}</p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none"><span style="font-family:Arial, Helvetica, sans-serif; font-size:12px; color:#000000;">{orderdetails}</span></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p class="MsoNormal"><span style="font-size: 9pt; line-height: 115%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We hope you enjoyed your shopping experience with Vedson.<o:p></o:p></span></p>
            <p class="MsoNormal"><span style="font-size: 9pt; line-height: 115%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We would love to get your feedback. Thank you for shopping with Vedson!<o:p></o:p></span></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 3,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:1px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p><strong>Dear Friend,</strong></p>
            <p>I have added the following items to my Wishlist on the website {websiteurl} which I thought you would find interesting.<br />
            <br />
            Please visit the link below to view the items on the Wishlist.&nbsp;</p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none"><span style="font-family:Arial, Helvetica, sans-serif; font-size:12px; color:#000000;">{clickhere}</span></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p>Thanks and Happy Shopping,&nbsp;</p>
            <p><span style="font-family: Arial, Helvetica, sans-serif;">{user-name}</span></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 4,
    htmlContent: `<table style="border:1px solid #CCCCCC;" width="600" cellspacing="1" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:1px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, Helvetica, sans-serif; font-size:12px; color:#000000;" align="left">Dear <strong>{user-name}</strong>,</td>
        </tr>
        <tr>
            <td style="background-color:#EEEEEE;" align="left"><br />
            <table width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family: Arial, Helvetica, sans-serif; font-weight: bold; line-height: 36px;" width="72%"><span style="color: rgb(0, 0, 0);"><span style="font-size: larger;">It seems that your cart needs a jumpstart!&nbsp;</span><span style="font-size: 24px;">&nbsp;</span></span></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, Helvetica, sans-serif; font-size:13px; color:#000000; line-height:24px;" align="center">The following items are still in your cart. Complete your order by {coupondate} <br />
            using the code <span style="color:#CC0000; font-weight:bold;">{couponcode}</span> and save {percentage}.</td>
        </tr>
        <tr>
            <td style="font-family:Arial, Helvetica, sans-serif; font-size:12px; color:#000000; line-height:20px; border-top:1px dotted #CCCCCC; border-bottom:1px dotted #CCCCCC;" align="left">{orderdetails}</td>
        </tr>
        <tr>
            <td align="center"><a style="text-decoration:none;" target="_blank" href="{domainname}/AbandonCart.aspx?id={userid}&amp;buyerid={buyerid}"><span style="border:1px solid #b30808; -webkit-border-radius: 3px; -moz-border-radius: 3px;border-radius: 3px;font-size:15px;font-family:arial, helvetica, sans-serif; padding:10px 30px; text-decoration:none; display:inline-block;text-shadow: -1px -1px 0 rgba(0,0,0,0.3);font-weight:bold; color: #FFFFFF; background-color: #e40a0a; background-image: -webkit-gradient(linear, left top, left bottom, from(#e40a0a), to(#9f0202));   background-image: -webkit-linear-gradient(top, #e40a0a, #9f0202);   background-image: -moz-linear-gradient(top, #e40a0a, #9f0202);   background-image: -ms-linear-gradient(top, #e40a0a, #9f0202);   background-image: -o-linear-gradient(top, #e40a0a, #9f0202);   background-image: linear-gradient(to bottom, #e40a0a, #9f0202);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#e40a0a, endColorstr=#9f0202);">GO TO YOUR ORDER</span></a></td>
        </tr>
        <tr>
            <td style="font-family:Arial, Helvetica, sans-serif; font-size:12px; color:#888888; line-height:20px;" align="right">* offer applicable if your minimum order amount is {amount}</td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 5,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td style="border-bottom:1px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p><strong>Dear {user-name}</strong>,</p>
            <p class="MsoNormal"><span style="font-size: 9pt; line-height: 115%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Thank you for placing your order at&nbsp;<strong><span style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;">{mpname}<o:p></o:p></span></strong></span></p>
            <p><span style="font-size: 9pt; line-height: 115%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">You will receive an email for order confirmation from us shortly.</span><span style="font-size: 9pt; line-height: 115%; font-family: Arial, sans-serif;"><br />
            <span style="font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We will also send a confirmation when your order ships.</span><b><br />
            </b><br />
            <span style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Your order details are as below.</span><br />
            <!--[if !supportLineBreakNewLine]--><br />
            <!--[endif]--></span>             <br />
            <strong>Order Number :</strong> {orderno}<br />
            <strong>Order Date :</strong> {orderdate}<br />
            <strong>Current Status :</strong> {orderstatus}<br />
            <strong>Payment Mode :</strong> {paymentmode}<br />
            <br />
            {orderdetails}<br />
            <br />
            <br />
            <br />
            Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 6,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:1px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p><strong>Dear {user-name}</strong>,<br />
            <br />
            <span style="font-size: 9pt; line-height: 115%; font-family: Arial, sans-serif;">We are happy to let you know that your order you placed on&nbsp;<b>{mpname} </b>is confirmed.<br />
            <br />
            Your order details are given below.</span></p>
            <p><br />
            <strong>Order Number :</strong> {orderno}<br />
            <strong>Order Date :</strong> {orderdate}<br />
            <strong>Current Status :</strong> {orderstatus}<br />
            <strong>Payment Mode :</strong> {paymode}<br />
            <strong>Payment Status :</strong> {paystatus}<br />
            <br />
            {orderdetails}</p>
            <p class="MsoNormal" style="line-height: normal; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif;">We will try and ship the order within 24 to 48 hours. Visit My Orders section to get further updates on your order.<o:p></o:p></span></p>
            <p>&nbsp;</p>
            <p class="MsoNormal" style="line-height: normal; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif;">Thank you for your continued support and we hope to see you again.<o:p></o:p></span></p>
            <p><br />
            Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 7,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:1px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p><strong>Dear {user-name}</strong>,<br />
            <br />
            You have requested to retrieve your login details at <strong>{mpname}</strong><br />
            <br />
            Please reset your password on clicking the below link.</p>
            <p>For reset please <a href="{url}">click here</a><br />
            <br />
            <br />
            <o:p></o:p></p>
            <p><br />
            Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 8,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
    <tbody>
        <tr>
            <td align="center"><img src="http://picotec.com.my/pwimage/thankyou_600x124.jpg" alt="" width="600" height="124" /></td>
        </tr>
        <tr>
            <td>
            <table width="600" cellspacing="0" cellpadding="10" border="0" align="center">
                <tbody>
                    <tr>
                        <td colspan="3" style=" "><strong>{logo}</strong></td>
                    </tr>
                    <tr>
                        <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
                        <p><strong>Dear&nbsp;</strong><span style="color: rgb(51, 51, 51); font-family: Arial, Helvetica, sans-serif; font-size: 13px; font-weight: bold;">{user-name}</span>,</p>
                        <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif;">Your request to the subscription to <strong><span style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;">{mpname}</span></strong>&nbsp;has been submitted successfully.<o:p></o:p></span></p>
                        <p>You can visit the store by <span style="color: rgb(128, 0, 0);"><strong>{clickhere}</strong></span> or by pasting this url in your browser window<br />
                        <span style="color: rgb(128, 0, 0);"><strong>{websiteurl}</strong></span></p>
                        <p>&nbsp;</p>
                        <p class="MsoNormal"><o:p></o:p></p>
                        <p>&nbsp;</p>
                        <p><a href="{clickunsub}">{clickunsub} for unsubscribe</a></p>
                        <br />
                        <br />
                        Best Regards,<br />
                        <strong>{mpname} Support Team<br />
                        </strong>
                        <p>&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
                        <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                                    <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                                    Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                                    <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td height="10" align="center"><img alt="" height="5" /></td>
                                            </tr>
                                            <tr>
                                                <td align="right">{socialmedia}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </a></td>
                                </tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 9,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:1px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p><strong>Dear {user-name}</strong>,</p>
            <p>We will like to inform you about the cancellation of the order you placed on&nbsp;<span style="color: rgb(128, 0, 0);"><strong>{mpname}</strong></span></p>
            <p>Your order details are given below.</p>
            <p><strong>Order Number :</strong> {orderno}<br />
            <strong>Order Date :</strong> {orderdate}<br />
            <strong>Current Status :</strong> {orderstatus}</p>
            <p>{orderdetails}</p>
            <br />
            <p><br />
            Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 10,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:1px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p><strong>Name :</strong> {user-name}<br />
            <strong>Email :</strong> {user-emailaddress}<br />
            <strong>Mobile No :</strong> {mobile}<br />
            <strong>Query :</strong> {Query}</p>
            <p>Thank you for contacting us. We appreciate your feedback and our team will revert soon.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            <p style="font-family: Arial, Verdana, sans-serif;"><font face="Arial, sans-serif"><span style="font-size: 10px;">P.S.- This is an automated email. Please do not reply to this email. The Information contained in this e-mail and/or in any of the attached files or links mentioned in this email is the confidential and proprietary information of&nbsp;</span></font><font face="Arial, sans-serif"><span style="font-size: 10px;"><font face="Arial, sans-serif">{mpname}</font>, and intended solely for the use of the individual or entity to whom it is addressed. If you are not the intended recipient, any use, disclosure, distribution, reproduction of this mail or the taking any action on the basis of the information contained in the email is unauthorized and strictly prohibited.</span></font></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 11,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:1px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p>Dear <strong>{user-name}</strong>,</p>
            <p>We are pleased to confirm delivery of products against order no <span style="color: rgb(128, 0, 0);"><strong>{orderno}</strong></span> with AWB No. <span style="color: rgb(128, 0, 0);"><strong>{awbnumber}</strong></span>.</p>
            <p>Support us and our Sellers by reviewing the items you have purchased.&nbsp;<span style="font-size: 9pt;">We and other buyers would love to have your valuable feedback!&nbsp;</span></p>
            <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif;"><o:p></o:p></span></p>
            <p>{orderdetails}</p>
            <p>Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 12,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
    <tbody>
        <tr>
            <td align="center"><img src="http://picotec.com.my/pwimage/thankyou_600x124.jpg" alt="" width="600" height="124" /></td>
        </tr>
        <tr>
            <td>
            <table width="600" cellspacing="0" cellpadding="10" border="0" align="center">
                <tbody>
                    <tr>
                        <td colspan="3" style="border-bottom:0px solid #e94a43;"><strong>{logo}</strong></td>
                    </tr>
                    <tr>
                        <td>
                        <p style=""><strong style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">Hello {user-name},<br />
                        </strong>         <br />
                        <span style="font-size: 9pt; line-height: 115%; font-family: Arial, sans-serif;">Thank you for getting in touch with us.</span><br />
                        <font face="Arial, sans-serif">                                                               <font face="Arial, sans-serif">                                <br />
                        </font></font><span style="font-size: 9pt; line-height: 115%; font-family: Arial, sans-serif;">Kindly complete the registration process by clicking on the given link.&nbsp;</span><font face="Arial, sans-serif"><font face="Arial, sans-serif"><font face="Arial, sans-serif"><a href="{regurl}">{regurl}</a></font></font></font></p>
                        <font face="Arial, sans-serif"><font face="Arial, sans-serif">
                        <p style="">User Id:&nbsp;<span style="color: rgb(51, 51, 51); font-family: Arial, Helvetica, sans-serif; font-size: 13px; font-weight: bold;">{user-emailaddress}</span></p>
                        <p style=""><span style="font-size: 9pt; line-height: 115%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">For any queries that you have with regards to accessing your account, please feel free to contact us at the details below.&nbsp;</span><br />
                        <br />
                        <font face="Arial, sans-serif">             Best Regards,</font><br />
                        <strong style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">Seller Support Team<br />
                        </strong></p>
                        </font></font><font face="Arial, sans-serif"><font face="Arial, sans-serif">
                        <p style=""><span style="font-family: Arial, Verdana, sans-serif;">P.S.-</span><span style="font-size: 10pt;">This is an automated email. Please do not reply to this email. The Information contained in this e-mail and/or in any of the attached files or links mentioned in this email is the confidential and proprietary information of&nbsp;{mpname}&nbsp;and intended solely for the use of the individual or entity to whom it is addressed. If you are not the intended recipient, any use, disclosure, distribution, reproduction of this mail or the taking any action based on the information contained in the email is unauthorized and strictly prohibited.</span></p>
                        </font></font>
                        <p><span style="font-size: 10pt; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><o:p></o:p></span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
                        <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                                    <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                                    Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                                    <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td height="10" align="center"><img alt="" height="5" /></td>
                                            </tr>
                                            <tr>
                                                <td align="right">{socialmedia}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </a></td>
                                </tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 13,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
    <tbody>
        <tr>
            <td align="center"><strong style="text-align: start;"><br />
            {logo}<br />
            </strong></td>
        </tr>
        <tr>
            <td>
            <table width="600" cellspacing="0" cellpadding="10" border="0" align="center">
                <tbody>
                    <tr>
                        <td colspan="3">&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                        <p style=""><strong style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">Hello {user-name},<br />
                        </strong></p>
                        <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Thank you for getting in touch with us. <o:p></o:p></span></p>
                        <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">We are glad that very soon you will be a part of our Vedson family. At this point, we want to make you familiar with our Seller's On-Boarding Process. <o:p></o:p></span></p>
                        <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Completing the Seller Registration Process does not grant you the status of a Seller on {mpname}. We will review all your details and documents to approve and activate you as a Seller. <o:p></o:p></span></p>
                        <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">Assuring you of our best services at all times, we will keep you updated about your activation as our Seller very shortly.<o:p></o:p></span></p>
                        <p style=""><br />
                        <font face="Arial, sans-serif">             Best Regards,</font><br />
                        <strong style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">Seller Support Team<br />
                        </strong>     <br />
                        <font face="Arial, sans-serif"><span style="font-size: 12px;">P.S.-&nbsp;</span></font><span style="font-family: Arial, sans-serif; font-size: 10pt;">This is an automated email. Please do not reply to this email. The Information contained in this e-mail and/or in any of the attached files or links mentioned in this email is the confidential and proprietary information of&nbsp;{mpname}&nbsp;and intended solely for the use of the individual or entity to whom it is addressed. If you are not the intended recipient, any use, disclosure, distribution, reproduction of this mail or the taking any action based on the information contained in the email is unauthorized and strictly prohibited.</span></p>
                        <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 10pt; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><o:p></o:p></span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
                        <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                                    <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                                    Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                                    <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td height="10" align="center"><img alt="" height="5" /></td>
                                            </tr>
                                            <tr>
                                                <td align="right"><a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w" style="font-family: Arial, Helvetica, sans-serif;">
                                                <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td align="right">{socialmedia}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </a></td>
                                </tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>
<p>&nbsp;</p>
<div style="display: none;">&nbsp;</div>`,
  },
  {
    id: 14,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
    <tbody>
        <tr>
            <td align="center"><img src="http://picotec.com.my/pwimage/Welcome.jpg" alt="" width="600" height="124" /></td>
        </tr>
        <tr>
            <td>
            <table width="600" cellspacing="0" cellpadding="10" border="0" align="center">
                <tbody>
                    <tr>
                        <td colspan="3" style="border-bottom:0px solid #e94a43;"><strong>{logo}</strong></td>
                    </tr>
                    <tr>
                        <td>
                        <p style=""><font face="Arial, sans-serif">Dear &nbsp;{user-name},</font><br />
                        <br />
                        <font face="Arial, sans-serif">             Welcome to {mpname} Family.&nbsp;</font><br />
                        <br />
                        <font face="Arial, sans-serif">             Your login details are as below</font><br />
                        <br />
                        <strong style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">Login link :</strong><font face="Arial, sans-serif">&nbsp;</font><font face="Arial, sans-serif" color="#0000ff">{sellerurl}</font><br />
                        <br />
                        <strong style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">User id :</strong><font face="Arial, sans-serif">&nbsp;{user-emailaddress}</font></p>
                        <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif;">Thank you for signing up with {mpname}. Your account has been successfully created. <br />
                        You can now start uploading your product catalogue and maximise your sales across India with easy dashboard and analytics support. <o:p></o:p></span></p>
                        <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif;">If you have any queries, you can email us at Vedson17@gmail.com<o:p></o:p></span></p>
                        <p><span style="font-size: 9pt; line-height: 115%; font-family: Arial, sans-serif;">Kindly note that we have a strict policy so do ensure that all your products uploaded on our platform are 100% genuine products.<br />
                        <!--[if !supportLineBreakNewLine]--><br />
                        <!--[endif]--></span></p>
                        <p style=""><font face="Arial, sans-serif">             Thanks and Best Regards,&nbsp;&nbsp;</font><br />
                        <strong style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">Seller Support Team&nbsp;</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
                        <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                                    <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                                    Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                                    <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td height="10" align="center"><img alt="" height="5" /></td>
                                            </tr>
                                            <tr>
                                                <td align="right">{socialmedia}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </a></td>
                                </tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 15,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:0px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p><strong>Dear {user-name}</strong>,<br />
            <br />
            Greetings from {mpname}!</p>
            <p style="color: rgb(34, 34, 34); font-family: arial, sans-serif; font-size: 12.8000001907349px;">You have received a new order. Do log in to your Seller Panel and Confirm the same. When you do so, kindly convey to us your acceptance of the order.</p>
            <p style="color: rgb(34, 34, 34); font-family: arial, sans-serif; font-size: 12.8000001907349px;">If you cannot furnish the order within the given SLA, please cancel it immediately.</p>
            <p style="color: rgb(34, 34, 34); font-family: arial, sans-serif; font-size: 12.8000001907349px;">However, here is the fallout of a cancellation:</p>
            <ul style="color: rgb(34, 34, 34); font-family: arial, sans-serif; font-size: 12.8000001907349px;">
                <li style="margin-left: 15px;">You will have to bear the cancellation charges</li>
                <li style="margin-left: 15px;">Your seller rating and performance on {mpname} will be affected</li>
            </ul>
            <p>To go to your Seller Panel -&nbsp;<span style="color: rgb(51, 51, 51); font-family: Arial, Helvetica, sans-serif; font-size: 13px; font-weight: bold;">{vendorurl}</span></p>
            <p><br />
            <br />
            Best Regards,<br />
            <strong>{mpname} Seller Support Team<br />
            </strong></p>
            <p>&nbsp;</p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 16,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:0px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><strong>Dear {user-name}</strong>,</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">Greetings of the day!</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">We are glad to tell you that we have credited a store credit amount of Rs.&nbsp;<span style="font-family: Arial, Helvetica, sans-serif; line-height: 25px;">{Amount}/- into your {mpname} account. Hope to see you again soon!&nbsp;</span></p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><strong style="font-family: Arial, Helvetica, sans-serif; line-height: 25px;">Payment Date:</strong><span style="font-family: Arial, Helvetica, sans-serif; line-height: 25px;">&nbsp;{orderdate}</span><br style="font-family: Arial, Helvetica, sans-serif; line-height: 25px;" />
            <strong style="font-family: Arial, Helvetica, sans-serif; line-height: 25px;">Amount :</strong><span style="font-family: Arial, Helvetica, sans-serif; line-height: 25px;">&nbsp;Rs. {Amount}/-</span><br style="font-family: Arial, Helvetica, sans-serif; line-height: 25px;" />
            <strong style="font-family: Arial, Helvetica, sans-serif; line-height: 25px;">Description :</strong><span style="font-family: Arial, Helvetica, sans-serif; line-height: 25px;">&nbsp;{Description}</span></p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">The above amount can take up to 24 working hours to reflect into your account.</p>
            <p class="MsoNormal"><o:p></o:p></p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">&nbsp;</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><br />
            Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: small;"><span style="font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">P.S.- This is an automated email. Please do not reply to this email. The Information contained in this e-mail and/or in any of the attached files or links mentioned in this email is the confidential and proprietary information of&nbsp;{mpname}&nbsp;and intended solely for the use of the individual or entity to whom it is addressed. If you are not the intended recipient, any use, disclosure, distribution, reproduction of this mail or the taking any action based on the information contained in the email is unauthorized and strictly prohibited.</span></span><span style="font-size: 10pt; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><o:p></o:p></span></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 17,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
    <tbody>
        <tr>
            <td align="center"><img src="http://picotec.com.my/pwimage/GetSecured_600x124.jpg" alt="" width="600" height="124" /></td>
        </tr>
        <tr>
            <td>
            <table width="600" cellspacing="0" cellpadding="10" border="0" align="center">
                <tbody>
                    <tr>
                        <td colspan="3" style="border-bottom:0px solid #e94a43;"><strong>{logo}</strong></td>
                    </tr>
                    <tr>
                        <td>
                        <p style=""><strong style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">Dear {user-name}</strong><font face="Arial, sans-serif">,</font><br />
                        <br />
                        <font face="Arial, sans-serif">                         You have requested to retrieve your login details at </font><strong style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">{mpname}</strong><br />
                        <br />
                        <font face="Arial, sans-serif">                         Please click the link below to reset your password</font><br />
                        <br />
                        <a href="{url}" style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">click here</a><font face="Arial, sans-serif"> to reset your password</font><br />
                        <br />
                        <span style="font-size: 9pt; line-height: 107%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">For any enquiries that you have with regards to accessing your account, please feel free to contact us at the details below.&nbsp;</span><o:p></o:p></p>
                        <p style=""><br />
                        <font face="Arial, sans-serif">                         Best Regards,</font><br />
                        <strong><font face="Arial, sans-serif">Seller Support Team</font>                         </strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
                        <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                                    <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                                    Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                                    <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td height="10" align="center"><img alt="" height="5" /></td>
                                            </tr>
                                            <tr>
                                                <td align="right">{socialmedia}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </a></td>
                                </tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 18,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:0px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p><strong>Dear&nbsp;</strong><span style="color: rgb(51, 51, 51); font-family: Arial, Helvetica, sans-serif; font-size: 13px; font-weight: bold; line-height: 35px;">{sellerdisplayname}</span><strong>,</strong></p>
            <p>Greetings of the day!</p>
            <p><span style="color: rgb(51, 51, 51); font-family: arial, sans-serif; line-height: 16px;">This is to inform you that&nbsp;</span>{shippingmode}&nbsp;<span style="color: rgb(51, 51, 51); font-family: arial, sans-serif; line-height: 16px;">has delivered your order, with AWB No.&nbsp;</span>{awbnumber}<span style="color: rgb(51, 51, 51); font-family: arial, sans-serif; line-height: 16px;">&nbsp;.</span><span style="color: rgb(51, 51, 51); font-family: arial, sans-serif; line-height: 16px;">&nbsp;</span></p>
            <p>Your order details are given below.</p>
            <p><strong>Order Number : </strong>{orderno}<br />
            <strong>Order Date : </strong>{orderdate}</p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">{orderdetails}</td>
        </tr>
        <tr>
            <td>
            <p class="MsoNormal"><o:p></o:p></p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><span style="font-size: 9pt;">For any enquiries that you have with regards to the above-mentioned order number, please feel free to contact us at the details below.&nbsp;</span><span style="color: rgb(51, 51, 51); font-family: arial, sans-serif;">We hope to see you again soon!</span></p>
            <p style=""><font face="Arial, sans-serif">Best Regards,</font><br />
            <font face="Arial, sans-serif"><b>Seller Support Team</b></font></p>
            <p style=""><font face="Arial, sans-serif"><span style="font-size: 10px;">P.S.- This is an automated email. Please do not reply to this email. The Information contained in this e-mail and/or in any of the attached files or links mentioned in this email is the confidential and proprietary information of </span></font><font face="Arial, sans-serif"><span style="font-size: 10px;"><font face="Arial, sans-serif"><span style="font-size: 10px;">{mpname}</span></font>, and intended solely for the use of the individual or entity to whom it is addressed. If you are not the intended recipient, any use, disclosure, distribution, reproduction of this mail or the taking any action on the basis of the information contained in the email is unauthorized and strictly prohibited.</span></font></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 19,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:0px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p><strong>Dear {name}</strong>,</p>
            <p><br />
            Admin has reset your password. Please click the link below to manage your password :</p>
            <br />
            <p>{newpassword}</p>
            &nbsp;<br />
            <p>For Login &nbsp;<a href="{url}">click here</a>&nbsp;<br />
            <br />
            <span style="font-size: 9pt; line-height: 107%; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">For any enquiries that you have with regards to accessing your account, please feel free to contact us at the details below.&nbsp;</span><o:p></o:p></p>
            <p><br />
            Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 20,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:0px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><strong>Dear {user-name}</strong>,</p>
            </td>
        </tr>
        <tr>
            <td>{productdetails}</td>
        </tr>
        <tr>
            <td>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">&nbsp;</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">Greetings of the day!</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">One buyer has sent you a message. Please find below details</p>
            </td>
        </tr>
        <tr>
            <td>
            <table width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td bgcolor="#DDDDDD">
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td style="font-family:Arial, sans-serif; font-size:12px; font-weight:bold;" width="30%" bgcolor="#F5F5F5" align="center">Buyer Details</td>
                                    <td style="font-family:Arial, sans-serif; font-size:12px; font-weight:bold;" width="70%" bgcolor="#F5F5F5" align="center">Message</td>
                                </tr>
                                <tr>
                                    <td style="font-family:Arial, sans-serif; font-size:12px; font-weight:bold;" valign="top" bgcolor="#FFFFFF" align="left">{buyername}<br />
                                    &nbsp;</td>
                                    <td style="text-align: justify;" valign="top" bgcolor="#FFFFFF" align="left"><strong><font face="Arial, sans-serif">Question : </font></strong><font face="Arial, sans-serif"><strong style="font-family: Arial, sans-serif; font-size: 12px;"><br />
                                    </strong><font face="Arial, sans-serif">{Description}</font></font></td>
                                </tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
        <tr>
            <td>
            <p class="MsoNormal"><o:p></o:p></p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><span style="font-size: 9pt;">For any enquiries that you have with regards to the above, please feel free to contact us at the details below.</span></p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><br />
            Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            <p style=""><font face="Arial, sans-serif"><span style="font-size: 10px;">P.S.- This is an automated email. Please do not reply to this email. The Information contained in this e-mail and/or in any of the attached files or links mentioned in this email is the confidential and proprietary information of&nbsp;{mpname}, and intended solely for the use of the individual or entity to whom it is addressed. If you are not the intended recipient, any use, disclosure, distribution, reproduction of this mail or the taking any action on the basis of the information contained in the email is unauthorized and strictly prohibited.</span></font></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 21,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
    <tbody>
        <tr>
            <td><img src="http://picotec.com.my/pwimage/Welcome.jpg" alt="" width="600" height="124" /></td>
        </tr>
        <tr>
            <td>
            <table width="600" cellspacing="0" cellpadding="10" border="0" align="center">
                <tbody>
                    <tr>
                        <td colspan="3" style="border-bottom:1px solid #e94a43;"><strong>{logo}</strong></td>
                    </tr>
                    <tr>
                        <td style="">
                        <p style=""><span style="font-size: medium;"><strong><span style="line-height: 115%; font-family: Calibri, sans-serif;">Hello {user-name}!</span></strong></span></p>
                        <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;&#xA;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-font-family:&#xA;Arial;color:#202020">Welcome to </span><font face="Calibri, sans-serif" color="#222222"><span style="font-size: 14.6667px;"><b>Vedson,&nbsp;</b></span></font><span style="font-size:11.0pt;font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;mso-ascii-theme-font:&#xA;minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-font-family:Arial;&#xA;color:#202020">&nbsp;your unique and ultimate shopping destination for electronics, Clothings,&nbsp;&nbsp;</span><span style="color: rgb(32, 32, 32); font-family: Calibri, sans-serif; font-size: 11pt;">appliances, mobile, Desktop!</span></p>
                        <p style="margin-bottom: 12pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 11pt; font-family: Calibri, sans-serif;">Thank you for signing up with <strong><span style="font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;mso-ascii-theme-font:&#xA;minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-font-family:Arial">{mpname}.&nbsp; </span></strong><span style="font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;mso-ascii-theme-font:&#xA;minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-font-family:Arial">We are happy you chose a safe and convenient shopping experience. <o:p></o:p></span></span></p>
                        <p style="margin-bottom: 12pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 11pt; font-family: Calibri, sans-serif;">At </span><span style="font-family: Calibri, sans-serif; font-size: 14.6667px;"><font color="#222222"><b>Vedson</b></font></span><span style="font-size: 11pt; font-family: Calibri, sans-serif;">, we are committed to delighting you with our products and services every time you visit us.</span><strong><span style="font-size: 11pt; font-family: Calibri, sans-serif;"> <o:p></o:p></span></strong></p>
                        <p style="margin-bottom: 12pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><strong><span style="font-size: 11pt; font-family: Calibri, sans-serif;">Now you can-<o:p></o:p></span></strong></p>
                        <p style="margin-bottom: 12pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><strong><span style="font-size: 11pt; font-family: Calibri, sans-serif;">Complete your order faster by saving your addresses and billing details.<o:p></o:p></span></strong></p>
                        <p style="margin-bottom: 12pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><strong><span style="font-size: 11pt; font-family: Calibri, sans-serif;">Manage your orders and return easily from your account.</span></strong></p>
                        <p style="margin-bottom: 12pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 11pt; font-family: Calibri, sans-serif;"><o:p></o:p></span></p>
                        <p>&nbsp;</p>
                        <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><strong><span style="font-size: 11pt; font-family: Calibri, sans-serif;">Your Username:</span></strong><span style="font-size: 11pt; font-family: Calibri, sans-serif;">&nbsp;{user-emailaddress}</span></p>
                        <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">&nbsp;</p>
                        <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 11pt; line-height: 115%; font-family: Calibri, sans-serif;">Best Regards, <br />
                        <strong><span style="font-family:&quot;Calibri&quot;,&quot;sans-serif&quot;;mso-ascii-theme-font:&#xA;minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-font-family:Arial">{mpname} Support Team</span></strong></span></p>
                        <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 11pt; font-family: Calibri, sans-serif;"><o:p></o:p></span></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
                        <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                                    <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                                    Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                                    <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td height="10" align="center"><img alt="" height="5" /></td>
                                            </tr>
                                            <tr>
                                                <td align="right">{socialmedia}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </a></td>
                                </tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>
<p>&nbsp;</p>`,
  },
  {
    id: 22,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:1px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p class="p1" style="-webkit-text-stroke-width: 0px;"><span class="s1"><b>Hi {user-name}</b>,</span></p>
            <p>Your COD order OTP: {codotp}</p>
            <br />
            <p><br />
            Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>
<p>&nbsp;</p>
<p><style type="text/css">  p.p1 {margin: 0.0px 0.0px 0.0px 0.0px; font: 12.0px Arial; -webkit-text-stroke: #000000}  span.s1 {font-kerning: none}  </style></p>`,
  },
  {
    id: 23,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:0px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><strong>Dear {user-name}</strong>,</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">Greetings of the day!</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">We would like to inform you that you have new product issue report.</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">{orderdetails}</p>
            <p class="MsoNormal"><o:p></o:p></p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><span style="font-size: 9pt;">For any enquiries that you have with regards to the above-mentioned order number, please feel free to contact us at the details below.</span></p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><span style="color: rgb(51, 51, 51); line-height: 16px;">We hope to see you again soon!</span></p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><br />
            Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            <p style=""><font face="Arial, sans-serif"><span style="font-size: 10px;">P.S.- This is an automated email. Please do not reply to this email. The Information contained in this e-mail and/or in any of the attached files or links mentioned in this email is the confidential and proprietary information of {mpname}</span></font><font face="Arial, sans-serif"><span style="font-size: 10px;">, and intended solely for the use of the individual or entity to whom it is addressed. If you are not the intended recipient, any use, disclosure, distribution, reproduction of this mail or the taking any action on the basis of the information contained in the email is unauthorized and strictly prohibited.</span></font></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 24,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:0px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><strong>Dear </strong>,</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">You have new brand request please check&nbsp;</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">{orderdetails}</p>
            <p class="MsoNormal"><o:p></o:p></p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><br />
            Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            <p style=""><font face="Arial, sans-serif"><span style="font-size: 10px;">P.S.- This is an automated email. Please do not reply to this email. The Information contained in this e-mail and/or in any of the attached files or links mentioned in this email is the confidential and proprietary information of {mpname}</span></font><font face="Arial, sans-serif"><span style="font-size: 10px;">, and intended solely for the use of the individual or entity to whom it is addressed. If you are not the intended recipient, any use, disclosure, distribution, reproduction of this mail or the taking any action on the basis of the information contained in the email is unauthorized and strictly prohibited.</span></font></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 25,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:0px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><strong>Dear&nbsp;&nbsp;</strong><span style="font-family: Arial, Verdana, sans-serif;">{user-name}</span>,</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">&nbsp;</p>
            <p style=""><font face="Arial, sans-serif">Your Brand request has been rejected.&nbsp;</font></p>
            <p style=""><font face="Arial, sans-serif">You can check for the same in your Seller Panel.</font></p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">{orderdetails}</p>
            <p class="MsoNormal"><o:p></o:p></p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">&nbsp;</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><span style="color: rgb(51, 51, 51); line-height: 16px;">We hope to see you again soon!</span></p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><br />
            Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            <p style=""><font face="Arial, sans-serif"><span style="font-size: 10px;">P.S.- This is an automated email. Please do not reply to this email. The Information contained in this e-mail and/or in any of the attached files or links mentioned in this email is the confidential and proprietary information of {mpname}</span></font><font face="Arial, sans-serif"><span style="font-size: 10px;">, and intended solely for the use of the individual or entity to whom it is addressed. If you are not the intended recipient, any use, disclosure, distribution, reproduction of this mail or the taking any action on the basis of the information contained in the email is unauthorized and strictly prohibited.</span></font></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 26,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:0px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><strong>Dear&nbsp;</strong><span style="font-family: Arial, Verdana, sans-serif;">{user-name}</span><strong>&nbsp;</strong>,</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">Your Brand request has been approved.&nbsp;</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">You can check for the same in your Seller Panel.</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">{orderdetails}</p>
            <p class="MsoNormal"><o:p></o:p></p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">&nbsp;</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><br />
            Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            <p style=""><font face="Arial, sans-serif"><span style="font-size: 10px;">P.S.- This is an automated email. Please do not reply to this email. The Information contained in this e-mail and/or in any of the attached files or links mentioned in this email is the confidential and proprietary information of {mpname}</span></font><font face="Arial, sans-serif"><span style="font-size: 10px;">, and intended solely for the use of the individual or entity to whom it is addressed. If you are not the intended recipient, any use, disclosure, distribution, reproduction of this mail or the taking any action on the basis of the information contained in the email is unauthorized and strictly prohibited.</span></font></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 27,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:0px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><strong>Dear&nbsp;</strong><span style="font-family: Arial, Verdana, sans-serif;">{user-name}</span><strong>&nbsp;</strong>,</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">&nbsp;</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">Your Brand request has been removed by the Admin.</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">Please contact the support team for more information.</p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">{orderdetails}</p>
            <p class="MsoNormal"><o:p></o:p></p>
            <p style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;"><br />
            Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            <p style=""><font face="Arial, sans-serif"><span style="font-size: 10px;">P.S.- This is an automated email. Please do not reply to this email. The Information contained in this e-mail and/or in any of the attached files or links mentioned in this email is the confidential and proprietary information of {mpname}</span></font><font face="Arial, sans-serif"><span style="font-size: 10px;">, and intended solely for the use of the individual or entity to whom it is addressed. If you are not the intended recipient, any use, disclosure, distribution, reproduction of this mail or the taking any action on the basis of the information contained in the email is unauthorized and strictly prohibited.</span></font></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 28,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
    <tbody>
        <tr>
            <td align="center"><img src="http://picotec.com.my/pwimage/thankyou_600x124.jpg" alt="" width="600" height="124" /></td>
        </tr>
        <tr>
            <td>
            <table width="600" cellspacing="0" cellpadding="10" border="0" align="center">
                <tbody>
                    <tr>
                        <td colspan="3" style=" "><strong>{logo}</strong></td>
                    </tr>
                    <tr>
                        <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
                        <p><strong>Dear&nbsp;</strong><span style="color: rgb(51, 51, 51); font-family: Arial, Helvetica, sans-serif; font-size: 13px; font-weight: bold;">{user-name}</span>,</p>
                        <p style="background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif;">Your request to the subscription to <strong><span style="font-family:&quot;Arial&quot;,&quot;sans-serif&quot;">{mpname}</span></strong>&nbsp;has been submitted successfully.<o:p></o:p></span></p>
                        <p>You can visit the store by <span style="color: rgb(128, 0, 0);"><strong>{clickhere}</strong></span> or by pasting this url in your browser window<br />
                        <span style="color: rgb(128, 0, 0);"><strong>{websiteurl}</strong></span></p>
                        <p>&nbsp;</p>
                        <p class="MsoNormal"><o:p></o:p></p>
                        <p>Splash Coupon Code:<strong>{Couponcode}</strong></p>
                        <p><a href="{clickunsub}">{clickunsub} for unsubscribe</a></p>
                        <br />
                        <br />
                        Best Regards,<br />
                        <strong>{mpname} Support Team<br />
                        </strong>
                        <p>&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
                        <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                                    <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                                    Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                                    <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td height="10" align="center"><img alt="" height="5" /></td>
                                            </tr>
                                            <tr>
                                                <td align="right">{socialmedia}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </a></td>
                                </tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 29,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:1px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p><strong>Name :</strong> {user-name}<br />
            <strong> Company Name :</strong> {Company}<br />
            <strong>Email :</strong> {user-emailaddress}<br />
            <strong>Mobile No :</strong> {mobile}<br />
            <strong>Query :</strong> {Query}</p>
            <p><br />
            <br />
            Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 30,
    htmlContent: `<table style="border: 1px solid #CCCCCC;" width="600" cellspacing="0" cellpadding="10" border="0" align="center">
    <tbody>
        <tr>
            <td colspan="3" style="border-bottom:1px solid #e94a43;"><strong>{logo}</strong></td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
            <p><strong>Dear {user-name}</strong>,<br />
            <br />
            <span style="font-size: 9pt; line-height: 115%; font-family: Arial, sans-serif;">We are happy to let you know that your order you placed on&nbsp;<b>{mpname} </b>is confirmed.<br />
            <br />
            Your order details are given below.</span></p>
            <p><br />
            <strong>Order Number :</strong> {orderno}<br />
            <strong>Order Date :</strong> {orderdate}<br />
            <strong>Current Status :</strong> {orderstatus}<br />
            <strong>Payment Mode :</strong> {paymode}<br />
            <strong>Payment Status :</strong> {paystatus}<br />
            <br />
            {orderdetails}</p>
            <p class="MsoNormal" style="line-height: normal; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif;">We will try and ship the order within 24 to 48 hours. Visit My Orders section to get further updates on your order.<o:p></o:p></span></p>
            <p>&nbsp;</p>
            <p class="MsoNormal" style="line-height: normal; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 9pt; font-family: Arial, sans-serif;">Thank you for your continued support and we hope to see you again.<o:p></o:p></span></p>
            <p><br />
            Best Regards,<br />
            <strong>{mpname} Support Team<br />
            </strong></p>
            </td>
        </tr>
        <tr>
            <td style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none" bgcolor="#EEEEEE">
            <table style="width:100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;" width="30%" align="left">{address}</td>
                        <td style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;" width="70%" valign="top" align="right">Phone: {admin-contactno}<br />
                        Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td height="10" align="center"><img alt="" height="5" /></td>
                                </tr>
                                <tr>
                                    <td align="right">{socialmedia}</td>
                                </tr>
                            </tbody>
                        </table>
                        </a></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
  {
    id: 31,
    htmlContent: `<table width="600" cellspacing="0" cellpadding="0" border="0" align="center" style="border: 1px solid #CCCCCC;">
    <tbody>
        <tr>
            <td align="center"><img src="http://picotec.com.my/pwimage/GetSecured_600x124.jpg" width="600" height="124" alt="" /></td>
        </tr>
        <tr>
            <td>
            <table width="600" cellspacing="0" cellpadding="10" border="0" align="center">
                <tbody>
                    <tr>
                        <td colspan="3" style="border-bottom:0px solid #e94a43;"><strong>{logo}</strong></td>
                    </tr>
                    <tr>
                        <td>
                        <p style=""><strong style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">Dear {user-name}</strong><font face="Arial, sans-serif">,</font><br />
                        <br />
                        <font face="Arial, sans-serif">You have requested to retrieve your login details at </font><strong style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">{mpname}</strong><br />
                        <br />
                        <font face="Arial, sans-serif">Please click the link below to reset your password</font><br />
                        <br />
                        <a href="{url}" style="font-family: Arial, sans-serif; font-size: 12px; text-decoration: none;">click here</a><font face="Arial, sans-serif"> to reset your password</font><br />
                        <br />
                        <span style="font-size: 9pt; line-height: 107%; font-family: Arial, sans-serif; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">For any enquiries that you have with regards to accessing your account, please feel free to contact us at the details below.&nbsp;</span><o:p></o:p></p>
                        <p style=""><br />
                        <font face="Arial, sans-serif">                         Best Regards,</font><br />
                        <strong><font face="Arial, sans-serif">Seller Support Team</font>                         </strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td bgcolor="#EEEEEE" style="font-family:Arial, sans-serif; font-size:12px; text-decoration:none">
                        <table width="100%" cellspacing="0" cellpadding="0" border="0" style="width:100%;">
                            <tbody>
                                <tr>
                                    <td width="30%" align="left" style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;vertical-align: top;">{address}</td>
                                    <td width="70%" align="right" valign="top" style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#000000;line-height:20px;">Phone: {admin-contactno}<br />
                                    Email: <a target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=http://support@fzon.in&amp;source=gmail&amp;ust=1493810369615000&amp;usg=AFQjCNHan6iD_eJpLZhePMXCqkHNmoAA1w">{admin-contactemail}
                                    <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td align="center" height="10"><img height="5" alt="" /></td>
                                            </tr>
                                            <tr>
                                                <td align="right">{socialmedia}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </a></td>
                                </tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>`,
  },
];
