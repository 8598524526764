import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import {
  CircularProgress,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import RemoveProductsModal from "./RemoveProductsModal";
import { useState } from "react";

export default function ProductCategoryTable({ Products }) {
  const [categories, setCategories] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState();
  const [Loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = useState(false);
  // console.log(selectedCategory);
  const navigate = useNavigate();

  const handleGetAllCategory = async () => {
    setLoading(true);
    try {
      const res = await axios(
        "https://rccore-backend.cstechns.com/api/admin/getAllSubCategoriesForRoot"
      );
      if (res) {
        setCategories(res.data);
        // console.log(res.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCategoryBYid = async (id) => {
    try {
      const res = await axios.delete(
        `https://rccore-backend.cstechns.com/api/admin/DeleteSingleCategoryProduct/${id}`
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });

        // Update the categories state with the updated data
        const updatedCategories = categories.filter((item) => item._id !== id);
        setRefresh((prev) => !prev);
        setCategories(updatedCategories);
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data,
        showConfirmButton: true,
      });
    }
  };

  React.useEffect(() => {
    handleGetAllCategory();
  }, [refresh]);

  const filteredCategories = categories.filter((category) =>
    category.subcategories.find((subcategory) => {
      const searchValue = searchQuery.toLowerCase();
      switch (selectedCategory) {
        case "Category Name":
          return subcategory.name.toLowerCase().includes(searchValue);
        case "Parent":
          return category.name.toLowerCase().includes(searchValue);
        case "Unique Url":
          return subcategory?.uniqueUrl?.toLowerCase().includes(searchValue);
        default:
          return true;
      }
    })
  );

  return (
    <>
      {Loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", gap: "30px", ml: 3, mb: 2 }}>
            <Select
              displayEmpty
              sx={{ width: "300px" }}
              value={selectedCategory}
              size="small"
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <InputLabel sx={{ ml: 2 }}>Select</InputLabel>
              <MenuItem value="Category Name">By Category Name</MenuItem>
              <MenuItem value="Parent">By Parent</MenuItem>
              <MenuItem value="Unique Url">By Unique Url</MenuItem>
            </Select>
            <TextField
              label="Search"
              placeholder="Search"
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ width: "300px" }}
            />
          </Box>
          <TableContainer
            component={Paper}
            sx={{ width: "96.5%", ml: 3, mb: 4 }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "#dddddd" }}>
                <TableRow>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Parent{" "}
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Category Name
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Unique Url
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Image
                  </TableCell>
                  {/* <TableCell align="center">Sort Order</TableCell> */}
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Featured
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCategories.map((category, index) =>
                  category.subcategories.map((subcategory, subIndex) => {
                    return (
                      <TableRow
                        key={`${category.name}-${subcategory.name}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{category.name}</TableCell>
                        <TableCell align="center">{subcategory.name}</TableCell>
                        <TableCell align="center">
                          {subcategory?.uniqueUrl}
                        </TableCell>
                        <TableCell align="center">
                          {subcategory?.newCategoryData?.image}
                        </TableCell>
                        {/* <TableCell align="center">
                    {subcategory?.newCategoryData?.sortOrder}
                  </TableCell> */}
                        <TableCell align="center">
                          {subcategory?.newCategoryData?.FeatureBrand == true
                            ? "True"
                            : "False"}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Manage Best Selling Products">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/ManageBestSelling/${subcategory.name}/${subcategory._id}`
                                )
                              }
                            >
                              <WorkspacePremiumIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="edit">
                            <IconButton>
                              <EditIcon
                                onClick={() =>
                                  navigate(
                                    `/update-product-categories/${subcategory._id}`
                                  )
                                }
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() =>
                                handleDeleteCategoryBYid(subcategory._id)
                              }
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Manage Products">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/ManageProducts/${subcategory.name}/${subcategory._id}`
                                )
                              }
                            >
                              <WysiwygIcon />
                            </IconButton>
                          </Tooltip>
                          {/* <IconButton>
                            <WysiwygIcon />
                          </IconButton> */}
                          <IconButton>
                            <VisibilityIcon />
                          </IconButton>

                          <RemoveProductsModal
                            Products={Products}
                            subCategory1={subcategory.name}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
