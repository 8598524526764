import {
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";

const LogoAndDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [logoImage, setLogoImage] = useState(null);
  const [faviconImage, setFaviconImage] = useState(null);
  const [validationError, setValidationError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [PunchlineError, setPunchlineError] = useState("");

  const cloudName = "bunny1705";
  const apiKey = "686471132156718";
  const apiSecret = "PF0u158oD2YzCfWEou1-ijeVOmQ";

  // console.log(logoImage);

  const isValidEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setData({ ...data, supportEmailId: newEmail });

    // Check if the entered email is valid
    if (event.target.value.length === 0) {
      setEmailError("Email is Required");
    } else if (!isValidEmail(event.target.value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const handleMobileNumberChange = (e) => {
    const mobileRegex = /^[1-9]\d{9}$/; // regular expression to match 10-digit numbers starting from 1-9
    const enteredValue = e.target.value;
    if (enteredValue.length === 0) {
      setValidationError("contact Number is required");
    } else if (enteredValue.length > 10) {
      setValidationError("Mobile number should be maximum 10 digits.");
    } else if (!mobileRegex.test(enteredValue)) {
      setValidationError(
        "Please enter a valid 10-digit mobile number starting from 1-9."
      );
    } else {
      setValidationError("");
    }
    setData({ ...data, supportContactNumber: enteredValue });
  };

  const handlePunchlineError = (e) => {
    if (e.target.value.length === 0) {
      setPunchlineError("Punchline is required");
    } else {
      setPunchlineError("");
    }
    setData({ ...data, PunchLine: e.target.value });
  };

  const handleLogoAndDetails = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getLogoDetailsById/${id}`,
        config
      );
      if (res) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const updateLogoDetails = async () => {
  //   try {
  //     // Get the JWT token from local storage
  //     const token = localStorage.getItem("token");

  //     // Create a new FormData object to send the logo details and images
  //     const formData = new FormData();
  //     formData.append("PunchLine", data.PunchLine);
  //     if (logoImage) {
  //       formData.append("logoImage", logoImage);
  //     }
  //     if (faviconImage) {
  //       formData.append("faviconImage", faviconImage);
  //     }
  //     formData.append("supportEmailId", data.supportEmailId);
  //     formData.append("supportContactNumber", data.supportContactNumber);

  //     // Set the request headers with the JWT token and content type
  //     const config = {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };

  //     // Send the PUT request to the server with the logo details and images in the request body
  //     const res = await axios.put(
  //       `https://rccore-backend.cstechns.com/api/admin/UpdateLogoDetails/${id}`,
  //       formData,
  //       config
  //     );

  //     // Show a success message if the request was successful
  //     if (res) {
  //       Swal.fire({
  //         position: "center",
  //         icon: "success",
  //         text: "Updated successful",
  //         showConfirmButton: true,
  //       });
  //     }
  //   } catch (error) {
  //     // Show an error message if the request failed
  //     Swal.fire({
  //       position: "center",
  //       icon: "error",
  //       text: error.response.data.message,
  //       showConfirmButton: true,
  //     });
  //   }
  // };
  const Postdata = async () => {
    setLoading1(true);
    try {
      if (validationError || emailError || PunchlineError) {
        return;
      }

      const formData = new FormData();
      formData.append("PunchLine", data.PunchLine);
      formData.append("supportEmailId", data.supportEmailId);
      formData.append("supportContactNumber", data.supportContactNumber);

      if (logoImage) {
        const logoData = new FormData();
        logoData.append("file", logoImage);
        logoData.append("upload_preset", "insta-clone");
        logoData.append("cloud_name", "bunny1705");

        const logoRes = await fetch(
          "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
          {
            method: "POST",
            body: logoData,
          }
        );

        const logoDataJson = await logoRes.json();
        const logoUrl = logoDataJson.url;

        formData.append("logo", logoUrl);
        setData((prevState) => ({ ...prevState, logo: logoUrl }));
        setLogoImage(null);
      }

      if (faviconImage) {
        const faviconData = new FormData();
        faviconData.append("file", faviconImage);
        faviconData.append("upload_preset", "insta-clone");
        faviconData.append("cloud_name", "bunny1705");

        const faviconRes = await fetch(
          "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
          {
            method: "POST",
            body: faviconData,
          }
        );

        const faviconDataJson = await faviconRes.json();
        const faviconUrl = faviconDataJson.url;

        formData.append("favicon", faviconUrl);
        // console.log(faviconUrl);
        setData((prevState) => ({ ...prevState, favicon: faviconUrl }));
        setFaviconImage(null);
      }

      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/UpdateLogoDetails/${id}`,
        formData
      );

      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated successfully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.message,
        showConfirmButton: true,
      });
    } finally {
      setLoading1(false);
    }
  };

  const handleFaviconImageChange = (event) => {
    setFaviconImage(event.target.files[0]);
  };

  const generateSignature = (publicId) => {
    const timestamp = Date.now();
    const signature = CryptoJS.SHA1(
      `public_id=${publicId}&timestamp=${timestamp}${apiSecret}`
    ).toString();
    return { timestamp, signature };
  };

  const handleDeleteLogo = async () => {
    try {
      if (data.logo) {
        const publicId = data.logo.split("/").slice(-1)[0].split(".")[0];
        // console.log(publicId);
        const { signature, timestamp } = generateSignature(publicId);
        const formData = new FormData();
        formData.append("public_id", publicId);
        formData.append("timestamp", timestamp);
        formData.append("api_key", apiKey);
        formData.append("signature", signature);

        const res = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/destroy`,
          formData
        );
        if (res) {
          // console.log(res);
        }
      }
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/UpdateLogoDetails/${id}`,
        {
          logo: "",
        }
      );
      if (res) {
        setData((prevState) => ({ ...prevState, logo: "" }));
        setData((prevState) => ({ ...prevState, logoImagePreview: null }));
        setLogoImage(null);
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteFavicon = async () => {
    try {
      // delete favicon from Cloudinary
      if (data.favicon) {
        const publicId = data.favicon.split("/").slice(-1)[0].split(".")[0];
        // console.log(publicId);
        const { signature, timestamp } = generateSignature(publicId);
        const formData = new FormData();
        formData.append("public_id", publicId);
        formData.append("timestamp", timestamp);
        formData.append("api_key", apiKey);
        formData.append("signature", signature);

        const res = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/destroy`,
          formData
        );
        if (res) {
          // console.log(res);
        }
      }

      // update database
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/UpdateLogoDetails/${id}`,
        {
          favicon: "",
        }
      );
      if (res) {
        setData((prevState) => ({ ...prevState, favicon: "" }));
        setData((prevState) => ({ ...prevState, faviconImagePreview: null }));
        setFaviconImage(null);

        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });
      }
      ///
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleLogoAndDetails();
  }, []);

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", ml: 4, mr: 4 }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Logo & Details
        </Typography>
        <Button
          variant="contained"
          onClick={Postdata}
          disabled={loading1}
          disableElevation
        >
          {loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
        </Button>
      </Box>
      {!loading ? (
        <>
          <Card
            sx={{ maxWidth: "550px", p: 2, mt: 2, border: "1px solid #dddddd" }}
            style={{ margin: "auto", marginTop: "30px" }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  textAlign: "center",
                  mb: 2,
                }}
              >
                Manage Logo
              </Typography>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>PunchLine</Typography>
                <TextField
                  fullWidth
                  multiline
                  variant="outlined"
                  value={data.PunchLine}
                  onChange={handlePunchlineError}
                  error={PunchlineError}
                  helperText={PunchlineError}
                  sx={{ mb: 2 }}
                />
              </Box>
              <Box>
                <Box>
                  <Typography sx={{ fontSize: "17px" }}>Logo</Typography>
                  <TextField
                    fullWidth
                    type="file"
                    variant="outlined"
                    onChange={(e) => {
                      setLogoImage(e.target.files[0]);
                      setData({ ...data, logo: logoImage });
                      const reader = new FileReader();
                      reader.onload = () => {
                        if (reader.readyState === 2) {
                          setData({ ...data, logoImagePreview: reader.result });
                        }
                      };
                      reader.readAsDataURL(e.target.files[0]);
                    }}
                    sx={{ mb: 2 }}
                  />
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <img
                      src={
                        data.logoImagePreview
                          ? data.logoImagePreview
                          : data.logo
                      }
                      alt={data.name}
                      width="120px"
                    />
                    {data.logo && (
                      <Button
                        onClick={handleDeleteLogo}
                        sx={{ color: "red", textDecoration: "underline" }}
                      >
                        Delete
                      </Button>
                    )}
                  </Box>
                </Box>

                <Box>
                  <Typography sx={{ fontSize: "17px" }}>Favicon</Typography>
                  <TextField
                    fullWidth
                    type="file"
                    variant="outlined"
                    onChange={(e) => {
                      setFaviconImage(e.target.files[0]);
                      setData({ ...data, favicon: faviconImage });
                      const reader = new FileReader();
                      reader.onload = () => {
                        if (reader.readyState === 2) {
                          setData({
                            ...data,
                            faviconImagePreview: reader.result,
                          });
                        }
                      };
                      reader.readAsDataURL(e.target.files[0]);
                    }}
                    sx={{ mb: 2 }}
                  />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={
                        data.faviconImagePreview
                          ? data.faviconImagePreview
                          : data.favicon
                      }
                      alt={data.name}
                      width="120px"
                    />
                    {data.favicon && (
                      <Button
                        onClick={handleDeleteFavicon}
                        sx={{ color: "red", textDecoration: "underline" }}
                      >
                        Delete
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              maxWidth: "550px",
              p: 2,
              mt: 2,
              border: "1px solid #dddddd",
            }}
            style={{ margin: "auto", marginTop: "30px", marginBottom: "20px" }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  mb: 2,
                  textAlign: "center",
                  mt: 2,
                }}
              >
                Support Details
              </Typography>

              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  Support EmailId
                </Typography>
                <TextField
                  fullWidth
                  type="email"
                  variant="outlined"
                  value={data.supportEmailId}
                  onChange={handleEmailChange}
                  error={emailError}
                  helperText={emailError}
                  sx={{ mb: 2 }}
                />
              </Box>

              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  Support Contact Number
                </Typography>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  value={data.supportContactNumber}
                  onChange={handleMobileNumberChange}
                  error={Boolean(validationError)}
                  helperText={validationError}
                  sx={{ mb: 2 }}
                />
              </Box>
            </Box>
          </Card>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default LogoAndDetails;
