import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/system";

export default function ProviderListTable({ data, loading }) {
  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "150px",
        }}
        sx={{ mt: 3, ml: 3, mr: 3 }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Logistic Providers
        </Typography>

        <Button variant="contained">
          <Link
            to="/createLogisticProvider"
            style={{ color: "white", textDecoration: "none" }}
          >
            Create New Logistic Provider
          </Link>
        </Button>
      </Box>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "bold",
          textAlign: "end",
          mr: 3,
          mt: 1.5,
        }}
      >
        Total Records : {data.length}
      </Typography>
      {!loading ? (
        <>
          <TableContainer
            component={Paper}
            sx={{ mt: 3, border: "1px solid #dddddd", width: "96.5%", ml: 3 }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ background: "#dddddd" }}>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Name
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Comapny Name
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Email
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Phone
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    URL
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Self Shipping
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell align="center">{row.ContactPerson}</TableCell>
                    <TableCell align="center">
                      {row.CourierCompanyName}
                    </TableCell>
                    <TableCell align="center">{row.ContactEmailId}</TableCell>
                    <TableCell align="center">
                      {row.ContactPhoneNumber}
                    </TableCell>
                    <TableCell align="center">{row.TrackingURL}</TableCell>
                    <TableCell align="center">
                      {row.selfShipping === false ? "No" : "Yes"}
                    </TableCell>
                    <TableCell align="center">
                      <Link to={`/logisiticProviderEdit/${row._id}`}>
                        <EditIcon
                          style={{ cursor: "pointer", color: "black" }}
                        />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
