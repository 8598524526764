import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ManageSizeChartTable from "./ManageSizeChartTable";

const ManageSizeChart = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <div style={{ marginTop: "150px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", m: 3 }}>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Manage Size chart
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate(`/create-new-Size-Chart/${id}`)}
        >
          Add Attribute
        </Button>
      </Box>
      <ManageSizeChartTable />
    </div>
  );
};

export default ManageSizeChart;
