import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { motion } from "framer-motion";

const ManageHomePageMainBanners = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [bannerData, setBannerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [visibility, setVisibility] = React.useState({});

  const cloudName = "bunny1705";
  const apiKey = "686471132156718";
  const apiSecret = "PF0u158oD2YzCfWEou1-ijeVOmQ";

  const handleEditBanner = (banner) => {
    // console.log(banner);
    if (data) {
      setSelectedBanner(banner);
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      setSelectedBanner(data[0]);
    }
  }, [data]);

  const generateSignature = (publicId) => {
    const timestamp = Date.now();
    const signature = CryptoJS.SHA1(
      `public_id=${publicId}&timestamp=${timestamp}${apiSecret}`
    ).toString();
    return { timestamp, signature };
  };

  const handleGetAllMAinBAnnerImages = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/handleGetAllImagesforMainBanner"
      );
      if (res) {
        const visibilityObj = {};
        res.data.forEach((row) => {
          visibilityObj[row._id] = !row.visible;
        });
        setData(res.data);
        setVisibility(visibilityObj);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // handle visibility

  const handleVisibility = async (id, value) => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/handleUpdateHomePage/${id}`,
        {
          visible: value,
        }
      );
      if (res) {
        setVisibility((prevState) => ({
          ...prevState,
          [id]: !value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle Delete Banner Image

  const handleDeleteBannerImage = async (id) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (shouldDelete) {
      try {
        const banner = data.find((item) => item._id === id);

        if (!banner) {
          console.log("Banner not found");
          return;
        }

        const publicId = banner.image.split("/").slice(-1)[0].split(".")[0];
        const { signature, timestamp } = generateSignature(publicId);

        const formData = new FormData();
        formData.append("public_id", publicId);
        formData.append("timestamp", timestamp);
        formData.append("api_key", apiKey);
        formData.append("signature", signature);

        const deleteImageResponse = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/destroy`,
          formData
        );

        if (deleteImageResponse.status === 200) {
          const deleteBannerResponse = await axios.delete(
            `https://rccore-backend.cstechns.com/api/admin/handleDeleteHomepageBannerImages/${id}`
          );

          if (deleteBannerResponse.status === 200) {
            console.log("Image and data deleted successfully");
            // Update the state or perform any additional actions if needed
            const updatedData = data.filter((item) => item._id !== id);
            setData(updatedData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // handle get Banner Images

  const handleGetBannerImageById = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/handleGetBannerImageById/${selectedBanner._id}`
      );
      if (res) {
        console.log(res);
        setBannerData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle update Banner data

  const handleUpdateBanner = async () => {
    setLoading1(true);
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/handleUpdateHomePage/${selectedBanner._id}`,
        {
          url: bannerData.url,
          altText: bannerData.altText,
          sortingOrder: bannerData.sortingOrder,
          title: bannerData.title,
          subtitle: bannerData.subtitle,
        }
      );
      if (res) {
        console.log(res);
        const updatedData = await axios.get(
          "https://rccore-backend.cstechns.com/api/admin/handleGetAllImagesforMainBanner"
        );

        // Update the data state with the fetched data
        setData(updatedData.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    handleGetAllMAinBAnnerImages();
  }, []);

  useEffect(() => {
    handleGetBannerImageById();
  }, [selectedBanner]);
  return (
    <div style={{ marginTop: "150px" }}>
      {!loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ml: 3,
              mr: 3,
              mb: 2,
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Home Page Banner
            </Typography>
            <Button variant="contained">Publish</Button>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", gap: "50px", ml: 3 }}
          >
            <Box>
              <Typography>
                This section allows you to manage Home Page Banner
              </Typography>{" "}
              <Typography>
                {" "}
                To upload new Home Page Banner images please use the upload
                button on the right,
                <br /> you can modify or delete already existing Home Page
                Banner images from the list below.
              </Typography>{" "}
              <Typography sx={{ color: "red" }}>
                Make sure you have published the Home Page Banner images once
                you are finished editing and image
                <br /> dimension should be 728px X 350px
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                onClick={() =>
                  navigate("/theme-customization/upload-banner-images")
                }
              >
                Upload Banner Images
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              m: 3,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Card elevation={0} sx={{ width: "100%" }}>
              <Box>
                <Card elevation={0} sx={{ border: "2px solid #dddddd" }}>
                  <Grid container spacing={2}>
                    {data.map((item, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        xl={3}
                        key={index}
                      >
                        <Box
                          sx={{
                            p: 2,
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              height: "150px",
                              width: "150px",
                              border: "2px solid #dddddd",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "flex-end",
                            }}
                          >
                            <img src={item.image} alt="" width="150px" />
                          </Box>
                          {/*  */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "5px",
                              mt: 0.5,
                            }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                width: "162px",
                                height: "30px",
                              }}
                              onClick={() =>
                                handleVisibility(item._id, visibility[item._id])
                              }
                            >
                              {visibility[item._id] ? "In-visible" : "visible"}
                            </Button>
                            <Button
                              variant="contained"
                              sx={{ width: "162px", height: "30px" }}
                              onClick={() => handleEditBanner(item)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                width: "162px",
                                height: "30px",
                                background: "red",
                              }}
                              onClick={() => handleDeleteBannerImage(item._id)}
                            >
                              Delete
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Box>
            </Card>

            {selectedBanner && (
              <motion.div
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ duration: 0.3 }}
                style={{ marginLeft: "20px" }}
              >
                <Card
                  elevation={0}
                  sx={{ border: "2px solid #dddddd", width: "450px", p: 2 }}
                >
                  <Box>
                    <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      Edit Info
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={bannerData.image} alt="" width="150px" />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>Url </Typography>
                    <TextField
                      variant="outlined"
                      value={bannerData.url}
                      onChange={(e) =>
                        setBannerData({ ...bannerData, url: e.target.value })
                      }
                      fullWidth
                      size="small"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>Alt Text </Typography>
                    <TextField
                      variant="outlined"
                      value={bannerData.altText}
                      onChange={(e) =>
                        setBannerData({
                          ...bannerData,
                          altText: e.target.value,
                        })
                      }
                      fullWidth
                      size="small"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>Sorting Order</Typography>
                    <TextField
                      variant="outlined"
                      value={bannerData.sortingOrder}
                      onChange={(e) =>
                        setBannerData({
                          ...bannerData,
                          sortingOrder: e.target.value,
                        })
                      }
                      fullWidth
                      size="small"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>Title</Typography>
                    <TextField
                      variant="outlined"
                      value={bannerData.title}
                      onChange={(e) =>
                        setBannerData({
                          ...bannerData,
                          title: e.target.value,
                        })
                      }
                      fullWidth
                      size="small"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>Sub Title</Typography>
                    <TextField
                      variant="outlined"
                      value={bannerData.subtitle}
                      onChange={(e) =>
                        setBannerData({
                          ...bannerData,
                          subtitle: e.target.value,
                        })
                      }
                      fullWidth
                      size="small"
                    />
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleUpdateBanner}
                    sx={{ mt: 2 }}
                    disabled={loading1}
                  >
                    {loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
                  </Button>
                </Card>
              </motion.div>
            )}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default ManageHomePageMainBanners;
