import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import SellerProfileTable from "./SellerProfileTable";

const newdata = [
  {
    name: "Profile Navbar",
  },
  {
    name: "My Orders Panel",
  },
  {
    name: "Seller Cancellation banner",
  },
];

const SellerProfile = () => {
  const [data, setData] = useState();
  const [moduleType, setModuleType] = useState();
  const [moduleName, setModuleName] = useState();
  const [image, setImage] = useState(null);
  const [seqNo, setSeqNo] = useState();

  const handleCreatePDPModules = async () => {
    try {
      const logoData = new FormData();
      logoData.append("file", image);
      logoData.append("upload_preset", "insta-clone");
      logoData.append("cloud_name", "bunny1705");

      const logoRes = await fetch(
        "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
        {
          method: "POST",
          body: logoData,
        }
      );

      const logoDataJson = await logoRes.json();
      const logoUrl = logoDataJson.url;

      if (logoUrl) {
        const res = await axios.post(
          "https://rccore-backend.cstechns.com/api/admin/CreateSellerProfileModules",
          {
            moduleType: moduleType,
            moduleName: moduleName,
            image: logoUrl,
          }
        );
        if (res) {
          console.log(res.data);
          setModuleType("");
          setModuleName("");
          setImage("");
          setSeqNo("");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ marginTop: "150px" }}>
      <Box>
        <Box sx={{ ml: 3 }}>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            Category Listing Customization
          </Typography>
        </Box>
        <Box
          sx={{
            ml: 3,
            mt: 2,
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Select
            displayEmpty
            sx={{ width: "300px" }}
            size="small"
            value={moduleType}
            onChange={(e) => setModuleType(e.target.value)}
          >
            <InputLabel sx={{ ml: 2 }}>Select Module Type</InputLabel>
            {newdata.map((item) => (
              <MenuItem value={item.name}>{item.name}</MenuItem>
            ))}
          </Select>
          <TextField
            variant="outlined"
            label="Module Name"
            placeholder="Module Name"
            size="small"
            value={moduleName}
            onChange={(e) => setModuleName(e.target.value)}
            sx={{ width: "300px" }}
          />
          <TextField
            variant="outlined"
            type="file"
            size="small"
            onChange={(e) => setImage(e.target.files[0])}
            sx={{ width: "300px" }}
          />

          <Button variant="contained" onClick={handleCreatePDPModules}>
            SUBMIT
          </Button>
        </Box>
      </Box>
      <Box>
        <SellerProfileTable newdata={newdata} />
      </Box>
    </div>
  );
};

export default SellerProfile;
