import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const HomepageCategorySlider = () => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState("");
  const [RootCategoryData, setMainRootCategoryData] = useState("");
  const [mainCategoryData, setMainCategoryData] = useState([]);
  const [subCategoryData, setsubCategoryData] = useState([]);
  const [mainCategory, setMainCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [categorySliderData, setCategorySliderData] = useState([]);
  const [sliderData, setSliderData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const [productsList, setProductsList] = useState([]);

  const handleRootCategory = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllSubCategoriesForRoot"
      );

      if (res) {
        setData(res.data[0].subcategories);

        // Find the selected category object
        let selectedCategory = res.data[0].subcategories.find(
          (item) => item.name === sliderData.RootCategory
        );

        // Define main categories here
        let mainCategories = [];
        if (selectedCategory) {
          mainCategories = selectedCategory.subcategories;
        }

        // console.log(mainCategories);

        setMainCategoryData(mainCategories);

        let selectedMainCategory = mainCategories.find(
          (item) => item.name === sliderData.MainCategory
        );

        // console.log(selectedMainCategory);

        // Define main categories here
        let subMainCategories = [];
        if (selectedMainCategory) {
          subMainCategories = selectedMainCategory.subcategories;
        }
        // console.log(subMainCategories);
        setsubCategoryData(subMainCategories);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handeGetAllProductsList = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/get-all-Products`
      );
      if (res.data) {
        const filteredItems = res.data.filter((item) => {
          if (item.active) {
            if (sliderData.RootCategory === item.data.Category) {
              //   console.log("1", item);
              return item;
            } else if (sliderData.MainCategory === item.data.mainCategory) {
              //   console.log("2", item);
              return item;
            } else if (sliderData.subCategory === item.data.subCategory) {
              //   console.log("3", item);
              return item;
            }
          }
        });
        setProductsList(filteredItems);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetCategorySliderData = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/handleGetAllCategorySlider"
      );
      if (res) {
        // console.log(res);
        setCategorySliderData(res.data[0]._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCategorySliderById = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/handleGetCategorySliderById/${categorySliderData}`
      );
      if (res) {
        console.log(res);
        setSliderData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateCatgorySlider = async () => {
    setLoading1(true);
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/hadleUpdateCategorySlider/${categorySliderData}`,
        {
          Heading: sliderData.Heading,
          ButtonText: sliderData.ButtonText,
          LinktoButton: sliderData.LinktoButton,
          RootCategory: sliderData.RootCategory,
          MainCategory: sliderData.MainCategory,
          subCategory: sliderData.subCategory,
        }
      );
      if (res) {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    handleRootCategory();
    handleGetCategorySliderData();
  }, [sliderData]);

  useEffect(() => {
    handleGetCategorySliderById();
  }, [categorySliderData]);

  useEffect(() => {
    handeGetAllProductsList();
  }, [
    sliderData.RootCategory,
    sliderData.MainCategory,
    sliderData.subCategory,
  ]);

  return (
    <div style={{ marginTop: "150px" }}>
      <Box sx={{ ml: 3, mr: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            Category Slider
          </Typography>
          <Button variant="contained">Publish</Button>
        </Box>
        <Box>
          <Box sx={{ mt: 2 }}>
            <Typography>Heading</Typography>
            <TextField
              variant="outlined"
              placeholder="Heading"
              size="small"
              value={sliderData.Heading}
              onChange={(e) =>
                setSliderData({ ...sliderData, Heading: e.target.value })
              }
              sx={{ width: "450px", mb: 2, mt: 0.5 }}
            />
          </Box>
          <Box>
            <Typography>Button Text</Typography>
            <TextField
              variant="outlined"
              placeholder="Button Text"
              size="small"
              value={sliderData.ButtonText}
              onChange={(e) =>
                setSliderData({ ...sliderData, ButtonText: e.target.value })
              }
              sx={{ width: "450px", mb: 2, mt: 0.5 }}
            />
          </Box>
          <Box>
            <Typography>Link to Button</Typography>
            <TextField
              variant="outlined"
              placeholder="Link to Button"
              size="small"
              value={sliderData.LinktoButton}
              onChange={(e) =>
                setSliderData({ ...sliderData, LinktoButton: e.target.value })
              }
              sx={{ width: "450px", mb: 2, mt: 0.5 }}
            />
          </Box>
          <Box>
            <Typography>Select Root Category</Typography>
            <Select
              sx={{ width: "450px", mb: 2, mt: 0.5 }}
              size="small"
              value={sliderData.RootCategory || ""}
              onChange={(e) => {
                const selectedCategory = data.find(
                  (item) => item.name === e.target.value
                );
                setSliderData({ ...sliderData, RootCategory: e.target.value });
                setMainCategoryData(selectedCategory.subcategories);
              }}
            >
              {data.map((item) => (
                <MenuItem value={item.name} key={item._id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <Typography>Select Main Category</Typography>
            <Select
              sx={{ width: "450px", mb: 2, mt: 0.5 }}
              size="small"
              value={sliderData.MainCategory || ""}
              onChange={(e) => {
                const selectedCategory = mainCategoryData.find(
                  (item) => item.name === e.target.value
                );
                setSliderData({ ...sliderData, MainCategory: e.target.value });
                setsubCategoryData(selectedCategory.subcategories);
              }}
            >
              {mainCategoryData.map((item) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <Typography>Select Sub Category</Typography>
            <Select
              sx={{ width: "450px", mb: 2, mt: 0.5 }}
              size="small"
              value={sliderData?.subCategory || ""}
              onChange={(e) =>
                setSliderData({
                  ...sliderData,
                  subCategory: e.target.value,
                })
              }
            >
              {subCategoryData.map((item) => (
                <MenuItem value={item.name} key={item._id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Button
            variant="contained"
            sx={{ width: "450px" }}
            onClick={handleUpdateCatgorySlider}
            disabled={loading1}
          >
            {loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
          </Button>
        </Box>
        <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
          <Typography sx={{ mt: 0, color: "red" }}>
            * Note Select Category to get Top 10 Products for selected
            <br />
            Category
          </Typography>
        </Box>
        <Card elevation={0} sx={{ width: "100%", mt: 2, mb: 3 }}>
          <Box>
            <Card elevation={0} sx={{ border: "2px solid #dddddd" }}>
              <Grid container spacing={2}>
                {productsList.length > 0 ? (
                  productsList.map((item, index) => (
                    <Grid item xs={12} sm={8} md={6} lg={3} xl={2} key={index}>
                      <Box
                        sx={{
                          p: 2,
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Box
                          sx={{
                            width: "150px",
                            height: "150px",
                            border: "2px solid #dddddd",
                            padding: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {/* <img src={item.image} alt="" /> */}
                          <Typography>SkuCode:{item.SkuCode}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "5px",
                            mt: 0.5,
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              width: "162px",
                              height: "30px",
                            }}
                            //   onClick={() =>
                            //     handleVisibility(item._id, visibility[item._id])
                            //   }
                          >
                            Visible
                            {/* {visibility[item._id] ? "In-visible" : "visible"} */}
                          </Button>

                          {/* <Button
                                  variant="contained"
                                  sx={{
                                    width: "162px",
                                    height: "30px",
                                    background: "red",
                                  }}
                                  // onClick={() => handleDeleteBannerImage(item._id)}
                                >
                                  Delete
                                </Button> */}
                        </Box>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Box
                    sx={{
                      p: 3,
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      No item Found For {selected} Category
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Card>
          </Box>
        </Card>
      </Box>
    </div>
  );
};

export default HomepageCategorySlider;
