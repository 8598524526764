import {
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const CreateNewStaffLogin = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleCreateStaffLogin = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/createStaffLogin",
        {
          firstName,
          lastName,
          email,
          password,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Created successful",
          showConfirmButton: true,
          timer: 1500,
        });
        navigate("/staff-login");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);

    // Check if the entered password is valid

    if (event.target.value.length === 0) {
      setPasswordError("Password is Required");
    } else if (!isValidPassword(event.target.value)) {
      setPasswordError("Password must be at least 6 characters long");
    } else {
      setPasswordError("");
    }
  };

  const isValidPassword = (password) => {
    return password.length >= 6;
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);

    // Check if the entered email is valid
    if (event.target.value.length === 0) {
      setEmailError("Email is Required");
    } else if (!isValidEmail(event.target.value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const isValidEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidFirstName = (firstName) => {
    return firstName.length > 3;
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    if (e.target.value.length === 0) {
      setFirstNameError("First Name is required");
    } else if (!isValidFirstName(e.target.value)) {
      setFirstNameError("First Name Should be greater than 3 charecters");
    } else {
      setFirstNameError("");
    }
  };

  const isValidLastName = (lastName) => {
    return lastName.length > 3;
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    if (e.target.value.length === 0) {
      setLastNameError("Last Name is required");
    } else if (!isValidLastName(e.target.value)) {
      setLastNameError("Last Name Should be greater than 3 charecters");
    } else {
      setLastNameError("");
    }
  };

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card
          sx={{
            maxWidth: "450px",
            border: "1px solid #dddddd",
            p: 2,
          }}
          elevation={0}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                mb: 2,
                textAlign: "center",
              }}
            >
              Create New Staff Login
            </Typography>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>First Name</Typography>
              <TextField
                sx={{ width: "450px", mb: 2, mt: 0.5 }}
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={handleFirstNameChange}
                error={firstNameError}
                helperText={firstNameError}
              />

              <Box>
                <Typography sx={{ fontSize: "17px" }}>Last Name</Typography>
                <TextField
                  sx={{ width: "450px", mb: 2, mt: 0.5 }}
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={handleLastNameChange}
                  error={lastNameError}
                  helperText={lastNameError}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>Email</Typography>
                <TextField
                  sx={{ width: "450px", mb: 2, mt: 0.5 }}
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                  error={emailError}
                  helperText={emailError}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>Password</Typography>
                <TextField
                  sx={{ width: "450px", mb: 2, mt: 0.5 }}
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                  error={!!passwordError}
                  helperText={passwordError}
                />
              </Box>
            </Box>
          </Box>
          <Button
            variant="contained"
            fullWidth
            onClick={handleCreateStaffLogin}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "SUBMIT"}
          </Button>
        </Card>
      </Box>
    </div>
  );
};

export default CreateNewStaffLogin;
