import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import {
  Button,
  Card,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const EditCustomAttributes = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const handleGetCustomAttributesById = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getCustomAttributeById/${id}`
      );
      if (res) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // handle customAttributes Update

  const handleCustomAttributesUpdate = async () => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/updateCustomAttributeById/${id}`,
        data
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Data Updated SuccessFully",
          showConfirmButton: true,
          timer: 1500,
        });
        navigate("/product-attributes-custom");
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    handleGetCustomAttributesById();
  }, []);
  return (
    <div style={{ marginTop: "150px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mr: 3 }}>
        <Typography></Typography>
        <Button variant="contained" onClick={() => navigate(-1)}>
          BACK
        </Button>
      </Box>
      {!loading ? (
        <Card
          sx={{ maxWidth: "450px", p: 2 }}
          style={{
            margin: "auto",
            marginTop: "30px",
            border: "1px solid #dddddd",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              textAlign: "center",
              mb: 2,
            }}
          >
            Update Custom Attribute
          </Typography>
          <Box>
            <Box>
              <Typography>Enter Name</Typography>
              <TextField
                variant="outlined"
                value={data.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                fullWidth
                sx={{ mt: 0.5, mb: 2 }}
              />
            </Box>
            <Box>
              <Typography>Select Type</Typography>
              <Select
                fullWidth
                value={data.AttributType || ""}
                onChange={(e) =>
                  setData({ ...data, AttributType: e.target.value })
                }
                sx={{ mb: 2, mt: 0.5 }}
              >
                <MenuItem value="RadioButton">RadioButton</MenuItem>
                <MenuItem value="TextBox">TextBox</MenuItem>
                <MenuItem value="CheckBox">CheckBox</MenuItem>
                <MenuItem value="TextArea">TextArea</MenuItem>
                <MenuItem value="DropdownList">DropdownList</MenuItem>
              </Select>
            </Box>

            {data.AttributType === "DropdownList" && (
              <TextField
                variant="outlined"
                label="Initial Values"
                placeholder="Initial Values"
                value={data.initialValue.join(",")}
                onChange={(e) => {
                  const values = e.target.value.split(",");
                  setData({ ...data, initialValue: values });
                }}
                fullWidth
                sx={{ mt: 2 }}
              />
            )}

            {data.AttributType == "RadioButton" && (
              <FormControl sx={{ mt: 2 }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Initial Value
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="true"
                    checked={data.initialValue[0] == true}
                    //   onChange={(e) => setInitialValue(true)}
                    onChange={(e) =>
                      setData({
                        ...data,
                        initialValue: [true, ...data.initialValue.slice(1)],
                      })
                    }
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    checked={data.initialValue[0] == false}
                    //   onChange={(e) => setInitialValue(false)}
                    onChange={(e) =>
                      setData({
                        ...data,
                        initialValue: [false, ...data.initialValue.slice(1)],
                      })
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            )}
            {data.AttributType == "CheckBox" && (
              <FormControl sx={{ mt: 2 }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Initial Value
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="true"
                    checked={data.initialValue[0] == true}
                    //   onChange={(e) => setInitialValue(true)}
                    onChange={(e) =>
                      setData({
                        ...data,
                        initialValue: [true, ...data.initialValue.slice(1)],
                      })
                    }
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    checked={data.initialValue[0] == true}
                    //   onChange={(e) => setInitialValue(false)}
                    onChange={(e) =>
                      setData({
                        ...data,
                        initialValue: [false, ...data.initialValue.slice(1)],
                      })
                    }
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            )}
            <Box>
              <Typography>Sequence Id</Typography>
              <TextField
                variant="outlined"
                value={data.sequenceId}
                onChange={(e) =>
                  setData({ ...data, sequenceId: e.target.value })
                }
                fullWidth
                sx={{ mt: 0.5, mb: 2 }}
              />
            </Box>
            <Box style={{ display: "flex", gap: "60px" }}>
              <Box>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Use in Product Search
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={data.useInProductSearch}
                      checked={data.useInProductSearch == true}
                      // onChange={(e) => setuseInProductSearch(true)}
                      onChange={(e) =>
                        setData({ ...data, useInProductSearch: true })
                      }
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={data.useInProductSearch}
                      checked={data.useInProductSearch == false}
                      // onChange={(e) => setuseInProductSearch(false)}
                      onChange={(e) =>
                        setData({ ...data, useInProductSearch: false })
                      }
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Use in Order Details
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={data.OrderDetails}
                      checked={data.OrderDetails == true}
                      onChange={(e) => setData({ ...data, OrderDetails: true })}
                      control={<Radio />}
                      // onChange={(e) => setOrderDetails(true)}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={data.OrderDetails}
                      control={<Radio />}
                      checked={data.OrderDetails == false}
                      onChange={(e) =>
                        setData({ ...data, OrderDetails: false })
                      }
                      // onChange={(e) => setOrderDetails(false)}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2, mb: 2 }}
            onClick={handleCustomAttributesUpdate}
          >
            SUBMIT
          </Button>
        </Card>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default EditCustomAttributes;
