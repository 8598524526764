import { Box, CircularProgress, Grid } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import FollowerCard from "./shared/FollowerCard";
import InventoryLineChart from "./shared/InventoryLineChart";
import InventoryLineChart1 from "./shared/InventoryLineChart1";
import StatCard3 from "./shared/StatCard3";
import StatCard4 from "./shared/StatCard4";
import StatCard5 from "./shared/StatCard5";
import TopSellingTable from "./shared/TopSellingTable";
import TopSellingTable1 from "./shared/TopSellingTable1";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import {
  getAllSellers,
  handleGetAllOrder,
  handleGetAllProducts,
  handleGetAllUsers,
  handleGetLoginToday,
} from "../axios";

const AnalyticsRoot = styled("div")(({ theme }) => ({
  margin: "30px",
  marginTop: "150px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
}));

const Analytics2 = () => {
  const [Loading, setLoading] = useState(false);
  const [newdata, setData] = useState([]);

  const getLoginToday = async () => {
    setLoading(true);
    try {
      const res = await handleGetLoginToday();

      if (res) {
        return res;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllOrders = async () => {
    setLoading(true);
    try {
      const res = await handleGetAllOrder();

      if (res) {
        return res;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllSellersAdmin = async () => {
    setLoading(true);
    try {
      const res = await getAllSellers();

      if (res) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllProducts = async () => {
    setLoading(true);
    try {
      const res = await handleGetAllProducts();

      if (res) {
        return res;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllUsers = async () => {
    setLoading(true);
    try {
      const res = await handleGetAllUsers();

      if (res) {
        return res;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      Promise.all([
        getLoginToday(),
        getAllOrders(),
        getAllSellersAdmin(),
        getAllProducts(),
        getAllUsers(),
      ]).then((res) => {
        setData(res);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      {!Loading ? (
        <AnalyticsRoot>
          <Grid container spacing={5}>
            <Grid item lg={6} xs={12}>
              <FollowerCard />
            </Grid>
            <Grid item lg={6} md={12} xs={12} xl={6}>
              <Grid item>
                <StatCard3 data={newdata[1]} loginToday={newdata[0]} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item lg={12} md={12} xl={12} xs={12} sx={{ mt: 3 }}>
              <StatCard4 data={newdata[1]} />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item lg={12} xs={12} sx={{ mt: 3 }}>
              <StatCard5 sellers={newdata[2]} Products={newdata[3]} />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item lg={6} md={12} sm={12} sx={{ mt: 3 }}>
              <InventoryLineChart users={newdata[4]} />
            </Grid>
            <Grid item lg={6} sm={12} sx={{ mt: 3 }}>
              <InventoryLineChart1 data={newdata[1]} />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item lg={6} sx={{ mt: 3 }}>
              <TopSellingTable />
            </Grid>
            <Grid item lg={6} sx={{ mt: 3 }}>
              <TopSellingTable1 />
            </Grid>
          </Grid>
        </AnalyticsRoot>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default Analytics2;
