import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { TbFileSettings } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { handleGetAllOrder } from "../../../axios";

const columns = [
  { id: "code", label: "Order No", minWidth: 120, align: "center" },
  {
    id: "Order Type",
    label: "Order Type",
    minWidth: 80,
    align: "center",
  },
  {
    id: "size",
    label: "Buyer",
    minWidth: 120,
    align: "center",
  },
  {
    label: "Order Date(DD/MM/YYYY) ",
    minWidth: 60,
    align: "center",
  },
  {
    label: "Order Confirm Date (MM/DD/YYYY)",
    minWidth: 60,
    align: "center",
  },
  {
    label: "Amount",
    minWidth: 60,
    align: "center",
  },
  {
    label: "Mode of Payment",
    minWidth: 80,
    align: "center",
  },
  {
    label: "Payment Status",
    minWidth: 120,
    align: "center",
  },
  {
    label: "Order Status",
    minWidth: 60,
    align: "center",
  },
  {
    label: "Seller Entity Details",
    minWidth: 80,
    align: "center",
  },
  {
    label: "Action",
    minWidth: 20,
    align: "center",
  },
];

export default function OrderTable() {
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.table_to_sheet(
      document.getElementById("order-table")
    );
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");
    XLSX.writeFile(workbook, "orders.xlsx");
  };

  const handleGetAllOrders1 = async () => {
    setLoading(true);
    try {
      const res = await handleGetAllOrder();

      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllOrders1();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
          mb: 3,
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Orders List
        </Typography>
        <Box>
          <Button variant="contained" onClick={handleExportToExcel}>
            Export to Excel
          </Button>
        </Box>
      </Box>
      {!loading ? (
        <Paper
          sx={{
            width: "96.5%",
            overflow: "hidden",
            ml: 3,
            border: "1px solid #ddd",
            mb: 5,
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        background: "#eee",
                        fontWeight: "bold",
                      }}
                      sx={{ borderRight: "1px solid #ddd" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        {row.orderId}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        ----
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              Name:
                            </Typography>
                            <Typography sx={{ fontSize: "14px" }}>
                              {row?.fullName}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              Mobile:
                            </Typography>
                            <Typography sx={{ fontSize: "14px" }}>
                              {row?.orderPhone}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              CompanyName:
                            </Typography>
                            <Typography sx={{ fontSize: "14px" }}>
                              {row?.companyName}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              GSTIN:
                            </Typography>
                            <Typography sx={{ fontSize: "14px" }}>
                              {row?.GSTIN}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        {row.orderDate}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        {row.orderConfirmDate}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        ₹ {row.totalPrice}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        {row.orderPaymentMethod}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        {row.orderPaymentStatus === "Paid" ? "Paid" : "UnPaid"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        {row?.orderStatus}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              Name:
                            </Typography>
                            <Typography sx={{ fontSize: "14px" }}>
                              {
                                row?.orderItems?.[0]?.productId?.createdBy
                                  ?.fullName
                              }
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              GSTIN:
                            </Typography>
                            <Typography sx={{ fontSize: "14px" }}>
                              --
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              PAN No:
                            </Typography>
                            <Typography sx={{ fontSize: "14px" }}>
                              --
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              State:
                            </Typography>
                            <Typography sx={{ fontSize: "14px" }}>
                              {
                                row?.orderItems?.[0]?.productId?.createdBy
                                  ?.state
                              }
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              city:
                            </Typography>
                            <Typography sx={{ fontSize: "14px" }}>
                              {row?.orderItems?.[0]?.productId?.createdBy?.city}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        <Tooltip title="manage Order">
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-order/${row._id}`, {
                                state: { orderData: [row] },
                              })
                            }
                          >
                            <TbFileSettings />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
