import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export default function UnselectedItems({
  Products,
  setSelectedItems,
  selectedItems,
}) {
  //   console.log(selectedItems);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedItems(Products.filter((row) => row.active));
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (event, row) => {
    if (event.target.checked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, row]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== row)
      );
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ width: "100%", overflow: "hidden" }}
    >
      <Table aria-label="simple table">
        <TableHead sx={{ background: "#eee" }}>
          <TableRow>
            <TableCell
              align="left"
              sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
            >
              Select
              <Checkbox
                checked={
                  selectedItems.length ===
                  Products.filter((row) => row.active).length
                }
                onChange={handleSelectAll}
              />
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
            >
              S.No
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
            >
              Image
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
            >
              Product
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
            >
              Seller
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Products.filter((row) => row.active).map((row, index) => (
            <TableRow key={index}>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                <Checkbox
                  checked={selectedItems.includes(row)}
                  onChange={(event) => handleSelectItem(event, row)}
                />
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                {index + 1}
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                <Box sx={{ width: "60px" }}>
                  <img
                    src={row.MainImage}
                    alt="image1"
                    width="100%"
                    height="100%"
                  />
                </Box>
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: "60px",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Brand:
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {row.BrandName}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: "60px",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Name:
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {row.Title.slice(0, 25)}...
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: "60px",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      SkuCode:
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {row.SKUCode}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        alignItems: "center",
                        minWidth: "60px",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      SellerId:
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {row.createdBy.sellerId}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: "60px",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      SellerName:
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {row.createdBy.fullName}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: "60px",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      EntityName:
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {row.createdBy.companyName}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
