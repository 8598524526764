import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ClearIcon from "@mui/icons-material/Clear";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StoreCalculationManageModal1({
  data,
  setData,
  handleUpdateStoreCalculationData,
  DefaultCurrency,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{ background: "#1ab394" }}
        onClick={handleClickOpen}
      >
        Manage
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              PPC COD Fee Differential
            </Typography>
            <IconButton onClick={handleClose}>
              <ClearIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Box>
          <Typography
            sx={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}
          >
            We will charge which ever is higher
          </Typography>
        </Box>
        <DialogContent>
          <Box>
            <Box sx={{ display: "flex", gap: "220px" }}>
              <Typography>Value (%)</Typography>
              <Typography>
                Value ({DefaultCurrency && DefaultCurrency.split("-")[1]})
              </Typography>
            </Box>
            {data.PaymentChargesForCodTypeDifferentialFieldValues.map(
              (item) => (
                <Box
                  sx={{
                    display: "flex",
                    mt: 2,
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      value={item.from}
                      size="small"
                      sx={{ width: "250px" }}
                      onChange={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          PaymentChargesForCodTypeDifferentialFieldValues:
                            prevData.PaymentChargesForCodTypeDifferentialFieldValues.map(
                              (prevItem) =>
                                prevItem === item
                                  ? {
                                      ...prevItem,
                                      from: e.target.value,
                                    }
                                  : prevItem
                            ),
                        }));
                      }}
                    />
                    <Typography>%</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      value={item.to}
                      size="small"
                      sx={{ width: "250px" }}
                      onChange={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          PaymentChargesForCodTypeDifferentialFieldValues:
                            prevData.PaymentChargesForCodTypeDifferentialFieldValues.map(
                              (prevItem) =>
                                prevItem === item
                                  ? {
                                      ...prevItem,
                                      to: e.target.value,
                                    }
                                  : prevItem
                            ),
                        }));
                      }}
                    />
                    <Typography>
                      {DefaultCurrency && DefaultCurrency.split("-")[1]}
                    </Typography>
                  </Box>
                </Box>
              )
            )}
          </Box>
        </DialogContent>
        <Typography sx={{ textAlign: "center", mb: 2 }}>
          <Button
            sx={{ background: "#ee6810", color: "#fff" }}
            variant="contained"
            onClick={() => {
              handleClose();
              handleUpdateStoreCalculationData();
            }}
          >
            SUBMIT
          </Button>
        </Typography>
      </Dialog>
    </div>
  );
}
