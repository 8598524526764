import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CreateNewProductTypeValues = () => {
  const [productTypeData, setProductTypeData] = useState([]);
  const [MainAttributesData, setMainAttributesData] = useState([]);
  const [CustomAttributesData, setCustomAttributesData] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [SelectedCheckboxForCustom, setSelectedCheckboxForCustom] = useState(
    []
  );
  const [productType, setProductType] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const navigate = useNavigate();

  //   console.log("selectedCheckbox", SelectedCheckboxForCustom);
  const handleGetAllProductTypes = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllProductTypesAttributes"
      );
      if (res) {
        setProductTypeData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // get all Main Attributes

  const getAllMainAttributes = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllMainProductAttributes"
      );
      if (res) {
        console.log("res", res);
        setMainAttributesData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // get All custom Attributes
  const getAllCustomAttributes = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllCustomAttributes"
      );
      if (res) {
        // console.log(res);
        setCustomAttributesData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // handle Create Product Type Values

  const handleCreateProductTypeValues = async () => {
    setLoading1(true);
    try {
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/createProductTypeValues",
        {
          ProductType: productType,
          MainAttributes: selectedCheckbox,
          CustomAttributes: SelectedCheckboxForCustom,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Product Type Values Created successfully",
          showConfirmButton: true,
          timer: 1500,
        });
        navigate("/product-type-values");
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
        timer: 1500,
      });
    } finally {
      setLoading1(false);
    }
  };

  const handleAllGetProductTypeValues = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllProductTypesValues"
      );
      if (res) {
        setData(res.data);
        // console.log(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllProductTypes();
    getAllMainAttributes();
    getAllCustomAttributes();
    handleAllGetProductTypeValues();
  }, []);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const categoryName = e.target.value;
    setSelectedCheckbox((prevState) => {
      if (isChecked) {
        return [...prevState, categoryName];
      } else {
        return prevState.filter((category) => category !== categoryName);
      }
    });
  };
  const handleCheckboxChangeforCustom = (e) => {
    const isChecked = e.target.checked;
    const categoryName = e.target.value;
    setSelectedCheckboxForCustom((prevState) => {
      if (isChecked) {
        return [...prevState, categoryName];
      } else {
        return prevState.filter((category) => category !== categoryName);
      }
    });
  };

  const availableProductTypes = productTypeData.filter((brand) => {
    return !data.find((item) => item.ProductType === brand.name);
  });

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", ml: 3, mr: 3 }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Product Type Values
        </Typography>
        <Button variant="contained" onClick={() => navigate(-1)}>
          Back
        </Button>
      </Box>
      {!loading ? (
        <Card elevation={0} sx={{ border: "1px solid #dddddd", m: 3, p: 2 }}>
          <Box sx={{ mt: 3 }}>
            <Box sx={{ display: "flex", gap: "70px", alignItems: "center" }}>
              <Typography>Product Type : </Typography>
              <Select
                displayEmpty
                sx={{ width: "400px" }}
                size="small"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
              >
                <InputLabel sx={{ ml: 2 }}>Select</InputLabel>
                {availableProductTypes.map((product) => (
                  <MenuItem value={product.name}>{product.name}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "50px",
                marginTop: "40px",
              }}
            >
              <Typography>Main Attributes : </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {MainAttributesData.map((mainAttribute) => (
                  <Box>
                    {mainAttribute.hide === false ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          value={mainAttribute.AttributeName}
                          onChange={handleCheckboxChange}
                        />
                        <Typography>{mainAttribute.AttributeName}</Typography>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "23px",
                marginTop: "40px",
              }}
            >
              <Typography>Custom Attributes : </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {CustomAttributesData.map((CustomAttribute) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      value={CustomAttribute.name}
                      onChange={handleCheckboxChangeforCustom}
                    />
                    <Typography>{CustomAttribute.name}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "170px", mt: 2 }}>
              <Typography></Typography>
              <Button
                variant="contained"
                onClick={handleCreateProductTypeValues}
                disabled={loading1}
              >
                {loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
              </Button>
            </Box>
          </Box>
        </Card>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default CreateNewProductTypeValues;
