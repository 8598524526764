import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { BiHide, BiShow } from "react-icons/bi";

import { getAllRatingsAndReviews, getAllReturnPolicy } from "../../../axios";

export default function RatingsAndReviewsTable() {
  const [data, setData] = useState([]);
  const [visibility, setVisibility] = React.useState({});
  const [Loading, setLoading] = useState(false);

  const handleGetAllRatingsAndReviews = async () => {
    setLoading(true);
    try {
      const res = await getAllRatingsAndReviews();
      if (res) {
        const visibilityObj = {};
        res.forEach((row) => {
          visibilityObj[row._id] = !row.active;
        });
        console.log(res);
        setData(res);
        setVisibility(visibilityObj);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVisibility = async (id, value) => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/handleUpdateReview/${id}`,
        {
          active: value,
        }
      );
      if (res) {
        setVisibility((prevState) => ({
          ...prevState,
          [id]: !value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  function convertUtcToIst(utcDateTimeString) {
    const utcDateTime = new Date(utcDateTimeString);
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const istDate = utcDateTime.toLocaleDateString("en-IN", options);
    return istDate;
  }

  useEffect(() => {
    handleGetAllRatingsAndReviews();
  }, []);

  return (
    <>
      {!Loading ? (
        <TableContainer
          component={Paper}
          sx={{ mt: 3, width: "96.5%", ml: 3, border: "1px solid #ddd" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ background: "#eee" }}>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  S.No
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Image
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    borderRight: "1px solid #ddd",
                    fontWeight: "bold",
                    width: "400px",
                  }}
                >
                  Product Details
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Customer Details
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Rating Avg.
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Quality of product
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Value for Money
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Performance
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Date
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Description
                </TableCell>

                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    <Box sx={{ width: "60px" }}>
                      <img
                        src={row?.ProductDetails?.MainImage}
                        alt=""
                        width="100%"
                        height="100%"
                      />
                    </Box>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    <Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "130px",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          Product Code:
                        </Typography>
                        <Typography sx={{ fontSize: "14px" }}>
                          {row?.ProductDetails?.SKUCode}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "130px",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          Brand Name:
                        </Typography>
                        <Typography sx={{ fontSize: "14px" }}>
                          {row?.ProductDetails?.BrandName}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "130px",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          Product Name:
                        </Typography>
                        <Typography sx={{ fontSize: "14px" }}>
                          {row?.ProductDetails?.Title}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "130px",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          Company Name:
                        </Typography>
                        <Typography sx={{ fontSize: "14px" }}>
                          {row?.ProductDetails?.createdBy?.companyName}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    <Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "70px",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          Name:
                        </Typography>
                        <Typography sx={{ fontSize: "14px" }}>
                          {row?.Name}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "70px",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          Email:
                        </Typography>
                        <Typography sx={{ fontSize: "14px" }}>
                          {row?.Email}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                    {(
                      (row?.Quality + row?.valueForMoney + row?.Performence) /
                      3
                    ).toFixed(1)}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                    {row?.Quality}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                    {row?.valueForMoney}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                    {row?.Performence}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                    {convertUtcToIst(row.Date)}
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                    {row?.Description}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {visibility[row._id] ? (
                      <Tooltip title="Activate">
                        <IconButton
                          onClick={() =>
                            handleVisibility(row._id, visibility[row._id])
                          }
                        >
                          <BiHide style={{ color: "red" }} size={22} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="DeActivate">
                        <IconButton
                          onClick={() =>
                            handleVisibility(row._id, visibility[row._id])
                          }
                        >
                          <BiShow style={{ color: "green" }} size={22} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
