import React from "react";
import EmailTemplateEdit from "./EmailTemplateEdit";
import EmailTemplateTable from "./EmailTemplatesTable";

const EmailTemplates = () => {
  return (
    <div style={{ marginTop: "150px" }}>
      <EmailTemplateTable />
    </div>
  );
};

export default EmailTemplates;
