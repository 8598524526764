import { Button, Card, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const CreateNewProductType = () => {
  const [name, setName] = useState();
  const [sortId, setSortId] = useState();

  const navigate = useNavigate();
  const { id } = useParams();

  const handleCreateNewProductType = async () => {
    try {
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/createNewProductTypeAttribute",
        {
          name,
          sortId,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Product Attribute Created SuccessFully",
          showConfirmButton: true,
          timer: 1500,
        });
        navigate(`/manage-product-type/${id}`);
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };
  return (
    <div style={{ marginTop: "150px" }}>
      <Card
        sx={{ maxWidth: "450px", p: 2 }}
        style={{ margin: "auto", marginTop: "30px" }}
      >
        <Typography
          style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}
        >
          Create New Product Type Attribute
        </Typography>
        <Box>
          <TextField
            label="Attribute Name"
            placeholder="Attribute Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          />
          <TextField
            label="Sort Id"
            placeholder="Sort Id"
            value={sortId}
            onChange={(e) => setSortId(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Box>
        <Button
          variant="contained"
          fullWidth
          onClick={handleCreateNewProductType}
        >
          SUBMIT
        </Button>
      </Card>
    </div>
  );
};

export default CreateNewProductType;
