import {
  Avatar,
  Box,
  Card,
  Icon,
  IconButton,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import React from "react";

const FlexBetween = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const CardHeader = styled(FlexBetween)(() => ({
  paddingLeft: "34px",
  paddingRight: "34px",
  marginBottom: "12px",
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));

const ProductTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& small": {
    height: 15,
    width: 50,
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
  },
  "& td": { borderBottom: "none" },
  "& td:first-of-type": { paddingLeft: "16px !important" },
}));

const TopSellingTable1 = () => {
  const { palette } = useTheme();

  return (
    <Card elevation={2} sx={{ pt: "20px", mb: 0, borderRadius: "20px" }}>
      <CardHeader>
        <Title>
          <Typography sx={{ fontWeight: "bold" }}>
            Zero Record Search
          </Typography>
        </Title>
        <Select size="small" defaultValue="this_month">
          <MenuItem value="this_month">This Month</MenuItem>
          <MenuItem value="last_month">Last Month</MenuItem>
        </Select>
      </CardHeader>

      <Box overflow="auto" sx={{ height: "440px" }}>
        <ProductTable>
          <TableBody>
            {productList.map((product, index) => (
              <TableRow key={index} hover>
                <TableCell
                  colSpan={4}
                  align="left"
                  sx={{ px: 0, textTransform: "capitalize" }}
                >
                  <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                    <Box>
                      <Typography
                        sx={{ m: 0, ml: 3 }}
                        style={{
                          fontWeight: "bold",
                          lineHeight: "24px",
                          letterSpacing: "2px",
                        }}
                      >
                        {product.name}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>

                <TableCell align="right" colSpan={2}>
                  <Typography
                    sx={{ m: 0, mr: 3 }}
                    style={{ color: "rgba(82,82,108,0.75)" }}
                  >
                    10 minutes ago
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ProductTable>
      </Box>
    </Card>
  );
};

const productList = [
  {
    name: "earphone",
  },
  {
    name: "earphone",
  },
  {
    name: "iPhone x",
  },
  {
    name: "iPhone x",
  },
  {
    name: "Head phone",
  },
];

export default TopSellingTable1;
