import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import CategoryAssociationTable from "./CategoryAssociationTable";

const CategoryAssociation = () => {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "150px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", m: 3 }}>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Category Association
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/new-category-association")}
        >
          Create New
        </Button>
      </Box>
      <CategoryAssociationTable />
    </div>
  );
};

export default CategoryAssociation;
