import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const LoayltyPointsMaster = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [LoyaltyPointsData, setLoyaltyPointsData] = useState({});

  console.log(LoyaltyPointsData);

  const handleGetAllLoyaltyPointsMaster = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/handleGetAllLoyaltyPointsMaster"
      );
      if (res) {
        setData(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hadleGetLoyaltyPointsById = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/handleGetLoyaltypointById/${data?._id}`
      );
      if (res) {
        setLoyaltyPointsData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleUpdateLoyaltyPoints = async () => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/handleUpdateLoyaltyPoints/${data._id}`,
        {
          active: LoyaltyPointsData.active,
          moneytoPoints: LoyaltyPointsData.moneytoPoints,
          pointstoMoney: LoyaltyPointsData.pointstoMoney,
          pointsExpire: LoyaltyPointsData.pointsExpire,
          pointswillCreditinWhichStage:
            LoyaltyPointsData.pointswillCreditinWhichStage,
        }
      );
      if (res) {
        alert("Loyalty Points Updated Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllLoyaltyPointsMaster();
  }, []);

  useEffect(() => {
    hadleGetLoyaltyPointsById();
  }, [data]);
  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Loyalty Point Master
        </Typography>
        <Button variant="contained" onClick={handleUpdateLoyaltyPoints}>
          SUBMIT
        </Button>
      </Box>
      {!loading ? (
        <Box sx={{ m: 3 }}>
          <Box sx={{ border: "1px solid #ddd", borderRadius: "5px" }}>
            <Box sx={{ background: "#000", p: 1.5, borderRadius: "5px" }}>
              <Typography sx={{ color: "white", fontWeight: "bold" }}>
                Loyalty Points
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "400px",
                }}
              >
                Active:
              </Typography>
              <Checkbox
                checked={LoyaltyPointsData.active === true}
                onChange={(e) =>
                  setLoyaltyPointsData({
                    ...LoyaltyPointsData,
                    active: !LoyaltyPointsData.active,
                  })
                }
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "400px",
                }}
              >
                How many ₹ equal to 1 point:
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  size="small"
                  placeholder="How many ₹ equal to 1 point"
                  sx={{ width: "300px" }}
                  value={LoyaltyPointsData.moneytoPoints}
                  onChange={(e) =>
                    setLoyaltyPointsData({
                      ...LoyaltyPointsData,
                      moneytoPoints: e.target.value,
                    })
                  }
                />
                <Typography sx={{ ml: 1 }}>₹ = 1 point</Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "400px",
                }}
              >
                How many points equal to 1 ₹:
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  size="small"
                  placeholder="How many points equal to 1 ₹"
                  sx={{ width: "300px" }}
                  value={LoyaltyPointsData.pointstoMoney}
                  onChange={(e) =>
                    setLoyaltyPointsData({
                      ...LoyaltyPointsData,
                      pointstoMoney: e.target.value,
                    })
                  }
                />
                <Typography sx={{ ml: 1 }}>Points = 1 ₹</Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "400px",
                }}
              >
                Points will expire in:
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  size="small"
                  placeholder="Points will expire in"
                  sx={{ width: "300px" }}
                  value={LoyaltyPointsData.pointsExpire}
                  onChange={(e) =>
                    setLoyaltyPointsData({
                      ...LoyaltyPointsData,
                      pointsExpire: e.target.value,
                    })
                  }
                />
                <Typography sx={{ ml: 1 }}>Days</Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "400px",
                }}
              >
                Loyalty points will be credited when the order is in:
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Select
                  size="small"
                  sx={{ width: "300px" }}
                  value={LoyaltyPointsData.pointswillCreditinWhichStage ?? ""}
                  onChange={(e) =>
                    setLoyaltyPointsData({
                      ...LoyaltyPointsData,
                      pointswillCreditinWhichStage: e.target.value,
                    })
                  }
                >
                  <MenuItem value="Ready To Ship">Ready To Ship</MenuItem>
                  <MenuItem value="Shipped">Shipped</MenuItem>
                  <MenuItem value="Delivered">Delivered</MenuItem>
                </Select>
                <Typography sx={{ ml: 1 }}>Stage</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default LoayltyPointsMaster;
