import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Swal from "sweetalert2";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";

export default function EmailSettingsTable({
  data,
  loading,
  setLoading,
  setData,
}) {
  const navigate = useNavigate();
  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.delete(
        `https://rccore-backend.cstechns.com/api/admin/deleteEmailSettings/${id}`,
        config
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res.data,
          showConfirmButton: true,
        });
        setData(data.filter((item) => item._id !== id));
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "success",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };
  return (
    <>
      <Box
        style={{ display: "flex", justifyContent: "space-between" }}
        sx={{ ml: 3, mr: 3, mt: 3 }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Email Settings
        </Typography>
        <Button variant="contained">
          <Link
            to="/CreateEmailSetting"
            style={{ textDecoration: "none", color: "white" }}
          >
            Create New
          </Link>
        </Button>
      </Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer
            component={Paper}
            sx={{ mt: 3, ml: 3 }}
            style={{ border: "1px solid #dddddd", width: "96.5%" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "#dddddd" }}>
                <TableRow>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    S.NO
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Profile Name
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Host
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Port
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Username
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    SSL
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Network Credential
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row.profileName}</TableCell>
                    <TableCell align="center">{row.SMTPHost}</TableCell>
                    <TableCell align="center">{row.SMTPPort}</TableCell>
                    <TableCell align="center">{row.userName}</TableCell>
                    <TableCell align="center">
                      {row.EnableSSL === true ? "True" : "False"}
                    </TableCell>
                    <TableCell align="center">
                      {row.NetworkCredential === true ? "True" : "False"}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() =>
                          navigate(`/EmailSettingsEdit/${row._id}`)
                        }
                      >
                        <FiEdit size={20} />
                      </IconButton>

                      {row.profileName !== "Default" && (
                        <IconButton onClick={() => handleDelete(row._id)}>
                          <MdDelete style={{ color: "red" }} />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
