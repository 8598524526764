import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StaffLoginTable from "./StaffLoginTable";

const StaffLogin = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const navigations = location.state?.navigations;
  // console.log(navigations);
  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          ml: 3,
          mr: 3,
          mb: 2,
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Staff Login
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/new-staff-login")}
          disableElevation
        >
          Create New
        </Button>
      </Box>

      <StaffLoginTable navigations={navigations} />
    </div>
  );
};

export default StaffLogin;
