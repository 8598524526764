import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { motion, AnimatePresence } from "framer-motion";

const Brands = [{ value: "Flat", label: "Flat" }];

const PaymentMethods = () => {
  const { id } = useParams();
  const [paymentData, setPaymentData] = useState({});
  const [brandName, SetbrandName] = React.useState(Brands[0].value);
  const [CodDisplayError, setCodDisplayError] = React.useState("");
  const [CodChargesError, setCodChargesError] = React.useState("");
  const [RazorPayApiKeyIdError, setRazorPayApiKeyIdError] = React.useState("");
  const [RazorPayApiKeySecretError, setRazorPayApiKeySecretError] =
    React.useState("");
  const [RazorPayDisplayNameError, setRazorPayDisplayNameError] =
    React.useState("");
  const [loading, setLoading] = useState(false);

  const [CoDActiveDisplay, setCoDActiveDisplay] = useState();
  const [RazorPayActiveDisplay, setRazorPayActiveDisplay] = useState();
  const [RazorPayMode, setRazorPayMode] = useState();

  const [openCardIndex, setOpenCardIndex] = useState(-1);
  const [loading1, setLoading1] = useState(false);

  const handleCodDisplayNameChange = (e) => {
    if (e.target.value.length === 0) {
      setCodDisplayError("Cod Display Name is Required");
    } else {
      setCodDisplayError("");
    }
    setPaymentData({
      ...paymentData,
      CODpaymentModeDispayOnWebsite: e.target.value,
    });
  };
  const handleRazorPayDisplayNameChange = (e) => {
    if (e.target.value.length === 0) {
      setRazorPayDisplayNameError(" Display Name is Required");
    } else {
      setRazorPayDisplayNameError("");
    }
    setPaymentData({
      ...paymentData,
      RazorPaypaymentModeDispayOnWebsite: e.target.value,
    });
  };
  const handleStripeDisplayNameChange = (e) => {
    if (e.target.value.length === 0) {
      setRazorPayDisplayNameError(" Display Name is Required");
    } else {
      setRazorPayDisplayNameError("");
    }
    setPaymentData({
      ...paymentData,
      StripepaymentModeDispayOnWebsite: e.target.value,
    });
  };
  const handleRazorPayApiKeyIdChange = (e) => {
    if (e.target.value.length === 0) {
      setRazorPayApiKeyIdError(" API Key is Required");
    } else {
      setRazorPayApiKeyIdError("");
    }
    setPaymentData({
      ...paymentData,
      RazorPayAPIKeyId: e.target.value,
    });
  };
  const handleStripeApiKeyIdChange = (e) => {
    if (e.target.value.length === 0) {
      setRazorPayApiKeyIdError(" API Key is Required");
    } else {
      setRazorPayApiKeyIdError("");
    }
    setPaymentData({
      ...paymentData,
      StripeAPIKeyId: e.target.value,
    });
  };
  const handleRazorPayApiKeyIdChangeLive = (e) => {
    if (e.target.value.length === 0) {
      setRazorPayApiKeyIdError(" API Key is Required");
    } else {
      setRazorPayApiKeyIdError("");
    }
    setPaymentData({
      ...paymentData,
      RazorPayAPIKeyIdLive: e.target.value,
    });
  };
  const handleStripeApiKeyIdChangeLive = (e) => {
    if (e.target.value.length === 0) {
      setRazorPayApiKeyIdError(" API Key is Required");
    } else {
      setRazorPayApiKeyIdError("");
    }
    setPaymentData({
      ...paymentData,
      StripeAPIKeyIdLive: e.target.value,
    });
  };
  const handleRazorPayApiKeySecretChange = (e) => {
    if (e.target.value.length === 0) {
      setRazorPayApiKeySecretError(" API Secret is Required");
    } else {
      setRazorPayApiKeySecretError("");
    }
    setPaymentData({
      ...paymentData,
      RazorPayAPISecretKey: e.target.value,
    });
  };
  const handleStripeApiKeySecretChange = (e) => {
    if (e.target.value.length === 0) {
      setRazorPayApiKeySecretError(" API Secret is Required");
    } else {
      setRazorPayApiKeySecretError("");
    }
    setPaymentData({
      ...paymentData,
      StripeAPISecretKey: e.target.value,
    });
  };
  const handleRazorPayApiKeySecretChangeLive = (e) => {
    if (e.target.value.length === 0) {
      setRazorPayApiKeySecretError(" API Secret is Required");
    } else {
      setRazorPayApiKeySecretError("");
    }
    setPaymentData({
      ...paymentData,
      RazorPayAPISecretKeyLive: e.target.value,
    });
  };
  const handleStripeApiKeySecretChangeLive = (e) => {
    if (e.target.value.length === 0) {
      setRazorPayApiKeySecretError(" API Secret is Required");
    } else {
      setRazorPayApiKeySecretError("");
    }
    setPaymentData({
      ...paymentData,
      StripeAPISecretKeyLive: e.target.value,
    });
  };
  const handleCodChargesChange = (e) => {
    if (e.target.value.length === 0) {
      setCodChargesError("Cod Charges is Required");
    } else {
      setCodChargesError("");
    }
    setPaymentData({
      ...paymentData,
      CODCharges: e.target.value,
    });
  };

  const handleCardClick = (index) => {
    if (index === openCardIndex) {
      setOpenCardIndex(-1);
    } else {
      setOpenCardIndex(index);
    }
  };

  const handleOptionChange = (event) => {
    SetbrandName(event.target.value);
  };

  const handlePayemntMethods = async () => {
    setLoading1(true);
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/paymentMethodsById/${id}`,
        config
      );
      if (res) {
        setPaymentData(res.data);
        setCoDActiveDisplay(res.data.CODActive);
        setRazorPayActiveDisplay(res.data.RazorPayActive);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  // Handle Edit Payemnt Method

  const handleEditPayemntMethod = async () => {
    setLoading(true);
    try {
      if (
        CodDisplayError ||
        CodChargesError ||
        RazorPayApiKeyIdError ||
        RazorPayApiKeySecretError
      ) {
        return;
      }
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/EditPaymentMethod/${id}`,
        paymentData,
        config
      );
      if (res) {
        setCoDActiveDisplay(paymentData.CODActive);
        setRazorPayActiveDisplay(paymentData.RazorPayActive);
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Data Updated SuccessFully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handlePayemntMethods();
  }, []);
  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 4,
          mr: 4,
          mb: 2,
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Payment Method
        </Typography>
        <Button
          variant="contained"
          onClick={handleEditPayemntMethod}
          disabled={loading}
          disableElevation
        >
          {loading ? <CircularProgress size={24} /> : "SUBMIT"}
        </Button>
      </Box>
      {!loading1 ? (
        <>
          <AnimatePresence>
            <motion.div
              initial={{ height: 50 }}
              animate={{ height: openCardIndex === 0 ? 450 : 50 }}
              exit={{ height: 50 }}
              transition={{ duration: 0.5 }}
            >
              <Card
                sx={{
                  border: "1px solid #dddddd",
                  ml: 3,
                  mr: 3,
                }}
                elevation={0}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#333333",
                    p: 1.5,
                    cursor: "pointer",
                  }}
                  onClick={() => handleCardClick(0)}
                >
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold", color: "#fff" }}
                  >
                    COD
                  </Typography>
                  {CoDActiveDisplay === true ? (
                    <Typography sx={{ fontSize: "16px", color: "#65a13d" }}>
                      Active
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: "16px", color: "red" }}>
                      IN-Active
                    </Typography>
                  )}
                </Box>
                {openCardIndex === 0 && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                  >
                    <Box sx={{ width: "450px", ml: 3 }}>
                      <Box
                        sx={{ mt: 1.5 }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "50px",
                        }}
                      >
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            Active
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              checked={paymentData.CODActive === true}
                              onChange={(e) =>
                                setPaymentData({
                                  ...paymentData,
                                  CODActive: true,
                                })
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              checked={paymentData.CODActive === false}
                              onChange={(e) =>
                                setPaymentData({
                                  ...paymentData,
                                  CODActive: false,
                                })
                              }
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "17px" }}>
                          Payment Mode Display on Website
                        </Typography>
                        <TextField
                          fullWidth
                          value={paymentData.CODpaymentModeDispayOnWebsite}
                          onChange={handleCodDisplayNameChange}
                          error={CodDisplayError}
                          helperText={CodDisplayError}
                          sx={{ mt: 0.4, mb: 1.5 }}
                        />
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "176x" }}>
                          COD Type
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          select
                          value={brandName}
                          onChange={handleOptionChange}
                          sx={{ mt: 0.4, mb: 1.5 }}
                        >
                          {Brands.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "176x" }}>
                          COD Charges
                        </Typography>
                        <TextField
                          fullWidth
                          sx={{ mt: 0.4, mb: 1.5 }}
                          value={paymentData.CODCharges}
                          error={CodChargesError}
                          helperText={CodChargesError}
                          onChange={handleCodChargesChange}
                        />
                      </Box>
                    </Box>
                  </motion.div>
                )}
              </Card>
            </motion.div>
            <motion.div
              initial={{ height: 50 }}
              animate={{ height: openCardIndex === 1 ? 530 : 50 }}
              exit={{ height: 50 }}
              transition={{ duration: 0.3 }}
            >
              <Card
                sx={{ border: "1px solid #dddddd", ml: 3, mr: 3 }}
                elevation={0}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#333333",
                    p: 1.5,
                    cursor: "pointer",
                  }}
                  onClick={() => handleCardClick(1)}
                >
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold", color: "#fff" }}
                  >
                    Razor Pay
                  </Typography>
                  {RazorPayActiveDisplay ? (
                    <Typography sx={{ fontSize: "16px", color: "#65a13d" }}>
                      Active
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: "16px", color: "red" }}>
                      IN-Active
                    </Typography>
                  )}
                </Box>
                {openCardIndex == 1 && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                  >
                    <Box sx={{ width: "450px", ml: 3 }}>
                      <Box
                        sx={{ mt: 1.5 }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "50px",
                        }}
                      >
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            Active
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              checked={paymentData.RazorPayActive === true}
                              onChange={(e) =>
                                setPaymentData({
                                  ...paymentData,
                                  RazorPayActive: true,
                                })
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              checked={paymentData.RazorPayActive === false}
                              onChange={(e) =>
                                setPaymentData({
                                  ...paymentData,
                                  RazorPayActive: false,
                                })
                              }
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "17px" }}>
                          Payment Mode Display on Website
                        </Typography>
                        <TextField
                          fullWidth
                          value={paymentData.RazorPaypaymentModeDispayOnWebsite}
                          error={RazorPayDisplayNameError}
                          helperText={RazorPayDisplayNameError}
                          onChange={handleRazorPayDisplayNameChange}
                          sx={{ mt: 0.2, mb: 1.5 }}
                        />
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "17px" }}>
                          RazorPay Mode
                        </Typography>
                        <Select
                          sx={{ mt: 0.2, mb: 1.5 }}
                          fullWidth
                          value={paymentData.RazorPayMode}
                          onChange={(e) =>
                            setPaymentData({
                              ...paymentData,
                              RazorPayMode: e.target.value,
                            })
                          }
                        >
                          <MenuItem value="Test">Test Mode</MenuItem>
                          <MenuItem value="live">Live Mode</MenuItem>
                        </Select>
                      </Box>
                      {paymentData.RazorPayMode === "Test" ? (
                        <>
                          <Box>
                            <Typography sx={{ fontSize: "17px" }}>
                              RazorPay API Test KEYID
                            </Typography>
                            <TextField
                              fullWidth
                              sx={{ mt: 0.4, mb: 1.5 }}
                              value={paymentData.RazorPayAPIKeyId}
                              error={RazorPayApiKeyIdError}
                              helperText={RazorPayApiKeyIdError}
                              onChange={handleRazorPayApiKeyIdChange}
                            />
                          </Box>
                          <Box>
                            <Typography sx={{ fontSize: "17px" }}>
                              RazorPay API Test SecretKey
                            </Typography>
                            <TextField
                              fullWidth
                              sx={{ mt: 0.4, mb: 1.5 }}
                              value={paymentData.RazorPayAPISecretKey}
                              error={RazorPayApiKeySecretError}
                              helperText={RazorPayApiKeySecretError}
                              onChange={handleRazorPayApiKeySecretChange}
                            />
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box>
                            <Typography sx={{ fontSize: "17px" }}>
                              RazorPay API Live KEYID
                            </Typography>
                            <TextField
                              fullWidth
                              sx={{ mt: 0.4, mb: 1.5 }}
                              value={paymentData.RazorPayAPIKeyIdLive}
                              error={RazorPayApiKeyIdError}
                              helperText={RazorPayApiKeyIdError}
                              onChange={handleRazorPayApiKeyIdChangeLive}
                            />
                          </Box>
                          <Box>
                            <Typography sx={{ fontSize: "17px" }}>
                              RazorPay API Live SecretKey
                            </Typography>
                            <TextField
                              fullWidth
                              sx={{ mt: 0.4, mb: 1.5 }}
                              value={paymentData.RazorPayAPISecretKeyLive}
                              error={RazorPayApiKeySecretError}
                              helperText={RazorPayApiKeySecretError}
                              onChange={handleRazorPayApiKeySecretChangeLive}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                  </motion.div>
                )}
              </Card>
            </motion.div>
            <Box>
              <motion.div
                initial={{ height: 50 }}
                animate={{ height: openCardIndex === 2 ? 530 : 50 }}
                exit={{ height: 50 }}
                transition={{ duration: 0.3 }}
              >
                <Card
                  sx={{ border: "1px solid #dddddd", ml: 3, mr: 3 }}
                  elevation={0}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      background: "#333333",
                      p: 1.5,
                      cursor: "pointer",
                    }}
                    onClick={() => handleCardClick(2)}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#fff",
                      }}
                    >
                      Stripe
                    </Typography>
                    {paymentData?.StripeActive === true ? (
                      <Typography sx={{ fontSize: "16px", color: "#65a13d" }}>
                        Active
                      </Typography>
                    ) : (
                      <Typography sx={{ fontSize: "16px", color: "red" }}>
                        IN-Active
                      </Typography>
                    )}
                  </Box>
                  {openCardIndex == 2 && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      transition={{ duration: 0.2 }}
                    >
                      <Box sx={{ width: "450px", ml: 3 }}>
                        <Box
                          sx={{ mt: 1.5 }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "50px",
                          }}
                        >
                          <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">
                              Active
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              <FormControlLabel
                                value="true"
                                control={<Radio />}
                                checked={paymentData.StripeActive === true}
                                onChange={(e) =>
                                  setPaymentData({
                                    ...paymentData,
                                    StripeActive: true,
                                  })
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value="false"
                                control={<Radio />}
                                checked={paymentData.StripeActive === false}
                                onChange={(e) =>
                                  setPaymentData({
                                    ...paymentData,
                                    StripeActive: false,
                                  })
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: "17px" }}>
                            Payment Mode Display on Website
                          </Typography>
                          <TextField
                            fullWidth
                            value={paymentData.StripepaymentModeDispayOnWebsite}
                            error={RazorPayDisplayNameError}
                            helperText={RazorPayDisplayNameError}
                            onChange={handleStripeDisplayNameChange}
                            sx={{ mt: 0.2, mb: 1.5 }}
                          />
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: "17px" }}>
                            Stripe Mode
                          </Typography>
                          <Select
                            sx={{ mt: 0.2, mb: 1.5 }}
                            fullWidth
                            value={paymentData.StripeMode}
                            onChange={(e) =>
                              setPaymentData({
                                ...paymentData,
                                StripeMode: e.target.value,
                              })
                            }
                          >
                            <MenuItem value="Test">Test Mode</MenuItem>
                            <MenuItem value="live">Live Mode</MenuItem>
                          </Select>
                        </Box>
                        {paymentData.StripeMode === "Test" ? (
                          <>
                            <Box>
                              <Typography sx={{ fontSize: "17px" }}>
                                Stripe API Test KEYID
                              </Typography>
                              <TextField
                                fullWidth
                                sx={{ mt: 0.4, mb: 1.5 }}
                                value={paymentData.StripeAPIKeyId}
                                error={RazorPayApiKeyIdError}
                                helperText={RazorPayApiKeyIdError}
                                onChange={handleStripeApiKeyIdChange}
                              />
                            </Box>
                            <Box>
                              <Typography sx={{ fontSize: "17px" }}>
                                Stripe API Test SecretKey
                              </Typography>
                              <TextField
                                fullWidth
                                sx={{ mt: 0.4, mb: 1.5 }}
                                value={paymentData.StripeAPISecretKey}
                                error={RazorPayApiKeySecretError}
                                helperText={RazorPayApiKeySecretError}
                                onChange={handleStripeApiKeySecretChange}
                              />
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box>
                              <Typography sx={{ fontSize: "17px" }}>
                                Stripe API Live KEYID
                              </Typography>
                              <TextField
                                fullWidth
                                sx={{ mt: 0.4, mb: 1.5 }}
                                value={paymentData.StripeAPIKeyIdLive}
                                error={RazorPayApiKeyIdError}
                                helperText={RazorPayApiKeyIdError}
                                onChange={handleStripeApiKeyIdChangeLive}
                              />
                            </Box>
                            <Box>
                              <Typography sx={{ fontSize: "17px" }}>
                                Stripe API Live SecretKey
                              </Typography>
                              <TextField
                                fullWidth
                                sx={{ mt: 0.4, mb: 1.5 }}
                                value={paymentData.StripeAPISecretKeyLive}
                                error={RazorPayApiKeySecretError}
                                helperText={RazorPayApiKeySecretError}
                                onChange={handleStripeApiKeySecretChangeLive}
                              />
                            </Box>
                          </>
                        )}
                      </Box>
                    </motion.div>
                  )}
                </Card>
              </motion.div>
            </Box>
          </AnimatePresence>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default PaymentMethods;
