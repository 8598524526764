import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import CategoryListingTable from "./CategoryListingTable";

const newdata = [
  {
    name: "Navbar",
  },
];

const CategoryListingPage = () => {
  const [data, setData] = useState();
  const [moduleType, setModuleType] = useState();
  const [moduleName, setModuleName] = useState();
  const [image, setImage] = useState(null);
  const [seqNo, setSeqNo] = useState();

  const handleCreatePDPModules = async () => {
    try {
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/CreateCategoryListingModules",
        {
          moduleType: moduleType,
          moduleName: moduleName,
        }
      );
      if (res) {
        console.log(res.data);
        setModuleType("");
        setModuleName("");
        setSeqNo("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ marginTop: "150px" }}>
      <Box>
        <Box sx={{ ml: 3 }}>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            Seller Profile Customization
          </Typography>
        </Box>
        <Box
          sx={{
            ml: 3,
            mt: 2,
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Select
            displayEmpty
            sx={{ width: "300px" }}
            size="small"
            value={moduleType}
            onChange={(e) => setModuleType(e.target.value)}
          >
            <InputLabel sx={{ ml: 2 }}>Select Module Type</InputLabel>
            {newdata.map((item) => (
              <MenuItem value={item.name}>{item.name}</MenuItem>
            ))}
          </Select>
          <TextField
            variant="outlined"
            label="Module Name"
            placeholder="Module Name"
            size="small"
            value={moduleName}
            onChange={(e) => setModuleName(e.target.value)}
            sx={{ width: "300px" }}
          />

          <Button variant="contained" onClick={handleCreatePDPModules}>
            SUBMIT
          </Button>
        </Box>
      </Box>
      <Box>
        <CategoryListingTable newdata={newdata} />
      </Box>
    </div>
  );
};

export default CategoryListingPage;
