import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, IconButton } from "@mui/material";
import { TiTick } from "react-icons/ti";
import axios from "axios";
import { useParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";

export default function ManageProductsTableSelected({ data, setRefresh1 }) {
  const { id } = useParams();
  const handleDelete = async (productId) => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/handleDeleteManageProducts/${id}/${productId}`
      );

      if (res.data.message) {
        // Update the data state by removing the deleted product
        setRefresh1(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 500, minHeight: "100%" }}
        aria-label="simple table"
      >
        <TableHead sx={{ background: "#eee" }}>
          <TableRow>
            <TableCell
              align="left"
              sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
            >
              Image
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
            >
              SKUCode
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
            >
              Name
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
            >
              UniqueUrl
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
            >
              Select
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => {
            if (row.active) {
              return (
                <TableRow key={index}>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #dddd" }}
                  >
                    <Box sx={{ width: "60px" }}>
                      <img
                        src={row.MainImage}
                        alt={row.Title}
                        width="100%"
                        height="100%"
                      />
                    </Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #dddd" }}
                  >
                    {row.SKUCode}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #dddd" }}
                  >
                    {row.Title.slice(0, 10)}...
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #dddd" }}
                  >
                    {row.UniqueUrl.slice(0, 20)}...
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #dddd" }}
                  >
                    <IconButton onClick={() => handleDelete(row._id)}>
                      <MdDelete style={{ color: "red" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
