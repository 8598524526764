import { Box, Button, Card, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const CreateNewAttributes4 = () => {
  const [name, setName] = useState();
  const [sortingId, setSortingId] = useState();

  const { id } = useParams();
  const navigate = useNavigate();

  const handleCreateModelAttributes = async () => {
    try {
      const res = await axios.post(
        `https://rccore-backend.cstechns.com/api/admin/CreateAttributes4`,
        {
          name,
          sortingId,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Attributes Created SuccessFully",
          showConfirmButton: true,
          timer: 1500,
        });
        navigate(`/manage-attributes4/${id}`);
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };
  return (
    <div style={{ marginTop: "150px" }}>
      <Card
        sx={{ maxWidth: "450px", p: 2 }}
        style={{ margin: "auto", marginTop: "30px" }}
      >
        <Typography
          style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}
        >
          Create New Attribute
        </Typography>
        <Box>
          <TextField
            label="Name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          />

          <TextField
            label="Sorting Id"
            placeholder="Sorting Id"
            value={sortingId}
            onChange={(e) => setSortingId(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Box>
        <Button
          variant="contained"
          fullWidth
          onClick={handleCreateModelAttributes}
        >
          SUBMIT
        </Button>
      </Card>
    </div>
  );
};

export default CreateNewAttributes4;
