import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  CreateSellerNavigation,
  handleGetAllSellerStaticPages,
} from "../../../axios";
import { useEffect } from "react";

const CreateNewSellerFooterNavigation = () => {
  const [defaultColumn, setDefaultColumn] = useState("Footer Column 1");
  const [NavigationFor, setNavigationFor] = useState("Seller Header");
  const [defaultMenu, setDefaultMenu] = useState("Label");
  const [Heading, setHeading] = useState("");
  const [Link, setLink] = useState("");
  const [SortingOrder, setSortingOrder] = useState("");
  const [ShowNew, setShowNew] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState();
  const [newData, setNewData] = useState({});

  const navigate = useNavigate();

  const handleGetAllStaticPages = async () => {
    try {
      const res = await handleGetAllSellerStaticPages();
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateSellerNavigation = async () => {
    setLoading(true);
    try {
      const res = await CreateSellerNavigation(
        NavigationFor,
        defaultColumn,
        selected,
        defaultMenu,
        newData.Heading,
        newData.Page,
        newData.Link,
        SortingOrder,
        ShowNew
      );

      console.log(res);

      if (res.message) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Created Successfully",
          showConfirmButton: true,
        });
        navigate("/seller-navigation");
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response,
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllStaticPages();
  }, []);

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          background: isFixed ? "#000" : "transparent",
          p: 1.2,
          position: isFixed ? "fixed" : "relative",
          top: isFixed && 130,
          left: 0,
          right: 0,
          zIndex: 999,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ml: 3,
            mr: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: isFixed ? "white" : "black",
            }}
          >
            Navigation Manage
          </Typography>
          <Button
            variant="contained"
            onClick={handleCreateSellerNavigation}
            disabled={Loading}
          >
            {Loading ? <CircularProgress size={24} /> : "SUBMIT"}
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Card
          sx={{ border: "1px solid #ddd", width: "450px", p: 2 }}
          elevation={0}
        >
          <Box>
            <Box>
              <Typography>Navigation For </Typography>
              <Select
                fullWidth
                size="small"
                sx={{ mt: 0.5, mb: 2 }}
                value={NavigationFor}
                onChange={(e) => setNavigationFor(e.target.value)}
                displayEmpty
              >
                <InputLabel sx={{ ml: 2 }}></InputLabel>
                <MenuItem value="Seller Header">Seller Header</MenuItem>
                <MenuItem value="Seller Footer">Seller Footer</MenuItem>
              </Select>
            </Box>
            <Box>
              <Typography>Menu Type</Typography>
              <Select
                fullWidth
                size="small"
                sx={{ mt: 0.5, mb: 2 }}
                value={defaultMenu}
                onChange={(e) => setDefaultMenu(e.target.value)}
              >
                <MenuItem value="Label">Label</MenuItem>
                <MenuItem value="Static Page">Static Page</MenuItem>
              </Select>
            </Box>
            {NavigationFor === "Seller Header" &&
              defaultMenu === "Static Page" && (
                <Box>
                  <Typography>Select Static Page</Typography>
                  <Select
                    fullWidth
                    size="small"
                    sx={{ mt: 0.5, mb: 2 }}
                    value={selected}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const matchingItem = data.find(
                        (item) => item.Heading === selectedValue
                      );

                      if (matchingItem) {
                        setNewData(matchingItem);
                      }

                      setSelected(selectedValue);
                    }}
                    displayEmpty
                  >
                    <InputLabel sx={{ ml: 2 }}>Select Static Page</InputLabel>
                    {data.map((item) => {
                      if (item.PageFor === "Header") {
                        return (
                          <MenuItem key={item.Heading} value={item.Heading}>
                            {item.Heading}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </Select>
                </Box>
              )}
            {NavigationFor === "Seller Footer" &&
              defaultMenu === "Static Page" && (
                <Box>
                  <Typography>Select Static Page</Typography>
                  <Select
                    fullWidth
                    size="small"
                    sx={{ mt: 0.5, mb: 2 }}
                    value={selected}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const matchingItem = data.find(
                        (item) => item.Heading === selectedValue
                      );

                      if (matchingItem) {
                        setNewData(matchingItem);
                      }

                      setSelected(selectedValue);
                    }}
                    displayEmpty
                  >
                    <InputLabel sx={{ ml: 2 }}>Select Static Page</InputLabel>
                    {data.map((item) => {
                      if (item.PageFor === "Footer") {
                        return (
                          <MenuItem key={item.Heading} value={item.Heading}>
                            {item.Heading}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </Select>
                </Box>
              )}
            {NavigationFor !== "Seller Header" && (
              <Box>
                <Typography>Footer Column</Typography>
                <Select
                  fullWidth
                  size="small"
                  sx={{ mt: 0.5, mb: 2 }}
                  value={defaultColumn}
                  onChange={(e) => setDefaultColumn(e.target.value)}
                >
                  <MenuItem value="Footer Column 1">Footer Column 1</MenuItem>
                  <MenuItem value="Footer Column 2">Footer Column 2</MenuItem>
                  <MenuItem value="Footer Column 3">Footer Column 3</MenuItem>
                  <MenuItem value="Footer Column 4">Footer Column 4</MenuItem>
                </Select>
              </Box>
            )}

            <Box>
              <Typography>Heading</Typography>
              <TextField
                size="small"
                placeholder="Heading"
                fullWidth
                value={newData.Heading}
                sx={{ mt: 0.5, mb: 2 }}
              />
            </Box>
            <Box>
              <Typography>Page URL</Typography>
              <TextField
                size="small"
                placeholder="Page URL"
                fullWidth
                value={newData ? newData.Page : data?.PageUrl}
                sx={{ mt: 0.5, mb: 2 }}
              />
            </Box>
            {defaultMenu === "Static Page" && (
              <Box>
                <Typography>Link</Typography>
                <TextField
                  size="small"
                  placeholder="Link"
                  fullWidth
                  value={newData.Link}
                  sx={{ mt: 0.5, mb: 2 }}
                />
              </Box>
            )}
            <Box>
              <Typography>Sorting Order</Typography>
              <TextField
                size="small"
                placeholder="Sorting Order"
                fullWidth
                value={SortingOrder}
                onChange={(e) => setSortingOrder(e.target.value)}
                sx={{ mt: 0.5, mb: 2 }}
              />
            </Box>
            <Box>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Show New
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={ShowNew}
                  onChange={(e) => setShowNew(e.target.value)}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Card>
      </Box>
    </div>
  );
};

export default CreateNewSellerFooterNavigation;
