import {
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../Redux/actions/AuthActions";
import logo from "../images/logo.png";
import { AdminLogin } from "../axios";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmail(e.target.value);
    setEmailError(!emailPattern.test(e.target.value));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);

    if (e.target.value === "") {
      setPasswordError("Password is required");
    } else {
      setPasswordError("");
    }
  };

  const handleLogin = async () => {
    setIsLoading(true);
    if (!email && !password) {
      setEmailError("Email is required");
      setPasswordError("Password is required");
    }
    try {
      const res = await AdminLogin(email, password);
      if (res.status === 200) {
        localStorage.setItem("token", res.data.token);
        dispatch(login(res.data.token, res.data));
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Logged in Successfully",
          showConfirmButton: true,
        });
        navigate("/dashboard");
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box sx={{ mb: 1.5 }}>
        <img src={logo} alt={logo} style={{ width: "120px" }} />
      </Box>
      <Card
        sx={{
          maxWidth: "450px",
          p: 2,
          maxHeight: "450px",
        }}
        style={{
          border: "1px solid #dddddd",
          padding: "50px",
        }}
        elevation={0}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          Sign in with your ID
        </Typography>

        <Box>
          <TextField
            variant="outlined"
            type="email"
            label="Email"
            placeholder="Email"
            inputProps={{ style: { fontSize: 15, color: "#333" } }}
            fullWidth
            value={email}
            onChange={handleEmailChange}
            sx={{ mt: 1.5, mb: 1.5 }}
            error={emailError}
            helperText={emailError && "Invalid email address"}
          />
          <TextField
            variant="outlined"
            type="password"
            label="Password"
            placeholder="Password"
            inputProps={{ style: { fontSize: 15, color: "#333" } }}
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            error={passwordError}
            helperText={passwordError}
            sx={{ mt: 1.5, mb: 1.5 }}
          />
        </Box>
        <Button
          variant="contained"
          fullWidth
          sx={{ p: 1.5, mt: 1.5, fontSize: "18px", mb: 2 }}
          onClick={handleLogin}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "LOGIN"}
        </Button>
        <Typography sx={{ fontSize: "17px" }}>
          <Link
            to="/forgot-password"
            style={{
              color: "#0099cc",
              textDecoration: "none",
            }}
          >
            Forgot Password
          </Link>
        </Typography>
      </Card>
      <Typography sx={{ fontSize: "14px", mt: 2 }}>
        Copyright © 2023, SkillShop.in, All Rights Reserved
      </Typography>
    </Box>
  );
};

export default Login;
