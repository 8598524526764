import React from "react";
import MyProductListTable from "./ProductListTable";

const ProductList = () => {
  return (
    <div>
      <MyProductListTable />
    </div>
  );
};

export default ProductList;
