import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { TiTick } from "react-icons/ti";

export default function UnselectedProducts() {
  const [data, setData] = React.useState([]);
  const [selected, setSelected] = React.useState(false);
  const [selectedHide, setSelectedHide] = React.useState(false);

  const { id } = useParams();

  const getSingleListingData = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/get-all-Products`
      );
      if (res) {
        // console.log(res.data);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelectFrequentlyBought = async (id) => {
    try {
      const updatedData = data.map((row) => {
        if (row._id === id) {
          return {
            ...row,
            selected: !row.selected,
            selectedHide: !row.selectedHide,
          };
        }
        return row;
      });
      setData(updatedData);
      console.log(updatedData);
      console.log(id);
      await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/update-single-product/${id}`,
        {
          selected: updatedData.selected,
          selectedHide: updatedData.selectedHide,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getSingleListingData();
  }, []);
  return (
    <TableContainer
      component={Paper}
      sx={{ border: "1px solid #dddddd" }}
      elevation={0}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ background: "#dddddd" }}>
          <TableRow>
            <TableCell align="center">Image</TableCell>
            <TableCell align="center">SKU Code</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Unique URL</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            if (row.hide == false) {
              if (row.selected !== true && row.selectedHide !== true) {
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">--</TableCell>
                    <TableCell align="center">{row.SKUCode}</TableCell>
                    <TableCell align="center">{row.Title}</TableCell>
                    <TableCell align="center">{row.UniqueUrl}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => handleSelectFrequentlyBought(row._id)}
                      >
                        <TiTick style={{ color: "green" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              }
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
