import axios from "axios";
import React, { useEffect, useState } from "react";
import EmailSettingsTable from "./EmailSettingsTable";

const EmailSettings = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const handleAllEmailSettings = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllEmailSettings",
        config
      );

      if (res) {
        setData(res.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleAllEmailSettings();
  }, []);
  return (
    <div style={{ marginTop: "150px" }}>
      <EmailSettingsTable
        data={data}
        loading={loading}
        setLoading={setLoading}
        setData={setData}
      />
    </div>
  );
};

export default EmailSettings;
