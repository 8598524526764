import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UnselectedItems from "./UnselectedItems";
import SelectedItems from "./SelectedProducts";

import Swal from "sweetalert2";
import { createNewCouponCOdes } from "../../../axios";

const SelectItemsForCouponCode = ({ Products }) => {
  const location = useLocation();

  const CouponData = location.state?.couponData;

  const [selectedItems, setSelectedItems] = useState([]);
  const [newSelectedItems, setNewSelectedItems] = useState([]);
  const [selectProductItems, setSelectProductItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    return formattedDate;
  }

  const navigate = useNavigate();

  const handleCreateNewCouponCode = async () => {
    try {
      const res = await createNewCouponCOdes(
        CouponData.CouponCode,
        CouponData.selected,
        CouponData.AmountCapping,
        CouponData.CouponType,
        CouponData.Discount,
        formatDate(CouponData.ValidityStart.$d),
        formatDate(CouponData.ValidityEnd.$d),
        CouponData.Description,
        CouponData.selected1,
        CouponData.TotalCoupon,
        CouponData.userCoupon,
        newSelectedItems.map((item) => {
          return item._id;
        })
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Coupon Code Created Successfully",
          showConfirmButton: true,
        });
        navigate("/coupon-codes");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectSelectedItems = () => {
    setNewSelectedItems((prevSelectedItems) => [
      ...prevSelectedItems,
      ...selectedItems,
    ]);
  };

  const handleRemoveSelectedItems = () => {
    setNewSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter(
        (item) =>
          !selectProductItems.some((newItem) => newItem._id === item._id)
      )
    );
  };

  const filteredProducts = Products.filter((item) => {
    const hasMatchingId = newSelectedItems.some(
      (Newitem) => item._id === Newitem._id
    );

    return (
      !hasMatchingId &&
      (item.BrandName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.createdBy.fullName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.SKUCode.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  });
  const filteredSelectedItems = newSelectedItems.filter((item) => {
    const hasMatchingId = selectProductItems.some(
      (Newitem) => item._id === Newitem._id
    );

    return (
      !hasMatchingId &&
      (item.BrandName.toLowerCase().includes(searchQuery1.toLowerCase()) ||
        item.createdBy.fullName
          .toLowerCase()
          .includes(searchQuery1.toLowerCase()) ||
        item.SKUCode.toLowerCase().includes(searchQuery1.toLowerCase()))
    );
  });

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Coupon Code - {CouponData?.CouponCode}
        </Typography>
        <Button variant="contained" onClick={handleCreateNewCouponCode}>
          Submit
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ px: 3, mt: 3 }}>
        <Grid item xs={12} lg={6} xl={6}>
          <Box sx={{ border: "1px solid #ddd" }}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
                Unselected Products
              </Typography>
              <Button variant="contained" onClick={handleSelectSelectedItems}>
                Add Multiple
              </Button>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography>
                Search By Brand, Seller Name, Sku Code, Categories
              </Typography>
              <TextField
                size="small"
                placeholder="Search By Brand, Seller Name, Sku Code, Categories"
                sx={{ width: "400px", mt: 0.5 }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Box>
            <UnselectedItems
              Products={filteredProducts}
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} xl={6}>
          <Box sx={{ border: "1px solid #ddd" }}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
                Selected Products
              </Typography>
              <Button variant="contained" onClick={handleRemoveSelectedItems}>
                Remove Multiple
              </Button>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography>
                Search By Brand, Seller Name, Sku Code, Categories
              </Typography>
              <TextField
                size="small"
                placeholder="Search By Brand, Seller Name, Sku Code, Categories"
                sx={{ width: "400px", mt: 0.5 }}
                value={searchQuery1}
                onChange={(e) => setSearchQuery1(e.target.value)}
              />
            </Box>
            <SelectedItems
              Products={filteredSelectedItems}
              setSelectProductItems={setSelectProductItems}
              selectProductItems={selectProductItems}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectItemsForCouponCode;
