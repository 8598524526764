import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ProductTab from "./ProductTabs";

const ManageSingleProduct = () => {
  return (
    <div>
      <Box>
        <Typography sx={{ fontSize: "23px", fontWeight: "bold", m: 3 }}>
          Product's Manage
        </Typography>
      </Box>
      <ProductTab />
    </div>
  );
};

export default ManageSingleProduct;
