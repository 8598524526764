import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  handleGetAllSellerStaticPages,
  handleGetSellerNAvigationById,
  handleSellerNavigationUpdateById,
} from "../../../axios";

const EditSellerNavigation = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);
  const [Loading2, setLoading2] = useState(false);
  const [newData, setNewData] = useState();
  const [staticPages, setstaticPges] = useState([]);

  console.log(data);

  console.log(data);
  const navigate = useNavigate();

  const handleGetAllStaticPages = async () => {
    setLoading2(true);
    try {
      const res = await handleGetAllSellerStaticPages();
      if (res) {
        setstaticPges(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading2(false);
    }
  };

  const handleGetSellerNavDataById = async () => {
    setLoading(true);
    try {
      const res = await handleGetSellerNAvigationById(id);
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateFooter = async () => {
    setLoading1(true);
    try {
      const res = await handleSellerNavigationUpdateById(
        id,
        data.NavigationFor,
        data.FooterColumn,
        data.staticPage,
        data.MenuType,
        newData ? newData.Heading : data.Heading,
        newData ? newData.Page : data.PageUrl,
        newData ? newData.Link : data.Link,
        data.SortingOrder,
        data.ShowNew
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated Successfully",
          showConfirmButton: true,
        });
        navigate("/seller-navigation");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    handleGetSellerNavDataById();
    handleGetAllStaticPages();
  }, []);

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {!Loading && !Loading2 ? (
        <div style={{ marginTop: "150px" }}>
          <Box
            sx={{
              background: isFixed ? "#000" : "transparent",
              p: 1.2,
              position: isFixed ? "fixed" : "relative",
              top: isFixed && 130,
              left: 0,
              right: 0,
              zIndex: 999,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                ml: 3,
                mr: 3,
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: isFixed ? "white" : "black",
                }}
              >
                Navigation Manage
              </Typography>
              <Button
                variant="contained"
                onClick={handleUpdateFooter}
                disabled={Loading1}
              >
                {Loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
            <Card
              sx={{ border: "1px solid #ddd", width: "450px", p: 2 }}
              elevation={0}
            >
              <Box>
                <Box>
                  <Typography>Navigation For </Typography>
                  <Select
                    fullWidth
                    size="small"
                    sx={{ mt: 0.5, mb: 2 }}
                    value={data?.NavigationFor || ""}
                    onChange={(e) =>
                      setData({ ...data, NavigationFor: e.target.value })
                    }
                    displayEmpty
                  >
                    <InputLabel sx={{ ml: 2 }}>Select</InputLabel>
                    <MenuItem value="Seller Header">Seller Header</MenuItem>
                    <MenuItem value="Seller Footer">Seller Footer</MenuItem>
                  </Select>
                </Box>
                <Box>
                  <Typography>Menu Type</Typography>
                  <Select
                    fullWidth
                    size="small"
                    sx={{ mt: 0.5, mb: 2 }}
                    value={data?.MenuType || ""}
                    onChange={(e) =>
                      setData({ ...data, MenuType: e.target.value })
                    }
                  >
                    <MenuItem value="Label">Label</MenuItem>
                    <MenuItem value="Static Page">Static Page</MenuItem>
                  </Select>
                </Box>
                {data.NavigationFor === "Seller Header" &&
                  data.MenuType === "Static Page" && (
                    <Box>
                      <Typography>Select Static Page</Typography>
                      <Select
                        fullWidth
                        size="small"
                        sx={{ mt: 0.5, mb: 2 }}
                        value={data.staticPage}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          const matchingItem = staticPages.find(
                            (item) => item.Heading === selectedValue
                          );

                          if (matchingItem) {
                            setNewData(matchingItem);
                          }

                          setData({ ...data, staticPage: selectedValue });
                        }}
                        displayEmpty
                      >
                        <InputLabel sx={{ ml: 2 }}>
                          Select Static Page
                        </InputLabel>
                        {staticPages.map((item) => {
                          if (item.PageFor === "Header") {
                            return (
                              <MenuItem key={item.Heading} value={item.Heading}>
                                {item.Heading}
                              </MenuItem>
                            );
                          }
                          return null;
                        })}
                      </Select>
                    </Box>
                  )}
                {data.NavigationFor !== "Seller Header" && (
                  <Box>
                    <Typography>Footer Column</Typography>
                    <Select
                      fullWidth
                      size="small"
                      sx={{ mt: 0.5, mb: 2 }}
                      value={data.FooterColumn}
                      onChange={(e) =>
                        setData({ ...data, FooterColumn: e.target.value })
                      }
                    >
                      <MenuItem value="Footer Column 1">
                        Footer Column 1
                      </MenuItem>
                      <MenuItem value="Footer Column 2">
                        Footer Column 2
                      </MenuItem>
                      <MenuItem value="Footer Column 3">
                        Footer Column 3
                      </MenuItem>
                      <MenuItem value="Footer Column 4">
                        Footer Column 4
                      </MenuItem>
                    </Select>
                  </Box>
                )}

                <Box>
                  <Typography>Heading</Typography>
                  <TextField
                    size="small"
                    placeholder="Heading"
                    fullWidth
                    value={newData ? newData.Heading : data?.Heading}
                    sx={{ mt: 0.5, mb: 2 }}
                  />
                </Box>
                <Box>
                  <Typography>Page URL</Typography>
                  <TextField
                    size="small"
                    placeholder="Page URL"
                    fullWidth
                    value={newData ? newData.Page : data?.PageUrl}
                    sx={{ mt: 0.5, mb: 2 }}
                  />
                </Box>
                {data.MenuType === "Static Page" && (
                  <Box>
                    <Typography>Link</Typography>
                    <TextField
                      size="small"
                      placeholder="Link"
                      fullWidth
                      value={newData ? newData.Link : data?.Link}
                      sx={{ mt: 0.5, mb: 2 }}
                    />
                  </Box>
                )}
                <Box>
                  <Typography>Sorting Order</Typography>
                  <TextField
                    size="small"
                    placeholder="Sorting Order"
                    fullWidth
                    value={data.SortingOrder}
                    onChange={(e) =>
                      setData({ ...data, SortingOrder: e.target.value })
                    }
                    sx={{ mt: 0.5, mb: 2 }}
                  />
                </Box>
                <Box>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Show New
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value={data?.ShowNew}
                        checked={data.ShowNew === true}
                        onChange={(e) => setData({ ...data, ShowNew: true })}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={data?.ShowNew}
                        checked={data.ShowNew === false}
                        onChange={(e) => setData({ ...data, ShowNew: false })}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            </Card>
          </Box>
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default EditSellerNavigation;
