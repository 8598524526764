import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const LogisticProviderEdit = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleLogisticProviderById = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getLogisticProviderById/${id}`,
        config
      );
      if (res) {
        // console.log("logistic", res.data);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //handle LogisticProvider Edit

  const handleLogisticProviderEdit = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/EditLogisticProvider/${id}`,
        data,
        config
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Data Updated SuccessFully",
          showConfirmButton: true,
        });
        navigate("/LogisticProviders");
      }
    } catch (error) {
      //   console.log(error);
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    handleLogisticProviderById();
  }, []);
  return (
    <div style={{ marginTop: "150px" }}>
      {!loading ? (
        <Card
          sx={{ maxWidth: "450px", p: 2 }}
          style={{ margin: "auto", marginTop: "30px" }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              mb: 2,
              textAlign: "center",
            }}
          >
            Edit Logistic Provider
          </Typography>
          <Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>
                Courier Company Name
              </Typography>
              <TextField
                placeholder="Courier Company Name"
                value={data.CourierCompanyName}
                onChange={(e) =>
                  setData({ ...data, CourierCompanyName: e.target.value })
                }
                fullWidth
                sx={{ mt: 0.5, mb: 2 }}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>Contact Person</Typography>
              <TextField
                placeholder="Contact Person"
                value={data.ContactPerson}
                onChange={(e) =>
                  setData({ ...data, ContactPerson: e.target.value })
                }
                fullWidth
                sx={{ mt: 0.5, mb: 2 }}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>
                Contact Email id
              </Typography>
              <TextField
                placeholder="Contact Email id"
                value={data.ContactEmailId}
                onChange={(e) =>
                  setData({ ...data, ContactEmailId: e.target.value })
                }
                fullWidth
                sx={{ mt: 0.5, mb: 2 }}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>
                Contact Phone Number
              </Typography>
              <TextField
                placeholder="Contact Phone Number"
                value={data.ContactPhoneNumber}
                onChange={(e) =>
                  setData({ ...data, ContactPhoneNumber: e.target.value })
                }
                fullWidth
                sx={{ mb: 2 }}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>
                Tracking URL (enter url with http://)
              </Typography>
              <TextField
                placeholder="Tracking URL (enter url with http://)"
                value={data.TrackingURL}
                onChange={(e) =>
                  setData({ ...data, TrackingURL: e.target.value })
                }
                fullWidth
                sx={{ mb: 2 }}
              />
            </Box>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Self Shipping
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Yes"
                  checked={data.selfShipping === true}
                  onChange={(e) => setData({ ...data, selfShipping: true })}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                  checked={data.selfShipping === false}
                  onChange={(e) => setData({ ...data, selfShipping: false })}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 1, mb: 1 }}
            onClick={handleLogisticProviderEdit}
          >
            SUBMIT
          </Button>
        </Card>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default LogisticProviderEdit;
