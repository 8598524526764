import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useState } from "react";
import { useEffect } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function ManageProductTypeTable() {
  const [data, setData] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [visibility, setVisibility] = React.useState({});

  const { id } = useParams();
  const navigate = useNavigate();

  const handleBrandAttributes = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllProductTypeAttributes"
      );
      if (res) {
        const visibilityObj = {};
        res.data.forEach((row) => {
          visibilityObj[row._id] = !row.hide;
        });
        setData(res.data);
        setVisibility(visibilityObj);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVisibility = async (id, value) => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/UpdateProductTypeAttributes/${id}`,
        {
          hide: value,
        }
      );
      if (res) {
        setVisibility((prevState) => ({
          ...prevState,
          [id]: !value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllProductTypeValues = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getAllProductTypesValues`
      );
      if (res && data) {
        const ProductTypes = data.map((productType) => productType.name);
        const ProductTypeValues = res.data.filter((item) =>
          ProductTypes.includes(item.ProductType)
        );
        setProductsList(ProductTypeValues);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteProductTypeAttributes = async (id) => {
    try {
      const res = await axios.delete(
        `https://rccore-backend.cstechns.com/api/admin/DeleteProductTypeAttribute/${id}`
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });
        setData(data.filter((item) => item._id !== id));
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };

  React.useEffect(() => {
    handleBrandAttributes();
  }, []);

  useEffect(() => {
    handleGetAllProductTypeValues();
  }, [data]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data.filter((row) =>
    row.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div>
      {!loading ? (
        <>
          <TextField
            label="Search"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearch}
            size="small"
            sx={{ ml: 3, width: "400px", mb: 2 }}
          />
          <TableContainer component={Paper} sx={{ width: "96.5%", ml: 3 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "#dddddd" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>S.No</TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Attribute Name
                  </TableCell>
                  {/* <TableCell align="center" style={{ fontWeight: "bold" }}>
              Image
            </TableCell> */}
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Sort Id
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    {/* <TableCell align="center"></TableCell> */}
                    <TableCell align="center">{row.sortId}</TableCell>
                    <TableCell align="center">
                      {productsList.map((product) => {
                        if (product.ProductType === row.name) {
                          return (
                            <>
                              {visibility[row._id] ? (
                                <>
                                  <Tooltip title="De-Activate">
                                    <IconButton
                                      onClick={() =>
                                        handleVisibility(
                                          row._id,
                                          visibility[row._id]
                                        )
                                      }
                                    >
                                      <VisibilityIcon
                                        style={{ color: "green" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              ) : (
                                <>
                                  <Tooltip title="Activate">
                                    <IconButton
                                      onClick={() =>
                                        handleVisibility(
                                          row._id,
                                          visibility[row._id]
                                        )
                                      }
                                    >
                                      <VisibilityOffIcon
                                        style={{ color: "red" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </>
                          );
                        }
                      })}
                      {productsList.some(
                        (product) => product.ProductType === row.name
                      ) ? null : (
                        <>
                          <IconButton>
                            <EditIcon
                              onClick={() =>
                                navigate(
                                  `/update-ProductType-attributes/${row._id}`
                                )
                              }
                            />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon
                              onClick={() =>
                                handleDeleteProductTypeAttributes(row._id)
                              }
                              style={{ color: "red" }}
                            />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
