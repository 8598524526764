import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { deleteStoreWise, getAllStoreWise } from "../../../axios";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";

export default function StoreWiseTable({ refresh, setLoading3 }) {
  const [data, setData] = React.useState([]);
  const [Loading, setLoading] = React.useState(false);

  const handleGetAllStoreWise = async () => {
    setLoading(true);
    try {
      const res = await getAllStoreWise();
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteStoreWise = async (id) => {
    try {
      const res = await deleteStoreWise(id);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted Store Wise Successfully",
          showConfirmButton: true,
        });
        setData(data.filter((item) => item._id !== id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    handleGetAllStoreWise();
  }, [refresh]);
  return (
    <>
      {!Loading ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ background: "#eee" }}>
              <TableRow>
                <TableCell
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  S.No
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Date From
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Date To
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Amount
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Type
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Discount
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.DateFrom}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.DateTo}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.orderAmount}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.DiscountIn}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.Discount}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    <Tooltip title="Edit">
                      <IconButton>
                        <FiEdit size={20} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleDeleteStoreWise(row._id)}
                      >
                        <MdDelete size={20} style={{ color: "red" }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
