import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { Button, Checkbox, TextField, Typography } from "@mui/material";
import Swal from "sweetalert2";

const columns = [
  { id: "S.No", label: "S.No", minWidth: 70 },
  {
    id: "ProductParticular",
    label: "Product Particular",
    minWidth: 170,
    align: "center",
  },
  {
    id: "MRP",
    label: "MRP",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "OfferPrice",
    label: "Offer Price (inc GST)",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "taxonProduct",
    label: "Tax on Product (inclusive)",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Commission",
    label: "Commission",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },

  {
    id: "ClosingFee ",
    label: "Closing Fee ",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "shippingcharges",
    label: "Shipping Charges(Subjective to Changes)",
    minWidth: 190,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "paymentprocessing",
    label: "Payment Processing Charges",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "GST",
    label: "GST",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "sellerpayout",
    label: "Seller Payout",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "AvailStock",
    label: "Avail Stock ",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "AddStock",
    label: "Add Stock ",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "RemoveStock",
    label: "Remove Stock ",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "MakeDefaultSet ",
    label: "Make Default Set ",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

export default function ApprovePriceTable({
  priceData,
  IGST,
  CGST,
  SGST,
  IgstValue,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { id } = useParams();
  const [data, setData] = React.useState({});
  const [colors, setColors] = React.useState([]);
  const [Sizes, setSizes] = React.useState([]);
  const [selectedIndices, setSelectedIndices] = React.useState([]);

  const [addStocks, setAddStocks] = React.useState(
    Array(colors.length * Sizes.length).fill(0)
  );

  const [removeStocks, setRemoveStocks] = React.useState(
    Array(colors.length * Sizes.length).fill(0)
  );

  const navigate = useNavigate();

  const getSingleListingData = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/get-single-product/${id}`
      );
      if (res) {
        setData(res.data);
        setColors(res.data.data.Colors);
        setSizes(res.data.data.Sizes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    getSingleListingData();
  }, []);

  const commission = priceData.offerPrice
    ? priceData.offerPrice / IgstValue
    : data.offerPrice / IgstValue;
  console.log(commission);

  const SellerPayout = priceData.offerPrice
    ? priceData.offerPrice - (commission + IgstValue + 60 + 20 + 34.2)
    : data.offerPrice - (commission + IgstValue + 60 + 20 + 34.2);

  // console.log("price", priceData);

  const handleUpdateProductPrice = async () => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/update-single-product/${id}`,
        {
          mrp: priceData.mrp,
          offerPrice: priceData.offerPrice,
          IGST: IGST,
          CGST: CGST,
          SGST: SGST,
          taxonProduct: IGST,
          commission: commission,
          addstock: data.addstock - removeStocks,
          removeStock: removeStocks,
          makeDefault: data.makeDefault,
          sellerPayout: SellerPayout,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: " Updated successfully",
          showConfirmButton: true,
        });
        navigate("/products-list");
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };

  return (
    <>
      <Paper
        sx={{
          width: "96.5%",
          overflow: "hidden",
          ml: 3,
          border: "1px solid #dddddd",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      background: "#dddddd",
                      fontWeight: "bold",
                      fontSize: "12px",
                      borderRight: "1px solid ",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {colors.map((item, colorIndex) =>
                Sizes.map((row, sizeIndex) => {
                  const index = colorIndex * Sizes.length + sizeIndex;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      <TableCell
                        sx={{ borderRight: "1px solid" }}
                        align="center"
                      ></TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid" }}
                        align="center"
                      >
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <Typography sx={{ fontSize: "13px" }}>
                              SKUCODE:
                            </Typography>
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: "bold" }}
                            >
                              {data.SKUCode}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <Typography sx={{ fontSize: "13px" }}>
                              Name:
                            </Typography>
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: "bold" }}
                            >
                              {data.Title}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <Typography sx={{ fontSize: "13px" }}>
                              Color:
                            </Typography>
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: "bold" }}
                            >
                              {item}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <Typography sx={{ fontSize: "13px" }}>
                              Size:
                            </Typography>
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: "bold" }}
                            >
                              {row}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid " }}
                        align="center"
                      >
                        <TextField
                          defaultValue={0}
                          size="small"
                          value={
                            priceData.mrp ? priceData.mrp : data.mrp || "0"
                          }
                        />
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid " }}
                        align="center"
                      >
                        <TextField
                          defaultValue={0}
                          size="small"
                          value={
                            priceData.offerPrice
                              ? priceData.offerPrice
                              : data.offerPrice || "0"
                          }
                        />
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid " }}
                        align="center"
                      >
                        <Typography>
                          {IGST ? IGST : data.IGST || "0"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid " }}
                        align="center"
                      >
                        <Typography>
                          {commission ? commission : data.commission}
                        </Typography>
                      </TableCell>

                      <TableCell
                        sx={{ borderRight: "1px solid " }}
                        align="center"
                      >
                        <Typography>{IgstValue}</Typography>
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid " }}
                        align="center"
                      >
                        <Typography>60</Typography>
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid " }}
                        align="center"
                      >
                        <Typography>20</Typography>
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid " }}
                        align="center"
                      >
                        <Typography>34.20</Typography>
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid " }}
                        align="center"
                      >
                        <Typography>
                          {SellerPayout ? SellerPayout : data.sellerPayout}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid " }}
                        align="center"
                      >
                        <Typography>{data?.addstock?.[index] ?? ""}</Typography>
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid " }}
                        align="center"
                      >
                        <TextField
                          size="small"
                          value={data?.addstock?.[index] ?? ""}
                          onChange={(e) => {
                            setData((prevData) => {
                              const addstock = prevData?.addstock ?? [];
                              return {
                                ...prevData,
                                addstock: [
                                  ...addstock.slice(0, index),
                                  e.target.value,
                                  ...addstock.slice(index + 1),
                                ],
                              };
                            });
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid " }}
                        align="center"
                      >
                        <TextField
                          size="small"
                          value={removeStocks[index]}
                          onChange={(e) => {
                            const newRemoveStocks = [...removeStocks];
                            newRemoveStocks[index] = e.target.value;
                            setRemoveStocks(newRemoveStocks);
                          }}
                        />
                      </TableCell>

                      <TableCell
                        sx={{ borderRight: "1px solid " }}
                        align="center"
                      >
                        <Checkbox
                          checked={data.makeDefault[index] == true}
                          onChange={() => {
                            setData((prevData) => ({
                              ...prevData,
                              makeDefault: [
                                ...prevData.makeDefault.slice(0, index),
                                true,
                                ...prevData.makeDefault.slice(index + 1),
                              ],
                            }));
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={colors.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Box sx={{ ml: 4, mt: 2, mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mr: 3,
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "14px" }}>NOTE : </Typography>
          <Button variant="contained" onClick={handleUpdateProductPrice}>
            SUBMIT
          </Button>
        </Box>
        <Typography sx={{ fontSize: "15px" }}>
          *The above shipping charges are approximate and will be modified as
          per the customer location and package weight or volumetric weight
          whichever is higher at the time of order processing.{" "}
        </Typography>
        <Typography sx={{ fontSize: "15px" }}>
          *Seller payout may change as per actual.{" "}
        </Typography>
      </Box>
    </>
  );
}
