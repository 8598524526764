import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const ManageProductDescription = ({ toast }) => {
  const [id, setId] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const handleGetAllProductDescription = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/handleGetAllProductDescription`
      );
      if (res) {
        // console.log(res);
        setId(res.data[0]._id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetProductDescriptionById = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/handleGetProductDescriptionById/${id}`
      );
      if (res) {
        // console.log(res.data);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateProductDescription = async () => {
    setLoading1(true);
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/handleUpdateProductDescription/${id}`,
        {
          Heading: data.Heading,
          CountOfCharecters: data.CountOfCharecters,
          SpecificationHeading: data.SpecificationHeading,
          SpecificationType: data.SpecificationType,
        }
      );
      if (res) {
        // console.log(res);
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };
  useEffect(() => {
    handleGetAllProductDescription();
  }, []);

  useEffect(() => {
    handleGetProductDescriptionById();
  }, [id]);
  return (
    <div style={{ marginTop: "150px" }}>
      {!loading ? (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ml: 3,
              mr: 3,
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Manage Product Description
            </Typography>
            <Button variant="contained">Publish</Button>
          </Box>
          <Box sx={{ mt: 2, ml: 3 }}>
            <Box>
              <Typography>Heading</Typography>
              <TextField
                type="text"
                size="small"
                placeholder="Heading"
                value={data.Heading}
                onChange={(e) => setData({ ...data, Heading: e.target.value })}
                sx={{ width: "450px", mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <Typography>Number Of Charecters</Typography>
              <TextField
                type="text"
                size="small"
                placeholder="Count Of Charecters"
                value={data.CountOfCharecters}
                onChange={(e) =>
                  setData({ ...data, CountOfCharecters: e.target.value })
                }
                sx={{ width: "450px", mb: 1, mt: 0.5 }}
              />
              <Typography sx={{ color: "red", mb: 2 }}>
                * Note : How many Charecters need to show in the Product
                Description <br />
                if it is more than that charecters it shows button view more
              </Typography>
            </Box>
            <Box>
              <Typography>Specification Heading</Typography>
              <TextField
                type="text"
                size="small"
                placeholder="Specification Heading"
                value={data.SpecificationHeading}
                onChange={(e) =>
                  setData({ ...data, SpecificationHeading: e.target.value })
                }
                sx={{ width: "450px", mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <Typography>Specification Type</Typography>
              <Select
                value={data.SpecificationType || ""}
                sx={{ mb: 2, mt: 0.5, width: "450px" }}
                size="small"
              >
                <MenuItem value={data.SpecificationType}>
                  {data.SpecificationType}
                </MenuItem>
              </Select>
            </Box>

            <Button
              variant="contained"
              sx={{ width: "450px", mb: 3 }}
              onClick={handleUpdateProductDescription}
              disabled={loading1}
            >
              {loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default ManageProductDescription;
