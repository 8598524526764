import axios from "axios";
import React, { useEffect } from "react";
import ProductCreateTabPanel from "./ProductCreateTabPanel";

const CreateNewProductCategory = () => {
  return (
    <div>
      <ProductCreateTabPanel />
    </div>
  );
};

export default CreateNewProductCategory;
