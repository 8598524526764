import {
  Button,
  Card,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import CryptoJS from "crypto-js";

const HomepagePreFooter = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [bannerData, setBannerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [visibility, setVisibility] = React.useState({});
  const [punChlineData, setPunChlineData] = useState([]);
  const [newImage, setNewImage] = useState("");
  const [image1, setImage] = useState(null);

  // console.log(newImage);

  const cloudName = "bunny1705";
  const apiKey = "686471132156718";
  const apiSecret = "PF0u158oD2YzCfWEou1-ijeVOmQ";

  const handleEditBanner = (banner) => {
    // console.log(banner);
    if (data) {
      setSelectedBanner(banner);
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      setSelectedBanner(data[0]);
    }
  }, [data]);

  const generateSignature = (publicId) => {
    const timestamp = Date.now();
    const signature = CryptoJS.SHA1(
      `public_id=${publicId}&timestamp=${timestamp}${apiSecret}`
    ).toString();
    return { timestamp, signature };
  };

  const handleGetAllEndBannerImages = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/handleGetAllImagesforPreFooter"
      );
      if (res) {
        const visibilityObj = {};
        res.data.forEach((row) => {
          visibilityObj[row._id] = !row.visible;
        });
        setData(res.data);
        setVisibility(visibilityObj);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // handle visibility

  const handleVisibility = async (id, value) => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/handleUpdatePreFooterImages/${id}`,
        {
          visible: value,
        }
      );
      if (res) {
        setVisibility((prevState) => ({
          ...prevState,
          [id]: !value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle get Banner Images

  const handleGetEndBannerImageById = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/handleGetPreFooterImageById/${selectedBanner._id}`
      );
      if (res) {
        console.log(res);
        setBannerData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle update Banner data

  const handleUpdateEndBanners = async () => {
    setLoading1(true);
    try {
      const oldPublicId = bannerData.image
        .split("/")
        .slice(-1)[0]
        .split(".")[0];

      const { signature, timestamp } = generateSignature(oldPublicId);

      const formData = new FormData();
      formData.append("file", image1);
      formData.append("upload_preset", "insta-clone");
      formData.append("cloud_name", "bunny1705");

      let newPublicId = oldPublicId;
      let imageUrl = bannerData.image;
      if (image1) {
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
          formData
        );

        newPublicId = response.data.public_id;
        imageUrl = response.data.secure_url;
        setNewImage(imageUrl);

        if (oldPublicId) {
          const DestoryData = new FormData();
          DestoryData.append("public_id", oldPublicId);
          DestoryData.append("timestamp", timestamp);
          DestoryData.append("api_key", apiKey);
          DestoryData.append("signature", signature);
          await axios.post(
            `https://api.cloudinary.com/v1_1/${cloudName}/image/destroy`,
            DestoryData
          );
        }
      }

      // Update the banner data in your backend
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/handleUpdatePreFooterImages/${selectedBanner._id}`,
        {
          image: imageUrl,
          url: bannerData.url,
          altText: bannerData.altText,
          sortingOrder: bannerData.sortingOrder,
          textArea: bannerData.textArea,
        }
      );

      if (res) {
        console.log("Image and data updated successfully");

        // Fetch the updated data
        const updatedData = await axios.get(
          "https://rccore-backend.cstechns.com/api/admin/handleGetAllImagesforPreFooter"
        );

        // Update the data state with the fetched data
        setData(updatedData.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  const handleDeleteEndBannerImage = async (id) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (shouldDelete) {
      try {
        const banner = data.find((item) => item._id === id);

        if (!banner) {
          console.log("Banner not found");
          return;
        }

        const publicId = banner.image.split("/").slice(-1)[0].split(".")[0];
        const { signature, timestamp } = generateSignature(publicId);

        const formData = new FormData();
        formData.append("public_id", publicId);
        formData.append("timestamp", timestamp);
        formData.append("api_key", apiKey);
        formData.append("signature", signature);

        const deleteImageResponse = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/destroy`,
          formData
        );

        if (deleteImageResponse.status === 200) {
          const deleteBannerResponse = await axios.delete(
            `https://rccore-backend.cstechns.com/api/admin/handleDeletePreFooterImages/${id}`
          );

          if (deleteBannerResponse.status === 200) {
            console.log("Image and data deleted successfully");
            // Update the state or perform any additional actions if needed
            const updatedData = data.filter((item) => item._id !== id);
            setData(updatedData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    handleGetAllEndBannerImages();
  }, []);

  useEffect(() => {
    handleGetEndBannerImageById();
  }, [selectedBanner]);
  return (
    <div style={{ marginTop: "150px" }}>
      {!loading ? (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ml: 3,
              mr: 3,
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Homepage Pre Footer
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "80px",
              alignItems: "center",
              ml: 3,
              mr: 3,
              mb: 2,
            }}
          >
            <Box sx={{ mt: 3 }}>
              <Typography>
                This section allows you to manage Homepage Pre Footer Images
              </Typography>{" "}
              <Typography>
                {" "}
                To upload new Homepage Pre Footer images please
                <br /> use the upload button on the right you can modify or
                delete already existing
                <br /> Homepage Pre Footer images from the list below.
              </Typography>{" "}
              <Typography sx={{ color: "red" }}>
                Make sure you have published the Homepage Pre Footer Images
                <br />
                images once you are finished editting and image dimension should
                be 160px X 240px
              </Typography>
            </Box>
          </Box>
          <Box sx={{ m: 3, display: "flex", justifyContent: "space-between" }}>
            <Card elevation={0} sx={{ width: "100%" }}>
              <Box>
                <Card elevation={0} sx={{ border: "2px solid #dddddd" }}>
                  <Grid container spacing={2}>
                    {data.map((item, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={6}
                        lg={3}
                        xl={3}
                        key={index}
                      >
                        <Box
                          sx={{
                            p: 2,
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              width: "150px",
                              height: "150px",
                              border: "2px solid #dddddd",
                              padding: "5px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img src={item.image} alt="" width="120px" />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "5px",
                              mt: 0.5,
                            }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                width: "162px",
                                height: "30px",
                              }}
                              onClick={() =>
                                handleVisibility(item._id, visibility[item._id])
                              }
                            >
                              {visibility[item._id] ? "In-visible" : "visible"}
                            </Button>
                            <Button
                              variant="contained"
                              sx={{ width: "162px", height: "30px" }}
                              onClick={() => handleEditBanner(item)}
                            >
                              Edit
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Box>
            </Card>

            {selectedBanner && (
              <motion.div
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ duration: 0.3 }}
                style={{ marginLeft: "20px" }}
              >
                <Card
                  elevation={0}
                  sx={{ border: "2px solid #dddddd", width: "450px", p: 2 }}
                >
                  <Box>
                    <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      Edit Info
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {image1 ? (
                      <img
                        src={URL.createObjectURL(image1)}
                        alt=""
                        width="120px"
                      />
                    ) : (
                      <img src={bannerData.image} alt="" width="120px" />
                    )}
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>Update Image </Typography>
                    <TextField
                      type="file"
                      variant="outlined"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                      fullWidth
                      size="small"
                      sx={{ mt: 0.5 }}
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>Url </Typography>
                    <TextField
                      variant="outlined"
                      value={bannerData.url}
                      onChange={(e) =>
                        setBannerData({ ...bannerData, url: e.target.value })
                      }
                      fullWidth
                      size="small"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>Alt Text </Typography>
                    <TextField
                      variant="outlined"
                      value={bannerData.altText}
                      onChange={(e) =>
                        setBannerData({
                          ...bannerData,
                          altText: e.target.value,
                        })
                      }
                      fullWidth
                      size="small"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>Sorting Order</Typography>
                    <TextField
                      variant="outlined"
                      value={bannerData.sortingOrder}
                      onChange={(e) =>
                        setBannerData({
                          ...bannerData,
                          sortingOrder: e.target.value,
                        })
                      }
                      fullWidth
                      size="small"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography>TextArea</Typography>
                    <TextField
                      variant="outlined"
                      multiline
                      value={bannerData.textArea}
                      onChange={(e) =>
                        setBannerData({
                          ...bannerData,
                          textArea: e.target.value,
                        })
                      }
                      fullWidth
                      size="small"
                    />
                  </Box>

                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleUpdateEndBanners}
                    sx={{ mt: 2 }}
                    disabled={loading1}
                  >
                    {loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
                  </Button>
                </Card>
              </motion.div>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default HomepagePreFooter;
