import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ManageBrandTable from "./ManageBrandTable";

const ManageBrand = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <div style={{ marginTop: "150px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", m: 3 }}>
        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          Manage Brand Attribute Values
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate(`/create-new-brand/${id}`)}
        >
          Create New
        </Button>
      </Box>
      <ManageBrandTable />
    </div>
  );
};

export default ManageBrand;
