import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { format } from "date-fns";
import React, { useState } from "react";
import { createNewCouponCOdes } from "../../../axios";
import { useNavigate } from "react-router-dom";

const CreateNewCouponCode = () => {
  const [selected, setSelected] = useState("Percentage");
  const [selected1, setSelected1] = useState("Yes");
  const [CouponCode, setCouponCode] = useState(null);
  const [AmountCapping, setAmountCapping] = useState(null);
  const [CouponType, setCouponType] = useState();
  const [Discount, setDiscount] = useState(null);
  const [ValidityStart, setValidityStart] = useState(null);
  const [ValidityEnd, setValidityEnd] = useState(null);
  const [Description, setDescription] = useState(null);
  const [TotalCoupon, setTotalCoupon] = useState(null);
  const [userCoupon, setuserCoupon] = useState(null);
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);

  //   console.log(
  //     CouponCode,
  //     selected,
  //     AmountCapping,
  //     CouponType,
  //     Discount,
  //     formatDate(ValidityStart),
  //     formatDate(ValidityEnd),
  //     Description,
  //     selected1,
  //     TotalCoupon,
  //     userCoupon
  //   );

  const handleDateChange = (date) => {
    if (date && date <= new Date()) {
      setError(true);
    } else {
      setError(false);
      setValidityStart(date);
    }
  };
  const handleDateChange1 = (date) => {
    if (date && date < ValidityStart) {
      setError1(true);
    } else {
      setError1(false);
      setValidityEnd(date);
    }
  };

  const currentDate = format(new Date(), "yyyy-MM-dd");

  const navigate = useNavigate();

  const handleNextClick = () => {
    const formState = {
      CouponCode,
      selected,
      AmountCapping,
      CouponType,
      Discount,
      ValidityStart,
      ValidityEnd,
      Description,
      selected1,
      TotalCoupon,
      userCoupon,
    };

    navigate("/select-items-coupoun-code", {
      state: { couponData: formState },
    });
  };

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Manage Coupon Codes
        </Typography>
        <Button
          variant="contained"
          onClick={handleNextClick}
          disabled={
            !CouponCode ||
            !CouponType ||
            !Discount ||
            !ValidityStart ||
            !ValidityEnd ||
            !Description ||
            !TotalCoupon ||
            !userCoupon
          }
        >
          Next
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Box sx={{ border: "1px solid #ddd", ml: 3, mr: 3 }}>
          <Box sx={{ background: "#000", p: 1.5 }}>
            <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
              Create Coupon Code
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Coupon Code:
              </Typography>
              <TextField
                size="small"
                placeholder="Coupon Code"
                sx={{ width: "400px" }}
                value={CouponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Coupon Code Type:
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selected}
                  onChange={(e) => setSelected(e.target.value)}
                >
                  <FormControlLabel
                    value="Percentage"
                    control={<Radio />}
                    label="Percentage"
                  />
                  <FormControlLabel
                    value="Flat"
                    control={<Radio />}
                    label="Flat"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {selected === "Percentage" && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Maximum Amount Capping:
                </Typography>
                <TextField
                  size="small"
                  placeholder="Maximum Amount Capping"
                  sx={{ width: "400px" }}
                  value={AmountCapping}
                  onChange={(e) => setAmountCapping(e.target.value)}
                />
              </Box>
            )}

            <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Type of Coupon :
              </Typography>
              <Select
                size="small"
                sx={{ width: "400px" }}
                displayEmpty
                value={CouponType}
                onChange={(e) => setCouponType(e.target.value)}
              >
                <InputLabel sx={{ ml: 2 }}>---Select---</InputLabel>
                <MenuItem value="General">General</MenuItem>
                <MenuItem value="Specific">Specific</MenuItem>
              </Select>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Discount:
              </Typography>
              <TextField
                size="small"
                placeholder="Discount"
                sx={{ width: "400px" }}
                value={Discount}
                onChange={(e) => setDiscount(e.target.value)}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Validity Start Date:
              </Typography>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    size="small"
                    sx={{ width: "400px" }}
                    disablePast
                    value={ValidityStart && ValidityStart.$d}
                    onChange={(date) => handleDateChange(date)}
                    inputProps={{
                      min: currentDate,
                    }}
                  />
                </LocalizationProvider>

                {error && (
                  <Typography sx={{ color: "red" }}>
                    Validity Start Date should be greater than or equal to
                    current date
                  </Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Validity End Date:
              </Typography>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    size="small"
                    sx={{ width: "400px" }}
                    disablePast
                    value={ValidityEnd && ValidityEnd.$d}
                    onChange={(date) => handleDateChange1(date)}
                    inputProps={{
                      min: currentDate,
                    }}
                  />
                </LocalizationProvider>

                {error1 && (
                  <Typography sx={{ color: "red" }}>
                    Validity End Date should be greater than validity Start Date
                  </Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Description:
              </Typography>
              <textarea
                size="small"
                placeholder="Description"
                style={{
                  width: "380px",
                  border: "1px solid #ddd",
                  padding: "10px",
                  resize: "vertical",
                  borderRadius: "5px",
                  height: "80px",
                }}
                value={Description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Multiple Usage:
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selected1}
                  onChange={(e) => setSelected1(e.target.value)}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Total Coupon Usage (In Times) :
              </Typography>
              <TextField
                size="small"
                placeholder="Total Coupon Usage (In Times) "
                sx={{ width: "400px" }}
                value={TotalCoupon}
                onChange={(e) => setTotalCoupon(e.target.value)}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                User Coupon Uses (In Times) :
              </Typography>
              <TextField
                size="small"
                placeholder="User Coupon Uses (In Times)"
                sx={{ width: "400px" }}
                value={userCoupon}
                onChange={(e) => setuserCoupon(e.target.value)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CreateNewCouponCode;
