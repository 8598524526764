import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CreateLogisticProvider = () => {
  const navigate = useNavigate();
  const [CourierCompanyName, setCourierCompanyName] = useState();
  const [ContactPerson, setContactPerson] = useState();
  const [ContactEmailId, setContactEmailId] = useState();
  const [ContactPhoneNumber, setContactPhoneNumber] = useState();
  const [TrackingURL, setTrackingURL] = useState();
  const [selfShipping, setselfShipping] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCreateLogisticProvider = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/CreateLogisticProvider",
        {
          CourierCompanyName,
          ContactPerson,
          ContactEmailId,
          ContactPhoneNumber,
          TrackingURL,
          selfShipping,
        },
        config
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Logistic Provider Created SuccessFully",
          showConfirmButton: true,
        });
        navigate("/LogisticProviders");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div style={{ marginTop: "150px" }}>
      <Card
        sx={{ maxWidth: "450px", p: 2 }}
        style={{ margin: "auto", marginTop: "30px" }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
          Create Logistic Provider
        </Typography>
        <Box>
          <TextField
            label="Courier Company Name"
            placeholder="Courier Company Name"
            value={CourierCompanyName}
            onChange={(e) => setCourierCompanyName(e.target.value)}
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          />
          <TextField
            label="Contact Person"
            placeholder="Contact Person"
            value={ContactPerson}
            onChange={(e) => setContactPerson(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Contact Email id"
            placeholder="Contact Email id"
            value={ContactEmailId}
            onChange={(e) => setContactEmailId(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Contact Phone Number"
            placeholder="Contact Phone Number"
            value={ContactPhoneNumber}
            onChange={(e) => setContactPhoneNumber(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Tracking URL (enter url with http://)"
            placeholder="Tracking URL (enter url with http://)"
            value={TrackingURL}
            onChange={(e) => setTrackingURL(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Self Shipping
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Yes"
                checked={selfShipping === true}
                onChange={(e) => setselfShipping(true)}
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="No"
                checked={selfShipping === false}
                onChange={(e) => setselfShipping(false)}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 1, mb: 1 }}
          onClick={handleCreateLogisticProvider}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "SUBMIT"}
        </Button>
      </Card>
    </div>
  );
};

export default CreateLogisticProvider;
