import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, CircularProgress, TextField, Typography } from "@mui/material";

import { useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { SolutionForQuestionByAdmin } from "../../../axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomerAnswerDialog({ Question, id, setRefresh }) {
  const [open, setOpen] = React.useState(false);
  const [Loading, setLoading] = useState(false);
  const [Answer, setAnswer] = useState("");

  const { user } = useSelector((state) => state.auth);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSolution = async () => {
    setLoading(true);
    try {
      const res = await SolutionForQuestionByAdmin(
        id,
        Answer,
        "skillshop",
        true
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res,
          showConfirmButton: true,
        });
        setRefresh((refresh) => !refresh);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button sx={{ color: "#0099cc" }} onClick={handleClickOpen}>
        Click here to answer
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
          >
            Solution
          </Typography>
          <Typography
            sx={{
              border: "2px solid #0099cc",
              width: "60px",
              margin: "auto",
            }}
          ></Typography>
        </Box>
        <DialogContent>
          <Box>
            <Typography>
              <strong>Question</strong> : <span>{Question}</span>
            </Typography>
            <TextField
              multiline
              placeholder="Enter the answer"
              value={Answer}
              onChange={(e) => setAnswer(e.target.value)}
              sx={{ mt: 2, width: "300px" }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{ background: "#000" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSolution();
            }}
            disabled={!Answer || Loading}
            variant="contained"
          >
            {Loading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
