import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import PDPTable from "./PDPTable";
import Swal from "sweetalert2";

const newdata = [
  {
    name: "Product Details",
  },
  {
    name: "Credit Banner",
  },
  {
    name: "Product Combination",
  },
  {
    name: "Product Description",
  },

  {
    name: "Ratings and Reviews",
  },
  {
    name: "Questions About Product",
  },
  {
    name: "Recently Viewed",
  },
  {
    name: "Most Similar",
  },
  {
    name: "More like this",
  },
];

const ProductDetailsPage = () => {
  const [data, setData] = useState();
  const [moduleType, setModuleType] = useState();
  const [moduleName, setModuleName] = useState();
  const [image, setImage] = useState(null);
  const [seqNo, setSeqNo] = useState();
  const [modules, setModules] = useState([]);

  const handleCreatePDPModules = async () => {
    try {
      if (modules.length >= 9) {
        return Swal.fire({
          position: "center",
          icon: "error",
          text: "Maximum modules to create is 9",
          showConfirmButton: true,
        });
      }
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/CreateProductPageModules",
        {
          moduleType: moduleType,
          moduleName: moduleName,
        }
      );
      if (res) {
        console.log(res.data);
        setModuleType("");
        setModuleName("");

        setSeqNo("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllProductPageModules = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/handleGetProductPageModules"
      );
      if (res.data) {
        console.log(res.data);
        setModules(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllProductPageModules();
  }, []);

  return (
    <div style={{ marginTop: "150px" }}>
      <Box>
        <Box sx={{ ml: 3 }}>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            Product Details Customization
          </Typography>
        </Box>
        <Box
          sx={{
            ml: 3,
            mt: 2,
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Select
            displayEmpty
            sx={{ width: "300px" }}
            size="small"
            value={moduleType}
            onChange={(e) => setModuleType(e.target.value)}
          >
            <InputLabel sx={{ ml: 2 }}>Select Module Type</InputLabel>
            {newdata.map((item) => (
              <MenuItem value={item.name}>{item.name}</MenuItem>
            ))}
          </Select>
          <TextField
            variant="outlined"
            label="Module Name"
            placeholder="Module Name"
            size="small"
            value={moduleName}
            onChange={(e) => setModuleName(e.target.value)}
            sx={{ width: "300px" }}
          />

          <Button
            variant="contained"
            onClick={handleCreatePDPModules}
            disabled={!moduleType || !moduleName}
          >
            SUBMIT
          </Button>
        </Box>
      </Box>
      <Box>
        <PDPTable newdata={newdata} />
      </Box>
    </div>
  );
};

export default ProductDetailsPage;
