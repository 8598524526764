import {
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const UpdateBrandAttributes = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState();
  const [loading1, setLoading1] = useState(false);
  const [newimage, setImage] = useState(null);

  console.log(newimage);
  console.log(data.image);
  const { id } = useParams();
  const navigate = useNavigate();
  const handleGetUpdateBrandById = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getBrandAttributeById/${id}`
      );
      if (res) {
        // console.log(res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // handle get all prducts List by sellers

  const handleUpdateBrandData = async () => {
    try {
      setLoading1(true);

      // Upload the new image to Cloudinary
      const formData = new FormData();
      formData.append("file", newimage);
      formData.append("upload_preset", "insta-clone");
      formData.append("cloud_name", "bunny1705");
      formData.append("folder", "Brand_images");

      const response = await fetch(
        "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const imageData = await response.json();
      const logoUrl = imageData.secure_url;

      // Update the brand attributes
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/updateBrandAttributes/${id}`,
        {
          name: data.name,
          image: logoUrl, // Use the updated image URL
          sortId: data.sortId,
        }
      );

      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated Successfully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    handleGetUpdateBrandById();
  }, []);

  return (
    <div style={{ marginTop: "150px", marginBottom: "30px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mr: 3 }}>
        <Typography></Typography>
        <Button
          variant="contained"
          disableElevation
          onClick={() => navigate(-1)}
        >
          BACK
        </Button>
      </Box>
      {!loading ? (
        <>
          <Card
            sx={{ maxWidth: "450px", p: 2, mb: 2 }}
            style={{
              margin: "auto",
              marginTop: "30px",
              border: "1px solid #dddddd",
            }}
          >
            <Typography
              sx={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}
            >
              Update Brand Attributes
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Box>
                <Typography>Enter Name</Typography>
                <TextField
                  fullWidth
                  sx={{ mb: 2, mt: 0.5 }}
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </Box>
              <Box>
                <Typography>Image</Typography>
                <TextField
                  fullWidth
                  type="file"
                  sx={{ mb: 2, mt: 0.5 }}
                  inputProps={{ accept: ".jpeg,.png,.jpg,.gif" }}
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    setData({ ...data, image: newimage });
                    const reader = new FileReader();
                    reader.onload = () => {
                      if (reader.readyState === 2) {
                        setData({ ...data, imagePreview: reader.result });
                      }
                    };
                    reader.readAsDataURL(e.target.files[0]);
                  }}
                />
                <img
                  src={data.imagePreview ? data.imagePreview : data.image}
                  alt={data.name}
                  width="120px"
                />
              </Box>
              <Box>
                <Typography>Sort Id</Typography>
                <TextField
                  fullWidth
                  sx={{ mb: 2, mt: 0.5 }}
                  value={data.sortId}
                  onChange={(e) => setData({ ...data, sortId: e.target.value })}
                />
              </Box>
            </Box>
            <Button
              variant="contained"
              sx={{ mb: 2 }}
              fullWidth
              onClick={handleUpdateBrandData}
              disabled={loading1}
            >
              {loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
            </Button>
          </Card>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
    </div>
  );
};

export default UpdateBrandAttributes;
