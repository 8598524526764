import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { Box, CircularProgress, IconButton, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function ManageAttributes4Table() {
  const [data, setData] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const handleBrandAttributes = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllAttributes4"
      );
      if (res) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAttributes4 = async (id) => {
    try {
      const res = await axios.delete(
        `https://rccore-backend.cstechns.com/api/admin/DeleteAttributes4/${id}`
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });
        setData(data.filter((item) => item._id !== id));
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };

  React.useEffect(() => {
    handleBrandAttributes();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data.filter((row) =>
    row.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div>
      {!loading ? (
        <>
          <TextField
            label="Search"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearch}
            size="small"
            sx={{ ml: 3, width: "400px", mb: 2 }}
          />
          <TableContainer component={Paper} sx={{ width: "96.5%", ml: 3 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "#dddddd" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>S.No</TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Attribute Name
                  </TableCell>

                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Sorting Id
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.sortingId}</TableCell>
                    <TableCell align="center">
                      <IconButton>
                        <EditIcon
                          onClick={() =>
                            navigate(
                              `/update-Attributes4-inside-attributes/${row._id}`
                            )
                          }
                        />
                      </IconButton>
                      <IconButton>
                        <DeleteIcon
                          onClick={() => handleDeleteAttributes4(row._id)}
                          style={{ color: "red" }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
    </div>
  );
}
