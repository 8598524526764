import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/actions/AuthActions";
import { useNavigate } from "react-router-dom";
import logo from "../images/logo.png";

export default function NavbarTop() {
  const { token, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    const res = dispatch(logout());
    if (res) {
      localStorage.clear();
    }
    navigate("/");
  };
  return (
    <Box sx={{ flexGrow: 1, alignItems: "center" }}>
      <AppBar
        position="fixed"
        style={{
          background: "#1976d2",
          height: "65px",
        }}
      >
        <Toolbar>
          <Typography sx={{ flexGrow: 1, color: "#fff" }}>
            <Box
              onClick={() => navigate("/dashboard")}
              style={{ cursor: "pointer" }}
            >
              <img src={logo} alt={logo} style={{ width: "100px" }} />
            </Box>
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              mr: 2,
            }}
          >
            {/* <AccountCircleIcon /> */}
            <Box>{!token ? "" : <Typography>Hi {user.email}</Typography>}</Box>
            {token ? (
              <Box>
                <Typography
                  style={{ border: "1px solid #fff", height: "15px" }}
                  sx={{ mt: 0.5 }}
                ></Typography>
              </Box>
            ) : (
              ""
            )}
            <Box>
              {!token ? (
                ""
              ) : (
                <Typography
                  onClick={() => handleLogout()}
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  LogOut
                </Typography>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {/* <Navbar /> */}
    </Box>
  );
}
