import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import {
  Box,
  CircularProgress,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RemoveProductsModal({ Products, subCategory1 }) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([]);
  const [Category, setCategory] = useState();
  const [mainCategory, setMainCategory] = useState();
  const [mainCategories, setMainCategories] = useState([]);
  const [subcategory, setSubCategory] = useState();
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const products1 = Products.filter(
    (item) =>
      item.active === true &&
      (item.Category === subCategory1 ||
        item.mainCategory === subCategory1 ||
        item.subCategory === subCategory1)
  );

  const handleRootCategory = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllSubCategoriesForRoot"
      );

      if (res) {
        setData(res.data[0].subcategories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const UpdateproductsShifting = async () => {
    setLoading(true);
    try {
      const res = products1.map(async (item) => {
        const res1 = await axios.put(
          `https://rccore-backend.cstechns.com/api/admin/updateAllProductsInCategory/${item._id}`,
          {
            Category: Category,
            mainCategory: mainCategory,
            subCategory: subcategory,
          }
        );
        if (res1) {
        }
      });
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Products Shifted SuccessFully",
          showConfirmButton: true,
        });
        navigate("/product-categories");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleRootCategory();
  }, []);

  return (
    <div>
      <Tooltip title="Remove All Products From Category">
        <IconButton onClick={handleClickOpen}>
          <DisabledByDefaultIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Typography sx={{ fontWeight: "14px", color: "red", m: 3 }}>
          *Note : Before removing all the products from this category shift
          Products to another category
        </Typography>

        <Typography sx={{ fontWeight: "bold", ml: 3, mb: 3 }}>
          You have {products1.length} products in this Category
        </Typography>
        {products1.length >= 1 && (
          <>
            <Typography sx={{ fontWeight: "bold", ml: 3, mt: 2 }}>
              Note * : To Shift Products from this category to another category
              please select
            </Typography>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Box>
                  <Box>
                    <Typography>Category</Typography>
                    <Select
                      sx={{ width: "400px", mt: 0.5 }}
                      size="small"
                      displayEmpty
                      value={Category}
                      onChange={(e) => {
                        const selectData = data.find(
                          (item) => item.name === e.target.value
                        );
                        // console.log(selectData);
                        setCategory(e.target.value);
                        setMainCategories(
                          selectData && selectData.subcategories
                        );
                      }}
                    >
                      <InputLabel sx={{ ml: 2 }}>
                        ---Select Category---
                      </InputLabel>
                      {data.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography>Main Category</Typography>
                    <Select
                      sx={{ width: "400px", mt: 0.5 }}
                      size="small"
                      displayEmpty
                      value={mainCategory}
                      onChange={(e) => {
                        const selectData = mainCategories.find(
                          (item) => item.name === e.target.value
                        );
                        // console.log(selectData);
                        setMainCategory(e.target.value);
                        setSubCategories(
                          selectData && selectData.subcategories
                        );
                      }}
                    >
                      <InputLabel sx={{ ml: 2 }}>
                        ---Select MainCategory---
                      </InputLabel>
                      {mainCategories.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography>Sub Category</Typography>
                    <Select
                      sx={{ width: "400px", mt: 0.5 }}
                      size="small"
                      displayEmpty
                      value={subcategory}
                      onChange={(e) => setSubCategory(e.target.value)}
                    >
                      <InputLabel sx={{ ml: 2 }}>
                        ---Select SubCategory---
                      </InputLabel>
                      {subCategories.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="contained"
                style={{ backgroundColor: "#000" }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  UpdateproductsShifting();
                  handleClose();
                }}
                variant="contained"
                disabled={!Category || !mainCategory || !subcategory || loading}
              >
                {loading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
