import { Box, Button, Typography } from "@mui/material";
import React from "react";
import BuyerTable from "./BuyerTable";
import { useNavigate } from "react-router-dom";

const BuyerList = () => {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "150px" }}>
      
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Registered Buyers
        </Typography>
        <Button variant="contained" onClick={() => navigate(-1)}>
          Back
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <BuyerTable />
      </Box>
    </div>
  );
};

export default BuyerList;
