import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Tooltip } from "@mui/material";
import { AiFillEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import CustomerAnswerDialog from "./ProductAnswerDialog";
import { BiHide, BiShow } from "react-icons/bi";
import {
  DeactivateProductAnswer,
  DeactivateProductQuestion,
  DeleteAnswer,
  DeleteQuestion,
} from "../../../axios";
import Swal from "sweetalert2";
import axios from "axios";

export default function ProductQuestionTable({
  data,
  setRefresh,
  visibility,
  setVisibility,
  visibility1,
  setVisibility1,
  setData,
}) {
  const handleDeactivateQuestion = async (id, value) => {
    try {
      const res = await DeactivateProductQuestion(id, value);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "product updated successfully",
          showConfirmButton: true,
        });
        setVisibility((prevState) => ({
          ...prevState,
          [id]: value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeactivateAnswer = async (id, value) => {
    try {
      const res = await DeactivateProductAnswer(id, value);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "product updated successfully",
          showConfirmButton: true,
        });
        setVisibility1((prevState) => ({
          ...prevState,
          [id]: value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteQuestion = async (id) => {
    try {
      const res = await DeleteQuestion(id);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res,
          showConfirmButton: true,
        });
        setData(data.filter((item) => item._id !== id));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteAnswer = async (id, value) => {
    try {
      const res = await DeleteAnswer(id, value);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res,
          showConfirmButton: true,
        });
        setRefresh((refresh) => !refresh);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{ border: "1px solid #ddd" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ background: "#f9f9f9" }}>
          <TableRow>
            <TableCell
              sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
            >
              S.No
            </TableCell>
            <TableCell
              align="left"
              sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
            >
              Buyer Name
            </TableCell>
            <TableCell
              align="left"
              sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
            >
              Buyer Email
            </TableCell>
            <TableCell
              align="left"
              sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
            >
              Product SkuCode
            </TableCell>
            <TableCell
              align="left"
              sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
            >
              Product Name
            </TableCell>
            <TableCell
              align="left"
              sx={{
                borderRight: "1px solid #ddd",
                fontWeight: "bold",
                width: "170px",
              }}
            >
              Question
            </TableCell>
            <TableCell
              align="left"
              sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
            >
              Answer
            </TableCell>
            <TableCell
              align="left"
              sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
            >
              Answered By
            </TableCell>
            <TableCell
              align="left"
              sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                {index + 1}
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                {row?.Buyer?.name}
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                {row?.Buyer?.userEmail}
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                {row?.product?.SKUCode}
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                {row?.product?.Title}
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                {row?.Question}
              </TableCell>
              {row.Answer === "" ? (
                <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                  <CustomerAnswerDialog
                    setRefresh={setRefresh}
                    Question={row.Question}
                    id={row._id}
                  />
                </TableCell>
              ) : (
                <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                  {row?.Answer}
                </TableCell>
              )}
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                {row?.AnsweredBy}
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                {visibility[row._id] ? (
                  <Tooltip title="ActivateQuestion">
                    <IconButton
                      onClick={() =>
                        handleDeactivateQuestion(row._id, !visibility[row._id])
                      }
                    >
                      <BiHide style={{ color: "red" }} size={22} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="DeActivateQuestion">
                    <IconButton
                      onClick={() =>
                        handleDeactivateQuestion(row._id, !visibility[row._id])
                      }
                    >
                      <BiShow style={{ color: "red" }} size={22} />
                    </IconButton>
                  </Tooltip>
                )}
                {visibility1[row._id] ? (
                  <Tooltip title="ActivateAnswer">
                    <IconButton
                      onClick={() =>
                        handleDeactivateAnswer(row._id, !visibility1[row._id])
                      }
                    >
                      <BiHide style={{ color: "green" }} size={22} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="DeActivateAnswer">
                    <IconButton
                      onClick={() =>
                        handleDeactivateAnswer(row._id, !visibility1[row._id])
                      }
                    >
                      <BiShow style={{ color: "green" }} size={22} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Delete Question">
                  <IconButton onClick={() => handleDeleteQuestion(row._id)}>
                    <MdDelete style={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Answer">
                  <IconButton onClick={() => handleDeleteAnswer(row._id, "")}>
                    <MdDelete style={{ color: "green" }} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
