import {
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const CreateNewSizeChart = () => {
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [sortingId, setSortingId] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const { user } = useSelector((state) => state.auth);

  const handleCreateNewSizeChart = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", image);
      formData.append("upload_preset", "insta-clone");
      formData.append("cloud_name", "bunny1705");
      formData.append("folder", "Brand_images");

      const response = await fetch(
        "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      console.log(data);
      const logoUrl = data.secure_url;
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/CreateSizeChart",
        {
          name,
          image: logoUrl,
          sortingId,
          creatorDetails: user.email,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Size Chart Created SuccessFully",
          showConfirmButton: true,
          timer: 1500,
        });
        navigate(`/manage-Size-chart/${id}`);
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div style={{ marginTop: "150px" }}>
      <Card
        sx={{ maxWidth: "450px", p: 2 }}
        style={{ margin: "auto", marginTop: "30px" }}
      >
        <Typography
          style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}
        >
          Create New Size chart
        </Typography>
        <Box>
          <TextField
            label="Name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          />
          <TextField
            label="image"
            type="file"
            inputProps={{ accept: ".jpeg,.png,.jpg,.gif" }}
            onChange={(e) => setImage(e.target.files[0])}
            fullWidth
            sx={{ mb: 2 }}
            focused
          />
          {image && (
            <img
              src={URL.createObjectURL(image)}
              alt={image.name}
              width="120px"
              style={{ margin: "10px" }}
            />
          )}

          <TextField
            label="Sorting Id"
            placeholder="Sorting Id"
            value={sortingId}
            onChange={(e) => setSortingId(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Box>
        <Button
          variant="contained"
          fullWidth
          onClick={handleCreateNewSizeChart}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "SUBMIT"}
        </Button>
      </Card>
    </div>
  );
};

export default CreateNewSizeChart;
