import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import NavigationTable from "./NavigationTable";

const ManageNavbar = () => {
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "150px" }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ml: 3,
            mr: 3,
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            Navigation List
          </Typography>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Button
              variant="contained"
              onClick={() => navigate("/theme-customization/navigation-add")}
            >
              Create New
            </Button>
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          <NavigationTable />
        </Box>
      </Box>
    </div>
  );
};

export default ManageNavbar;
