import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import ManageBestSellingTable from "./ManageBestSellingTable";
import axios from "axios";
import ManageBestSellingTableSelected from "./ManageBestSellingSelected";
import { useState } from "react";

const ManageBestSelling = ({ Products }) => {
  const [data, setData] = React.useState({});
  const [refresh, setRefresh] = useState(null);
  const [refresh1, setRefresh1] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSearchQuery, setselectedSearchQuery] = useState("");

  const { id, name } = useParams();
  const handleGetAllBestSellingItems = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/handleGetAllBestSellingForCategoryId/${id}`
      );
      if (res) {
        setData(res.data);
        // console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Products1 = Products.filter(
    (item) =>
      !data?.Product_id?.some((newItem) => item._id === newItem._id) &&
      (item.SKUCode.includes(searchQuery) ||
        item.Title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.UniqueUrl.includes(searchQuery))
  );

  const selectedProducts = data?.Product_id?.filter(
    (item) =>
      item.SKUCode.includes(selectedSearchQuery) ||
      item.Title.toLowerCase().includes(selectedSearchQuery.toLowerCase()) ||
      item.UniqueUrl.includes(selectedSearchQuery)
  );

  React.useEffect(() => {
    handleGetAllBestSellingItems();
  }, [name, id, refresh, refresh1, selectedSearchQuery]);

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", ml: 3, mr: 3 }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Manage Best Selling For {name}
        </Typography>
        <Button variant="contained">Back</Button>
      </Box>
      <Grid container spacing={2} sx={{ px: 3, mt: 2 }}>
        <Grid item xs={12} lg={6}>
          <Box sx={{ border: "1px solid #ddd", mb: 2 }}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                background: "#eee",
                p: 2,
              }}
            >
              Un Selected Products
            </Typography>
            <Divider />
            <Box sx={{ mt: 1, p: 2 }}>
              <Typography>Search by Product Code, Name, Unique URL</Typography>
              <TextField
                sx={{ width: "300px", mt: 0.5, mb: 2 }}
                size="small"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Box>
            <Box>
              <ManageBestSellingTable
                Products={Products1}
                setRefresh={setRefresh}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box sx={{ border: "1px solid #ddd", mb: 2 }}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                background: "#eee",
                p: 2,
              }}
            >
              Selected Products
            </Typography>
            <Divider />
            <Box sx={{ mt: 1, p: 2 }}>
              <Typography>Search by Product Code, Name, Unique URL</Typography>
              <TextField
                sx={{ width: "300px", mt: 0.5, mb: 2 }}
                size="small"
                placeholder="Search"
                value={selectedSearchQuery}
                onChange={(e) => setselectedSearchQuery(e.target.value)}
              />
            </Box>
            <Box>
              <ManageBestSellingTableSelected
                data={selectedProducts}
                setRefresh1={setRefresh1}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ManageBestSelling;
