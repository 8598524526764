import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CreateNewFooterNavigation = () => {
  const [defaultColumn, setDefaultColumn] = useState("Footer Column 1");
  const [defaultMenu, setDefaultMenu] = useState("Label");
  const [Heading, setHeading] = useState("");
  const [Link, setLink] = useState("");
  const [SortingOrder, setSortingOrder] = useState("");
  const [ShowNew, setShowNew] = useState(false);
  const [Loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleCreateFooterNavigation = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/CreateFooterNavigation",
        {
          FooterColumn: defaultColumn,
          MenuType: defaultMenu,
          Heading: Heading,
          Link: Link,
          SortingOrder: SortingOrder,
          ShowNew: ShowNew,
        }
      );
      if (res.data.message) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Created Successfully",
          showConfirmButton: true,
        });
        navigate("/footer-navigation");
      } else {
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Navigation Manage
        </Typography>
        <Button
          variant="contained"
          onClick={handleCreateFooterNavigation}
          disabled={Loading}
        >
          {Loading ? <CircularProgress size={24} /> : "SUBMIT"}
        </Button>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Card
          sx={{ border: "1px solid #ddd", width: "450px", p: 2 }}
          elevation={0}
        >
          <Box>
            <Box>
              <Typography>Footer Column</Typography>
              <Select
                fullWidth
                size="small"
                sx={{ mt: 0.5, mb: 2 }}
                value={defaultColumn}
                onChange={(e) => setDefaultColumn(e.target.value)}
              >
                <MenuItem value="Footer Column 1">Footer Column 1</MenuItem>
                <MenuItem value="Footer Column 2">Footer Column 2</MenuItem>
                <MenuItem value="Footer Column 3">Footer Column 3</MenuItem>
                <MenuItem value="Footer Column 4">Footer Column 4</MenuItem>
              </Select>
            </Box>
            <Box>
              <Typography>Menu Type</Typography>
              <Select
                fullWidth
                size="small"
                sx={{ mt: 0.5, mb: 2 }}
                value={defaultMenu}
                onChange={(e) => setDefaultMenu(e.target.value)}
              >
                <MenuItem value="Label">Label</MenuItem>
                <MenuItem value="Static Page">Static Page</MenuItem>
              </Select>
            </Box>
            <Box>
              <Typography>Heading</Typography>
              <TextField
                size="small"
                placeholder="Heading"
                fullWidth
                value={Heading}
                onChange={(e) => setHeading(e.target.value)}
                sx={{ mt: 0.5, mb: 2 }}
              />
            </Box>
            <Box>
              <Typography>Link</Typography>
              <TextField
                size="small"
                placeholder="Link"
                fullWidth
                value={Link}
                onChange={(e) => setLink(e.target.value)}
                sx={{ mt: 0.5, mb: 2 }}
              />
            </Box>
            <Box>
              <Typography>Sorting Order</Typography>
              <TextField
                size="small"
                placeholder="Sorting Order"
                fullWidth
                value={SortingOrder}
                onChange={(e) => setSortingOrder(e.target.value)}
                sx={{ mt: 0.5, mb: 2 }}
              />
            </Box>
            <Box>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Show New
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={ShowNew}
                  onChange={(e) => setShowNew(e.target.value)}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Card>
      </Box>
    </div>
  );
};

export default CreateNewFooterNavigation;
