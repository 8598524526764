import React from "react";
import { Box } from "@mui/system";

import {
  Grid,
  Card,
  IconButton,
  Icon,
  Typography,
  useTheme,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { H3 } from "../Typography";
import SmallChart from "./SmallChart";
import SmallChart2 from "./SmallChart2";
import TinyBarChart from "./TinyBarchart";
import TinyLineChart from "./TinyLineChart";

const StatCard3 = ({ sellers, Products }) => {
  console.log(sellers);
  const PendingSellers = sellers?.filter((item) => item?.approved === false);
  const PendingProducts = Products?.filter((item) => item?.active === false);
  const statList = [
    {
      icon: <PeopleIcon />,
      amount: PendingSellers?.length || 0,
      title: "Total Pending Sellers",
      chartComponent: <TinyBarChart />,
    },
    {
      icon: <PendingActionsIcon />,
      amount: PendingProducts?.length || 0,
      title: "Pending Products",
      chartComponent: <TinyBarChart />,
    },
  ];
  const { palette } = useTheme();
  const textMuted = palette.text.secondary;

  return (
    <div>
      <Grid container spacing={4}>
        {statList.map((item, ind) => (
          <Grid key={ind} item lg={6} xs={12}>
            <Card
              elevation={2}
              sx={{
                p: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "20px",
              }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box>
                  <IconButton
                    size="small"
                    sx={{
                      background: "rgba(0, 0, 0, 0.01)",
                    }}
                  >
                    <Icon sx={{ color: textMuted }}>{item.icon}</Icon>
                  </IconButton>
                </Box>

                <Box ml={2}>
                  <Typography sx={{ mt: "-4px", fontSize: "32px" }}>
                    {item.amount}
                  </Typography>
                  <Typography sx={{ color: textMuted }}>
                    {item.title}
                  </Typography>
                </Box>
              </Box>
              {item.chartComponent}
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default StatCard3;
