import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  DeactiveCouponCOde,
  deleteCouponCOdeByID,
  getAllCouponCOdes,
} from "../../../axios";
import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { BiHide, BiShow } from "react-icons/bi";

export default function CouponCodeTable() {
  const [data, setData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [visibility, setVisibility] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const navigate = useNavigate();

  const handleGetAllCouponCodes = async () => {
    setLoading(true);
    try {
      const res = await getAllCouponCOdes();
      if (res) {
        const visibilityObj = {};
        res.forEach((row) => {
          visibilityObj[row._id] = !row.active;
        });
        setData(res);
        setFilteredData(res);
        setVisibility(visibilityObj);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVisibility = async (id, value) => {
    try {
      const res = await DeactiveCouponCOde(id, value);
      if (res) {
        setVisibility((prevState) => ({
          ...prevState,
          [id]: !value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCouponCode = async (id) => {
    try {
      const res = await deleteCouponCOdeByID(id);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });
        setData((prevData) => prevData.filter((item) => item._id !== id));
        setFilteredData((prevData) =>
          prevData.filter((item) => item._id !== id)
        );
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "error",
        showConfirmButton: true,
        timer: 1500,
      });
    }
  };

  React.useEffect(() => {
    handleGetAllCouponCodes();
  }, []);

  React.useEffect(() => {
    const filteredResults = data.filter((row) =>
      row.couponCode.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filteredResults);
  }, [searchQuery, data]);

  return (
    <>
      <Box sx={{ ml: 3, mb: 2 }}>
        <Typography>Search</Typography>
        <TextField
          size="small"
          sx={{ width: "300px", mt: 0.5 }}
          placeholder="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>
      {!loading ? (
        <TableContainer component={Paper} sx={{ width: "96.5%", ml: 3, mr: 3 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ background: "#eee" }}>
              <TableRow>
                <TableCell
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  S.No
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Coupon Code
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Code Type
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Discount
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Validity Start Date
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Validity End Date
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Usage
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Type
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.couponCode}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.couponCodeType}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.Discount}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.validityStartDate}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.validityEndDate}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.multiple === "Yes" ? "multiple" : "NotMultiple"}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.CouponType}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() =>
                          navigate(`/edit-coupoun-code/${row._id}`)
                        }
                      >
                        <FiEdit size={20} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleDeleteCouponCode(row._id)}
                      >
                        <MdDelete style={{ color: "red" }} />
                      </IconButton>
                    </Tooltip>
                    {visibility[row._id] ? (
                      <Tooltip title="Show">
                        <IconButton
                          onClick={() => handleVisibility(row._id, true)}
                        >
                          <BiHide style={{ color: "red" }} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Hide">
                        <IconButton
                          onClick={() => handleVisibility(row._id, false)}
                        >
                          <BiShow style={{ color: "green" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
