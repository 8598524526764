import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const BasicInfo = ({
  checked,
  setChecked,
  productType,
  selectedId,
  setSelectedId,
  handleTitleChange,
  Title,
  handleChange,
  PreBook = "No",
  replacement = "No",
  refund = "No",
  singleProductData,
  setSingleProductData,
}) => {
  //   function generateUniqueUrl(title) {
  //     const lowercaseTitle = title.toLowerCase();
  //     const urlTitle = lowercaseTitle.replace(/\s+/g, "-");
  //     const uniqueId = Math.floor(Math.random() * 1000000);
  //     return `${urlTitle}-${uniqueId}`;
  //   }
  return (
    <Box>
      <Card
        sx={{ maxWidth: "770px", p: 2, border: "1px solid #dddddd" }}
        elevation={0}
      >
        <Box sx={{ width: "650px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>Product Type : </Typography>
            <Select
              displayEmpty
              size="small"
              sx={{ width: "300px" }}
              value={singleProductData.ProductType || ""}
              onChange={(e) => {
                const newData = productType.find(
                  (item) => item.ProductType === e.target.value
                );
                // console.log("new", newData);
                setSelectedId(newData._id);
                setSingleProductData({
                  ...singleProductData,
                  ProductType: e.target.value,
                });
              }}
            >
              <InputLabel sx={{ ml: 2 }}>Select</InputLabel>
              {productType.map((item) => (
                <MenuItem value={item.ProductType}>{item.ProductType}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "280px",
              mt: 2,
            }}
          >
            <Typography>PreBook : </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value={singleProductData.PreBook}
                  control={<Radio />}
                  checked={singleProductData.PreBook == "Yes"}
                  onChange={(e) =>
                    setSingleProductData({
                      ...singleProductData,
                      PreBook: "Yes",
                    })
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value={singleProductData.PreBook}
                  control={<Radio />}
                  checked={singleProductData.PreBook == "No"}
                  onChange={(e) =>
                    setSingleProductData({
                      ...singleProductData,
                      PreBook: "No",
                    })
                  }
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>Title : </Typography>
            <TextField
              placeholder="Title"
              sx={{ width: "300px" }}
              value={singleProductData.Title}
              onChange={(e) =>
                setSingleProductData({
                  ...singleProductData,
                  Title: e.target.value,
                })
              }
              size="small"
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>HSN Code : </Typography>
            <TextField
              placeholder="HSN Code"
              sx={{ width: "300px" }}
              value={singleProductData.HSNCode}
              onChange={(e) =>
                setSingleProductData({
                  ...singleProductData,
                  HSNCode: e.target.value,
                })
              }
              size="small"
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>Tax(%) : </Typography>
            <TextField
              placeholder="Tax(%)"
              sx={{ width: "300px" }}
              value={singleProductData.Tax}
              onChange={(e) =>
                setSingleProductData({
                  ...singleProductData,
                  Tax: e.target.value,
                })
              }
              size="small"
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>Description : </Typography>
            <TextField
              multiline
              placeholder="Description"
              value={singleProductData.Description}
              onChange={(e) =>
                setSingleProductData({
                  ...singleProductData,
                  Description: e.target.value,
                })
              }
              sx={{ width: "300px" }}
              size="small"
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>SKU Code : </Typography>
            <TextField
              multiline
              placeholder="SKU Code"
              value={singleProductData.SkuCode}
              onChange={(e) =>
                setSingleProductData({
                  ...singleProductData,
                  SkuCode: e.target.value,
                })
              }
              sx={{ width: "300px" }}
              size="small"
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>Unique Url : </Typography>
            <TextField
              multiline
              placeholder="Unique URL"
              value={singleProductData.UniqueUrl}
              onChange={(e) =>
                setSingleProductData({
                  ...singleProductData,
                  UniqueUrl: e.target.value,
                })
              }
              sx={{ width: "300px" }}
              size="small"
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              Main Image (only jpg,jpeg files allowed) :<br /> Image Size Should
              be less then 1 MB
              <br />{" "}
              <span style={{ color: "red" }}>
                Image Dimension should be 40X75
              </span>{" "}
            </Typography>
            <TextField
              type="file"
              placeholder="Unique URL"
              sx={{ width: "300px" }}
              size="small"
            />
          </Box>

          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              Product Details : <br />
              Upload only PDF file <br /> PDF Size Should be less then 1 MB
            </Typography>
            <TextField
              type="file"
              placeholder="Product Details"
              sx={{ width: "300px" }}
              size="small"
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>Search Title : </Typography>
            <TextField
              placeholder="Search Title"
              value={singleProductData.searchTitle}
              onChange={(e) =>
                setSingleProductData({
                  ...singleProductData,
                  searchTitle: e.target.value,
                })
              }
              sx={{ width: "300px" }}
              size="small"
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>Search Keywords (Separated By Comma) : </Typography>
            <TextField
              multiline
              placeholder="Search Keywords (Separated By Comma)"
              value={singleProductData.searchKeywords}
              onChange={(e) => {
                const data = e.target.value.split(",");
                setSingleProductData({
                  ...singleProductData,
                  searchKeywords: data,
                });
              }}
              sx={{ width: "300px" }}
              size="small"
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>Search Description : </Typography>
            <TextField
              multiline
              placeholder="Search Description"
              value={singleProductData.searchDescription}
              onChange={(e) =>
                setSingleProductData({
                  ...singleProductData,
                  searchDescription: e.target.value,
                })
              }
              sx={{ width: "300px" }}
              size="small"
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>Shipment Fulfilled by Vedson : </Typography>
            <Select
              displayEmpty
              size="small"
              multiline
              value={singleProductData.shipment || ""}
              onChange={(e) =>
                setSingleProductData({
                  ...singleProductData,
                  shipment: e.target.value,
                })
              }
              sx={{ width: "300px" }}
            >
              <InputLabel sx={{ ml: 2 }}>Select</InputLabel>
              <MenuItem value="SkillShop will pickUp and ship(SkillShop Fullfillment)">
                SkillShop will pickUp and ship(SkillShop Fullfillment)
              </MenuItem>
              <MenuItem value=" I will ship items myself(self Fullfillment)">
                I will ship items myself(self Fullfillment)
              </MenuItem>
            </Select>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>Return Policy: </Typography>
            <Select
              displayEmpty
              size="small"
              multiline
              value={singleProductData.returnPolicy || ""}
              onChange={(e) =>
                setSingleProductData({
                  ...singleProductData,
                  returnPolicy: e.target.value,
                })
              }
              sx={{ width: "300px" }}
            >
              <InputLabel sx={{ ml: 2 }}>Select Return Policy</InputLabel>
              <MenuItem value="Easy Exchange or Return 3 days">
                Easy Exchange or Return 3 days
              </MenuItem>
              <MenuItem value="Easy Exchange or Return 5 days">
                Easy Exchange or Return 5 days
              </MenuItem>
              <MenuItem value="Easy Exchange or Return 7 days">
                Easy Exchange or Return 7 days
              </MenuItem>
              <MenuItem value="Easy Exchange or Return 10 days">
                Easy Exchange or Return 10 days
              </MenuItem>
              <MenuItem value="Easy Exchange or Return 14 days">
                Easy Exchange or Return 14 days
              </MenuItem>
              <MenuItem value="Easy Exchange or Return 15 days">
                Easy Exchange or Return 15 days
              </MenuItem>
              <MenuItem value="Easy Exchange or Return 20 days">
                Easy Exchange or Return 20 days
              </MenuItem>
              <MenuItem value="Easy Exchange or Return 25 days">
                Easy Exchange or Return 25 days
              </MenuItem>
              <MenuItem value="Easy Exchange or Return 30 days">
                Easy Exchange or Return 30 days
              </MenuItem>
              <MenuItem value="Not Eligible for Exchange or Return">
                Not Eligible for Exchange or Return
              </MenuItem>
              <MenuItem value="1 Days Return / Replacement policy">
                1 Days Return / Replacement policy
              </MenuItem>
              <MenuItem value="4 Days Replacement policy">
                4 Days Replacement policy
              </MenuItem>
              <MenuItem value="90 Days Replacement policy">
                90 Days Replacement policy
              </MenuItem>
            </Select>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              Return Policy Description :<br /> Exchange & Return conditions{" "}
            </Typography>
            <TextField
              multiline
              placeholder="Search Description"
              sx={{ width: "300px" }}
              value={singleProductData.returnPolicyDescription}
              onChange={(e) =>
                setSingleProductData({
                  ...singleProductData,
                  returnPolicyDescription: e.target.value,
                })
              }
              size="small"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "245px",
              mt: 2,
            }}
          >
            <Typography>Replacement : </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value={singleProductData.replacement}
                  control={<Radio />}
                  checked={singleProductData.replacement == "Yes"}
                  onChange={(e) =>
                    setSingleProductData({
                      ...singleProductData,
                      replacement: "Yes",
                    })
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value={singleProductData.replacement}
                  control={<Radio />}
                  checked={singleProductData.replacement == "No"}
                  onChange={(e) =>
                    setSingleProductData({
                      ...singleProductData,
                      replacement: "No",
                    })
                  }
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "287px",
              mt: 2,
            }}
          >
            <Typography>Refund : </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value={refund}
                  control={<Radio />}
                  checked={refund == "Yes"}
                  onChange={(e) =>
                    setSingleProductData({
                      ...singleProductData,
                      refund: "Yes",
                    })
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value={refund}
                  control={<Radio />}
                  checked={refund == "No"}
                  onChange={(e) =>
                    setSingleProductData({ ...singleProductData, refund: "No" })
                  }
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
              mb: 5,
            }}
          >
            <Typography></Typography>
            <Button variant="contained" onClick={() => handleChange(0, 3)}>
              NEXT
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default BasicInfo;
