import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import HomePageTable from "./HoemPageTable";
import axios from "axios";
import Swal from "sweetalert2";

const newdata = [
  {
    name: "Topbar",
  },
  {
    name: "Header",
  },
  {
    name: "Navbar",
  },
  {
    name: "Full Banner",
  },

  {
    name: "Category Slider",
  },
  {
    name: "16 images under main banner",
  },
  {
    name: "Featured Categories",
  },
  {
    name: "Banner Link",
  },
  {
    name: "Featured Banners",
  },
  {
    name: "Footer Banners",
  },
  {
    name: "pre Footer",
  },
  {
    name: "Footer Nav",
  },
  {
    name: "Sub Footer",
  },
];

const HomePage = () => {
  const [data, setData] = useState();
  const [moduleType, setModuleType] = useState();
  const [moduleName, setModuleName] = useState();
  const [image, setImage] = useState(null);
  const [seqNo, setSeqNo] = useState();
  const [modules, setModules] = useState([]);

  const handleCreateHomepageModules = async () => {
    try {
      if (modules.length > 14) {
        return Swal.fire({
          position: "center",
          icon: "error",
          text: "Maximum modules to create is 14",
          showConfirmButton: true,
        });
      }
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/createThemeHomePage",
        {
          moduleType: moduleType,
          moduleName: moduleName,
        }
      );
      if (res) {
        console.log(res.data);
        setModuleType("");
        setModuleName("");
        setImage("");
        setSeqNo("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllHomepageModules = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/handleGetAllHomepageModules"
      );
      if (res.data) {
        console.log(res.data);
        setModules(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllHomepageModules();
  }, []);

  return (
    <div style={{ marginTop: "150px" }}>
      <Box>
        <Box sx={{ ml: 3 }}>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            Home Page Customization
          </Typography>
        </Box>
        <Box
          sx={{
            ml: 3,
            mt: 2,
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Select
            displayEmpty
            sx={{ width: "300px" }}
            size="small"
            value={moduleType}
            onChange={(e) => setModuleType(e.target.value)}
          >
            <InputLabel sx={{ ml: 2 }}>Select Module Type</InputLabel>
            {newdata.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>

          <TextField
            variant="outlined"
            label="Module Name"
            placeholder="Module Name"
            size="small"
            value={moduleName}
            onChange={(e) => setModuleName(e.target.value)}
            sx={{ width: "300px" }}
          />

          <Button
            variant="contained"
            onClick={handleCreateHomepageModules}
            disabled={!moduleType || !moduleName}
          >
            SUBMIT
          </Button>
        </Box>
      </Box>
      <Box>
        <HomePageTable newdata={newdata} />
      </Box>
    </div>
  );
};

export default HomePage;
