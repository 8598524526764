import {
  Button,
  Card,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const UpdateSizeAttributes = () => {
  const [data, setData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const handleGetAttributeBYId = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getSizeAttributeById/${id}`
      );
      if (res) {
        // console.log(res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateColorAttributeBYId = async () => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/UpdateSizeAttributes/${id}`,
        {
          attributeType: data.attributeType,
        }
      );
      if (res) {
        // console.log(res);
        // console.log(res);
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated SuccessFully",
          showConfirmButton: true,
          timer: 1500,
        });
        navigate("/product-attributes-main");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAttributeBYId();
  }, []);

  return (
    <div style={{ marginTop: "150px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mr: 3 }}>
        <Typography></Typography>
        <Button
          variant="contained"
          disableElevation
          onClick={() => navigate(-1)}
        >
          BACK
        </Button>
      </Box>
      <Box>
        <Card
          sx={{ maxWidth: "450px", p: 2 }}
          style={{
            margin: "auto",
            marginTop: "30px",
            border: "1px solid #dddddd",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
              mb: 2,
            }}
          >
            Update Size Attributes
          </Typography>
          <Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>
                Attribute Name :{" "}
              </Typography>
              <TextField
                sx={{ width: "100%", mb: 2, mt: 0.5 }}
                disabled
                size="small"
                value={data.AttributeName}
                // style={{ cursor: "not-allowed" }}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>Sequence Id : </Typography>
              <TextField
                sx={{ width: "100%", mb: 2, mt: 0.5 }}
                disabled
                size="small"
                value={data.sequenceId}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "17px" }}>
                Attribute Type :{" "}
              </Typography>
              <Select
                sx={{ width: "100%", mb: 2, mt: 0.5 }}
                size="small"
                value={data.attributeType || ""}
                onChange={(e) =>
                  setData({ ...data, attributeType: e.target.value })
                }
              >
                <MenuItem value="Box">Box</MenuItem>
                <MenuItem value="Dropdown">Dropdown</MenuItem>
              </Select>
            </Box>
          </Box>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 1 }}
            onClick={handleUpdateColorAttributeBYId}
          >
            SUBMIT
          </Button>
        </Card>
      </Box>
    </div>
  );
};

export default UpdateSizeAttributes;
