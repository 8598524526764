import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import Swal from "sweetalert2";

const MegaMenu = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [Loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);

      // console.log(formData);

      await fetch("https://rccore-backend.cstechns.com/api/buyer/megaMenu", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            Swal.fire({
              position: "center",
              icon: "success",
              text: "Xml File Uploaded SuccessFully",
              showConfirmButton: true,
            });
          } // Log the response data from the backend

          // Handle the response data as needed
          // ...
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    fetch(
      "https://rccore-backend.cstechns.com/api/admin/handleDownloadExistingSheet"
    )
      .then((response) => response.blob())
      .then((blob) => {
        console.log(blob);
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "MegaMenu.xml";
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      })
      .catch((error) => {
        console.error("Error:", error);
        Swal.fire({
          position: "center",
          icon: "error",
          text: "Error downloading file",
          showConfirmButton: true,
        });
      });
  };
  return (
    <div style={{ marginTop: "150px" }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ml: 3,
            mr: 3,
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            Import MegaMenu
          </Typography>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Button variant="contained" onClick={handleDownload}>
              Download Existing Sheet
            </Button>
          </Box>
        </Box>

        <Grid container spacing={2} sx={{ p: 3, mt: 5 }}>
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                border: "1px solid #ddd",
                p: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "100%",
                background: "#eee",
              }}
            >
              <Typography>Upload (*.xml) File </Typography>
              <TextField
                type="file"
                inputProps={{ accept: ".xml" }}
                onChange={(e) => setSelectedFile(e.target.files[0])}
                sx={{ width: "400px", mt: 2 }}
              />
              <Button
                variant="contained"
                sx={{ width: "200px", mt: 2 }}
                onClick={handleSubmit}
                disabled={Loading}
              >
                {Loading ? <CircularProgress size={24} /> : "SUMBIT"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default MegaMenu;
