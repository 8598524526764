import {
  Button,
  Card,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useEffect } from "react";

const UpdateProductCategory = () => {
  const [data, setData] = useState([]);
  const [SelectedCategory, setSelectedCategory] = React.useState([]);

  const [categoryName, setCategoryName] = React.useState();
  const [uniqueUrl, setuniqueUrl] = React.useState();

  const [updateData, setUpdateData] = useState({});
  const [Loading, setLoading] = useState(false);

  // console.log(updateData);

  const [newMainCategory, setNewMainCategory] = useState({});
  const [newRootCategory, setNewRootCategory] = useState({});
  const [newParentCategory, setNewParentCategory] = useState({});
  const [newSubCategory, setNewSubCategory] = useState({});

  const [selectedMainCategory, setSelectedMainCategory] = useState([]);
  // console.log("newSubCategory", newRootCategory.name);
  // console.log("newMainCategory", newMainCategory.name);
  // console.log("newRootCategory", newParentCategory.name);
  // console.log("newSubCategory", newSubCategory.name);

  const handleRootCategory = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllSubCategoriesForRoot"
      );
      if (res && res.data && res.data.length > 0) {
        setData(res.data[0].subcategories);

        // setNewId(res.data[0]._id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // console.log(updateData);

  const { id } = useParams();

  const handleParentCategoryChange = (event) => {
    const selectedCategory = data.find(
      (category) => category.name === event.target.value
    );
    setNewParentCategory(selectedCategory);
  };

  const handleCategoryChange = (event) => {
    const categoryName = event.target.value;
    setUpdateData({ ...updateData, name: categoryName });

    // Find the category object from SelectedCategory array using the selected categoryName
    const selectedCategory = SelectedCategory.find(
      (category) => category.name === categoryName
    );

    // If the selected category is a main category, update the newMainCategory state
    if (selectedCategory) {
      setNewMainCategory(selectedCategory);
      setNewSubCategory({});
    } else {
      // If the selected category is a subcategory, update the newSubCategory state
      setNewSubCategory(selectedCategory);
      setNewMainCategory(
        SelectedCategory.find(
          (category) => category.name === selectedCategory.parentCategory
        )
      );
    }
  };

  const handleSubCategoryById = async () => {
    // setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getSubCategoryById/${id}`
      );
      if (res && res.data) {
        // set the subcategory data
        setUpdateData(res.data);
        setNewSubCategory(res.data);
        // find the main category
        for (const category of data) {
          for (const subcategory of category.subcategories) {
            const newItem = subcategory.subcategories.find(
              (item) => item._id === res.data._id
            );
            if (newItem) {
              // found the subcategory, get the main category
              const newRes = await axios.get(
                `https://rccore-backend.cstechns.com/api/admin/getSubCategoryById/${subcategory._id}`
              );
              if (newRes && newRes.data) {
                // set the main category data
                setNewMainCategory(newRes.data);
              }

              // find the parent category
              for (const parentCategory of data) {
                const newParentItem = parentCategory.subcategories.find(
                  (item) => item._id === subcategory._id
                );
                if (newParentItem) {
                  // found the parent category, check if it's root
                  if (parentCategory._id === newParentItem._id) {
                    setNewRootCategory(parentCategory);
                  } else {
                    setNewParentCategory(parentCategory);
                  }
                }
              }
              return;
            } else if (subcategory._id === res.data._id) {
              // found the main category, get the parent category
              const newRootRes = await axios.get(
                `https://rccore-backend.cstechns.com/api/admin/getSubCategoryById/${category._id}`
              );
              if (newRootRes && newRootRes.data) {
                // set the parent category data
                setNewParentCategory(newRootRes.data);
              }

              // find the root category
              for (const rootCategory of data) {
                const newRootItem = rootCategory.subcategories.find(
                  (item) => item._id === category._id
                );
                if (newRootItem) {
                  setNewRootCategory(rootCategory);
                  return;
                }
              }
              return;
            }
          }
          if (category._id === res.data._id) {
            // found the parent category
            setNewParentCategory(category);

            // find the root category
            for (const rootCategory of data) {
              const newRootItem = rootCategory.subcategories.find(
                (item) => item._id === category._id
              );
              if (newRootItem) {
                setNewRootCategory(rootCategory);
                return;
              }
            }
            return;
          }
        }
      }
    } catch (error) {
      console.log(error);
      // } finally {
      //   setLoading(false);
    }
  };

  const handleUpdate = async (req, res) => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/updateCategories/${id}`,
        {
          name: updateData.name,
        }
      );
      if (res) {
        console.log(res);
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated SuccessFully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "error Occured",
        showConfirmButton: true,
      });
    }
  };

  React.useEffect(() => {
    handleRootCategory();
  }, []);

  useEffect(() => {
    handleSubCategoryById();
  }, [data, id]);

  const fetchSubCategories = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getSubCategoryById/${
          newMainCategory && newMainCategory?._id
            ? newMainCategory?._id
            : newSubCategory._id
        }`
      );
      if (res && res.data) {
        if (newMainCategory?._id) {
          // console.log(res.data);
          setSelectedMainCategory(res.data.subcategories);
        }
      }
    } catch (error) {
      console.log(error);
      //
    }
  };

  useEffect(() => {
    fetchSubCategories();
  }, [newMainCategory, newSubCategory]);

  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        const res = await axios.get(
          `https://rccore-backend.cstechns.com/api/admin/getSubCategoryById/${
            newParentCategory && newParentCategory?._id
          }`
        );
        if (res && res.data) {
          // console.log(res.data);

          setSelectedCategory(res.data.subcategories);
        }
      } catch (error) {
        console.log(error);
        //
      }
    };

    fetchSubCategories();
  }, [newParentCategory]);

  return (
    <div style={{ marginTop: "150px" }}>
      {!Loading ? (
        <Card
          sx={{ maxWidth: "700px", p: 2 }}
          style={{
            margin: "auto",
            marginTop: "30px",
            border: "1px solid #dddddd",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            Update Category
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography>Root Category : </Typography>
              <Select
                displayEmpty
                sx={{ width: "400px" }}
                size="small"
                value={newParentCategory?.name || ""}
                onChange={handleParentCategoryChange}
              >
                <InputLabel sx={{ ml: 2 }}>Select Category</InputLabel>
                {data.map((item) => (
                  <MenuItem value={item.name}>{item.name}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography>Main Category : </Typography>
              <Select
                displayEmpty
                sx={{ width: "400px" }}
                size="small"
                value={
                  newMainCategory?.name
                    ? newMainCategory?.name
                    : newSubCategory.name || ""
                }
                onChange={handleCategoryChange}
              >
                <InputLabel sx={{ ml: 2 }}>Select Category</InputLabel>
                {SelectedCategory.map((item) => (
                  <MenuItem value={item.name}>{item.name}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography>Sub Category : </Typography>
              <Select
                displayEmpty
                sx={{ width: "400px" }}
                size="small"
                value={newSubCategory?.name ?? ""}
                onChange={(event) => {
                  const selectedSubCategory = selectedMainCategory.find(
                    (item) => item.name === event.target.value
                  );
                  setUpdateData({ ...updateData, name: event.target.value });
                  setNewSubCategory(selectedSubCategory);
                }}
              >
                <InputLabel sx={{ ml: 2 }}>Select Category</InputLabel>
                {selectedMainCategory.map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography>Category Name : </Typography>
              <TextField
                placeholder="Category Name"
                sx={{ width: "400px" }}
                value={updateData.name}
                onChange={(e) =>
                  setUpdateData({ ...updateData, name: e.target.value })
                }
                size="small"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography>Unique URL : </Typography>
              <TextField
                placeholder="Unique URL"
                value={updateData?.uniqueUrl}
                sx={{ width: "400px" }}
                size="small"
              />
            </Box>
          </Box>
          <Typography sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{ mt: 2 }}
              fullWidth
              onClick={handleUpdate}
            >
              Submit
            </Button>
          </Typography>
        </Card>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default UpdateProductCategory;
