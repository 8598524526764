import { Button, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

import illustrator from "../../images/illustration-light.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";

function FollowerCard() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [storeData, setStoreData] = useState([]);

  const handleStoreProfileData = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllProfilers"
      );

      if (res) {
        // console.log(res);
        setStoreData(res.data[0]._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleStoreProfileData();
  }, []);
  return (
    <Card sx={{ borderRadius: "20px" }} elevation={2}>
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} md={12}>
            <Box display="flex" flexDirection="column" height="100%">
              <Typography variant="h5" fontWeight="bold" gutterBottom>
                Welcome {user.email == "manish@cstech.in" ? "Admin" : ""}!
              </Typography>
              <Box mb={6}>
                <Typography sx={{ fontSize: "15px", color: "gray" }}>
                  Hello {user.email} welcome to the SkillShop admin
                  <br />{" "}
                </Typography>
              </Box>
              <Button
                sx={{ mt: 3, mb: 2 }}
                variant="contained"
                style={{ backgroundColor: "#1976d2" }}
                onClick={() => navigate(`/store-profile/${storeData}`)}
              >
                MANAGE YOUR PROFILE
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ position: "relative", ml: "auto" }}>
            <Box
              height="100%"
              display="grid"
              justifyContent="center"
              alignItems="center"
              bgColor="info"
              borderRadius="lg"
              variant="gradient"
            >
              <Box>
                <img src={illustrator} alt="" width={320} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default FollowerCard;
