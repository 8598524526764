import {
  Button,
  Card,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";

const CreateProductAttributes = () => {
  const [AttributeName, setAttributeName] = useState();
  const [sequenceId, setsequenceId] = useState();

  const handleCreateProductMainAttributes = async () => {
    try {
      const res = await axios.post(
        "https://rccore-backend.cstechns.com/api/admin/createProductAttributes",
        {
          AttributeName,
          sequenceId,
        }
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Main Attribute Created SuccessFully",
          showConfirmButton: true,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };
  return (
    <div style={{ marginTop: "150px" }}>
      <Card
        sx={{ maxWidth: "450px", p: 2 }}
        style={{ margin: "auto", marginTop: "30px" }}
      >
        <Typography
          sx={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}
        >
          Create New Main Attribute
        </Typography>
        <Box>
          <TextField
            varaint="outlined"
            label="Attribute Name"
            placeholder="Attribute Name"
            value={AttributeName}
            onChange={(e) => setAttributeName(e.target.value)}
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          />
          <Select
            displayEmpty
            fullWidth
            value={sequenceId}
            onChange={(e) => setsequenceId(e.target.value)}
          >
            <InputLabel sx={{ ml: 2 }}>Select Sequence Id</InputLabel>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="6">6</MenuItem>
            <MenuItem value="7">7</MenuItem>
            <MenuItem value="8">8</MenuItem>
          </Select>
        </Box>
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
          onClick={handleCreateProductMainAttributes}
        >
          SUBMIT
        </Button>
      </Card>
    </div>
  );
};

export default CreateProductAttributes;
