import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import {
  Box,
  CircularProgress,
  IconButton,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CustomPopover from "../../CustomPopOver";
import { useState } from "react";

export default function ProductAttributesMainTable() {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [visibility, setVisibility] = React.useState({});
  const [searchQuery, setSearchQuery] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [productTypeValues, setProductTypeValues] = React.useState([]);
  const [includedAtributeNames, setIncludedAttributeNames] = React.useState([]);

  // console.log(includedAtributeNames);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  let arrowRef = null;

  const handleFetchAllMainAttributes = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllMainProductAttributes"
      );
      if (res) {
        const visibilityObj = {};
        res.data.forEach((row) => {
          visibilityObj[row._id] = !row.hide;
        });
        setData(res.data);
        setVisibility(visibilityObj);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // handle get all productTypeValues

  const handleAllGetProductTypeValues = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllProductTypesValues"
      );
      if (res) {
        setProductTypeValues(res.data);
        // console.log(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVisibility = async (id, value) => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/UpdateVisibilityofMainAttribute/${id}`,
        {
          hide: value,
        }
      );
      if (res) {
        setVisibility((prevState) => ({
          ...prevState,
          [id]: !value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    handleFetchAllMainAttributes();
  }, []);
  React.useEffect(() => {
    handleAllGetProductTypeValues();
  }, []);

  React.useEffect(() => {
    setLoading(true);
    if (data) {
      const mainAttrNames = data.map((attr) => attr.AttributeName);
      const newIncludedAttributes = [];

      productTypeValues.map((item) => {
        item.MainAttributes.forEach((attr) => {
          if (mainAttrNames.includes(attr)) {
            // console.log(attr);
            newIncludedAttributes.push(attr);
          }
        });
      });
      setIncludedAttributeNames(newIncludedAttributes);
    }

    setLoading(false);
  }, [productTypeValues, data]);

  // console.log(includedAtributeNames);

  const handleRoutes = (i) => {
    for (let a = i; a < data.length; a++) {
      return data[a].AttributeName;
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data.filter((row) =>
    row.AttributeName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div>
      <TextField
        label="Search"
        size="small"
        placeholder="Search"
        value={searchQuery}
        onChange={handleSearch}
        sx={{ ml: 3, width: "400px" }}
      />
      {!loading ? (
        <>
          <TableContainer component={Paper} sx={{ m: 3, width: "96.5%" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: "#dddddd" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Attribute Name
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Sequence Id
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row, index) => (
                  <TableRow key={row.name}>
                    <TableCell>{row.AttributeName}</TableCell>
                    <TableCell align="center">{row.sequenceId}</TableCell>
                    <TableCell align="center">
                      {row.sequenceId && (
                        <Tooltip
                          title={
                            !includedAtributeNames.includes(row.AttributeName)
                              ? "Edit"
                              : null
                          }
                        >
                          <IconButton
                            onMouseEnter={
                              includedAtributeNames.includes(row.AttributeName)
                                ? handlePopoverOpen
                                : null
                            }
                            onMouseLeave={handlePopoverClose}
                            onClick={() => {
                              if (
                                !includedAtributeNames.includes(
                                  row.AttributeName
                                )
                              ) {
                                if (row.AttributeName == handleRoutes(3)) {
                                  navigate(
                                    `/update-color-attributes/${row._id}`
                                  );
                                } else if (
                                  row.AttributeName == handleRoutes(4)
                                ) {
                                  navigate(
                                    `/update-size-attributes/${row._id}`
                                  );
                                } else if (
                                  row.AttributeName == handleRoutes(5)
                                ) {
                                  navigate(
                                    `/update-variants-attributes/${row._id}`
                                  );
                                } else if (
                                  row.AttributeName == handleRoutes(6)
                                ) {
                                  navigate(
                                    `/update-Model-attributes/${row._id}`
                                  );
                                } else if (
                                  row.AttributeName == handleRoutes(7)
                                ) {
                                  navigate(`/update-attributes1/${row._id}`);
                                } else if (
                                  row.AttributeName == handleRoutes(8)
                                ) {
                                  navigate(`/update-attributes2/${row._id}`);
                                } else if (
                                  row.AttributeName == handleRoutes(9)
                                ) {
                                  navigate(`/update-attributes3/${row._id}`);
                                } else if (
                                  row.AttributeName == handleRoutes(10)
                                ) {
                                  navigate(`/update-attributes4/${row._id}`);
                                }
                              }
                            }}
                          >
                            <EditIcon
                              sx={{
                                color:
                                  includedAtributeNames.includes(
                                    row.AttributeName
                                  ) && "lightgray",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      <IconButton
                        onClick={() => {
                          if (row.AttributeName == handleRoutes(0)) {
                            navigate(`/manage-brand/${row._id}`);
                          } else if (row.AttributeName == handleRoutes(1)) {
                            navigate(`/manage-product-type/${row._id}`);
                          } else if (row.AttributeName == handleRoutes(2)) {
                            navigate(`/manage-size-chart/${row._id}`);
                          } else if (row.AttributeName == handleRoutes(3)) {
                            navigate(`/manage-Color-attributes/${row._id}`);
                          } else if (row.AttributeName == handleRoutes(4)) {
                            navigate(`/manage-Size-attributes/${row._id}`);
                          } else if (row.AttributeName == handleRoutes(5)) {
                            navigate(`/manage-Variant-attributes/${row._id}`);
                          } else if (row.AttributeName == handleRoutes(6)) {
                            navigate(`/manage-Model-attributes/${row._id}`);
                          } else if (row.AttributeName == handleRoutes(7)) {
                            navigate(`/manage-attributes1/${row._id}`);
                          } else if (row.AttributeName == handleRoutes(8)) {
                            navigate(`/manage-attributes2/${row._id}`);
                          } else if (row.AttributeName == handleRoutes(9)) {
                            navigate(`/manage-attributes3/${row._id}`);
                          } else if (row.AttributeName == handleRoutes(10)) {
                            navigate(`/manage-attributes4/${row._id}`);
                          }
                        }}
                      >
                        <WysiwygIcon />
                      </IconButton>

                      {visibility[row._id] ? (
                        <>
                          <Tooltip
                            title={
                              !includedAtributeNames.includes(row.AttributeName)
                                ? "De-Activate"
                                : null
                            }
                          >
                            <IconButton
                              onMouseEnter={
                                includedAtributeNames.includes(
                                  row.AttributeName
                                )
                                  ? handlePopoverOpen
                                  : null
                              }
                              onMouseLeave={handlePopoverClose}
                              onClick={() => {
                                if (
                                  !includedAtributeNames.includes(
                                    row.AttributeName
                                  )
                                ) {
                                  handleVisibility(
                                    row._id,
                                    visibility[row._id]
                                  );
                                }
                              }}
                            >
                              <VisibilityIcon
                                style={{
                                  color: includedAtributeNames.includes(
                                    row.AttributeName
                                  )
                                    ? "lightgray"
                                    : "green",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip
                            title={
                              !includedAtributeNames.includes(row.AttributeName)
                                ? "Activate"
                                : null
                            }
                          >
                            <IconButton
                              onMouseEnter={
                                includedAtributeNames.includes(
                                  row.AttributeName
                                )
                                  ? handlePopoverOpen
                                  : null
                              }
                              onMouseLeave={handlePopoverClose}
                              onClick={() =>
                                handleVisibility(row._id, visibility[row._id])
                              }
                            >
                              <VisibilityOffIcon
                                style={{
                                  color: includedAtributeNames.includes(
                                    row.AttributeName
                                  )
                                    ? "lightgray"
                                    : "red",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}

                      <Popper
                        id="mouse-over-popper"
                        open={open}
                        anchorEl={anchorEl}
                        placement="bottom"
                        disablePortal
                        modifiers={[
                          {
                            name: "flip",
                            enabled: true,
                            options: {
                              altBoundary: true,
                              rootBoundary: "document",
                              padding: 8,
                            },
                          },
                          {
                            name: "preventOverflow",
                            enabled: true,
                            options: {
                              altAxis: true,
                              altBoundary: true,
                              tether: false,
                              rootBoundary: "document",
                              padding: 8,
                            },
                          },
                          {
                            name: "arrow",
                            enabled: true,
                            options: {
                              element: arrowRef,
                            },
                          },
                        ]}
                      >
                        {open && (
                          <div
                            style={{
                              background: "#f3f3f3",
                              padding: "8px",
                              position: "relative",
                            }}
                          >
                            <div
                              ref={(node) => {
                                arrowRef = node;
                              }}
                              style={{
                                position: "absolute",
                                top: "-8px",
                                left: "50%",
                                transform: "translateX(-50%) rotate(45deg)",
                                width: "16px",
                                height: "16px",
                                background: "#f3f3f3",
                              }}
                            />
                            <Typography>
                              You cannot be able to Edit or Hide <br /> the
                              Attribute When it is associated
                              <br /> with Any product or Category Association
                            </Typography>
                          </div>
                        )}
                      </Popper>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
