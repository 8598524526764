import {
  Button,
  Card,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const UpdateAttributes2 = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const handleGetAttributeBYId = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getAttribute2ById/${id}`
      );
      if (res) {
        // console.log(res);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleUpdateColorAttributeBYId = async () => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/UpdateAttributes2/${id}`,
        {
          AttributeName: data.AttributeName,
        }
      );
      if (res) {
        // console.log(res);
        // console.log(res);
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated SuccessFully",
          showConfirmButton: true,
          timer: 1500,
        });
        navigate("/product-attributes-main");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAttributeBYId();
  }, []);

  return (
    <div style={{ marginTop: "150px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mr: 3 }}>
        <Typography></Typography>
        <Button
          variant="contained"
          disableElevation
          onClick={() => navigate(-1)}
        >
          BACK
        </Button>
      </Box>
      <Box>
        {!loading ? (
          <Card
            sx={{ maxWidth: "450px", p: 2 }}
            style={{
              margin: "auto",
              marginTop: "30px",
              border: "1px solid #dddddd",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
                mb: 2,
              }}
            >
              Update Color Attributes
            </Typography>
            <Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  Attribute Name{" "}
                </Typography>
                <TextField
                  sx={{ width: "100%", mb: 2, mt: 0.5 }}
                  size="small"
                  value={data.AttributeName}
                  onChange={(e) =>
                    setData({ ...data, AttributeName: e.target.value })
                  }
                  // style={{ cursor: "not-allowed" }}
                />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "17px" }}>
                  Sequence Id :{" "}
                </Typography>
                <TextField
                  sx={{ width: "100%", mb: 2, mt: 0.5 }}
                  disabled
                  size="small"
                  value={data.sequenceId}
                />
              </Box>
            </Box>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 1 }}
              onClick={handleUpdateColorAttributeBYId}
            >
              SUBMIT
            </Button>
          </Card>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress size={24} />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default UpdateAttributes2;
