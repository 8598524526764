import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { createNewCouponCOdes, getCouponCodeById } from "../../../axios";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

const EditCouponCode = ({ refresh }) => {
  const [ValidityStart, setValidityStart] = useState(null);
  const [ValidityEnd, setValidityEnd] = useState(null);
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const handleGetCouponCodeById = async () => {
    setLoading(true);
    try {
      const res = await getCouponCodeById(id);
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetCouponCodeById();
  }, [refresh]);

  const handleDateChange = (date) => {
    if (date && date <= new Date()) {
      setError(true);
    } else {
      setError(false);
      setValidityStart(date);
    }
  };
  const handleDateChange1 = (date) => {
    if (date && date < ValidityStart) {
      setError1(true);
    } else {
      setError1(false);
      setValidityEnd(date);
    }
  };

  const currentDate = format(new Date(), "yyyy-MM-dd");

  const navigate = useNavigate();

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Manage Coupon Codes
        </Typography>
        <Button
          variant="contained"
          onClick={() =>
            navigate(`/edit-selected-items-coupoun-code/${id}`, {
              state: { CouponData: { data, ValidityStart, ValidityEnd } },
            })
          }
        >
          Next
        </Button>
      </Box>
      {!loading ? (
        <Box sx={{ mt: 3 }}>
          <Box sx={{ border: "1px solid #ddd", ml: 3, mr: 3 }}>
            <Box sx={{ background: "#000", p: 1.5 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                Create Coupon Code
              </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Coupon Code:
                </Typography>
                <TextField
                  size="small"
                  placeholder="Coupon Code"
                  sx={{ width: "400px" }}
                  value={data.couponCode}
                  onChange={(e) =>
                    setData({ ...data, couponCode: e.target.value })
                  }
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Coupon Code Type:
                </Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Percentage"
                      control={
                        <Radio
                          value={data.couponCodeType === "Percentage"}
                          checked={data.couponCodeType === "Percentage"}
                          onChange={(e) =>
                            setData({ ...data, couponCodeType: e.target.value })
                          }
                        />
                      }
                      label="Percentage"
                    />
                    <FormControlLabel
                      value="Flat"
                      control={
                        <Radio
                          value={data.couponCodeType === "Flat"}
                          checked={data.couponCodeType === "Flat"}
                          onChange={(e) =>
                            setData({ ...data, couponCodeType: e.target.value })
                          }
                        />
                      }
                      label="Flat"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {data.couponCodeType === "Percentage" && (
                <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "300px",
                    }}
                  >
                    Maximum Amount Capping:
                  </Typography>
                  <TextField
                    size="small"
                    placeholder="Maximum Amount Capping"
                    sx={{ width: "400px" }}
                    value={data.AmountCapping}
                    onChange={(e) =>
                      setData({ ...data, AmountCapping: e.target.value })
                    }
                  />
                </Box>
              )}

              <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Type of Coupon :
                </Typography>
                <Select
                  size="small"
                  sx={{ width: "400px" }}
                  displayEmpty
                  value={data?.CouponType ?? ""}
                  onChange={(e) =>
                    setData({ ...data, CouponType: e.target.value })
                  }
                >
                  <InputLabel sx={{ ml: 2 }}>---Select---</InputLabel>
                  <MenuItem value="General">General</MenuItem>
                  <MenuItem value="Specific">Specific</MenuItem>
                </Select>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Discount:
                </Typography>
                <TextField
                  size="small"
                  placeholder="Discount"
                  sx={{ width: "400px" }}
                  value={data.Discount}
                  onChange={(e) =>
                    setData({ ...data, Discount: e.target.value })
                  }
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Validity Start Date:
                </Typography>
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      size="small"
                      sx={{ width: "400px" }}
                      disablePast
                      value={dayjs(data?.validityStartDate)}
                      onChange={(date) => handleDateChange(date)}
                      inputProps={{
                        min: currentDate,
                      }}
                    />
                  </LocalizationProvider>

                  {error && (
                    <Typography sx={{ color: "red" }}>
                      Validity Start Date should be greater than or equal to
                      current date
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Validity End Date:
                </Typography>
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      size="small"
                      sx={{ width: "400px" }}
                      disablePast
                      value={dayjs(data?.validityEndDate)}
                      onChange={(date) => handleDateChange1(date)}
                      inputProps={{
                        min: currentDate,
                      }}
                    />
                  </LocalizationProvider>

                  {error1 && (
                    <Typography sx={{ color: "red" }}>
                      Validity End Date should be greater than validity Start
                      Date
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Description:
                </Typography>
                <textarea
                  size="small"
                  placeholder="Description"
                  style={{
                    width: "380px",
                    border: "1px solid #ddd",
                    padding: "10px",
                    resize: "vertical",
                    borderRadius: "5px",
                    height: "80px",
                  }}
                  value={data.Description}
                  onChange={(e) =>
                    setData({ ...data, Description: e.target.value })
                  }
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Multiple Usage:
                </Typography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={data.multiple}
                    onChange={(e) =>
                      setData({ ...data, multiple: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio checked={data.multiple === "Yes"} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio checked={data.multiple === "No"} />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Total Coupon Usage (In Times) :
                </Typography>
                <TextField
                  size="small"
                  placeholder="Total Coupon Usage (In Times) "
                  sx={{ width: "400px" }}
                  value={data.TotalCouponUsageInTimes}
                  onChange={(e) =>
                    setData({
                      ...data,
                      TotalCouponUsageInTimes: e.target.value,
                    })
                  }
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  User Coupon Uses (In Times) :
                </Typography>
                <TextField
                  size="small"
                  placeholder="User Coupon Uses (In Times)"
                  sx={{ width: "400px" }}
                  value={data.UserCouponUsageInTimes}
                  onChange={(e) =>
                    setData({ ...data, UserCouponUsageInTimes: e.target.value })
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default EditCouponCode;
