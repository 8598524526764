import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Attribute from "./Attribute";
import CodAndShipping from "./CodAndShipping";
import { Title } from "@mui/icons-material";
import BasicInfo from "./BasicInfo";
import { useParams } from "react-router-dom";
import { useState } from "react";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ProductTab() {
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState();
  const [mainCategories, setMainCategories] = React.useState([]);
  const [selectMainCategories, setSelectMainCategories] = React.useState();
  const [SubCategories, setSubCategories] = React.useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = React.useState();
  const [RootName, setRootName] = React.useState({});
  const [productType, setProductType] = React.useState([]);
  const [selected, setSelected] = React.useState();
  const [selectedId, setSelectedId] = React.useState();
  const [expanded, setExpanded] = React.useState({});
  const [checked, setChecked] = React.useState({});

  const [singleProductData, setSingleProductData] = React.useState({});
  const [loading, setLoading] = useState(false);

  // console.log(singleProductData);

  //   console.log(singleProductData.mainCategory);

  // const [checked, setChecked] = React.useState({});
  const { id } = useParams();

  const handleSingleProductlistingById = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/get-single-product/${id}`
      );
      if (res) {
        console.log(res);
        setSingleProductData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // console.log(checked);

  React.useEffect(() => {
    const initialExpanded = {};
    const initialChecked = {};
    data.forEach((item) => {
      initialExpanded[item.name] = false;
      initialChecked[item.name] = false;
      item.subcategories.forEach((mainCategory) => {
        initialExpanded[mainCategory.name] = false;
        initialChecked[mainCategory.name] = false;
        mainCategory.subcategories.forEach((subCategory) => {
          initialExpanded[subCategory.name] = false;
          initialChecked[subCategory.name] = false;
        });
      });
    });
    setExpanded(initialExpanded);
    setChecked(initialChecked);
  }, [data]);

  const handleCategoryToggle = (categoryId) => {
    setExpanded((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };

  const handleCategoryCheck = (event, category) => {
    const isChecked = event.target.checked;
    setChecked((prevState) => ({
      ...prevState,
      [category.name]: isChecked,
    }));
    if (category.subcategories.length > 0) {
      const updatedChecked = category.subcategories.reduce(
        (acc, subCategory) => {
          acc[subCategory.name] = isChecked;
          // Check if the subcategory has any child categories
          if (subCategory.subcategories) {
            subCategory.subcategories.forEach((childCategory) => {
              acc[childCategory.name] = isChecked;
              // Recursively update the checked state of child categories
              if (childCategory.subcategories) {
                handleCategoryCheck(event, childCategory);
              }
            });
          }
          return acc;
        },
        {}
      );
      setChecked((prevState) => ({
        ...prevState,
        ...updatedChecked,
      }));
    }
    if (isChecked && category.parentCategoryId) {
      const parentCategory = data.find(
        (item) => item.name === category.parentCategoryId
      );
      if (
        parentCategory &&
        parentCategory.subcategories.every(
          (subCategory) =>
            checked[subCategory.name] &&
            subCategory.subcategories.every(
              (grandChildCategory) => checked[grandChildCategory.name]
            )
        )
      ) {
        setChecked((prevState) => ({
          ...prevState,
          [parentCategory.name]: true,
        }));
      }
    } else if (!isChecked && category.parentCategoryId) {
      setChecked((prevState) => ({
        ...prevState,
        [category.parentCategoryId]: false,
      }));
    } else if (isChecked && !category.parentCategoryId) {
      category.subcategories.forEach((subCategory) => {
        setChecked((prevState) => ({
          ...prevState,
          [subCategory.name]: true,
        }));
        if (subCategory.subcategories) {
          subCategory.subcategories.forEach((grandChildCategory) => {
            setChecked((prevState) => ({
              ...prevState,
              [grandChildCategory.name]: true,
            }));
          });
        }
      });
    }
  };

  // console.log(productType);
  // console.log(selectedId);

  const handleRootCategory = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllSubCategoriesForRoot"
      );

      if (res) {
        setData(res.data[0].subcategories);
        setRootName(res.data[0]);

        // Find the selected category object
        let selectedCategory = res.data[0].subcategories.find(
          (item) => item.name === singleProductData.Category
        );

        // Define main categories here
        let mainCategories = [];
        if (selectedCategory) {
          mainCategories = selectedCategory.subcategories;
        }

        setMainCategories(mainCategories);

        let selectedMainCategory = mainCategories.find(
          (item) => item.name === singleProductData.mainCategory
        );

        // console.log(selectedMainCategory);

        // Define main categories here
        let subMainCategories = [];
        if (selectedMainCategory) {
          subMainCategories = selectedMainCategory.subcategories;
        }
        // console.log(subMainCategories);
        setSubCategories(subMainCategories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllProductTypesCategory = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllProductTypesValues"
      );

      if (res) {
        setProductType(res.data);

        let selectedProductCategory = productType.find(
          (item) => item.ProductType === singleProductData.ProductType
        );

        console.log(selectedProductCategory._id);

        // console.log(selectedMainCategory);

        // Define main categories here
        // let subProductCategories = [];
        // if (selectedMainCategory) {
        //   subMainCategories = selectedMainCategory.subcategories;
        // }
        // // console.log(subMainCategories);
        // setSubCategories(subMainCategories);
        setSelectedId(selectedProductCategory._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    handleSingleProductlistingById();
  }, []);

  React.useEffect(() => {
    handleRootCategory();
    handleGetAllProductTypesCategory();
  }, [singleProductData]);

  return (
    <Box sx={{ width: "100%" }} style={{ marginTop: "150px" }}>
      <Box>
        {!loading ? (
          <>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                textColor="#fff"
                sx={{
                  "& .Mui-selected": {
                    backgroundColor: "#262626",
                    transition: "background-color 0.3s ease-in",
                    color: "white",
                  },
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                <Tab
                  label="COMMISSION CATEGORY"
                  disableRipple
                  style={{ border: "1px solid #dddddd", marginLeft: "25px" }}
                />
                <Tab
                  label="DISPLAY CATEGORY"
                  disableRipple
                  style={{ border: "1px solid #dddddd", marginLeft: "5px" }}
                />
                <Tab
                  label="BASIC INFO"
                  disableRipple
                  style={{ border: "1px solid #dddddd", marginLeft: "5px" }}
                />
                <Tab
                  label="ATTRIBUTE"
                  disableRipple
                  style={{ border: "1px solid #dddddd", marginLeft: "5px" }}
                />
                <Tab
                  label="COD AND SHIPPING"
                  disableRipple
                  style={{ border: "1px solid #dddddd", marginLeft: "5px" }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Card
                sx={{ maxWidth: "790px", border: "1px solid #dddddd", p: 2 }}
                elevation={0}
              >
                <Box sx={{ width: "650px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Typography>Category : </Typography>
                    <Select
                      displayEmpty
                      size="small"
                      sx={{ width: "350px" }}
                      value={singleProductData.Category || ""}
                      onChange={(e) => {
                        const newData = data.find(
                          (item) => item.name === e.target.value
                        );
                        setSingleProductData({
                          ...singleProductData,
                          Category: e.target.value,
                        });
                        setMainCategories(newData.subcategories);
                      }}
                    >
                      <InputLabel sx={{ ml: 2 }}>
                        --Select Category--
                      </InputLabel>
                      {data.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Typography>Product Category : </Typography>
                    <Select
                      displayEmpty
                      size="small"
                      sx={{ width: "350px" }}
                      value={singleProductData.mainCategory || ""}
                      onChange={(e) => {
                        const selectMainCategoryData = mainCategories.find(
                          (item) => item.name === e.target.value
                        );
                        setSingleProductData({
                          ...singleProductData,
                          mainCategory: e.target.value,
                        });
                        setSubCategories(selectMainCategoryData.subcategories);
                      }}
                    >
                      <InputLabel sx={{ ml: 2 }}>
                        --Select Category--
                      </InputLabel>
                      {mainCategories.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Typography>Product Sub Category : </Typography>
                    <Select
                      size="small"
                      sx={{ width: "350px" }}
                      value={singleProductData.subCategory || ""}
                      onChange={(e) =>
                        setSingleProductData({
                          ...singleProductData,
                          subCategory: e.target.value,
                        })
                      }
                      displayEmpty
                    >
                      <InputLabel sx={{ ml: 2 }}>
                        --Select Category--
                      </InputLabel>
                      {SubCategories.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Typography> </Typography>
                    <Button
                      variant="contained"
                      onClick={() => handleChange(0, 1)}
                    >
                      NEXT
                    </Button>
                  </Box>
                </Box>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box>
                <Card
                  sx={{ maxWidth: "790px", border: "1px solid #dddddd", p: 2 }}
                  elevation={0}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton disabled>
                      <ExpandMoreIcon />
                    </IconButton>
                    <Checkbox
                      checked={checked[RootName.name]}
                      onChange={(event) => handleCategoryCheck(event, RootName)}
                    />
                    <Typography>{RootName.name}</Typography>
                  </Box>
                  {data.map((item) => (
                    <Box sx={{ ml: 2 }} key={item._id}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={() => handleCategoryToggle(item._id)}
                        >
                          {expanded[item._id] &&
                          item.subcategories.length > 0 ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ChevronRightIcon />
                          )}
                        </IconButton>
                        <Checkbox
                          checked={singleProductData.data[item.name]}
                          onChange={(event) => handleCategoryCheck(event, item)}
                        />
                        <Typography>{item.name}</Typography>
                      </Box>
                      {expanded[item._id] && item.subcategories.length > 0 ? (
                        <Box sx={{ ml: 2 }}>
                          {item.subcategories.map((mainCategory) => (
                            <Box key={mainCategory._id}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <IconButton
                                  onClick={() =>
                                    handleCategoryToggle(mainCategory._id)
                                  }
                                >
                                  {expanded[mainCategory._id] &&
                                  mainCategory.subcategories.length > 0 ? (
                                    <ExpandMoreIcon />
                                  ) : (
                                    <ChevronRightIcon />
                                  )}
                                </IconButton>
                                <Checkbox
                                  checked={
                                    singleProductData.data[mainCategory.name]
                                  }
                                  onChange={(event) =>
                                    handleCategoryCheck(event, mainCategory)
                                  }
                                />
                                <Typography>{mainCategory.name}</Typography>
                              </Box>
                              {expanded[mainCategory._id] &&
                              mainCategory.subcategories.length > 0 ? (
                                <Box sx={{ ml: 7 }}>
                                  {mainCategory.subcategories.map(
                                    (subCategory) => (
                                      <Box
                                        key={subCategory._id}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Checkbox
                                          checked={
                                            singleProductData.data[
                                              subCategory.name
                                            ]
                                          }
                                          onChange={(event) =>
                                            handleCategoryCheck(
                                              event,
                                              subCategory
                                            )
                                          }
                                        />
                                        <Typography>
                                          {subCategory.name}
                                        </Typography>
                                      </Box>
                                    )
                                  )}
                                </Box>
                              ) : null}
                            </Box>
                          ))}
                        </Box>
                      ) : null}
                    </Box>
                  ))}
                  <Typography sx={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      sx={{ mt: 2 }}
                      onClick={() => handleChange(0, 2)}
                    >
                      NEXT
                    </Button>
                  </Typography>
                </Card>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <BasicInfo
                checked={checked}
                setChecked={setChecked}
                productType={productType}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                handleChange={handleChange}
                PreBook={checked.PreBook}
                replacement={checked.replacement}
                refund={checked.refund}
                Title={checked.Title}
                singleProductData={singleProductData}
                setSingleProductData={setSingleProductData}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Attribute
                selectedId={selectedId}
                handleChange={handleChange}
                checked={checked}
                setChecked={setChecked}
                Color={checked.Color}
                Size={checked.Size}
                singleProductData={singleProductData}
                setSingleProductData={setSingleProductData}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <CodAndShipping
                checked={checked}
                setChecked={setChecked}
                CodAvailable={checked.CodAvailable}
                ShippingWeight={checked.ShippingWeight}
                Fragile={checked.Fragile}
                singleProductData={singleProductData}
                setSingleProductData={setSingleProductData}
              />
            </TabPanel>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
}
