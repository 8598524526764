import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UpdateReturnPolicy, getPolicyById } from "../../../axios";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const EditReturnPolicy = () => {
  const [data, setdata] = useState({});

  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const handleGetPolicy = async () => {
    setLoading(true);
    try {
      const res = await getPolicyById(id);
      if (res) {
        setdata(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateReturnPolicy = async () => {
    setLoading1(true);
    try {
      const res = await UpdateReturnPolicy(
        id,
        data.Heading,
        data.Description,
        data.days
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated Successfully",
          showConfirmButton: true,
        });
        navigate("/Return-policy");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    handleGetPolicy();
  }, []);

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Manage Return Policy
        </Typography>
        <Button
          variant="contained"
          onClick={handleUpdateReturnPolicy}
          disabled={Loading1}
        >
          {Loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
        </Button>
      </Box>
      {!Loading ? (
        <Box sx={{ mt: 3, ml: 3, mr: 3 }}>
          <Box sx={{ border: "1px solid #ddd" }}>
            <Box sx={{ p: 2, background: "#000" }}>
              <Typography sx={{ fontWeight: "bold", color: "#fff" }}>
                Edit Return Policy
              </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Heading:
                </Typography>
                <TextField
                  size="small"
                  placeholder="Heading"
                  sx={{ width: "400px" }}
                  value={data.Heading}
                  onChange={(e) =>
                    setdata({ ...data, Heading: e.target.value })
                  }
                />
              </Box>
            </Box>
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Description:
                </Typography>
                <textarea
                  size="small"
                  placeholder="Description"
                  style={{
                    width: "380px",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                    padding: "10px",
                    height: "300px",
                    resize: "vertical",
                  }}
                  value={data.Description}
                  onChange={(e) =>
                    setdata({ ...data, Description: e.target.value })
                  }
                />
              </Box>
            </Box>
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  No.Of Days:
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  placeholder="No.of Days"
                  sx={{ width: "400px" }}
                  value={data.days}
                  onChange={(e) => setdata({ ...data, days: e.target.value })}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default EditReturnPolicy;
