import { Box, Card, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "../App.css";

const Footer = () => {
  const [data, setData] = useState([]);
  const [storeProfile, setStoreProfile] = useState({});

  const handleGetAllStoreProfile = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllProfilers"
      );
      if (res) {
        setData(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStoreProfile = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const result = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getStoreProfile/${data._id}`,
        config
      );
      if (result) {
        setStoreProfile(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllStoreProfile();
  }, []);

  useEffect(() => {
    handleStoreProfile();
  }, [data]);

  return (
    <div className="footer">
      <Card
        elevation={0}
        sx={{
          border: "1px solid #eee",
          background: "#eee",
          p: 1.5,
          mt: 3,
        }}
      >
        <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
          {storeProfile?.copyRightMessage}
        </Typography>
      </Card>
    </div>
  );
};

export default Footer;
