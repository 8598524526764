import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const ManageRecentlyViewed = ({ toast }) => {
  const [id, setId] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const handleGetAllRecentlyViewed = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/handleGetAllRecentlyViewed`
      );
      if (res) {
        // console.log(res);
        setId(res.data[0]._id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetAllRecentlyViewedById = async () => {
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/handleGetRecentlyViewedById/${id}`
      );
      if (res) {
        // console.log(res.data);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateRecentlyViewed = async () => {
    setLoading1(true);
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/handleUpdateRecentlyViewed/${id}`,
        {
          Heading: data.Heading,
          ItemsCount: data.ItemsCount,
          ButtonText: data.ButtonText,
          ButtonURL: data.ButtonURL,
        }
      );
      if (res) {
        // console.log(res);
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };
  useEffect(() => {
    handleGetAllRecentlyViewed();
  }, []);

  useEffect(() => {
    handleGetAllRecentlyViewedById();
  }, [id]);
  return (
    <div style={{ marginTop: "150px" }}>
      {!loading ? (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ml: 3,
              mr: 3,
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
              Manage Recently viewed
            </Typography>
            <Button variant="contained">Publish</Button>
          </Box>
          <Box sx={{ mt: 2, ml: 3 }}>
            <Box>
              <Typography>Heading</Typography>
              <TextField
                type="text"
                size="small"
                placeholder="Heading"
                value={data.Heading}
                onChange={(e) => setData({ ...data, Heading: e.target.value })}
                sx={{ width: "450px", mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <Typography>Items Count</Typography>
              <TextField
                type="text"
                size="small"
                placeholder="Items Count"
                value={data.ItemsCount}
                onChange={(e) =>
                  setData({ ...data, ItemsCount: e.target.value })
                }
                sx={{ width: "450px", mb: 1, mt: 0.5 }}
              />
              <Typography sx={{ color: "red", mb: 2 }}>
                * Note : How many items need to show in the Recently viewed
                Panel
              </Typography>
            </Box>
            <Box>
              <Typography>Button Text</Typography>
              <TextField
                type="text"
                size="small"
                placeholder="Button Text"
                value={data.ButtonText}
                onChange={(e) =>
                  setData({ ...data, ButtonText: e.target.value })
                }
                sx={{ width: "450px", mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <Typography>Button URL</Typography>
              <TextField
                type="text"
                size="small"
                placeholder="Button URL"
                value={data.ButtonURL}
                onChange={(e) =>
                  setData({ ...data, ButtonURL: e.target.value })
                }
                sx={{ width: "450px", mb: 2, mt: 0.5 }}
              />
            </Box>
            <Button
              variant="contained"
              sx={{ width: "450px" }}
              onClick={handleUpdateRecentlyViewed}
              disabled={loading1}
            >
              {loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default ManageRecentlyViewed;
