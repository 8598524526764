import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { BiEdit, BiShow, BiHide } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Wysiwyg } from "@mui/icons-material";

export default function StaffLoginTable({ navigations }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibility, setVisibility] = React.useState({});
  const [selected, setSelected] = useState("FirstName");
  const [searchQuery, setSearchQuery] = useState("");

  const handleDeleteStaff = async (id) => {
    try {
      const res = await axios.delete(
        `https://rccore-backend.cstechns.com/api/admin/DeleteStaffById/${id}`
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });
        setData(data.filter((item) => item._id !== id));
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "error",
        showConfirmButton: true,
        timer: 1500,
      });
    }
  };

  const navigate = useNavigate();
  const handleGetAllStaffLoginData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/allStaffLogins"
      );
      if (res) {
        const visibilityObj = {};
        res.data.forEach((row) => {
          visibilityObj[row._id] = !row.active;
        });
        setData(res.data);
        setVisibility(visibilityObj);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVisibility = async (id, value) => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/updateStaffLogin/${id}`,
        {
          active: value,
        }
      );
      if (res) {
        setVisibility((prevState) => ({
          ...prevState,
          [id]: !value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = () => {
    let filteredData = data;
    if (selected === "FirstName") {
      filteredData = filteredData.filter((row) =>
        row.firstName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (selected === "LastName") {
      filteredData = filteredData.filter((row) =>
        row.lastName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (selected === "Email") {
      filteredData = filteredData.filter((row) =>
        row.email.includes(searchQuery)
      );
    }

    return filteredData;
  };

  useEffect(() => {
    handleGetAllStaffLoginData();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchQuery, data, selected]);
  return (
    <>
      <Box sx={{ ml: 3, mb: 2 }}>
        <Typography>Filter By</Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Box>
            <Select
              size="small"
              sx={{ width: "200px" }}
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
            >
              <MenuItem value="FirstName">FirsName</MenuItem>
              <MenuItem value="LastName">LastName</MenuItem>
              <MenuItem value="Email">Email</MenuItem>
            </Select>
          </Box>
          <TextField
            size="small"
            sx={{ width: "300px" }}
            placeholder="search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>
      </Box>
      {!loading ? (
        <>
          <TableContainer
            component={Paper}
            sx={{ width: "96.5%", ml: 3, border: "1px solid #dddddd" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ background: "#dddddd" }}>
                <TableRow>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    S.No
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    FirsName
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    LastName
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Email
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Password
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {handleSearch().map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">{row.firstName}</TableCell>
                    <TableCell align="center">{row.lastName}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">{row.password}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() =>
                            navigate(`/staff-login-edit/${row._id}`)
                          }
                        >
                          <BiEdit size={22} />
                        </IconButton>
                      </Tooltip>
                      {visibility[row._id] ? (
                        <Tooltip title="Activate">
                          <IconButton
                            onClick={() =>
                              handleVisibility(row._id, visibility[row._id])
                            }
                          >
                            <BiHide style={{ color: "red" }} size={22} />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="DeActivate">
                          <IconButton
                            onClick={() =>
                              handleVisibility(row._id, visibility[row._id])
                            }
                          >
                            <BiShow style={{ color: "green" }} size={22} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Delete">
                        <IconButton onClick={() => handleDeleteStaff(row._id)}>
                          <MdDelete size={22} style={{ color: "red" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Manage Permessions">
                        <IconButton
                          onClick={() =>
                            navigate(`/ManagePermessions/${row._id}`, {
                              state: { navigations: navigations },
                            })
                          }
                        >
                          <Wysiwyg size={22} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
