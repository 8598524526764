import {
  Button,
  Card,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Swal from "sweetalert2";
import StoreCalculationManageModal from "./StoreCalcuationManageModal";
import StoreCalculationManageModal1 from "./StoreCalcuationManageModal1";
import StoreCalculationManageModal2 from "./StoreCalcuationManageModal2";

const StoreCalculation = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [ReverseShippingChargesError, setReverseShippingChargesError] =
    useState("");
  const [GSTError, setGSTError] = useState("");
  const [DeliveryOrderError, setDeliveryOrderError] = useState("");
  const [TCSChargesError, setTCSChargesError] = useState("");
  const [TDSChargesError, setTDSChargesError] = useState("");
  const [ApplicableAfterError, setApplicableAfterError] = useState("");
  const [storeData, setStoreData] = useState([]);
  // console.log(data);

  const { id } = useParams();

  // handle StoreProfile Data
  const handleStoreProfileData = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllProfilers"
      );

      if (res) {
        console.log(res);
        setStoreData(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReverseShippingChargesChange = (e) => {
    if (e.target.value.length === 0) {
      setReverseShippingChargesError("Feild is Required");
    } else {
      setReverseShippingChargesError("");
    }
    setData({
      ...data,
      ReverseShippingChargesType: e.target.value,
    });
  };
  const handleGSTChange = (e) => {
    if (e.target.value.length === 0) {
      setGSTError("Feild is Required");
    } else {
      setGSTError("");
    }
    setData({
      ...data,
      GSTType: e.target.value,
    });
  };
  const handleVATChange = (e) => {
    if (e.target.value.length === 0) {
      setGSTError("Feild is Required");
    } else {
      setGSTError("");
    }
    setData({
      ...data,
      VATType: e.target.value,
    });
  };
  const handleDelivaryOrderAboveChange = (e) => {
    if (e.target.value.length === 0) {
      setDeliveryOrderError("Feild is Required");
    } else {
      setDeliveryOrderError("");
    }
    setData({
      ...data,
      FreeDeliveryOrderAbove: e.target.value,
    });
  };
  const handleTCSChargesChange = (e) => {
    if (e.target.value.length === 0) {
      setTCSChargesError("Feild is Required");
    } else {
      setTCSChargesError("");
    }
    setData({
      ...data,
      TCSChargesType: e.target.value,
    });
  };
  const handleTDSChargesChange = (e) => {
    if (e.target.value.length === 0) {
      setTDSChargesError("Feild is Required");
    } else {
      setTDSChargesError("");
    }
    setData({
      ...data,
      TDSChargesType: e.target.value,
    });
  };
  const handleApplicableAfterChange = (e) => {
    if (e.target.value.length === 0) {
      setApplicableAfterError("Feild is Required");
    } else {
      setApplicableAfterError("");
    }
    setData({
      ...data,
      ApplicableAfter: e.target.value,
    });
  };

  const handleStoreCalculationData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/getStoreCalculationDataById/${id}`,
        config
      );
      if (res) {
        // console.log("calc", res.data);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // handle Updatet the store Calculation Data

  const handleUpdateStoreCalculationData = async () => {
    setLoading1(true);
    try {
      if (
        ReverseShippingChargesError ||
        GSTError ||
        DeliveryOrderError ||
        TCSChargesError ||
        TDSChargesError ||
        ApplicableAfterError
      ) {
        return;
      }
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/updateStoreCalculationData/${id}`,
        data,
        config
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Data Updated SuccessFully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    handleStoreCalculationData();
  }, []);

  useEffect(() => {
    handleStoreProfileData();
  }, []);
  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          ml: 3,
          mr: 3,
          mb: 2,
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Store Calculation
        </Typography>
        <Button
          variant="contained"
          onClick={handleUpdateStoreCalculationData}
          disabled={loading1}
          disableElevation
        >
          {loading1 ? <CircularProgress size={20} /> : "SUBMIT"}
        </Button>
      </Box>
      {!loading ? (
        <>
          <Card
            elevation={0}
            sx={{
              border: "1px solid #dddddd",
              p: 2,
              ml: 3,
              mr: 3,
              mt: 2,
              mb: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "80%" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                  Comapany Name :{" "}
                </Typography>
                <Typography>{storeData.CompanyName}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  // alignItems: "center",
                  gap: "105px",
                  mt: 2,
                }}
              >
                <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                  Address:{" "}
                </Typography>
                <Typography>{storeData.ComapnayAddress}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "30px",
                  mt: 2,
                }}
              >
                <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                  Default Currency:{" "}
                </Typography>
                <Typography>{storeData.DefaultCurrency}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "40%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",

                  alignItems: "start",
                  justifyContent: "end",
                  // mt: 2,
                }}
              >
                <Typography
                  sx={{ fontSize: "17px", fontWeight: "bold", display: "flex" }}
                >
                  Pincode:{" "}
                </Typography>
                <Typography sx={{ display: "flex", ml: 9.5 }}>
                  {storeData.PinCode}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",

                  alignItems: "start",
                  justifyContent: "start",
                  mt: 2,
                }}
              >
                <Typography
                  sx={{ fontSize: "17px", fontWeight: "bold", display: "flex" }}
                >
                  City:{" "}
                </Typography>
                <Typography sx={{ display: "flex", ml: 14 }}>
                  {storeData.City}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",

                  alignItems: "start",
                  justifyContent: "start",
                  mt: 2,
                }}
              >
                <Typography
                  sx={{ fontSize: "17px", fontWeight: "bold", display: "flex" }}
                >
                  State,Country:{" "}
                </Typography>
                <Typography sx={{ display: "flex", ml: 4 }}>
                  {storeData.State},{storeData.Country}
                </Typography>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",

                  alignItems: "start",
                  justifyContent: "start",
                  mt: 2,
                }}
              >
                <Typography
                  sx={{ fontSize: "17px", fontWeight: "bold", display: "flex" }}
                >
                  Country:{" "}
                </Typography>
                <Typography sx={{ display: "flex" }}>
                  {storeData.Country}
                </Typography>
              </Box> */}
            </Box>
          </Card>
          <Card
            elevation={0}
            sx={{ border: "1px solid #dddddd", p: 2, ml: 3, mr: 3 }}
            style={{ marginBottom: "20px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>Commission on Product Sale :</Typography>
              <FormControl sx={{ ml: -29 }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    checked={data.CommissionOnProduct === true}
                    label="Active"
                  />
                </RadioGroup>
              </FormControl>
              <Typography></Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography>
                Closing Fee (
                {storeData.DefaultCurrency &&
                  storeData.DefaultCurrency.split("-")[1]}
                ):
                <br />
                (Fixed amount per order charged from seller)
              </Typography>
              <FormControl sx={{ ml: -30 }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{ ml: 1.8 }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    checked={data.ClosingFee === true}
                    onChange={(e) => setData({ ...data, ClosingFee: true })}
                    label="Active"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    checked={data.ClosingFee === false}
                    onChange={(e) => setData({ ...data, ClosingFee: false })}
                    label="DeActive"
                  />
                </RadioGroup>
              </FormControl>
              <Box>
                <Typography sx={{ ml: -30 }}>Type</Typography>
              </Box>
            </Box>
            <Box style={{ display: "flex", justifyContent: "end" }}>
              <Select
                style={{ width: "250px" }}
                value={data.closingFeeType || ""}
                onChange={(e) =>
                  setData({ ...data, closingFeeType: e.target.value })
                }
                size="small"
              >
                <MenuItem value="Flat">Flat</MenuItem>
                <MenuItem value="Differential">Differential</MenuItem>
              </Select>
            </Box>
            {data.closingFeeType == "Flat" && (
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <TextField
                  value={data?.closingFeeTypeFlatValue || ""}
                  onChange={(e) =>
                    setData({
                      ...data,
                      closingFeeTypeFlatValue: e.target.value,
                    })
                  }
                  size="small"
                  sx={{ width: "250px", mt: 2 }}
                />
              </Box>
            )}
            {data.closingFeeType == "Differential" && (
              <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
                <StoreCalculationManageModal
                  data={data}
                  setData={setData}
                  handleUpdateStoreCalculationData={
                    handleUpdateStoreCalculationData
                  }
                />
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography>
                Payment Processing Charges for COD (
                {storeData.DefaultCurrency &&
                  storeData.DefaultCurrency.split("-")[1]}
                )
                <br /> (Percentage order amount charged to seller)
              </Typography>
              <FormControl sx={{ ml: -30 }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{ ml: 1.8 }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    checked={data.PaymentChargesForCod === true}
                    onChange={(e) =>
                      setData({ ...data, PaymentChargesForCod: true })
                    }
                    label="Active"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    checked={data.PaymentChargesForCod === false}
                    onChange={(e) =>
                      setData({ ...data, PaymentChargesForCod: false })
                    }
                    label="DeActive"
                  />
                </RadioGroup>
              </FormControl>
              <Box>
                <Typography sx={{ ml: -30 }}>Type</Typography>
              </Box>
            </Box>
            <Box>
              <Box style={{ display: "flex", justifyContent: "end" }}>
                <Select
                  style={{ width: "250px" }}
                  value={data.PaymentChargesForCodType || ""}
                  onChange={(e) =>
                    setData({
                      ...data,
                      PaymentChargesForCodType: e.target.value,
                    })
                  }
                  size="small"
                >
                  <MenuItem value="Flat">Flat</MenuItem>
                  <MenuItem value="Differential">Differential</MenuItem>
                </Select>
              </Box>
              {data.PaymentChargesForCodType == "Flat" && (
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <TextField
                    value={data.PaymentChargesForCodTypeFlat}
                    onChange={(e) =>
                      setData({
                        ...data,
                        PaymentChargesForCodTypeFlat: e.target.value,
                      })
                    }
                    size="small"
                    sx={{ width: "250px", mt: 2 }}
                  />
                </Box>
              )}
              {data.PaymentChargesForCodType == "Differential" && (
                <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
                  <StoreCalculationManageModal1
                    data={data}
                    setData={setData}
                    handleUpdateStoreCalculationData={
                      handleUpdateStoreCalculationData
                    }
                    DefaultCurrency={storeData.DefaultCurrency}
                  />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography>
                Payment Processing Charges for ONLINE (
                {storeData.DefaultCurrency &&
                  storeData.DefaultCurrency.split("-")[1]}
                ):
                <br />
                (Percentage order amount charged to seller)
              </Typography>
              <FormControl sx={{ ml: -30 }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    checked={data.PaymentChargesForOnline === true}
                    onChange={(e) =>
                      setData({ ...data, PaymentChargesForOnline: true })
                    }
                    label="Active"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    checked={data.PaymentChargesForOnline === false}
                    onChange={(e) =>
                      setData({ ...data, PaymentChargesForOnline: false })
                    }
                    label="DeActive"
                  />
                </RadioGroup>
              </FormControl>
              <Box>
                <Typography sx={{ ml: -30 }}>Type:</Typography>
              </Box>
            </Box>
            <Box>
              <Box style={{ display: "flex", justifyContent: "end" }}>
                <Select
                  style={{ width: "250px" }}
                  value={data.PaymentChargesForOnlineType || ""}
                  onChange={(e) =>
                    setData({
                      ...data,
                      PaymentChargesForOnlineType: e.target.value,
                    })
                  }
                  size="small"
                >
                  <MenuItem value="Flat">Flat</MenuItem>
                  <MenuItem value="Differential">Differential</MenuItem>
                </Select>
              </Box>
              {data.PaymentChargesForOnlineType == "Flat" && (
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <TextField
                    value={data.PaymentChargesForOnlineTypeFlat}
                    onChange={(e) =>
                      setData({
                        ...data,
                        PaymentChargesForOnlineTypeFlat: e.target.value,
                      })
                    }
                    size="small"
                    sx={{ width: "250px", mt: 2 }}
                  />
                </Box>
              )}
              {data.PaymentChargesForOnlineType == "Differential" && (
                <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
                  <StoreCalculationManageModal2
                    data={data}
                    setData={setData}
                    handleUpdateStoreCalculationData={
                      handleUpdateStoreCalculationData
                    }
                    DefaultCurrency={storeData.DefaultCurrency}
                  />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography>
                Reverse Shipping Charges (
                {storeData.DefaultCurrency &&
                  storeData.DefaultCurrency.split("-")[1]}
                ):
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{ ml: 15 }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    checked={data.ReverseShippingCharges === true}
                    onChange={(e) =>
                      setData({ ...data, ReverseShippingCharges: true })
                    }
                    label="Active"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    checked={data.ReverseShippingCharges === false}
                    onChange={(e) =>
                      setData({ ...data, ReverseShippingCharges: false })
                    }
                    label="DeActive"
                  />
                </RadioGroup>
              </FormControl>

              <TextField
                sx={{ width: "250px" }}
                size="small"
                value={data.ReverseShippingChargesType}
                error={ReverseShippingChargesError}
                helperText={ReverseShippingChargesError}
                onChange={handleReverseShippingChargesChange}
              />
            </Box>
            {storeData.Country == "India" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Typography>
                    GST (
                    {storeData.DefaultCurrency &&
                      storeData.DefaultCurrency.split("-")[1]}
                    ):
                    <br />
                    (on Earnings from Sellers)
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{ ml: 19 }}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        checked={data.GST === true}
                        onChange={(e) => setData({ ...data, GST: true })}
                        label="Active"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        checked={data.GST === false}
                        onChange={(e) => setData({ ...data, GST: false })}
                        label="DeActive"
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    sx={{ width: "250px" }}
                    size="small"
                    value={data.GSTType}
                    error={GSTError}
                    helperText={GSTError}
                    onChange={handleGSTChange}
                  />
                </Box>
              </>
            )}
            <Box>
              {storeData.Country != "India" && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <Typography>
                      VAT (
                      {storeData.DefaultCurrency &&
                        storeData.DefaultCurrency.split("-")[1]}
                      ):
                      <br />
                      (on Earnings from Sellers)
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        sx={{ ml: 19 }}
                      >
                        <FormControlLabel
                          value={data.VAT}
                          control={<Radio />}
                          checked={data.VAT === true}
                          onChange={(e) => setData({ ...data, VAT: true })}
                          label="Active"
                        />
                        <FormControlLabel
                          value={data.VAT}
                          control={<Radio />}
                          checked={data.VAT === false}
                          onChange={(e) => setData({ ...data, VAT: false })}
                          label="DeActive"
                        />
                      </RadioGroup>
                    </FormControl>
                    <TextField
                      sx={{ width: "250px" }}
                      size="small"
                      value={data.VATType}
                      error={GSTError}
                      helperText={GSTError}
                      onChange={handleVATChange}
                    />
                  </Box>
                </>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "%",
                mt: 2,
              }}
            >
              <Typography>
                Free Delivery Order Above (
                {storeData.DefaultCurrency &&
                  storeData.DefaultCurrency.split("-")[1]}
                ):
              </Typography>

              <TextField
                sx={{ width: "250px" }}
                size="small"
                value={data.FreeDeliveryOrderAbove}
                error={DeliveryOrderError}
                helperText={DeliveryOrderError}
                onChange={handleDelivaryOrderAboveChange}
              />
            </Box>
            {storeData.Country == "India" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "%",
                    mt: 2,
                  }}
                >
                  <Typography>
                    TCS Charges (
                    {storeData.DefaultCurrency &&
                      storeData.DefaultCurrency.split("-")[1]}
                    ):{" "}
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{ ml: 27 }}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        checked={data.TCSCharges === true}
                        onChange={(e) => setData({ ...data, TCSCharges: true })}
                        label="Active"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        checked={data.TCSCharges === false}
                        onChange={(e) =>
                          setData({ ...data, TCSCharges: false })
                        }
                        label="DeActive"
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    sx={{ width: "250px" }}
                    size="small"
                    value={data.TCSChargesType}
                    error={TCSChargesError}
                    helperText={TCSChargesError}
                    onChange={handleTCSChargesChange}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "%",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <Typography>
                    TDS Charges (
                    {storeData.DefaultCurrency &&
                      storeData.DefaultCurrency.split("-")[1]}
                    ):{" "}
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{ ml: 27 }}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        checked={data.TDSCharges === true}
                        onChange={(e) => setData({ ...data, TDSCharges: true })}
                        label="Active"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        checked={data.TDSCharges === false}
                        onChange={(e) =>
                          setData({ ...data, TDSCharges: false })
                        }
                        label="DeActive"
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    sx={{ width: "250px" }}
                    size="small"
                    value={data.TDSChargesType}
                    error={TDSChargesError}
                    helperText={TDSChargesError}
                    onChange={handleTDSChargesChange}
                  />
                </Box>
              </>
            )}
          </Card>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default StoreCalculation;
