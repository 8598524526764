import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";

const SellerEdit = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [SLAError, setSLAError] = useState();
  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [imageBrandAuthorizationLetter, setBrandAuthorizationLetterImage] =
    useState(null);
  const [imageCompanyTradeLicense, setCompanyTradeLicenseImage] =
    useState(null);
  const [imageVATCertificate, setVATImage] = useState(null);

  const cloudName = "bunny1705";
  const apiKey = "686471132156718";
  const apiSecret = "PF0u158oD2YzCfWEou1-ijeVOmQ";

  const handleSLAChange = (e) => {
    const value = e.target.value;
    setData({ ...data, SLAPeriod: value });
    if (value.length === 0) {
      setSLAError("SLA Period is Required");
    } else if (value <= 0) {
      setSLAError("SLA Period Must be Greater than 0");
    } else {
      setSLAError("");
    }
  };

  const { id } = useParams();

  const generateSignature = (publicId) => {
    const timestamp = Date.now();
    const signature = CryptoJS.SHA1(
      `public_id=${publicId}&timestamp=${timestamp}${apiSecret}`
    ).toString();
    return { timestamp, signature };
  };

  const handleDeleteBrandAuthorizationLetter = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      if (data.BrandAuthorizationLetter) {
        const publicId = data.BrandAuthorizationLetter.split("/")
          .slice(-1)[0]
          .split(".")[0];
        console.log(publicId);
        const { signature, timestamp } = generateSignature(publicId);
        const formData = new FormData();
        formData.append("public_id", publicId);
        formData.append("timestamp", timestamp);
        formData.append("api_key", apiKey);
        formData.append("signature", signature);

        const res = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/destroy`,
          formData
        );
        if (res) {
          // console.log(res);
        }
      }
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/EditSellerData/${id}`,
        {
          BrandAuthorizationLetter: "",
        },
        config
      );
      if (res) {
        setData((prevState) => ({
          ...prevState,
          BrandAuthorizationLetter: "",
        }));
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteVAT = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      if (data.VATCertificate) {
        const publicId = data.VATCertificate.split("/")
          .slice(-1)[0]
          .split(".")[0];
        console.log(publicId);
        const { signature, timestamp } = generateSignature(publicId);
        const formData = new FormData();
        formData.append("public_id", publicId);
        formData.append("timestamp", timestamp);
        formData.append("api_key", apiKey);
        formData.append("signature", signature);

        const res = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/destroy`,
          formData
        );
        if (res) {
          // console.log(res);
        }
      }
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/EditSellerData/${id}`,
        {
          VATCertificate: "",
        },
        config
      );
      if (res) {
        setData((prevState) => ({ ...prevState, VATCertificate: "" }));
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteCompanyTradeLicense = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      if (data.CompanyTradeLicense) {
        const publicId = data?.CompanyTradeLicense?.split("/")
          .slice(-1)[0]
          .split(".")[0];
        console.log(publicId);
        const { signature, timestamp } = generateSignature(publicId);
        const formData = new FormData();
        formData.append("public_id", publicId);
        formData.append("timestamp", timestamp);
        formData.append("api_key", apiKey);
        formData.append("signature", signature);

        const res = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/destroy`,
          formData
        );
        if (res) {
          // console.log(res);
        }
      }
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/EditSellerData/${id}`,
        {
          CompanyTradeLicense: "",
        },
        config
      );
      if (res) {
        setData((prevState) => ({
          ...prevState,
          CompanyTradeLicense: "",
        }));
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSellerById = async () => {
    setLoading(true);
    try {
      await axios
        .get(
          `https://rccore-backend.cstechns.com/api/admin/getSellerByID/${id}`
        )
        .then((res) => {
          // console.log(res.data);
          setData(res.data);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSellerById();
  }, []);

  // const handle Seller Edit

  const handleSellerEdit = async () => {
    setLoading1(true);
    try {
      if (!data.SLAPeriod) {
        return;
      } else {
        setSLAError(null);
      }
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const imageBrandAuthorizationLetterData = new FormData();
      imageBrandAuthorizationLetterData.append(
        "file",
        imageBrandAuthorizationLetter
      );
      imageBrandAuthorizationLetterData.append("upload_preset", "insta-clone");
      imageBrandAuthorizationLetterData.append("cloud_name", "bunny1705");

      const imageBrandAuthorizationLetterRes = await fetch(
        "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
        {
          method: "POST",
          body: imageBrandAuthorizationLetterData,
        }
      );

      const imageBrandAuthorizationLetterDataJson =
        await imageBrandAuthorizationLetterRes.json();
      const imageBrandAuthorizationLetterUrl =
        imageBrandAuthorizationLetterDataJson.url;

      setData((prevState) => ({
        ...prevState,
        BrandAuthorizationLetter: imageBrandAuthorizationLetterUrl,
      }));
      setBrandAuthorizationLetterImage(null);

      const imageCompanyTradeLicenseData = new FormData();
      imageCompanyTradeLicenseData.append("file", imageCompanyTradeLicense);
      imageCompanyTradeLicenseData.append("upload_preset", "insta-clone");
      imageCompanyTradeLicenseData.append("cloud_name", "bunny1705");

      const imageCompanyTradeLicenseRes = await fetch(
        "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
        {
          method: "POST",
          body: imageCompanyTradeLicenseData,
        }
      );

      const imageCompanyTradeLicenseDataJson =
        await imageCompanyTradeLicenseRes.json();
      const imageCompanyTradeLicenseUrl = imageCompanyTradeLicenseDataJson.url;
      setData((prevState) => ({
        ...prevState,
        CompanyTradeLicense: imageCompanyTradeLicenseUrl,
      }));
      setCompanyTradeLicenseImage(null);

      const imageVATCertificateData = new FormData();
      imageVATCertificateData.append("file", imageVATCertificate);
      imageVATCertificateData.append("upload_preset", "insta-clone");
      imageVATCertificateData.append("cloud_name", "bunny1705");

      const imageVATCertificateRes = await fetch(
        "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
        {
          method: "POST",
          body: imageVATCertificateData,
        }
      );

      const imageVATCertificateDataJson = await imageVATCertificateRes.json();
      const imageVATCertificateUrl = imageVATCertificateDataJson.url;
      setData((prevState) => ({
        ...prevState,
        VATCertificate: imageVATCertificateUrl,
      }));
      setVATImage(null);

      //

      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/EditSellerData/${id}`,
        {
          fullName: data.fullName,
          mobileNumber: data.mobileNumber,
          SLAPeriod: data.SLAPeriod,
          companyName: data.companyName,
          companyDisplayName: data.companyDisplayName,
          POBox: data.POBox,
          warehouseAddress: data.warehouseAddress,
          warehouselandmark: data.warehouselandmark,
          warehousePersonTitle: data.warehousePersonTitle,
          warehousePersonPhoneNumber: data.warehousePersonPhoneNumber,
          warehousePersonEmail: data.warehousePersonEmail,
          BrandAuthorizationLetter: data.BrandAuthorizationLetter,
          CompanyTradeLicense: data.CompanyTradeLicense,
          VATCertificate: data.VATCertificate,
          sellerAccountNumber: data.sellerAccountNumber,
          sellerBankAccountName: data.sellerBankAccountName,
          sellerBankName: data.sellerBankName,
          sellerIBAN: data.sellerIBAN,
          sellerSWIFTCode: data.sellerSWIFTCode,
          sellerFinanceContactPersonName: data.sellerFinanceContactPersonName,
          SellerFinanceContactEmailID: data.SellerFinanceContactEmailID,
          approved: data.approved,
          sellerActiveCount:
            data.approved === true
              ? data.sellerActiveCount + 1
              : data.sellerActiveCount + 1,
        },
        config
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res.data.success,
          showConfirmButton: true,
          timer: 1500,
        });
        navigate("/sellers");
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "success",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    } finally {
      setLoading1(false);
    }
  };

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          background: isFixed ? "#000" : "transparent",
          p: 1.2,
          position: isFixed ? "fixed" : "relative",
          top: isFixed && 130,
          left: 0,
          right: 0,
          zIndex: 999,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ml: 3,
            mr: 3,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "20px",
              color: isFixed ? "white" : "black",
            }}
          >
            Seller Edit
          </Typography>
          <Button
            variant="contained"
            disableElevation
            onClick={handleSellerEdit}
            disabled={Loading1}
          >
            {Loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
          </Button>
        </Box>
      </Box>
      {!Loading ? (
        <Box>
          <Card
            sx={{
              border: "1px solid #dddddd",
              width: "500px",
              margin: "auto",
              mt: 3,
              p: 2,
            }}
            elevation={0}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                mb: 2,
                textAlign: "center",
              }}
            >
              Contact Details
            </Typography>
            <Box>
              <TextField
                variant="outlined"
                label="Full Name"
                fullWidth
                value={data.fullName}
                onChange={(e) => setData({ ...data, fullName: e.target.value })}
                sx={{ mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <TextField
                variant="outlined"
                label="Email"
                fullWidth
                value={data.email}
                disabled
                sx={{ mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <TextField
                variant="outlined"
                label=" Seller Unique ID"
                fullWidth
                value={data.sellerId}
                onChange={(e) => setData({ ...data, sellerId: e.target.value })}
                sx={{ mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <TextField
                variant="outlined"
                label="Password"
                fullWidth
                value={data.mobileNumber}
                onChange={(e) =>
                  setData({ ...data, mobileNumber: e.target.value })
                }
                sx={{ mb: 1, mt: 0.5 }}
              />
            </Box>
            <Box>
              <Typography
                sx={{ mt: 2, mb: 2, color: "#0099cc", cursor: "pointer" }}
                onClick={() => setShowPassword((item) => !item)}
              >
                Click to view password
              </Typography>
            </Box>
            {showPassword === true && (
              <Box>
                <TextField
                  variant="outlined"
                  label="Mobile No"
                  fullWidth
                  value={data.password}
                  sx={{ mb: 1, mt: 0.5 }}
                />
              </Box>
            )}
          </Card>
          <Card
            sx={{
              border: "1px solid #dddddd",
              width: "500px",
              margin: "auto",
              mt: 3,
              p: 2,
            }}
            elevation={0}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                mb: 2,
                textAlign: "center",
              }}
            >
              Entity Details
            </Typography>
            <Box>
              <TextField
                variant="outlined"
                label="Company Name"
                fullWidth
                value={data?.companyName}
                onChange={(e) => {
                  setData({
                    ...data,
                    companyName: e.target.value,
                  });
                }}
                sx={{ mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <TextField
                variant="outlined"
                label="Company Display Name"
                fullWidth
                value={data?.companyDisplayName}
                onChange={(e) => {
                  setData({
                    ...data,

                    companyDisplayName: e.target.value,
                  });
                }}
                sx={{ mb: 2, mt: 0.5 }}
              />
            </Box>

            <Box>
              <TextField
                variant="outlined"
                label="State"
                fullWidth
                value={data?.state}
                disabled
                sx={{ mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <TextField
                variant="outlined"
                label="City"
                fullWidth
                disabled
                value={data?.city}
                sx={{ mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <TextField
                variant="outlined"
                label="Country"
                fullWidth
                value={data?.country}
                disabled
                sx={{ mb: 2 }}
              />
            </Box>
            <Box>
              <TextField
                variant="outlined"
                label="Pincode"
                fullWidth
                value={data?.POBox}
                disabled
                sx={{ mb: 2, mt: 0.5 }}
              />
            </Box>
          </Card>
          <Card
            sx={{
              border: "1px solid #dddddd",
              width: "500px",
              margin: "auto",
              mt: 3,
              p: 2,
            }}
            elevation={0}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                mb: 2,
                textAlign: "center",
              }}
            >
              Warehouse Details
            </Typography>

            <Box>
              <TextField
                variant="outlined"
                label="Warehouse Person Title"
                fullWidth
                value={data?.warehousePersonTitle}
                onChange={(e) => {
                  setData({
                    ...data,

                    warehousePersonTitle: e.target.value,
                  });
                }}
                sx={{ mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <TextField
                variant="outlined"
                label="Warehouse Person PhoneNumber"
                fullWidth
                value={data?.warehousePersonPhoneNumber}
                onChange={(e) => {
                  setData({
                    ...data,

                    warehousePersonPhoneNumber: e.target.value,
                  });
                }}
                sx={{ mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                label="Warehouse Person Email"
                value={data?.warehousePersonEmail}
                onChange={(e) => {
                  setData({
                    ...data,
                    warehousePersonEmail: e.target.value,
                  });
                }}
                sx={{ mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <TextField
                variant="outlined"
                label="Warehouse Address"
                multiline
                fullWidth
                value={data?.warehouseAddress}
                onChange={(e) => {
                  setData({
                    ...data,

                    warehouseAddress: e.target.value,
                  });
                }}
                sx={{ mb: 2, mt: 0.5 }}
              />
            </Box>
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                label="Warehouse Landmark"
                multiline
                value={data?.warehouselandmark}
                onChange={(e) => {
                  setData({
                    ...data,

                    warehouselandmark: e.target.value,
                  });
                }}
                sx={{ mb: 2, mt: 0.5 }}
              />
            </Box>
          </Card>
          <Card
            sx={{
              border: "1px solid #dddddd",
              width: "500px",
              margin: "auto",
              mt: 3,
              p: 2,
            }}
            elevation={0}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
                mb: 2,
              }}
            >
              Documents - Pvt. Ltd Company
            </Typography>
            <Box>
              <TextField
                type="file"
                label="Company Trade License"
                sx={{ mb: 1, mt: 0.5 }}
                fullWidth
                onChange={(e) => {
                  setCompanyTradeLicenseImage(e.target.files[0]);
                  setData({
                    ...data,
                    CompanyTradeLicense: imageCompanyTradeLicense,
                  });
                  const reader = new FileReader();
                  reader.onload = () => {
                    if (reader.readyState === 2) {
                      setData({
                        ...data,
                        CompanyTradeImagePreview: reader.result,
                      });
                    }
                  };
                  reader.readAsDataURL(e.target.files[0]);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Box sx={{ display: "flex", gap: "40px", alignItems: "center" }}>
                <img
                  src={
                    data.CompanyTradeImagePreview
                      ? data.CompanyTradeImagePreview
                      : data.CompanyTradeLicense
                  }
                  alt={data.CompanyTradeLicense}
                  width="200px"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
                {data.CompanyTradeLicense && (
                  <Button
                    sx={{ color: "red" }}
                    onClick={handleDeleteCompanyTradeLicense}
                  >
                    Delete
                  </Button>
                )}
              </Box>
            </Box>
            {/* <Box>
              <Typography>Brand Authorization Letter</Typography>
              <TextField
                type="file"
                sx={{ mb: 2, mt: 0.5 }}
                fullWidth
                onChange={(e) => {
                  setBrandAuthorizationLetterImage(e.target.files[0]);
                  setData({
                    ...data,
                    BrandAuthorizationLetter: imageBrandAuthorizationLetter,
                  });
                  const reader = new FileReader();
                  reader.onload = () => {
                    if (reader.readyState === 2) {
                      setData({
                        ...data,
                        BrandAuthorizationLetterImagePreview: reader.result,
                      });
                    }
                  };
                  reader.readAsDataURL(e.target.files[0]);
                }}
              />
              <Box sx={{ display: "flex", gap: "40px", alignItems: "center" }}>
                <img
                  src={
                    data.BrandAuthorizationLetterImagePreview
                      ? data.BrandAuthorizationLetterImagePreview
                      : data.BrandAuthorizationLetter
                  }
                  alt={data.BrandAuthorizationLetter}
                  width="200px"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
                {data.BrandAuthorizationLetter && (
                  <Button
                    sx={{ color: "red" }}
                    onClick={handleDeleteBrandAuthorizationLetter}
                  >
                    Delete
                  </Button>
                )}
              </Box>
            </Box> */}

            <Box>
              <TextField
                type="file"
                sx={{ mb: 2, mt: 0.5 }}
                label="VAT Certificate"
                fullWidth
                onChange={(e) => {
                  setVATImage(e.target.files[0]);
                  setData({ ...data, VATCertificate: imageVATCertificate });
                  const reader = new FileReader();
                  reader.onload = () => {
                    if (reader.readyState === 2) {
                      setData({
                        ...data,
                        VATImagePreview: reader.result,
                      });
                    }
                  };
                  reader.readAsDataURL(e.target.files[0]);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Box sx={{ display: "flex", gap: "40px", alignItems: "center" }}>
                <img
                  src={
                    data.VATImagePreview
                      ? data.VATImagePreview
                      : data.VATCertificate
                  }
                  alt={data.VATCertificate}
                  width="200px"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
                {data.VATCertificate && (
                  <Button sx={{ color: "red" }} onClick={handleDeleteVAT}>
                    Delete
                  </Button>
                )}
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              border: "1px solid #dddddd",
              width: "500px",
              margin: "auto",
              mt: 3,
              p: 2,
            }}
            elevation={0}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
                mb: 2,
              }}
            >
              Accounts Details
            </Typography>
            <Box>
              <Box>
                <TextField
                  variant="outlined"
                  label="Beneficiary Name"
                  fullWidth
                  value={data?.sellerBankAccountName}
                  onChange={(e) => {
                    setData({
                      ...data,

                      sellerBankAccountName: e.target.value,
                    });
                  }}
                  sx={{ mb: 2, mt: 0.5 }}
                />
              </Box>
              <Box>
                <TextField
                  variant="outlined"
                  label="Account No"
                  fullWidth
                  value={data?.sellerAccountNumber}
                  onChange={(e) => {
                    setData({
                      ...data,

                      sellerAccountNumber: e.target.value,
                    });
                  }}
                  sx={{ mb: 2, mt: 0.5 }}
                />
              </Box>

              <Box>
                <TextField
                  variant="outlined"
                  label="Seller IBAN"
                  fullWidth
                  value={data?.sellerIBAN}
                  onChange={(e) => {
                    setData({
                      ...data,

                      sellerIBAN: e.target.value,
                    });
                  }}
                  sx={{ mb: 2, mt: 0.5 }}
                />
              </Box>
              <Box>
                <TextField
                  variant="outlined"
                  label="Seller SWIFT Code"
                  fullWidth
                  value={data?.sellerSWIFTCode}
                  onChange={(e) => {
                    setData({
                      ...data,

                      sellerSWIFTCode: e.target.value,
                    });
                  }}
                  sx={{ mb: 2, mt: 0.5 }}
                />
              </Box>
              <Box>
                <TextField
                  variant="outlined"
                  label="Bank Name"
                  fullWidth
                  value={data?.sellerBankName}
                  onChange={(e) => {
                    setData({
                      ...data,

                      sellerBankName: e.target.value,
                    });
                  }}
                  sx={{ mb: 2, mt: 0.5 }}
                />
              </Box>
              <Box>
                <TextField
                  variant="outlined"
                  label="Seller Finance Contact Person Name "
                  fullWidth
                  value={data?.sellerFinanceContactPersonName}
                  onChange={(e) => {
                    setData({
                      ...data,

                      sellerFinanceContactPersonName: e.target.value,
                    });
                  }}
                  sx={{ mb: 2, mt: 0.5 }}
                />
              </Box>
              <Box>
                <TextField
                  variant="outlined"
                  label="Seller Finance Contact EmailID"
                  fullWidth
                  value={data?.SellerFinanceContactEmailID}
                  onChange={(e) => {
                    setData({
                      ...data,

                      SellerFinanceContactEmailID: e.target.value,
                    });
                  }}
                  sx={{ mb: 2, mt: 0.5 }}
                />
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              border: "1px solid #dddddd",
              width: "500px",
              margin: "auto",
              mt: 3,
              p: 2,
              mb: 2,
            }}
            elevation={0}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
                mb: 2,
              }}
            >
              Action Steps
            </Typography>
            <Box>
              <TextField
                variant="outlined"
                placeholder="SLA Confirm to Ready to Ship Order (hours)"
                label="SLA Confirm to Ready to Ship Order (hours)"
                value={data.SLAPeriod}
                onChange={handleSLAChange}
                error={SLAError || (data.SLAPeriod === "" && !SLAError)}
                helperText={
                  SLAError || (data.SLAPeriod === "" && !SLAError)
                    ? "Please enter a valid SLA period"
                    : ""
                }
                fullWidth
                sx={{ mb: 1.5 }}
                style={{ width: "450px" }}
              />
            </Box>
            <Box>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Active Seller
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                >
                  <Box>
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      checked={data.approved === true}
                      onChange={(e) => setData({ ...data, approved: true })}
                      label="Active"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      checked={data.approved === false}
                      onChange={(e) => setData({ ...data, approved: false })}
                      label="In-Active"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>
          </Card>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default SellerEdit;
