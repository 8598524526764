import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import { useState } from "react";

export default function ProductTypeTable() {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = useState(false);

  const [visibility, setVisibility] = React.useState({});
  const [allProducts, setAllProducts] = React.useState([]);

  const navigate = useNavigate();

  const handleAllGetProductTypeValues = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllProductTypesValues"
      );
      if (res) {
        const visibilityObj = {};
        res.data.forEach((row) => {
          visibilityObj[row._id] = !row.active;
        });
        setData(res.data);
        setVisibility(visibilityObj);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVisibility = async (id, value) => {
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/UpdateProductTypeVaues/${id}`,
        {
          active: value,
        }
      );
      if (res) {
        setVisibility((prevState) => ({
          ...prevState,
          [id]: !value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteProductTypeValues = async (id) => {
    try {
      const res = await axios.delete(
        `https://rccore-backend.cstechns.com/api/admin/DeleteProductTypeVaues/${id}`
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Deleted successfully",
          showConfirmButton: true,
        });
        setData(data.filter((item) => item._id !== id));
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };

  const handleGetAllProductsBySellers = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/get-all-Products"
      );
      if (res) {
        // console.log(res.data);
        const ProductType = data.map((item) => item.ProductType);
        // console.log(ProductType);

        const AllProducts = res.data.filter((product) =>
          ProductType.includes(product.ProductType)
        );
        // console.log(AllProducts);
        setAllProducts(AllProducts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    handleAllGetProductTypeValues();
  }, []);

  React.useEffect(() => {
    handleGetAllProductsBySellers();
  }, [data]);

  const uniqueProductTypes = Array.from(
    new Set(allProducts.map((item) => item.ProductType))
  );

  // console.log(uniqueProductTypes);

  return (
    <>
      {!loading ? (
        <TableContainer component={Paper} sx={{ width: "96.5%", ml: 3 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ background: "#dddddd" }}>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>S.No</TableCell>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  Heading
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  Main Attributes
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  Custom Attributes
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="center">{row.ProductType}</TableCell>
                  <TableCell align="center">
                    {row.MainAttributes.join(", ")}
                  </TableCell>
                  <TableCell align="center">
                    {row.CustomAttributes.join(", ")}
                  </TableCell>
                  <TableCell align="center">
                    {/* <IconButton>
                      <EditIcon
                        onClick={() =>
                          navigate(`/edit-product-type-values/${row._id}`)
                        }
                      />
                    </IconButton>
                    <IconButton>
                      <DeleteIcon
                        onClick={() => handleDeleteProductTypeValues(row._id)}
                        style={{ color: "red" }}
                      />
                    </IconButton> */}

                    {visibility[row._id] ? (
                      <>
                        <Tooltip title="Activate">
                          <IconButton
                            onClick={() =>
                              handleVisibility(row._id, visibility[row._id])
                            }
                          >
                            <VisibilityOffIcon style={{ color: "red" }} />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <Tooltip title="De-Activate">
                          <IconButton
                            onClick={() =>
                              handleVisibility(row._id, visibility[row._id])
                            }
                          >
                            <VisibilityIcon style={{ color: "green" }} />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}

                    {uniqueProductTypes.map((item) => {
                      if (item === row.ProductType) {
                        return (
                          <Tooltip title="manage products">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/manage-product-productType/${row._id}`
                                )
                              }
                            >
                              <WysiwygIcon />
                            </IconButton>
                          </Tooltip>
                        );
                      }
                    })}
                    {allProducts.some(
                      (product) => product.ProductType === row.ProductType
                    )
                      ? null
                      : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
