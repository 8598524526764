import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Attribute from "./Attribute";
import CodAndShipping from "./CodAndShipping";
import { Category, Title } from "@mui/icons-material";
import BasicInfo from "./BasicInfo";
import Swal from "sweetalert2";

import { useEffect } from "react";
import { getAllProductTypes, getAllRootCategories } from "../../../axios";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState();
  const [mainCategories, setMainCategories] = React.useState([]);
  const [selectMainCategories, setSelectMainCategories] = React.useState();
  const [SubCategories, setSubCategories] = React.useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = React.useState();
  const [RootName, setRootName] = React.useState({});
  const [productType, setProductType] = React.useState([]);
  const [selected, setSelected] = React.useState();
  const [selectedId, setSelectedId] = React.useState();
  const [expanded, setExpanded] = React.useState({});
  const [checked, setChecked] = React.useState({
    Category: "",
    mainCategory: "",
    subCategory: "",
    ProductType: "",
    PreBook: "No",
    Title: "",
    HSNCode: "",
    Tax: "",
    Description: "",
    SkuCode: "",
    UniqueUrl: "",
    searchTitle: "",
    searchKeywords: [],
    searchDescription: "",
    shipment: "",
    returnPolicy: "",
    returnPolicyDescription: "",
    replacement: "No",
    refund: "No",
    Color: [],
    Size: [],
    CodAvailable: "No",
    ShippingWeight: "",
    Fragile: "No",
    MainImage: "",
    PrdoductDetailsDoc: "",
  });
  const [Loading, setLoading] = React.useState(false);
  const [Loading1, setLoading1] = React.useState(false);

  const isLargeScreen = useMediaQuery("(min-width: 1280px)");
  const isMediumScreen = useMediaQuery("(min-width: 960px)");

  // const [checked, setChecked] = React.useState({});

  // console.log(checked);

  React.useEffect(() => {
    const initialExpanded = {};
    const initialChecked = {};
    data.forEach((item) => {
      initialExpanded[item.name] = false;
      initialChecked[item.name] = false;
      item.subcategories.forEach((mainCategory) => {
        initialExpanded[mainCategory.name] = false;
        initialChecked[mainCategory.name] = false;
        mainCategory.subcategories.forEach((subCategory) => {
          initialExpanded[subCategory.name] = false;
          initialChecked[subCategory.name] = false;
        });
      });
    });
    setExpanded(initialExpanded);
    setChecked(initialChecked);
  }, [data]);

  const handleCategoryToggle = (categoryId) => {
    setExpanded((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };

  const handleCategoryCheck = (event, category) => {
    const isChecked = event.target.checked;
    setChecked((prevState) => ({
      ...prevState,
      [category.name]: isChecked,
    }));
    if (category.subcategories.length > 0) {
      const updatedChecked = category.subcategories.reduce(
        (acc, subCategory) => {
          acc[subCategory.name] = isChecked;
          // Check if the subcategory has any child categories
          if (subCategory.subcategories) {
            subCategory.subcategories.forEach((childCategory) => {
              acc[childCategory.name] = isChecked;
              // Recursively update the checked state of child categories
              if (childCategory.subcategories) {
                handleCategoryCheck(event, childCategory);
              }
            });
          }
          return acc;
        },
        {}
      );
      setChecked((prevState) => ({
        ...prevState,
        ...updatedChecked,
      }));
    }
    if (isChecked && category.parentCategoryId) {
      const parentCategory = data.find(
        (item) => item.name === category.parentCategoryId
      );
      if (
        parentCategory &&
        parentCategory.subcategories.every(
          (subCategory) =>
            checked[subCategory.name] &&
            subCategory.subcategories.every(
              (grandChildCategory) => checked[grandChildCategory.name]
            )
        )
      ) {
        setChecked((prevState) => ({
          ...prevState,
          [parentCategory.name]: true,
        }));
      }
    } else if (!isChecked && category.parentCategoryId) {
      setChecked((prevState) => ({
        ...prevState,
        [category.parentCategoryId]: false,
      }));
    } else if (isChecked && !category.parentCategoryId) {
      category.subcategories.forEach((subCategory) => {
        setChecked((prevState) => ({
          ...prevState,
          [subCategory.name]: true,
        }));
        if (subCategory.subcategories) {
          subCategory.subcategories.forEach((grandChildCategory) => {
            setChecked((prevState) => ({
              ...prevState,
              [grandChildCategory.name]: true,
            }));
          });
        }
      });
    }
  };

  // console.log(productType);
  // console.log(selectedId);

  const handleRootCategory = async () => {
    setLoading(true);
    try {
      const res = await getAllRootCategories();

      if (res.status === 200) {
        setData(res.data[0].subcategories);
        setRootName(res.data[0]);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGetAllProductTypesCategory = async () => {
    setLoading1(true);
    try {
      const res = await getAllProductTypes();

      if (res.status === 200) {
        setProductType(res.data);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    } finally {
      setLoading1(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    Promise.all([handleRootCategory(), handleGetAllProductTypesCategory()]);
  }, []);

  return (
    <Box sx={{ marginTop: "150px" }}>
      <Box>
        <Typography
          sx={{
            fontSize: "23px",
            fontWeight: "bold",
            textAlign: "center",
            mt: 3,
          }}
        >
          Add Single Listing
        </Typography>
        <Typography
          style={{
            border: "2px solid #4792C6",
            width: "60px",
            margin: "auto",
            marginTop: "5px",
          }}
        ></Typography>
      </Box>
      <Box sx={{ width: "100%", mt: 3 }}>
        <Box sx={{ overflowX: isLargeScreen ? "hidden" : "auto" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant={isLargeScreen ? "fullWidth" : "scrollable"}
            scrollButtons={isMediumScreen ? "on" : "on"}
            textColor="#fff"
            TabIndicatorProps={{ style: { display: "none" } }}
            sx={{
              "& .MuiTab-root": {
                border: "1px solid #dddddd",
                marginLeft: "5px",
                whiteSpace: "nowrap",
                minWidth: isMediumScreen ? "120px" : "auto",
                backgroundColor: "#f1f1f1",
                "&.Mui-selected": {
                  backgroundColor: "#262626",
                  color: "white",
                  border: "1px solid #dddddd",
                },
              },
              ml: 3,
              mr: 3,
            }}
          >
            <Tab label="COMMISSION CATEGORY" disableRipple />
            <Tab label="DISPLAY CATEGORY" disableRipple />
            <Tab label="BASIC INFO" disableRipple />
            <Tab label="ATTRIBUTE" disableRipple />
            <Tab label="COD AND SHIPPING" disableRipple />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Card sx={{ border: "1px solid #dddddd", p: 2 }} elevation={0}>
            {!Loading ? (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "5px",
                    mb: 3,
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",

                      minWidth: "300px",
                    }}
                  >
                    Category :{" "}
                  </Typography>

                  <Autocomplete
                    sx={{ width: 350 }}
                    value={checked.Category}
                    onChange={(e, newValue) => {
                      const selectData = data.find(
                        (item) => item.name === newValue
                      );
                      setChecked({ ...checked, Category: newValue });
                      setMainCategories(
                        selectData ? selectData.subcategories : []
                      );
                    }}
                    options={data.map((item) => item.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="--Select Category--"
                        InputLabelProps={{
                          style: { marginLeft: 2 },
                        }}
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "5px",
                    mb: 3,
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "300px",
                    }}
                  >
                    Product Category :{" "}
                  </Typography>
                  <Autocomplete
                    sx={{ width: 350 }}
                    value={checked.mainCategory}
                    onChange={(e, newValue) => {
                      const selectMainCategoryData = mainCategories.find(
                        (item) => item.name === newValue
                      );
                      setChecked({ ...checked, mainCategory: newValue });
                      setSubCategories(
                        selectMainCategoryData
                          ? selectMainCategoryData.subcategories
                          : []
                      );
                    }}
                    options={mainCategories.map((item) => item.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="--Select Product Category--"
                        InputLabelProps={{
                          style: { marginLeft: 2 },
                        }}
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "5px",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "300px",
                    }}
                  >
                    Product Sub Category :{" "}
                  </Typography>
                  <Autocomplete
                    sx={{ width: 350 }}
                    value={checked.subCategory}
                    onChange={(e, newValue) =>
                      setChecked({ ...checked, subCategory: newValue })
                    }
                    options={SubCategories.map((item) => item.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="--Select Product Sub Category--"
                        InputLabelProps={{
                          style: { marginLeft: 2 },
                        }}
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "5px",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "300px",
                    }}
                  >
                    {" "}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => handleChange(0, 1)}
                    disabled={
                      !checked.Category ||
                      !checked.mainCategory ||
                      !checked.subCategory
                    }
                    sx={{ width: "200px" }}
                  >
                    NEXT
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "40vh",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Card>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <Card sx={{ border: "1px solid #dddddd", p: 2 }} elevation={0}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton disabled>
                  <ExpandMoreIcon />
                </IconButton>
                <Checkbox
                  checked={checked[RootName._id]}
                  onChange={(event) => handleCategoryCheck(event, RootName)}
                />
                <Typography>{RootName.name}</Typography>
              </Box>
              {data.map((item) => (
                <Box sx={{ ml: 2 }} key={item._id}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      onClick={() => handleCategoryToggle(item._id)}
                      disabled={item.subcategories.length === 0}
                    >
                      {expanded[item._id] && item.subcategories.length > 0 ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </IconButton>
                    <Checkbox
                      checked={checked[item.name]}
                      onChange={(event) => handleCategoryCheck(event, item)}
                    />
                    <Typography>{item.name}</Typography>
                  </Box>
                  {expanded[item._id] && item.subcategories.length > 0 ? (
                    <Box sx={{ ml: 2 }}>
                      {item.subcategories.map((mainCategory) => (
                        <Box key={mainCategory._id}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton
                              onClick={() =>
                                handleCategoryToggle(mainCategory._id)
                              }
                              disabled={mainCategory.subcategories.length === 0}
                            >
                              {expanded[mainCategory._id] &&
                              mainCategory.subcategories.length > 0 ? (
                                <ExpandMoreIcon />
                              ) : (
                                <ChevronRightIcon />
                              )}
                            </IconButton>
                            <Checkbox
                              checked={checked[mainCategory.name]}
                              onChange={(event) =>
                                handleCategoryCheck(event, mainCategory)
                              }
                            />
                            <Typography>{mainCategory.name}</Typography>
                          </Box>
                          {expanded[mainCategory._id] &&
                          mainCategory.subcategories.length > 0 ? (
                            <Box sx={{ ml: 7 }}>
                              {mainCategory?.subcategories?.map(
                                (subCategory) => (
                                  <Box
                                    key={subCategory._id}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Checkbox
                                      checked={checked?.[subCategory.name]}
                                      onChange={(event) =>
                                        handleCategoryCheck(event, subCategory)
                                      }
                                    />
                                    <Typography>{subCategory.name}</Typography>
                                  </Box>
                                )
                              )}
                            </Box>
                          ) : null}
                        </Box>
                      ))}
                    </Box>
                  ) : null}
                </Box>
              ))}
              <Typography sx={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  sx={{ mt: 2, width: "200px" }}
                  onClick={() => handleChange(0, 2)}
                  disabled={!handleCategoryCheck}
                >
                  NEXT
                </Button>
              </Typography>
            </Card>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <BasicInfo
            checked={checked}
            setChecked={setChecked}
            productType={productType}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            handleChange={handleChange}
            PreBook={checked.PreBook}
            replacement={checked.replacement}
            refund={checked.refund}
            Title={checked.Title}
            Description={checked.Description}
            Loading1={Loading1}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Attribute
            selectedId={selectedId}
            handleChange={handleChange}
            checked={checked}
            setChecked={setChecked}
            Color={checked.Color}
            Size={checked.Size}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <CodAndShipping
            checked={checked}
            setChecked={setChecked}
            CodAvailable={checked.CodAvailable}
            ShippingWeight={checked.ShippingWeight}
            Fragile={checked.Fragile}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}
