import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  UpdateReturnPolicy,
  UpdateReturnReason,
  getPolicyById,
  getReasonById,
} from "../../../axios";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const EditReturnReasons = () => {
  const [data, setdata] = useState({});

  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const handleGetReasonById = async () => {
    setLoading(true);
    try {
      const res = await getReasonById(id);
      if (res) {
        setdata(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateReturnReason = async () => {
    setLoading1(true);
    try {
      const res = await UpdateReturnReason(id, data.Name, data.ReasonFor);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated Successfully",
          showConfirmButton: true,
        });
        navigate("/Return-reasons");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    handleGetReasonById();
  }, []);

  return (
    <div style={{ marginTop: "150px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Manage Return Reasons
        </Typography>
        <Button
          variant="contained"
          onClick={handleUpdateReturnReason}
          disabled={Loading1}
        >
          {Loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
        </Button>
      </Box>
      {!Loading ? (
        <Box sx={{ mt: 3, ml: 3, mr: 3 }}>
          <Box sx={{ border: "1px solid #ddd" }}>
            <Box sx={{ p: 2, background: "#000" }}>
              <Typography sx={{ fontWeight: "bold", color: "#fff" }}>
                Edit Return Reason
              </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Name:
                </Typography>
                <TextField
                  size="small"
                  placeholder="Heading"
                  sx={{ width: "400px" }}
                  value={data.Name}
                  onChange={(e) => setdata({ ...data, Name: e.target.value })}
                />
              </Box>
            </Box>
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Reason For:
                </Typography>
                <Select
                  size="small"
                  sx={{ width: "400px" }}
                  displayEmpty
                  value={data.ReasonFor ?? ""}
                  onChange={(e) =>
                    setdata({ ...data, ReasonFor: e.target.value })
                  }
                >
                  <InputLabel sx={{ ml: 2 }}>---Select---</InputLabel>
                  <MenuItem value="Buyer">Buyer</MenuItem>
                  <MenuItem value="Seller">Seller</MenuItem>
                </Select>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default EditReturnReasons;
