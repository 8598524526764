import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProductCreateTabPanel() {
  const [value, setValue] = React.useState(0);
  const [subcategoryNames, setsubcategoryNames] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [selectedMainCategory, setSelectedMainCategory] = React.useState();
  const [SelectedCategoryId, setSelectedCategoryId] = React.useState();
  const [MainCategoryData, setMainCategoryData] = React.useState();
  const [MainCategoryData1, setMainCategoryData1] = React.useState([]);
  const [subMainCategoryData1, setSubMainCategoryData1] = React.useState();
  const [subMainCategoryData, setSubMainCategoryData] = React.useState();
  const [commission, setCommission] = React.useState();
  const [commissionError, setCommissionError] = React.useState("");
  const [refresh, setRefresh] = React.useState(false);

  const handleCommission = (e) => {
    const commissionValue = e.target.value;
    if (e.target.value.length === 0) {
      setCommissionError("Commission is Required");
    } else if (!commissionValue) {
      setCommissionError("Commission is Required");
    } else {
      setCommissionError("");
    }
    setCommission(commissionValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { id } = useParams();

  const handleRootCategoriesCreation = async () => {
    try {
      const res = await axios.post(
        `https://rccore-backend.cstechns.com/api/admin/CreateSubCategoriesForRoot/${id}`,
        {
          subcategoryNames,
        }
      );
      if (res.data.error) {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Root Categories Created Successfully",
          showConfirmButton: true,
        });
        setRefresh((prev) => !prev);
        changeTab(1);
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
        timer: 1500,
      });
    }
  };

  // handle Main Category Creation
  const handleMainCategoriesCreation = async () => {
    try {
      const res = await axios.post(
        `https://rccore-backend.cstechns.com/api/admin/CreateSubCategoriesForRoot/${SelectedCategoryId}`,
        {
          subcategoryNames,
        }
      );
      if (res.data.error) {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Main Categories Created Successfully",
          showConfirmButton: true,
        });
        setRefresh((prev) => !prev);
        changeTab(2);
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
        timer: 1500,
      });
    }
  };

  // handle SubMain Category
  const handleSubMainCategoriesCreation = async () => {
    try {
      if (commissionError) {
        return;
      }
      const res = await axios.post(
        `https://rccore-backend.cstechns.com/api/admin/CreateSubCategoriesForRoot/${subMainCategoryData}`,
        {
          subcategoryNames,
          commission,
        }
      );
      if (res.data.error) {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Sub Categories Created Successfully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
        timer: 1500,
      });
    }
  };

  const handleRootCategory = async () => {
    try {
      const res = await axios.get(
        "https://rccore-backend.cstechns.com/api/admin/getAllSubCategoriesForRoot"
      );

      if (res) {
        setData(res.data[0].subcategories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    handleRootCategory();
  }, [refresh]);

  const changeTab = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ width: "100%", mt: 3 }} style={{ marginTop: "150px" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label=" Root Category" {...a11yProps(0)} />
          <Tab label="Main Category" {...a11yProps(1)} />
          <Tab label=" Sub Category" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box sx={{ maxWidth: "450px" }}>
          <TextField
            multiline
            label="Add Root Category Name"
            fullWidth
            value={subcategoryNames.join(",")}
            onChange={(e) => {
              const values = e.target.value.split(",");
              setsubcategoryNames(values);
            }}
          />

          <Typography sx={{ mt: 1.5, color: "red" }}>
            Note: Use comma(,) for adding multiple root Categories
          </Typography>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            disabled={!subcategoryNames[0]}
            onClick={() => {
              handleRootCategoriesCreation();
            }}
          >
            SUBMIT
          </Button>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box sx={{ maxWidth: "450px" }}>
          <Select
            displayEmpty
            fullWidth
            value={selectedMainCategory}
            onChange={(e) => {
              const selectedCategory = data.find(
                (item) => item.name === e.target.value
              );
              setSelectedMainCategory(e.target.value);
              setSelectedCategoryId(selectedCategory._id);
            }}
          >
            <InputLabel sx={{ ml: 2 }}>Select Root Category</InputLabel>

            {data.map((item) => (
              <MenuItem value={item.name}>{item.name}</MenuItem>
            ))}
          </Select>

          <TextField
            multiline
            label="Add Main Category Name"
            fullWidth
            value={subcategoryNames.join(",")}
            onChange={(e) => {
              const values = e.target.value.split(",");
              setsubcategoryNames(values);
            }}
            sx={{ mt: 2, mb: 2 }}
          />
          <Typography sx={{ color: "red" }}>
            Note: Use comma(,) for adding multiple Main Categories
          </Typography>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            disabled={!subcategoryNames[0]}
            onClick={handleMainCategoriesCreation}
          >
            SUBMIT
          </Button>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box sx={{ maxWidth: "450px" }}>
          <Select
            displayEmpty
            fullWidth
            value={MainCategoryData}
            onChange={(e) => {
              const selectedCategory = data.find(
                (item) => item.name === e.target.value
              );
              setMainCategoryData(e.target.value);
              setMainCategoryData1(selectedCategory.subcategories);
            }}
          >
            <InputLabel sx={{ ml: 2 }}>Select Root Category</InputLabel>

            {data.map((item) => (
              <MenuItem value={item.name}>{item.name}</MenuItem>
            ))}
          </Select>
          <Select
            displayEmpty
            fullWidth
            sx={{ mt: 2 }}
            value={MainCategoryData}
            onChange={(e) => {
              const selectedCategory = MainCategoryData1.find(
                (item) => item.name === e.target.value
              );
              setMainCategoryData(e.target.value);
              setSubMainCategoryData(selectedCategory._id);
            }}
          >
            <InputLabel sx={{ ml: 2 }}>Select Main Category</InputLabel>

            {MainCategoryData1.map((item) => (
              <MenuItem value={item.name}>{item.name}</MenuItem>
            ))}
          </Select>

          <TextField
            multiline
            label="Add Sub Category Name"
            fullWidth
            value={subcategoryNames.join(",")}
            onChange={(e) => {
              const values = e.target.value.split(",");
              setsubcategoryNames(values);
            }}
            sx={{ mt: 2, mb: 2 }}
          />
          <TextField
            multiline
            label="Add Category Commission"
            fullWidth
            value={commission}
            error={commissionError}
            helperText={commissionError}
            onChange={handleCommission}
            sx={{ mb: 2 }}
          />
          <Typography sx={{ color: "red" }}>
            Note: Use comma(,) for adding multiple Sub Categories
          </Typography>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            disabled={!subcategoryNames[0] || !commission}
            onClick={handleSubMainCategoriesCreation}
          >
            SUBMIT
          </Button>
        </Box>
      </TabPanel>
    </Box>
  );
}
