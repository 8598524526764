import React from "react";
import { Box } from "@mui/system";

import {
  Grid,
  Card,
  IconButton,
  Icon,
  Typography,
  useTheme,
} from "@mui/material";
import { FaShippingFast } from "react-icons/fa";
import { TbTruckReturn } from "react-icons/tb";
import { RiExchangeFill } from "react-icons/ri";
import { MdLocalShipping } from "react-icons/md";
import { H3, Paragraph } from "../Typography";

const StatCard3 = ({ data }) => {
  const ShippedOrders = data?.filter((item) => item.orderStatus === "Shipped");
  const ReadyToShipOrders = data?.filter(
    (item) => item.orderStatus === "Ready To Ship"
  );
  const statList = [
    {
      icon: <RiExchangeFill />,
      amount: 0,
      title: "Replacement Request",
    },

    {
      icon: <TbTruckReturn />,
      amount: 0,
      title: "Return Request",
    },
    {
      icon: <FaShippingFast />,
      amount: ShippedOrders?.length || 0,
      title: "Shipped Orders",
    },
    {
      icon: <MdLocalShipping />,
      amount: ReadyToShipOrders?.length || 0,
      title: "Ready To Ship",
    },
  ];
  const { palette } = useTheme();
  const textMuted = palette.text.secondary;

  return (
    <div>
      <Grid container spacing={4}>
        {statList.map((item, ind) => (
          <Grid key={ind} item lg={3} xs={12}>
            <Card
              elevation={2}
              sx={{ p: "20px", display: "flex", borderRadius: "20px" }}
            >
              <div>
                <IconButton
                  size="small"
                  sx={{
                    padding: "8px",
                    background: "rgba(0, 0, 0, 0.01)",
                  }}
                >
                  <Icon sx={{ color: textMuted }}>{item.icon}</Icon>
                </IconButton>
              </div>
              <Box ml={2}>
                <Typography sx={{ mt: "-4px", fontSize: "32px" }}>
                  {item.amount}
                </Typography>
                <Typography sx={{ color: textMuted }}>{item.title}</Typography>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default StatCard3;
